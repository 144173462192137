import axios from "axios"
import { Promise } from "core-js"

const state = {
  csv_file: null,
  existingUsers: null,
  missingUsers: null,
  sameUserCount: null,
  editableUser: null,
  studentsPhotos: null
}

const getters = {
  users(state) {
    return state.users
  },
  existingUsers(state) {
    return state.existingUsers
  },
  missingUsers(state) {
    return state.missingUsers
  },
  csv_file(state) {
    return state.csv_file
  },
  editableUser(state) {
    return state.editableUser
  },
  studentsPhotos(state) {
    return state.studentsPhotos
  }
}

const mutations = {
  SET_EXISTING_USERS(state, usersCollection) {
    state.existingUsers = usersCollection
  },
  SET_MISSING_USERS(state, usersCollection) {
    state.missingUsers = usersCollection
  },
  SET_CSV_FILE(state, file) {
    state.csv_file = file
  },
  SET_EDITABLE_USER(state, user) {
    state.editableUser = user
  },
  SET_STUDENTS_PHOTOS(state, photos) {
    state.studentsPhotos = photos
  }
}

const actions = {
  importUsers(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admin/users/imports/upload", data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
          context.commit("SET_CSV_FILE", data.csv_file)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  editImport(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/users/imports/${data.id}`, data.data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getImportInformation(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/users/imports`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  importFinal(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/users/imports`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  importPhotos(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admin/users/imports/photos", data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
