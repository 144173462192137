import axios from "axios"

const state = {
  outOfOfficePasses: [],
  editablePass: null
}

const getters = {
  outOfOfficePasses(state) {
    return state.outOfOfficePasses
  },
  editablePass(state) {
    return state.editablePass
  }
}

const mutations = {
  SET_OUTOFOFFICE_PASSES(state, passesCollection) {
    state.outOfOfficePasses = passesCollection
  },
  PUSH_OUTOFOFFICE_PASSES(state, passesCollection) {
    if (state.outOfOfficePasses && state.outOfOfficePasses.length) {
      state.outOfOfficePasses = state.outOfOfficePasses.concat(passesCollection)
    } else {
      state.outOfOfficePasses = passesCollection
    }
  },
  SET_EDITABLE_PASS(state, pass) {
    state.editablePass = pass
  },
  DELETE_OUT_OF_OFFICE_PASS(state, passID) {
    state.outOfOfficePasses = state.outOfOfficePasses.filter(
      (el) => el.id != passID
    )
  },
  UPDATE_OUT_OF_OFFICE_PASS(state, passObj) {
    const newCollection = state.outOfOfficePasses.map((pass) => {
      if (pass.id === passObj.id) {
        return passObj
      }
      return pass
    })
    state.outOfOfficePasses = newCollection
  },
  PUSH_OUT_OF_OFFICE_PASS(state, pass) {
    if (state.outOfOfficePasses.length) {
      state.outOfOfficePasses.push(pass)
    } else {
      state.outOfOfficePasses = [pass]
    }
  }
}

const actions = {
  createOutOfOfficePass(context, pass) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/unavailables`, pass)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getOutOfOfficePasses(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/unavailables`, {
          params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCSVOutOfOfficePassesExport(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/unavailables/export/csv`, {
          params: params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  editOutOfOffice(context, pass) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/unavailables/${pass.id}`, pass.data)
        .then((response) => {
          const passObj = response.data.data
          if (passObj && passObj.created_at) {
            context.commit("UPDATE_OUT_OF_OFFICE_PASS", passObj)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteOutOfOffice(context, passID) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admin/unavailables/${passID}`)
        .then((response) => {
          resolve(response)
          context.commit("DELETE_OUT_OF_OFFICE_PASS", passID)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateOutOfOfficeStatus(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/unavailables/${data.id}/status`, data.params)
        .then((response) => {
          resolve(response)
          const passObj = response.data.data
          if (passObj && passObj.created_at) {
            context.commit("UPDATE_OUT_OF_OFFICE_PASS", passObj)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
