<template>
  <div class="v3-form limit-active-pass-form">
    <div class="row">
      <div>
        <Label>Set limit</Label>
        <InputField
          v-model="state.form.limit"
          placeholder="No limit"
          :disabled="!isFormEditable"
          class="d-block"
          @input="setLimit($event.target.value)"
          :invalid-feedback="errors.limit || state.serverErrors.limit"
        ></InputField>
      </div>
      <div class="mt-4">
        <Label>Apply limit for</Label>
        <div class="mt-2 d-block" style="text-align: left">
          <CustomCheck
            v-model="state.form.student_pass"
            label="STU Passes initiated by the student"
            :disabled="!isFormEditable"
            @change="state.form.student_pass = !state.form.student_pass"
            :invalid-feedback="
              errors.student_pass || state.serverErrors.student_pass
            "
          ></CustomCheck>
        </div>
        <div class="mt-3 d-block" style="text-align: left">
          <CustomCheck
            v-model="state.form.proxy_pass"
            label="TCH (PRX) Passes"
            :disabled="!isFormEditable"
            @change="state.form.proxy_pass = !state.form.proxy_pass"
            :invalid-feedback="
              errors.proxy_pass || state.serverErrors.proxy_pass
            "
          ></CustomCheck>
        </div>
        <div
          v-if="state.form.proxy_pass"
          class="my-3 p-2 d-flex align-items-center justify-content-between"
          style="background-color: #f9fafb; border-radius: 8px"
        >
          <div>
            Allow teachers to override the limit<br />
            <span style="color: #92a7ba"> (they will get an alert first)</span>
          </div>
          <div>
            <CustomSwitch
              :model-value="state.form.teacher_override == '1' ? true : false"
              :disabled="!isFormEditable"
              @update:model-value="changeOverrideTeacher"
              :invalid-feedback="
                errors.teacher_override || state.serverErrors.teacher_override
              "
            />
          </div>
        </div>
        <div class="mt-3 d-block" style="text-align: left">
          <CustomCheck
            v-model="state.form.kiosk_pass"
            label="KSK Passes students initiate at a Kiosk."
            :disabled="!isFormEditable"
            @change="state.form.kiosk_pass = !state.form.kiosk_pass"
            :invalid-feedback="
              errors.kiosk_pass || state.serverErrors.kiosk_pass
            "
          ></CustomCheck>
        </div>
      </div>
      <div>
        <InfoBox class="mt-4"
          >APT passes are not affected by this limit.<br />A "-" allows for
          unlimited passes.</InfoBox
        >
      </div>
      <div>
        <InfoBox
          v-if="state.serverRes"
          class="mt-4"
          :class="{ danger: Object.keys(state.serverErrors).length }"
          :title="state.serverRes.title"
          >{{ state.serverRes.message }}</InfoBox
        >
      </div>
      <div v-if="isFormEditable">
        <LoadingButton
          :is-loading="state.isProcessing"
          @click="saveActivePassLimit()"
          class="mt-4 px-4"
          solid
          rounded
          >Save settings</LoadingButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted, watch } from "vue"
import { useStore } from "vuex"
import { helpers, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import Label from "@/v3components/shared/Form/Label.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"
import CustomCheck from "@/v3components/shared/Form/CustomCheck.vue"
import CustomSwitch from "@/v3components/shared/Form/CustomSwitch.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"

export default {
  name: "LimitActivePassForm",
  components: {
    Label,
    InputField,
    CustomCheck,
    CustomSwitch,
    InfoBox,
    LoadingButton
  },
  emits: ["cancel", "setActivePassLimit", "closeModal"],
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      isProcessing: false,
      serverErrors: {},
      serverRes: null,
      form: {
        limit: "",
        student_pass: false,
        proxy_pass: false,
        teacher_override: "0",
        kiosk_pass: false
      }
    })

    const activePassLimits = computed(
      () => store.getters["activePass/activePassLimits"]
    )

    const userRole = computed(() => store.getters["authentication/getUserRole"])

    const isFormEditable = computed(() => {
      return userRole.value === "admin"
    })

    watch(activePassLimits, (val) => {
      setFormData(val)
    })

    onMounted(() => {
      if (!activePassLimits.value) {
        store.dispatch("activePass/getActivePassLimits").then((response) => {
          const data = response.data.data
          setFormData(data)
        })
      } else {
        setFormData(activePassLimits.value)
      }
    })

    const setFormData = (data) => {
      if (data && Object.keys(data).length) {
        emit("setActivePassLimit", data.limit)
        state.form = {
          limit: data.limit === "" ? null : data.limit,
          student_pass: !!data.student_pass,
          proxy_pass: !!data.proxy_pass,
          kiosk_pass: !!data.kiosk_pass,
          teacher_override: data.teacher_override.toString()
        }
      } else {
        resetFormData()
      }
    }

    const setLimit = (value) => {
      const numbers = /^[0-9]+$/
      if (value) {
        if (value.match(numbers)) {
          state.form.limit = parseInt(value) > 0 ? parseInt(value) : 0
        } else {
          state.form.limit = value === "-" || value === "" ? value : ""
        }
      }
    }

    const changeOverrideTeacher = (enable) => {
      state.form.teacher_override = enable ? "1" : "0"
    }

    const validationMessages = {
      required: "This field is required"
    }
    const validations = {
      form: {
        limit: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isFormValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    const saveActivePassLimit = () => {
      if (isFormEditable.value) {
        state.isProcessing = true
        const data = {
          limit: state.form.limit === "" ? null : state.form.limit,
          student_pass: state.form.student_pass,
          proxy_pass: state.form.proxy_pass,
          teacher_override: state.form.teacher_override,
          kiosk_pass: state.form.kiosk_pass
        }
        store
          .dispatch("activePass/createActivePassLimit", data)
          .then(() => {
            setResponseInfoBox("Success", "Successfully saved!")
            state.isProcessing = false
            state.serverErrors = {}
            setTimeout(() => {
              store.dispatch("activePass/getActivePassLimits")
              emit("closeModal")
              emit("cancel")
              setResponseInfoBox()
            }, 1800)
          })
          .catch((err) => {
            const res = err.response.data
            state.serverErrors = res.errors ? res.errors : {}
            setResponseInfoBox("Error", res.message)
            state.isProcessing = false
          })
      }
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const resetFormData = () => {
      state.form.limit = null
      state.form.student_pass = false
      state.form.proxy_pass = false
      state.form.teacher_override = "0"
      state.form.kiosk_pass = false
    }

    return {
      state,
      isFormEditable,
      setLimit,
      changeOverrideTeacher,
      v$,
      isFormValid,
      errors,
      saveActivePassLimit,
      setResponseInfoBox,
      resetFormData
    }
  }
}
</script>
