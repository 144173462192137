<template>
  <CContainer>
    <CRow class="justify-content-center mt-4">
      <CCol md="12" class="text-start">
        <CRow>
          <CCol class="d-flex">
            <div class="v3-module-list-title">Auto Check-In</div>
            <HelpCenterButton
              classes="ms-2"
              content-key="module_autocheck_in"
            />
          </CCol>
          <CCol class="d-flex flex-row-reverse align-items-center">
            <CustomSwitch
              type="radio"
              :model-value="state.isActive"
              @change="toggleAutoCheckIn(state.isActive)"
            ></CustomSwitch>
          </CCol>
        </CRow>
        <p class="v3-module-list-box mt-4">
          Auto Check-In enables assigning a public PIN to a room so that
          students can Check into room using that public PIN.
        </p>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import HelpCenterButton from "../../HelpCenterButton.vue"
import CustomSwitch from "@/v3components/shared/Form/CustomSwitch.vue"
import { useStore } from "vuex"
import { onMounted, computed, reactive } from "vue"

export default {
  name: "AutoCheckInModuleForm",
  components: { HelpCenterButton, CustomSwitch },
  setup() {
    const store = useStore()

    const state = reactive({
      form: {},
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      },
      isActive: false
    })

    const active = computed(() => {
      const index = store.state.schools.activeSchoolModules.findIndex(
        (module) => module.name === "Auto Check-In"
      )
      return index !== -1
        ? Boolean(store.state.schools.activeSchoolModules[index].pivot.status)
        : false
    })
    onMounted(() => {
      state.isActive = active.value
    })

    const toggleAutoCheckIn = (status) => {
      store
        .dispatch("modules/toggleModule", {
          id: 2,
          moduleStatus: { status: status ? 0 : 1 }
        })
        .then(() => {
          state.isActive = !state.isActive
          store.dispatch("schools/getActiveModules", {
            clearCacheEntry: true
          })
        })
    }

    return {
      state,
      toggleAutoCheckIn
    }
  }
}
</script>
