<template>
  <div v-if="activePass" class="v3kiosk-active-pass">
    <CRow class="justify-content-center">
      <hr class="kiosk-horizontal-line mb-2" />
      <div class="v3kiosk-active-pass-title mb-2">
        <div v-if="activePass.approved_by">End Pass.</div>
      </div>

      <StatusBox
        v-if="activePass.approved_by"
        class="d-flex justify-content-center mb-2"
        title="active"
        title-color="#ffffff"
        background-color="#008566"
        icon="ri-checkbox-circle-line"
        icon-color="#ffffff"
      />

      <CCol class="v3kiosk-active-pass-table mb-2">
        <CRow class="v3kiosk-active-pass-table-header mb-1">
          <CCol class="v3kiosk-active-pass-table-header-info text-start ms-1">
            <div class="created-at">
              {{
                $helpers.transformDate(
                  activePass.created_at,
                  "dddd, DD, MMMM, YYYY"
                )
              }}
            </div>
            <div class="time">
              Time
              {{ $helpers.transformDate(activePass.created_at, "h:mm a") }}
            </div>
          </CCol>
          <!-- This will be used in future -->
          <!-- <CCol
            class="v3kiosk-active-pass-table-header-pass-history align-self-center text-end me-1"
            @click="showPassHistory(passHistories)"
          >
            {{ passHistories.length }} <i class="ri-door-open-line" />
          </CCol> -->
        </CRow>
        <CRow class="v3kiosk-active-pass-table-content">
          <CCol class="v3kiosk-active-pass-table-content-text">
            {{ activePass.from ? activePass.from.name : "" }}
          </CCol>
          <CCol class="v3kiosk-active-pass-table-content-icon">
            <i class="ri-arrow-go-back-line" />
          </CCol>
          <CCol class="v3kiosk-active-pass-table-content-text">
            {{ activePass.to ? activePass.to.name : "" }}
          </CCol>
        </CRow>
        <CRow class="v3kiosk-active-pass-table-bottom mt-1">
          <CCol
            class="v3kiosk-active-pass-table-bottom-type d-flex flex-column justify-content-center align-content-center ms-1"
          >
            <div class="v3kiosk-active-pass-table-bottom-type-box">
              <div class="icon align-self-center">
                <i class="ri-device-line" />
              </div>
              <div class="text">KSK</div>
            </div>
          </CCol>
          <!-- This will be used in future -->
          <!-- <CCol
              class="v3kiosk-active-pass-table-bottom-comments text-end align-self-center me-1"
            >
              <div>
                <span class="comment-count me-1">0</span>
                <i class="ri-chat-new-line" />
              </div>
            </CCol> -->
        </CRow>
      </CCol>

      <div class="d-flex justify-content-center gap-3 mt-2">
        <BaseButton
          class="w-50 d-flex align-items-center justify-content-center"
          rounded
          @click="cancel()"
        >
          Cancel({{ state.timeout }})
        </BaseButton>
        <BaseButton
          class="w-50 d-flex align-items-center justify-content-center"
          rounded
          solid
          @click="endPass()"
        >
          End Pass
        </BaseButton>
      </div>

      <InfoBox v-if="state.reqResponse.message" class="mt-2">{{
        state.reqResponse.message
      }}</InfoBox>

      <div class="d-flex align-items-center justify-content-center mt-5">
        <a
          class="kiosk-terms-and-privacy-text"
          target="_blank"
          href="https://eduspiresolutions.org/terms-of-use/"
          >Terms of Use</a
        >
        <span class="mx-2">|</span>
        <a
          class="kiosk-terms-and-privacy-text"
          target="_blank"
          href="https://eduspiresolutions.org/privacy-policy/"
          >Privacy policy</a
        >
      </div>
    </CRow>
  </div>
  <div class="px-3 py-5" v-else>
    <div class="pass-form-red m-0">
      <span class="flaticon-warning-1" />
    </div>
    <h2>You do not have an active pass.</h2>
    <p class="mb-1">
      This screen will be closed in {{ state.timeout }} seconds.
    </p>
    <p class="mt-0" @click="cancel()">(or click here to close it now)</p>
  </div>
</template>

<script>
import { useStore } from "vuex"
import { reactive, computed, onMounted, onUnmounted } from "vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import StatusBox from "@/v3components/shared/Alerts/StatusBox.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"

export default {
  name: "EndPassForm",
  components: { BaseButton, InfoBox, StatusBox },
  emits: ["tabChanged"],
  setup(args, { emit }) {
    const store = useStore()

    const state = reactive({
      isLoading: false,
      timeout: 20,
      interval: null,
      reqResponse: {
        type: "",
        message: null,
        errors: {}
      }
    })

    const activePass = computed(() => store.getters["kiosks/activePass"])
    const activeKiosk = computed(() => store.getters["kiosks/activeKiosk"])

    const isAutoPass = computed(
      () =>
        activePass.value.flags.auto_pass &&
        activePass.value.flags.auto_pass.is_valid &&
        activePass.value.flags.auto_pass.mode === "start_and_stop"
    )

    onMounted(() => {
      state.interval = setInterval(() => {
        if (state.timeout > 0) {
          state.timeout--
        } else {
          clearInterval(state.interval)
          cancel()
        }
      }, 1000)
    })

    onUnmounted(() => {
      clearInterval(state.interval)
    })

    const endPass = () => {
      resetResponseMessages()
      if (activePass.value) {
        state.isLoading = true
        let data = {
          id: activePass.value.id,
          data: {
            action: 7,
            kiosk_id: activeKiosk.value.id
          }
        }
        let action = "kiosks/updatePass"

        if (isAutoPass.value) {
          action = "kiosks/autoApprovePass"
          data = {
            passId: activePass.value.id,
            action: 2,
            kiosk_id: activeKiosk.value.id
          }
        }
        store
          .dispatch(action, data)
          .then(() => {
            state.isLoading = false
            setSuccessResponse("Your pass has successfully been ended.")
            setTimeout(() => {
              resetResponseMessages()
              cancel()
            }, 1000)
          })
          .catch((err) => {
            state.isLoading = false
            const response = err.response.data
            setErrorResponse(response.message, response.errors)
            state.isLoading = false
          })
      } else {
        cancel()
      }
    }

    const setSuccessResponse = (message) => {
      state.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Something went wrong!",
        errors: errors ? errors : {}
      }
    }

    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const cancel = () => {
      store.dispatch("kiosks/userLogout")
      emit("tabChanged", "mainScreen")
    }

    return {
      state,
      activePass,
      activeKiosk,
      isAutoPass,
      endPass,
      cancel
    }
  }
}
</script>
