<template>
  <div
    data-test-id="pass-block-banners"
    v-if="passBlock && passBlock.items && passBlock.items.length !== 0"
    class="v3-dashboard-banners-item d-flex"
  >
    <div class="text-center">
      <div>
        <h2 class="v3-dashboard-banners-item-title">
          <i class="ri-user-unfollow-line"></i> Pass Block
        </h2>
      </div>
      <Transition name="banner">
        <div v-if="active" class="mt-2">
          The following passes are blocked until
          {{
            helpers.transformDate(passBlock.to_date, "ddd, MM/DD/YYYY h:mm A")
          }}:
          <div class="d-flex justify-content-center mt-1">
            <div v-for="item in passBlock.items" :key="item">
              <CustomCheck
                :name="item"
                :label="item"
                :model-value="true"
                :disabled="true"
                class="me-2"
              />
            </div>
          </div>
          <span class="v3-dashboard-banners-item-reason"
            >Reason: {{ passBlock.reason }}</span
          >
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import helpers from "@/helpers/index.js"
import CustomCheck from "@/v3components/shared/Form/CustomCheck.vue"

export default {
  name: "PassBlockBanner",
  components: {
    CustomCheck
  },
  props: {
    passBlock: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      helpers
    }
  }
}
</script>
