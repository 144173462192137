<template>
  <CDropdownToggle class="p-0 border-0">
    <div class="notification-icon d-flex align-items-center p-0 ms-1 me-1">
      <span class="notification-bell-icon" />
      <div v-if="notSeen.length" class="notification-badge bg-accent-blue-dark">
        {{ notSeen.length > 9 ? "9+" : notSeen.length }}
      </div>
    </div>
  </CDropdownToggle>
</template>

<script>
import { useStore } from "vuex"
import { computed } from "vue"

export default {
  name: "NotificationsToggle",
  setup() {
    const store = useStore()

    const notifications = computed(() => store.getters["sockets/notifications"])

    const notSeen = computed(() => {
      return notifications.value.filter((e) => !e.seen_at)
    })

    return {
      notSeen
    }
  }
}
</script>
