<template>
  <div class="v3-info-box">
    <div v-if="title" class="v3-info-box-title">
      {{ title }}
    </div>
    <div class="v3-info-box-content">
      <p v-if="message" class="v3-info-box-content-message">{{ message }}</p>
      <slot></slot>
      <slot name="slot"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    }
  }
}
</script>
