<template>
  <div>
    <div class="d-flex">
      <h2 class="mb-0 text-center text-black-50 w-100 pt-4">
        Appointment Request Sent for
        {{ $helpers.currTzDate(form.for_date, "ddd, M/D") }}
      </h2>
    </div>

    <div class="d-flex w-50 my-2 mx-auto">
      <button
        data-test-id="stu-create-apt-pass-btn"
        class="btn btn-confirm w-100 m-2 p-3"
        @click="
          () => {
            $router.push('/appointments')
          }
        "
      >
        Apt Menu
      </button>
      <button class="btn btn-cancel w-100 m-2 p-3" @click="closePassSuccess()">
        Close
      </button>
    </div>
  </div>
</template>

<script>
import { inject } from "vue"
export default {
  name: "AptPassSuccesModal",
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const modal = inject("modal")
    const closePassSuccess = () => {
      modal.setAction("closeStudentAptPassSuccessModal")
      setTimeout(() => {
        emit("cancel")
      }, 100)
    }
    return {
      closePassSuccess
    }
  }
}
</script>

<style></style>
