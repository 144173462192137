<template>
  <div>
    <div>
      <Label>Location</Label>
      <LazyLoadSelect
        placeholder="From location for Kiosk"
        type="locations"
        :selected="state.form.room"
        :is-multiple="false"
        :default="defaultKioskValue"
        :prepend-items="[defaultKioskValue]"
        @update:model-value="
          (value) => {
            state.form.room = value
          }
        "
      ></LazyLoadSelect>
    </div>
    <div class="mt-2">
      <InfoBox
        >Choose the FROM location for your Kiosk below. "Me" will display your
        name as the FROM location.</InfoBox
      >
    </div>
    <div class="text-center">
      <LoadingButton class="me-2 px-4" @click="onCancel" rounded
        >Cancel</LoadingButton
      >
      <LoadingButton
        data-test-id="kiosk-create-button"
        :is-loading="state.isProcessing"
        @click="create()"
        class="mt-4 px-4"
        solid
        rounded
        >Create</LoadingButton
      >
    </div>
  </div>
</template>

<script>
import Label from "@/v3components/shared/Form/Label.vue"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import { reactive, computed, onMounted } from "vue"
import { useStore } from "vuex"

export default {
  name: "CreateKioskForm",
  components: {
    LoadingButton,
    Label,
    LazyLoadSelect,
    InfoBox
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      isProcessing: false,
      form: {
        room: null
      },
      serverErrors: {},
      serverRes: null
    })

    const currentUser = computed(() => store.getters["authentication/user"])

    const defaultKioskValue = computed(() => {
      return {
        label: "Me",
        value: {
          id: currentUser.value.id,
          type: "App\\Models\\User"
        }
      }
    })

    onMounted(() => {
      state.form.room = defaultKioskValue.value
    })

    const create = () => {
      state.isProcessing = true
      const kiosk = {
        kiosk_room: state.form.room.value.id,
        kiosk_type_id: state.form.room.value.id,
        kiosk_type: state.form.room.value.type
      }
      store
        .dispatch("kiosks/createKiosk", kiosk)
        .then(() => {
          resetFormData()
          setResponseInfoBox("Success", "Successfully created!")
          state.isProcessing = false
          state.serverErrors = {}
          setTimeout(() => {
            setResponseInfoBox()
            emit("cancel")
          }, 1000)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const onCancel = () => {
      resetFormData()
      emit("cancel")
    }

    const resetFormData = () => {
      state.form.room = null
    }

    return {
      state,
      currentUser,
      defaultKioskValue,
      create,
      onCancel,
      resetFormData
    }
  }
}
</script>
