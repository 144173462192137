<template>
  <button
    class="v3-base-btn"
    :class="{
      'full-rounded': rounded,
      solid,
      'solid-gradient': solidGradient,
      'solid-gray': solidGray
    }"
    :style="
      'background-color:' + customColor + '; border-color:' + customColor + ';'
    "
  >
    <div class="d-flex align-items-center">
      <div v-if="prependIcon" class="btn-icon prepened lh-normal me-2">
        <i class="lh-normal" :class="prependIcon"></i>
      </div>
      <div class="btn-label lh-normal">
        <slot></slot>
      </div>
      <div v-if="appendIcon" class="btn-icon append lh-normal ms-2">
        <i class="lh-normal" :class="appendIcon"></i>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    prependIcon: String,
    appendIcon: String,
    rounded: Boolean,
    solid: Boolean,
    solidGradient: Boolean,
    solidGray: Boolean,
    customColor: String
  }
}
</script>
