<template>
  <div class="v3-add-detention-adult-form">
    <div v-if="availableLadderOptions.length || isUpdateMode">
      <div class="pb-3">
        <Label required>Type</Label>
        <CustomSelect
          :disabled="isUpdateMode"
          :options="isUpdateMode ? ladderOptions : availableLadderOptions"
          :filterable="true"
          :close-on-select="true"
          class="w-full"
          v-model="state.form.ladder"
          :invalid-feedback="errors.ladder"
          :reduce="(item) => item.value"
        ></CustomSelect>
      </div>
      <div class="pb-3">
        <InfoBox class="instructive p-3 text-center">
          <div><i class="ri-list-check mb-2"></i></div>
          <div>
            {{
              isUpdateMode
                ? "Choose a new reset date for  infractions to return to zero."
                : "Choose a reset date for these infractions."
            }}
          </div>
        </InfoBox>
      </div>
      <div class="pb-3">
        <Label required>Reset Date</Label>

        <CustomDatePicker
          :min-date="tommorow"
          :max-date="maxResetDate"
          input-class="w-full"
          v-model="state.form.endDate"
          :invalid-feedback="errors.endDate"
        ></CustomDatePicker>
      </div>
      <div>
        <InfoBox
          v-if="state.serverRes"
          class="mt-4"
          :class="{ danger: Object.keys(state.serverErrors).length }"
          :title="state.serverRes.title"
          >{{ state.serverRes.message }}</InfoBox
        >
      </div>
      <div class="text-center">
        <LoadingButton class="me-2 px-4" @click="emitCancel" rounded
          >Cancel</LoadingButton
        >
        <LoadingButton
          :is-loading="state.isProcessing"
          @click="onSubmit()"
          class="mt-4 px-4"
          solid
          rounded
          >{{ isUpdateMode ? "Update" : "Submit" }}</LoadingButton
        >
      </div>
    </div>
    <div v-else>
      <InfoBox class="p-3 text-center">
        <div>You have already created all Ladders!</div>
      </InfoBox>
      <div class="text-center mt-4">
        <LoadingButton class="me-2 px-4" @click="emitCancel" rounded
          >Cancel</LoadingButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted, inject } from "vue"
import { useStore } from "vuex"
import { helpers, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"
import CustomDatePicker from "@/v3components/shared/Form/CustomDatePicker.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import Label from "@/v3components/shared/Form/Label.vue"
import dateHelpers from "@/helpers/index.js"
import moment from "moment-timezone"

export default {
  name: "CreateConsequencesLaddeForm",
  components: { CustomSelect, CustomDatePicker, LoadingButton, InfoBox, Label },
  props: {
    editableLadder: {
      type: String,
      default: null
    }
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const state = reactive({
      form: {
        ladder: null,
        endDate: null
      },
      isProcessing: false,
      serverErrors: {},
      serverRes: null
    })

    const store = useStore()
    const actionDialog = inject("actionDialog")
    const tommorow = dateHelpers.getTommorowDate()

    //Form
    const validations = {
      form: {
        ladder: {
          required: helpers.withMessage("This field is required", required)
        },
        endDate: {
          minDate: helpers.withMessage(
            "Reset date must be greater than today",
            (value) => {
              return moment(dateHelpers.currTzDate(value)).isSameOrAfter(
                moment(tommorow)
              )
            }
          ),
          required: helpers.withMessage("This field is required", required)
        }
      }
    }
    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })
      return errorObj
    })
    const v$ = useVuelidate(validations.form, state.form)
    const isFormValid = computed(() => !v$.value.$invalid)
    const isUpdateMode = computed(() => Boolean(props.editableLadder))

    const maxResetDate = computed(() => dateHelpers.maxSchoolYearDate())

    const onSubmit = () => {
      if (!isFormValid.value) {
        v$.value.$touch()
        return
      }
      if (isUpdateMode.value) {
        actionDialog.open(submit, {
          args: {},
          props: {
            danger: true,
            title: "This change will affect the student already on the ladder.",
            question: "Are you sure you want to proceed?"
          }
        })
      } else {
        submit()
      }
    }
    const submit = () => {
      const ladders = cloneLaddersObject()
      ladders[state.form.ladder] = {
        reset_dates: [dateHelpers.currTzDate(state.form.endDate, "YYYY-MM-DD")],
        steps: ladders[state.form.ladder]
          ? ladders[state.form.ladder].steps
          : null
      }
      state.isProcessing = true
      store
        .dispatch("consequencesLadder/createLadder", ladders)
        .then(() => {
          setResponseInfoBox("Success", "Successfully created!")
          state.isProcessing = false
          state.serverErrors = {}
          setTimeout(() => {
            setResponseInfoBox()
            store.dispatch("consequencesLadder/getAllLadders")
            emit("cancel")
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors || {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }
    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    //ladders
    const ladders = computed(() => store.getters["consequencesLadder/ladders"])
    const ladderOptions = [
      {
        label: "LTS Ladder",
        value: "LTS"
      },
      {
        label: "LTC Ladder",
        value: "LTC"
      },
      {
        label: "Combined (LTS & LTC)",
        value: "both"
      }
    ]
    const ladderTypes = computed(
      () => store.getters["consequencesLadder/ladderTypes"]
    )
    const availableLadderOptions = computed(() => {
      return ladderTypes.value && ladderTypes.value.length
        ? ladderTypes.value.includes("both")
          ? []
          : ladderOptions.filter(
              (el) =>
                el.value !== "both" && !ladderTypes.value.includes(el.value)
            )
        : ladderOptions
    })
    const cloneLaddersObject = () => {
      return JSON.parse(JSON.stringify(ladders.value))
    }
    const setEditableLadder = () => {
      if (props.editableLadder) {
        state.form.ladder = props.editableLadder
        state.form.endDate = ladders.value[props.editableLadder].reset_dates[0]
      }
    }

    onMounted(() => {
      setEditableLadder()
    })

    const emitCancel = () => {
      emit("cancel")
    }

    return {
      state,
      onSubmit,
      emitCancel,
      errors,
      ladderOptions,
      isUpdateMode,
      availableLadderOptions,
      tommorow,
      maxResetDate
    }
  }
}
</script>
