<template>
  <CContainer>
    <CRow class="justify-content-center">
      <CCol md="12" class="text-start px-0">
        <CRow>
          <CCol>
            <Label required> Limitation Type </Label>
            <CustomSelect
              v-model="state.form.limit_type"
              :options="state.limitationsTypes"
              :clearable="false"
              :close-on-select="true"
              label="label"
              :invalid-feedback="errors.limit_type"
              :reduce="(item) => item.value"
            >
            </CustomSelect>
          </CCol>
        </CRow>
        <CRow class="mt-2">
          <CCol>
            <Label required> Start Date </Label>
            <CustomDatePicker
              v-model="state.form.start_date"
              :masks="{ L: 'MM/DD/YYYY' }"
              :invalid-feedback="errors.start_date"
              class="cs-date-picker"
              :popover="{ visibility: 'click' }"
              :min-date="today"
              :max-date="maxDate"
              @update:model-value="onStartDateChange"
            >
            </CustomDatePicker>
          </CCol>
          <CCol>
            <Label required> End Date </Label>
            <CustomDatePicker
              v-model="state.form.end_date"
              :masks="{ L: 'MM/DD/YYYY' }"
              :invalid-feedback="errors.end_date"
              class="cs-date-picker"
              :popover="{ visibility: 'click' }"
              :min-date="state.form.start_date ? state.form.start_date : today"
              :max-date="maxDate"
            >
            </CustomDatePicker>
          </CCol>
        </CRow>

        <CRow class="mt-2">
          <CCol>
            <Label required> Location </Label>

            <LazyLoadSelect
              :selected="state.form.room"
              type="locations_availability"
              placeholder=" "
              :invalid:feedback="errors.room"
              @update:model-value="
                (value) => {
                  selectLocation(value)
                }
              "
            >
            </LazyLoadSelect>
          </CCol>
        </CRow>

        <CRow class="mt-2">
          <CCol>
            <Label required> Location availability for </Label>
            <CustomSelect
              v-model="state.form.limit_for"
              :options="state.locationAvailabilityOptions"
              :clearable="false"
              :close-on-select="true"
              label="label"
              :reduce="(item) => item.value"
              :invalid-feedback="errors.limit_for"
              @update:model-value="resetOptinalInputs"
            >
            </CustomSelect>
          </CCol>

          <CRow class="mt-2" v-if="state.form.limit_for === 'student'"
            ><CCol>
              <SelectList
                select-placeholder="Select students"
                select-type="student"
                counter-icon="ri-group-line"
                :invalid-feedback="state.selected_student_ids"
                @update-list-data="(value) => updateData(value)"
              />
            </CCol>
          </CRow>

          <CRow
            class="mt-2"
            v-if="state.gradeYears && state.form.limit_for === 'gradeyear'"
          >
            <CCol>
              <CustomSelect
                :filterable="true"
                :deselect-from-dropdown="true"
                :invalid-feedback="state.grade_years"
                type="textarea"
                :multiple="true"
                v-model="state.form.grade_years"
                :options="state.gradeYears"
                placeholder="Select grad years"
              ></CustomSelect>
            </CCol>
          </CRow>

          <CRow class="mt-2" v-if="state.form.limit_for === 'csv'">
            <CCol>
              <FileUpload
                :uploaded-file="state.form.csv_file"
                :download-example-file-path="state.downloadExamplePath"
                @update:model-value="onFileUpload"
                @remove-file="removeFile"
                :invalid-feedback="
                  errors.csv_file || state.reqResponse.errors['csv_file']
                "
              >
              </FileUpload>
            </CCol>
          </CRow>

          <CRow class="mt-2">
            <CCol>
              <CAlert
                v-if="state.reqResponse.message"
                class="mt-4 w-100"
                :color="state.reqResponse.type"
                :show="!!state.reqResponse.message"
              >
                {{ state.reqResponse.message }}
              </CAlert>
            </CCol>
          </CRow>

          <div class="d-flex align-items-center justify-content-center mt-4 text-center">
            <LoadingButton
              v-if="isInView"
              class="me-3 px-4"
              @click="cancelEdit"
              rounded
              >Cancel</LoadingButton
            >
            <LoadingButton @click="submit()" class="me-3 px-4" solid rounded
              >Create
            </LoadingButton>
            <router-link
              v-if="!isInView"
              class="text-graident-blue"
              to="/limits/limit-location-availability"
            >
              <div class="d-flex align-items-center" @click="goToFullMenu()">
                <img
                  width="18"
                  class="m-0 me-2"
                  src="@/assets/images/icons/gradient-link.png"
                />
                <span class="text-graident-blue">Go to full menu</span>
              </div>
            </router-link>
          </div>
        </CRow>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import moment from "moment-timezone"
import helpersJS from "../../helpers/index.js"
import { helpers, required, requiredIf } from "@vuelidate/validators"
import { useStore } from "vuex"
import { reactive, computed, onMounted, inject } from "vue"
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"
import Label from "@/v3components/shared/Form/Label.vue"
import CustomDatePicker from "@/v3components/shared/Form/CustomDatePicker.vue"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import FileUpload from "@/v3components/shared/Form/FileUpload.vue"
import SelectList from "@/v3components/shared/Form/SelectList.vue"

export default {
  name: "CreateLimitLocationAvailabilityForm",
  components: {
    CustomSelect,
    Label,
    CustomDatePicker,
    LazyLoadSelect,
    LoadingButton,
    FileUpload,
    SelectList
  },
  props: ["isInView"],
  emits: ["createLocationRestriction", "closeModal", "cancel"],
  setup(props, { emit }) {
    const store = useStore()
    const modal = inject("modal")

    const state = reactive({
      downloadExamplePath: "/csv-samples/passLimitSample.csv",
      isLoading: false,
      locationAvailabilityOptions: [
        { value: "student", label: "Selected Students" },
        { value: "gradeyear", label: "Grad Year Students" },
        { value: "csv", label: "CSV Student List" }
      ],
      limitationsTypes: [
        {
          value: "access_denied",
          label: "Access Denied",
          icon: "flaticon-forbidden text-red"
        },
        {
          value: "members_only",
          label: "Members Only",
          icon: "flaticon-profile-user text-accent-blue-dark"
        }
      ],
      form: {
        limit_for: null,
        room: null,
        csv_file: null,
        grade_years: null,
        selected_student_ids: null,
        start_date: null,
        end_date: null,
        limit_type: null
      },
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      },
      gradeYears: [],
      isLimitationTypeDisabled: false,
      forceClearCache: false
    })

    const validationMessages = {
      required: "This field is required"
    }

    const validations = {
      form: {
        room: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        limit_for: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        start_date: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        end_date: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        limit_type: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        selected_student_ids: {
          required: helpers.withMessage(
            validationMessages.required,
            requiredIf(() => {
              return state.form.limit_for && state.form.limit_for === "student"
            })
          )
        },
        grade_years: {
          required: helpers.withMessage(
            validationMessages.required,
            requiredIf(() => {
              return (
                state.form.limit_for && state.form.limit_for === "gradeyear"
              )
            })
          )
        },
        csv_file: {
          required: helpers.withMessage(
            validationMessages.required,
            requiredIf(() => {
              return state.form.limit_for && state.form.limit_for === "csv"
            })
          )
        }
      }
    }

    const v$ = useVuelidate(validations.form, state.form)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    onMounted(() => {
      getGradeYears()
    })

    const today = moment().format("MMM DD YYYY")

    const maxDate = computed(() => {
      return helpersJS.maxSchoolYearDate()
    })

    const submit = () => {
      resetResponseMessages()
      if (isValid.value) {
        let formData = null
        const inputsData = {
          room_id: state.form.room ? state.form.room.value.id : state.form.room,
          limit_for: state.form.limit_for,
          csv_file: state.form.csv_file,
          from_date: helpersJS.currTzDate(state.form.start_date),
          to_date: helpersJS.currTzDate(state.form.end_date),
          type: state.form.limit_type
        }
        if (state.form.selected_student_ids) {
          inputsData.selected_student_ids = JSON.stringify(
            state.form.selected_student_ids.map((el) => el.value.id)
          )
        }
        if (state.form.grade_years) {
          inputsData.grade_years = JSON.stringify(state.form.grade_years)
        }
        if (state.form.limit_for === "csv") {
          formData = new FormData()
          for (const key in inputsData) {
            if (Object.hasOwnProperty.call(inputsData, key)) {
              const element = inputsData[key]
              formData.append(key, element)
            }
          }
        }
        state.isLoading = true
        store
          .dispatch(
            "rooms/createRoomRestriction",
            formData ? formData : inputsData
          )
          .then(() => {
            modal.setAction("createLocationLimit")
            resetForm()
            setSuccessResponse("Successfully created.")
            state.isLoading = false
            setTimeout(() => {
              emit("createLocationRestriction")
              resetResponseMessages()
              emit("cancel")
              emit("closeModal")
            }, 1200)
            state.forceClearCache = true
          })
          .catch((err) => {
            if (err && err.response && err.response.data) {
              const response = err.response.data
              setErrorResponse(response.message, response.errors)
            }
            state.isLoading = false
          })
      } else {
        v$.value.$touch()
      }
    }

    const getGradeYears = () => {
      store.dispatch("passBlocks/getGradeYears").then((result) => {
        // let startYear = result.data.data.startYear;
        // let endYear = result.data.data.endYear;
        // state.gradeYears = [...Array(endYear - startYear + 1).keys()]. map(i => i + startYear);
        state.gradeYears = result.data.data
      })
    }

    const resetOptinalInputs = () => {
      state.form.csv_file = null
      state.form.grade_years = null
      state.form.selected_student_ids = null
    }

    const onFileUpload = (event) => {
      const files = event.target.files
      if (files && files.length) {
        state.form.csv_file = files[0]
      }
      event.target.value = ""
    }

    const onStartDateChange = () => {
      state.form.end_date =
        state.form.end_date && state.form.end_date < state.form.start_date
          ? state.form.start_date
          : state.form.end_date
    }

    const selectLocation = (currentLocation) => {
      resetLocationsAndLimitType()
      state.form.room = currentLocation
      if (currentLocation.restriction != null) {
        state.form.limit_type = currentLocation.restriction
        state.isLimitationTypeDisabled = true
      }
    }

    const resetLocationsAndLimitType = () => {
      state.isLimitationTypeDisabled = false
    }

    const resetForm = () => {
      v$.value.$reset()
      state.form = {
        limit_for: null,
        room: null,
        csv_file: null,
        grade_years: null,
        selected_student_ids: null,
        start_date: null,
        end_date: null,
        limit_type: null
      }
    }

    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const setSuccessResponse = (message) => {
      state.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Someting went wrong!",
        errors: errors ? errors : {}
      }
    }

    const refreshForm = () => {
      resetForm()
      resetResponseMessages()
      emit("closeModal")
      emit("cancel")
    }

    const goToFullMenu = () => {
      emit("closeModal")
    }

    const cancelEdit = () => {
      resetForm()
      resetResponseMessages()
      emit("closeModal")
      emit("cancel")
    }

    const removeFile = () => {
      state.form.csv_file = null
    }

    const updateData = (value) => {
      state.form.selected_student_ids = value
    }

    return {
      state,
      errors,
      today,
      maxDate,
      submit,
      getGradeYears,
      resetOptinalInputs,
      onFileUpload,
      onStartDateChange,
      selectLocation,
      resetLocationsAndLimitType,
      resetForm,
      resetResponseMessages,
      setSuccessResponse,
      setErrorResponse,
      refreshForm,
      goToFullMenu,
      cancelEdit,
      removeFile,
      updateData
    }
  }
}
</script>
