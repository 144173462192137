<template>
  <PeriodsTable />
</template>
<script>
import PeriodsTable from "../v3components/Datatables/PeriodsTable.vue"
export default {
  name: "Periods",
  components: { PeriodsTable }
}
</script>
