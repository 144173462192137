<template>
  <div class="dashboard-counters" v-if="!basic">
    <div class="d-flex justify-content-center">
      <div class="dashboard-counters-main-box">
        <div class="label">Flagged passes</div>
        <div class="dashboard-counters-main-box-passes">
          <span>{{ flaggedPassesLength }}</span>
        </div>
      </div>
      <div class="dashboard-counters-secondary-box">
        <i class="ri-flag-2-line"></i>
      </div>
    </div>
  </div>
  <div class="dashboard-basic-counters" v-else>
    <div class="dashboard-basic-counters-box">
      <div class="dashboard-basic-counters-box-passes">
        <span class="total-passes">{{ flaggedPassesLength }}</span>
      </div>
      <div class="gray-label ms-1">Flagged passes</div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex"
import { computed } from "vue"
export default {
  name: "FlaggedPasses",
  props: {
    basic: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore()

    const flaggedPassesLength = computed(
      () => store.getters["dashboardCounters/flaggedPassesLength"]
    )

    return { flaggedPassesLength }
  }
}
</script>

<style lang="scss" scoped></style>
