import { apiV7nocache } from "../axiousWithCache"

const state = {
  detentions: [],
  editableDetention: null,
  detentionsCalendarData: null,
  detentionsByDate: null,
  detentionsByDateBulk: null
}

const getters = {
  detentions(state) {
    return state.detentions
  },
  editableDetention(state) {
    return state.editableDetention
  },

  detentionsCalendarData(state) {
    if (state.detentionsByDate) {
      return Object.keys(state.detentionsByDate).flatMap(function (key) {
        return state.detentionsByDate[key].map((detention) => {
          return {
            ...detention.detention,
            date: key,
            backgroundColor: detention.detention.color,
            title: detention.tardy_appointment_detentions_count
              ? detention.tardy_appointment_detentions_count
              : 0,
            date_id: detention.date_id
          }
        })
      })
    } else {
      return []
    }
  },
  detentionsCalendarDataBulkForm(state) {
    if (state.detentionsByDateBulk) {
      return Object.keys(state.detentionsByDateBulk).flatMap(function (key) {
        return state.detentionsByDateBulk[key].map((detention) => {
          return {
            ...detention.detention,
            date: key,
            backgroundColor: detention.detention.color,
            title: detention.tardy_appointment_detentions_count
              ? detention.tardy_appointment_detentions_count
              : 0,
            date_id: detention.date_id
          }
        })
      })
    } else {
      return []
    }
  },
  detentionsByUsers(state) {
    return state.detentionsByUsers
  },
  detentionsByDate(state) {
    return state.detentionsByDate
  }
}

const mutations = {
  SET_DETENTIONS(state, detentions) {
    state.detentions = detentions
  },
  DELETE_DETENTION(state, id) {
    state.detentions = state.detentions.filter((el) => el.id != id)
  },
  SET_EDITABLE_DETENTION(state, detention) {
    state.editableDetention = detention
  },
  SET_DETENTIONS_CALENDAR_BY_DATE(state, detentions) {
    state.detentionsByDate = detentions.dates
  },
  SET_DETENTIONS_CALENDAR_BY_DATE_BULK(state, detentions) {
    state.detentionsByDateBulk = detentions.dates
  },
  SET_DETENTIONS_CALENDAR_USERS(state, detentions) {
    state.detentionsByUsers = detentions
  },
  ADD_DETENTION_BY_DAYE(state, detention) {
    Object.keys(detention).forEach((key) => {
      if (key in state.detentionsByDate) {
        state.detentionsByDate[key].push(detention[key][0])
      } else {
        state.detentionsByDate = {
          [key]: [...detention[key]],
          ...state.detentionsByDate
        }
      }
    })
  },
  DELETE_SCHEDULED_DETENTION(state, detention) {
    Object.keys(detention).forEach((key) => {
      if (key in state.detentionsByDate) {
        const index = state.detentionsByDate[key].findIndex((det) => {
          return det.detention.id == detention[key][0].detention.id
        })
        if (index > -1) {
          state.detentionsByDate[key].splice(index, 1)
        }
      }
    })
  }
}

const actions = {
  scheduleDetention(context, data) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .post(`/admin/tardy/detentions/calendar`, data)
        .then((response) => {
          context.commit("ADD_DETENTION_BY_DAYE", response.data.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createDetention(context, detention) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .post(`/admin/tardy/detentions`, detention)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getDetentions(context) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get("/admin/tardy/detentions")
        .then((response) => {
          const data = response.data
          context.commit("SET_DETENTIONS", data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateDetentions(context, detention) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .put(`/admin/tardy/detentions/${detention.id}`, detention)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteDetention(context, id) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .delete(`/admin/tardy/detentions/${id}`)
        .then((response) => {
          const data = response.data
          if (data.data) {
            context.commit("DELETE_DETENTION", data.data.id)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteScheduledDetention(context, id) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .delete(`/admin/tardy/detentions/calendar/${id}`)
        .then((response) => {
          const data = response.data
          if (data.data) {
            context.commit("DELETE_SCHEDULED_DETENTION", data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateScheduledDetention(context, data) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .put(`/admin/tardy/detentions/calendar/${data.dateId}`, data.data)
        .then((response) => {
          const data = response.data.data
          context.commit("DELETE_SCHEDULED_DETENTION", data.old_val)
          context.commit("ADD_DETENTION_BY_DAYE", data.new_val)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getDetentionsCalendarData(context, params) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get(
          `/admin/tardy/detentions/calendar?range[]=${params.startDate}&range[]=${params.endDate}`
        )
        .then((response) => {
          const data = response.data
          context.commit("SET_DETENTIONS_CALENDAR_BY_DATE", data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getDetentionsCalendarDataBulkForm(context, params) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get(
          `/admin/tardy/detentions/calendar?range[]=${params.startDate}&range[]=${params.endDate}`
        )
        .then((response) => {
          const data = response.data
          context.commit("SET_DETENTIONS_CALENDAR_BY_DATE_BULK", data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getDetentionsCalendarUsers(context, params) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get(
          `/admin/tardy/detentions/calendar/users?date=${params.date}&detention=${params.id}`
        )
        .then((response) => {
          const data = response.data
          context.commit("SET_DETENTIONS_CALENDAR_USERS", data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getCSVdetentionExport(context, params) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get(
          `/admin/tardy/detentions/calendar/users/exports/csv?date=${params.date}&detention=${params.id}`
        )
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
