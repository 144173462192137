import axios from "axios"
import { Promise } from "core-js"
import { apiV5nocache } from "./axiousWithCache"
const state = {
  periods: [],
  editablePeriod: null
}

const getters = {
  periods(state) {
    return state.periods
  },
  editablePeriod(state) {
    return state.editablePeriod
  },
  activePeriods(state) {
    if (state.periods) {
      return state.periods.filter((period) => period.status)
    }
  },
  formListStudents(state, getters) {
    if (state.periods) {
      return getters.activePeriods.map((periods) => {
        return { value: periods.id, label: periods.name }
      })
    }
  }
}

const mutations = {
  SET_PERIODS(state, periodsCollection) {
    state.periods = periodsCollection
  },
  SET_EDITABLE_PERIOD(state, period) {
    state.editablePeriod = period
  },
  SET_NEW_PERIODS_ORDER(state, periods) {
    state.periods = state.periods
      .map((period) => {
        const newPeriod = periods.find((p) => p.id === period.id)
        if (newPeriod) {
          period.order = newPeriod.position
        }
        return period
      })
      .sort((a, b) => a.order - b.order)
  }
}

const actions = {
  getPeriods(context, config) {
    return new Promise((resolve, reject) => {
      apiV5nocache
        .get("/admin/periods", config)
        .then((response) => {
          const data = response.data.data
          if (data && data.length) {
            context.commit("SET_PERIODS", data)
            resolve(data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createPeriod(context, period) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admin/periods", period)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updatePeriod(context, period) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/periods/${period.periodId}`, period.data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updatePosition(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/periods/order`, { periods: data })
        .then((response) => {
          const data = response.data.data
          if (data && data.length) {
            context.commit("SET_NEW_PERIODS_ORDER", data)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deletePeriod(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admin/periods/${data.periodId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
