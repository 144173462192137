<template>
  <div v-show="isProcessing" class="loader-container" :class="classes">
    <CSpinner color="primary" id="cloader" />
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    isProcessing: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: ""
    }
  }
}
</script>
<style>
#cloader {
  width: 4rem;
  height: 4rem;
}
</style>
