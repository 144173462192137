<template>
  <div v-if="comments" class="ps__child--consume">
    <div class="comments-list-container no-target">
      <div class="comments-list no-target position-relative">
        <div v-for="(comment, index) in comments" :key="index" class="comment">
          <b v-if="comment.user && comment.user !== 0">{{
            comment.user.first_name + " " + comment.user.last_name
          }}</b>
          <b v-else>System</b
          ><b
            >{{
              " [" +
              helpersJS.transformDate(comment.created_at, "M/D/yyyy h:mm A") +
              "]"
            }}
            :</b
          >
          {{ comment.comment }}
        </div>
        <Loader :is-processing="state.isLoading" classes="position-absolute" />
        <div v-if="comments.length == 0">No comments</div>
      </div>
      <div class="post-comment mt-3">
        <div class="form-group no-target">
          <textarea
            maxlength="250"
            @blur="resumeFocus"
            @input="typing()"
            v-model="state.form.comment"
            class="form-control no-target"
            placeholder="Type your comment here ..."
            :class="{
              'is-invalid': errors.comment
            }"
            rows="2"
          ></textarea>
          <div class="text-end mt-1">250 characters max</div>
          <div v-if="errors.comment" class="invalid-feedback">
            {{ errors.comment }}
          </div>
        </div>
        <CAlert
          v-if="state.reqResponse.message"
          class="mt-4"
          :color="state.reqResponse.type"
          :show="!!state.reqResponse.message"
        >
          {{ state.reqResponse.message }}
        </CAlert>
        <div class="text-center">
          <button
            :disabled="state.isLoading"
            type="button"
            class="btn btn bg-gradient-blue text-white py-3 my-3"
            @click="createComment(state.form.id)"
          >
            Comment
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, computed, onMounted } from "vue"
import { useStore } from "vuex"
import { helpers, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import Loader from "@/v3components/shared/Loader/Loader.vue"
import helpersJS from "@/helpers/index.js"

export default {
  name: "CommentsComponentOld",
  components: { Loader },
  props: ["toID", "commentableType", "silent"],
  emits: ["commentCreated"],
  setup(props, { emit }) {
    const store = useStore()

    const state = reactive({
      form: {
        comment: null,
        id: null
      },
      isLoading: false,
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      }
    })

    const comments = computed(() => store.getters["comments/comments"])

    const validationMessages = {
      required: "The comment is required!"
    }

    const validations = {
      form: {
        comment: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isFormValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    onMounted(() => {
      if (!props.silent) {
        getPassComments()
      }
    })

    const typing = () => {
      if (state.form.comment !== null) {
        resetResponseMessages()
      }
    }

    const resumeFocus = (e) => {
      setTimeout(() => {
        if (state.form.comment) {
          e.target.focus()
        }
      }, 100)
    }

    const createComment = (id) => {
      state.isLoading = true
      resetResponseMessages()
      if (isFormValid.value) {
        if (state.form.comment) {
          if (props.toID && props.commentableType) {
            const comment = {
              toID: id,
              data: {
                comment: state.form.comment,
                commentable_type: props.commentableType,
                permission: false
              }
            }
            store
              .dispatch("comments/createComment", comment)
              .then((response) => {
                const data = response.data.data
                if (data) {
                  setSuccessResponse("Successfully created!")
                  state.isLoading = false
                  state.form.comment = null
                  v$.value.$reset()
                  setTimeout(() => {
                    resetResponseMessages()
                    emit("commentCreated", {
                      id: id,
                      comment: data.comment
                    })
                  }, 1000)
                }
              })
              .catch((err) => {
                state.isLoading = false
                v$.value.$reset()
                const response = err.response.data
                setErrorResponse(response.message, response.errors)
              })
          }
        } else {
          state.reqResponse = {
            type: "danger",
            errors: {
              comment: ["The comments field is required!"]
            }
          }
          state.isLoading = false
        }
      } else {
        v$.value.$touch()
        state.reqResponse = {
          type: "danger",
          errors: {
            comment: ["The comments field is required!"]
          }
        }
        state.isLoading = false
      }
    }

    const getPassComments = () => {
      state.isLoading = true
      state.form.id = props.toID
      const getCommentData = {
        passId: props.toID,
        commentableType: props.commentableType
      }
      store.dispatch("comments/getPassComments", getCommentData).finally(() => {
        state.isLoading = false
      })
    }

    const setSuccessResponse = (message) => {
      state.reqResponse = {
        type: "success",
        message: message,
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Someting went wrong!",
        errors: errors ? errors : {}
      }
    }

    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const resetForm = () => {
      state.form = {
        comment: null
      }
      resetResponseMessages()
      v$.value.$reset()
    }

    return {
      helpersJS,
      state,
      comments,
      v$,
      errors,
      createComment,
      resetResponseMessages,
      setSuccessResponse,
      setErrorResponse,
      getPassComments,
      typing,
      resumeFocus,
      resetForm
    }
  }
}
</script>

<style scoped lang="scss">
textarea::-webkit-scrollbar {
  display: none;
}

.invalid-feedback {
  font-weight: bold;
  font-size: 12px;
}
</style>
