<template>
  <CRow class="justify-content-center p-5">
    <CCol md="9 d-flex flex-column">
      <h2 class="text-center mb-3 mt-0">Cancel Pass</h2>
      <div class="text-black-50 pb-5 text-center font-2xl">
        Do you really want to cancel the pass?
      </div>
      <div class="d-flex flex-wrap w-100 justify-content-center">
        <button
          data-test-id="student-active-pass-cancel"
          class="btn btn-delete m-2 h-75"
          @click="cancelPass()"
        >
          <span class="flaticon-cancel me-2" /> CANCEL
        </button>
        <button
          class="d-flex align-items-center h-75 btn btn-cencel m-2 border-1 border-secondary"
          @click="onCancel()"
        >
          <span class="flaticon-forbidden me-1 text-black" />
          CLOSE
        </button>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import { useStore } from "vuex"
export default {
  name: "CancelPassModal",
  props: {
    passID: {
      type: Number,
      required: true
    }
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const store = useStore()

    const cancelPass = () => {
      store.dispatch("passes/cancelActivePass", props.passID)
      onCancel()
    }

    const onCancel = () => {
      emit("cancel")
    }
    return {
      cancelPass,
      onCancel
    }
  }
}
</script>

<style></style>
