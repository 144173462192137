<template>
  <div>
    <CContainer>
      <CRow class="justify-content-center mt-4">
        <CCol md="12 text-center">
          <TardyCsvUploadTable> </TardyCsvUploadTable>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import TardyCsvUploadTable from "@/v3components/Datatables/TardyCsvUploadTable.vue"

export default {
  name: "TardyCsvUpload",
  components: { TardyCsvUploadTable }
}
</script>
