<template>
  <div class="stat inverse">
    Flagged Passes <span>{{ flaggedPassesLength }}</span>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"

export default {
  name: "FlaggedPassesOld",
  setup() {
    const store = useStore()

    const flaggedPassesLength = computed(
      () => store.getters["dashboardCounters/flaggedPassesLength"]
    )

    return {
      flaggedPassesLength
    }
  }
}
</script>
