<template>
  <VisitorWrapper>
    <CContainer>
      <!-- SERVER ERROR -->
      <VisitorErrorHandler
        v-if="serverError != null"
        :error="serverError"
        @done="serverError = null"
      />
      <!-- SERVER ERROR -->

      <CRow
        class="justify-content-center mt-4 bg-white rounded-30 p-4 d-flex flex-column gap-2"
      >
        <!-- EMERGENCY ALERT -->
        <div
          v-if="isSchoolSettingsLoading"
          class="d-flex justify-content-center align-content-center p-4"
        >
          <CSpinner color="primary" />
        </div>
        <VisitorAlertCard v-else type="emergency" />
        <!-- EMERGENCY ALERT -->
      </CRow>
      <CRow
        class="justify-content-center mt-4 bg-white rounded-30 p-4 d-flex flex-column gap-2"
      >
        <!-- SEX OFFENDER ALERT -->
        <div
          v-if="isSchoolSettingsLoading"
          class="d-flex justify-content-center align-content-center p-4"
        >
          <CSpinner color="primary" />
        </div>
        <VisitorAlertCard v-else type="sex-offender" />
        <!-- SEX OFFENDER ALERT -->
      </CRow>
      <CRow
        class="justify-content-center mt-4 bg-white rounded-30 p-4 d-flex flex-column gap-2"
      >
        <!-- WATCHLIST ALERT -->
        <div
          v-if="isSchoolSettingsLoading"
          class="d-flex justify-content-center align-content-center p-4"
        >
          <CSpinner color="primary" />
        </div>
        <VisitorAlertCard v-else type="watchlist" />
        <!-- WATCHLIST ALERT -->
      </CRow>
    </CContainer>
  </VisitorWrapper>
</template>
<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import VisitorWrapper from "@/v3components/VisitorWrapper.vue"
import VisitorAlertCard from "@/v3components/Forms/Visitor/SecuritySettings/VisitorAlertCard.vue"
import { onMounted, ref } from "vue"
import { useStore } from "vuex"
export default {
  name: "VisitorSecuritySettings",
  components: {
    VisitorAlertCard,
    VisitorWrapper,
    VisitorErrorHandler
  },
  setup() {
    const store = useStore()
    const isSchoolSettingsLoading = ref(true)
    const serverError = ref(null)

    onMounted(async () => {
      try {
        isSchoolSettingsLoading.value = true
        await store.dispatch("visitorSettings/getSchoolSettings")
      } catch (error) {
        serverError.value = error
      } finally {
        isSchoolSettingsLoading.value = false
      }
    })
    return {
      isSchoolSettingsLoading,
      serverError
    }
  }
}
</script>
