<template>
  <div>
    <CContainer>
      <CRow class="justify-content-center mt-4">
        <CCol md="6 text-center">
          <h1 class="mb-4">
            Acknowledged Pass
          </h1>
          <div>
            {{ message }}
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
export default {
  name: "NewReleases",
  data: () => ({
    message: null
  }),
  created() {
    if (this.$route.query && this.$route.query.token) {
      this.$store
        .dispatch(
          "adultAptPass/acknowledgeAppointmentPassEmail",
          this.$route.query.token
        )
        .then((res) => {
          this.message = res.data.message
        })
        .catch((err) => {
          this.message = err.response.data.message
        })
    } else {
      this.message = "Not Valid Parameters"
    }
  }
}
</script>
