<template>
  <CRow
    v-if="stats"
    class="users-stats-container justify-content-center mt-2 mb-4"
  >
    <CCol
      v-for="(stat, index) in stats"
      :key="index"
      col="12"
      sm="6"
      md="3"
      class="my-2 my-md-0 px-2 px-xxl-4"
    >
      <div class="users-role-stat bg-gradient-blue reverse py-4 px-2">
        <b class="text-uppercase d-block text-value-lg text-center">{{
          index
        }}</b>
        <div
          class="d-flex justify-content-between align-items-center flex-wrap"
        >
          <div class="d-flex flex-column align-items-center">
            <b class="text-value-lg fw-bold my-2">{{ stat.total }}</b>
            <b class="stat-label text-uppercase d-block">Total</b>
          </div>
          <div class="d-flex flex-column align-items-center">
            <b class="text-value-lg fw-bold my-2">{{ stat.todaysCreated }}</b>
            <b class="stat-label text-uppercase d-block">Added</b>
          </div>
          <div class="d-flex flex-column align-items-center">
            <b class="text-value-lg fw-bold my-2">{{ stat.invited }}</b>
            <b class="stat-label text-uppercase d-block">Invited</b>
          </div>
          <div class="d-flex flex-column align-items-center">
            <b class="text-value-lg fw-bold my-2">{{ stat.loggedIn }}</b>
            <b class="stat-label text-uppercase d-block">Logged in</b>
          </div>
        </div>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import { computed, onMounted } from "vue"
import { useStore } from "vuex"

export default {
  name: "UsersStats",
  setup() {
    const store = useStore()

    const stats = computed(() => store.getters["users/stats"])

    onMounted(() => {
      getStats()
    })

    const getStats = () => {
      store.dispatch("users/getStats")
    }

    return {
      stats
    }
  }
}
</script>
