<template>
  <div>
    <div id="createAppoinmentsPassFormCollection">
      <div
        v-for="(form, index) in state.formsCount"
        :key="index"
        :class="index >= 1 ? 'mt-4 border-top' : ''"
        class="createAppoinmentsPassForm"
      >
        <CreateAppoinmentsPassForm
          ref="appoinmentsPassForm"
          :editable-pass="editablePass"
          @cancel="closeModal()"
        />
      </div>
    </div>
    <div>
      <InfoBox
        data-test-id="create-admin-apt-pass-info"
        v-if="state.serverRes"
        class="mt-4"
        :class="{ danger: Object.keys(state.serverErrors).length }"
        :title="state.serverRes.title"
        >{{ state.serverRes.message }}</InfoBox
      >
    </div>
    <div
      v-if="!editablePass"
      class="d-flex align-items-center justify-content-center my-4"
    >
      <LoadingButton
        data-test-id="create-admin-apt-pass-form-submit"
        :is-loading="state.isProcessing"
        class="mx-2"
        solid
        rounded
        @click="validate()"
      >
        {{ editablePass ? "Update" : "Create" }}
      </LoadingButton>
      <LoadingButton
        v-if="state.formsCount < 200"
        class="me-2"
        rounded
        @click="addForm()"
      >
        <span class="d-flex align-items-center"
          ><i class="ri-add-line m-0 me-2"></i> Add</span
        >
      </LoadingButton>
      <LoadingButton
        v-if="state.formsCount > 1"
        class="me-2"
        rounded
        @click="removeForm()"
      >
        <span class="d-flex align-items-center"
          ><i class="ri-subtract-line m-0 me-2"></i>Remove</span
        >
      </LoadingButton>
      <router-link
        v-if="isHeaderCreateButton && !editablePass"
        class="text-graident-blue d-flex flex-row-reverse"
        to="/appointments"
      >
        <div class="d-flex align-items-center" @click="onCancel">
          <img
            width="18"
            class="m-0 me-2"
            src="@/assets/images/icons/gradient-link.png"
          />
          <span class="text-graident-blue"
            ><span class="text-graident-blue">Go to full menu</span></span
          >
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, inject } from "vue"
import { useStore } from "vuex"
import moment from "moment-timezone"
import helpersJS from "@/helpers/index.js"
import CreateAppoinmentsPassForm from "@/v3components/Forms/CreateAppoinmentsPassForm.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"

export default {
  name: "CreateAppointmentPassMultipleForm",
  components: {
    CreateAppoinmentsPassForm,
    InfoBox,
    LoadingButton
  },
  props: {
    editablePass: {
      type: Object,
      default: () => null
    },
    periods: {
      type: Array,
      default: () => []
    },
    isHeaderCreateButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ["cancel", "closeModal"],
  setup(props, { emit }) {
    const store = useStore()
    const appoinmentsPassForm = ref(null)
    const modal = inject("modal")

    const state = reactive({
      isProcessing: false,
      formsCount: 1,
      isInvalid: true,
      formDataCollection: [],
      formsCollection: [],
      serverErrors: {},
      serverRes: null
    })

    onMounted(() => {
      state.formsCollection = appoinmentsPassForm.value
    })

    const addForm = () => {
      state.formsCount++
      state.formsCollection = appoinmentsPassForm.value
      duplicateFormData()
      scrollTo()
    }

    const removeForm = () => {
      state.formsCount--
      scrollTo()
    }

    const duplicateFormData = () => {
      const formsLen = appoinmentsPassForm.value.length
      const current = appoinmentsPassForm.value[formsLen - 1]
      if (current) {
        setTimeout(() => {
          const newForm = appoinmentsPassForm.value[formsLen]
          if (newForm) {
            newForm.setFormData(current.state.form)
          }
        }, 200)
      }
    }

    const validate = () => {
      state.isInvalid = false
      state.formDataCollection = []
      appoinmentsPassForm.value.forEach((form) => {
        form.v$.$touch()
        if (form.v$.$invalid) {
          state.isInvalid = true
        } else {
          state.formDataCollection.push(form.state.form)
        }
      })
      if (!state.isInvalid) {
        submit()
      }
    }

    const submit = () => {
      const appointments = []
      state.formDataCollection.forEach((form) => {
        form.time = form.time ? form.time : moment().format("HH:mm")
        appointments.push({
          from_id: form.from_id ? form.from_id.value.id : form.from_id,
          to_id: form.to_id ? form.to_id.value.id : form.to_id,
          from_type: form.from_id ? form.from_id.value.type : form.from_id,
          to_type: form.to_id ? form.to_id.value.type : form.to_id,
          for_date: helpersJS.currTzDateTime(form.for_date, form.time),
          for_time: form.time,
          period_id: form.period_id ? form.period_id.value : form.period_id,
          reason: form.reason,
          user_id: form.user_id ? form.user_id.value.id : form.user_id,
          recurrence_type: form.recurrence_type,
          recurrence_days: JSON.stringify({
            days: form.recurrence_days
          }),
          recurrence_end_at: form.recurrence_end_at
            ? helpersJS.currTzDate(form.recurrence_end_at)
            : null,
          recurrence_week:
            form.recurrence_type == "monthly" ? form.recurrence_week : null
        })
      })

      createAppointmentPasses(appointments)
    }

    const createAppointmentPasses = (appointments) => {
      state.isProcessing = true
      store
        .dispatch("adultAptPass/createAppointmentPass", {
          appointments
        })
        .then(() => {
          scrollTo()
          setResponseInfoBox("Success", "Successfully created!")
          state.serverErrors = {}
          modal.setAction("createAppointments")
          setTimeout(() => {
            resetMultipleForm()
            onCancel()
            state.isProcessing = false
          }, 1800)
        })
        .catch((err) => {
          scrollTo()
          const res = err.response.data
          state.serverErrors = res.message ? res.message : {}
          setResponseInfoBox(res.status ? res.status : "Error", res.message)
          state.isProcessing = false
        })
    }

    const scrollTo = () => {
      setTimeout(() => {
        const element = document.getElementsByClassName("modal")
        if (element) {
          element[0].scroll({
            top: element[0].scrollHeight,
            behavior: "smooth"
          })
        }
      }, 100)
    }

    const resetMultipleForm = () => {
      setResponseInfoBox()
      state.serverErrors = {}
      helpersJS.scrollToTop()
      const resetForms = new Promise((resolve) => {
        appoinmentsPassForm.value.forEach((form, index, array) => {
          form.resetFormData()
          if (index === array.length - 1) resolve()
        })
      })
      resetForms.then(() => {
        state.formsCount = 1
        state.isInvalid = true
        state.isLoading = false
        state.formDataCollection = []
      })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const onCancel = () => {
      appoinmentsPassForm.value[0].onCancel()
    }

    const closeModal = () => {
      emit("cancel")
      emit("closeModal")
    }

    return {
      state,
      appoinmentsPassForm,
      addForm,
      removeForm,
      duplicateFormData,
      validate,
      onCancel,
      closeModal
    }
  }
}
</script>
