<template>
  <div class="container">
    <div class="row limit-locations-datatable justify-content-center mt-4">
      <ActionPanel
        class="mb-3"
        show-search
        show-export
        @on-search="searchByQuery"
        @on-export="handleLimitLocations(state.pagination.activePage, true)"
      ></ActionPanel>
      <div class="status-guides-container d-block mb-3">
        <PerPage
          v-if="roomCapacityLimits"
          :pagination="state.pagination"
          :showing-entries="true"
          @on-page-change="setPerPage"
        />
      </div>
      <DataTable
        v-if="roomCapacityLimits"
        class="datatable bg-white"
        :items="roomCapacityLimits"
        :fields="dataTableFields"
        :sorter="{ external: true }"
        column-filter
        :no-items-view="{
          noResults: 'No items available',
          noItems: 'No items available'
        }"
        @update:sorter-value="sortByColumn"
      >
        <template #search_column>
          <div class="sort-icon-v3">
            <i class="ri-search-line" @click="toggleSearchByColumn"></i>
          </div>
        </template>

        <template #room="{ item }">
          <td>
            {{ item.room ? item.room.name : "-" }}
          </td>
        </template>
        <template #room-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.room.value"
            placeholder="Search by name"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column')"
          />
        </template>

        <template #limit="{ item }">
          <td>
            <b>{{ item.limit }}</b>
          </td>
        </template>
        <template #limit-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.limit.value"
            placeholder="Search by limit"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column', '0')"
          />
        </template>

        <template #action="{ item }">
          <td>
            <div class="d-flex">
              <Actions
                :actions="limitLocationsActions"
                @click-action="handleTableAction($event, item)"
              >
              </Actions>
            </div>
          </td>
        </template>
      </DataTable>
    </div>

    <Pagination
      v-if="
        state.pagination.pages &&
        state.pagination.pages > 1 &&
        !state.isLazyLoadingMode
      "
      class="cs-pagination mt-4"
      :dots="false"
      :double-arrows="true"
      :active-page="state.pagination.activePage"
      :pages="state.pagination.pages"
      align="center"
      size="lg"
      @update:active-page="setActivePage"
    >
    </Pagination>
  </div>
</template>
<script>
import { reactive, computed, onMounted, watch, inject } from "vue"
import { useStore } from "vuex"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel.vue"
import PerPage from "@/v3components/shared/DataTable/Perpage.vue"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import Actions from "@/v3components/shared/DataTable/Actions.vue"
import Pagination from "@/v3components/shared/DataTable/Pagination.vue"
import CreateLimitLocationMaxCapForm from "@/v3components/Forms/CreateLimitLocationMaxCapForm.vue"
import helpers from "../../helpers/index.js"
import download from "@/helpers/downloadCSV.js"

export default {
  name: "LimitLocationsMaxCapTable",
  components: {
    ActionPanel,
    PerPage,
    DataTable,
    Actions,
    Pagination
  },
  emits: [],
  setup() {
    const store = useStore()
    const actionDialog = inject("actionDialog")
    const modal = inject("modal")

    const state = reactive({
      isLazyLoadingMode: false,
      pagination: {
        activePage: 1,
        total: 0,
        to: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      searchTimeOut: null,
      filter: false,
      filterParams: {
        sort_query: "",
        search_query: ""
      },
      columnSearchKeys: {
        globalQuery: "",
        room: { value: "", column: ["room.name"], sortBy: "room.name" },
        limit: { value: "", column: ["limit.raw"], sortBy: "limit.raw" }
      }
    })

    const dataTableFields = [
      { key: "room", label: "Location" },
      { key: "limit", label: "Limit" },
      { key: "action", label: "Actions", _style: "width:50px", filter: false }
    ]

    const limitLocationsActions = [
      {
        label: "Edit",
        icon: "ri-edit-line",
        action: "edit"
      },
      {
        label: "Delete",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete"
      }
    ]

    const roomCapacityLimits = computed(
      () => store.getters["rooms/roomCapacityLimits"]
    )

    watch(
      () => modal.options.action,
      (isAction) => {
        if (isAction == "createLimitLocationMaxCap") {
          handleLimitLocations()
        }
      }
    )

    onMounted(() => {
      initLazyLoading()
      handleLimitLocations()
    })

    const toggleSearchByColumn = () => {
      state.filter = !state.filter
    }

    const handleLimitLocations = (
      page,
      isExport,
      isDelete,
      isLazyLoadingMode
    ) => {
      if (isDelete && page) {
        state.pagination.total = state.pagination.total - 1
        state.pagination.pages = Math.ceil(
          state.pagination.total / state.pagination.per_page.value
        )
        state.pagination.activePage =
          page < state.pagination.pages ? page : state.pagination.pages
      } else {
        state.pagination.activePage = page ? page : 1
      }
      const params = {
        page: state.pagination.activePage,
        per_page: state.pagination.per_page.value
      }
      if (state.filterParams.search_query) {
        params.search_query = state.filterParams.search_query
      }
      if (state.filterParams.sort_query) {
        params.sort = state.filterParams.sort_query
      }
      if (isExport) {
        exportCSVLimitLocations(params)
      } else {
        getLimitLocations(params, isLazyLoadingMode)
      }
    }

    const getLimitLocations = (params, isLazyLoadingMode) => {
      store.dispatch("rooms/getRoomCapacityLimit", params).then((response) => {
        const data = response.data
        helpers.scrollToTop()
        if (data) {
          if (isLazyLoadingMode) {
            store.commit("rooms/PUSH_ROOM_CAPACITY_LIMITS", data.data)
          } else {
            store.commit("rooms/SET_ROOM_CAPACITY_LIMITS", data.data)
          }
          if (data.meta) {
            state.pagination.total = data.meta.total
            state.pagination.from = data.meta.from
            state.pagination.to = data.meta.to
            state.pagination.pages = Math.ceil(
              data.meta.total / data.meta.per_page
            )
            state.pagination.activePage = Number(state.pagination.activePage)
            state.pagination = JSON.parse(JSON.stringify(state.pagination))
          }
        }
      })
    }

    const exportCSVLimitLocations = (params) => {
      params.per_page = [25, 50, 100].includes(state.pagination.per_page.value)
        ? state.pagination.per_page.value
        : "all"
      store
        .dispatch("rooms/getCSVroomCapacityLimitsExport", params)
        .then((response) => {
          if (response.data) {
            download.CSVExport(response.data, "locations-max-cap-limits")
          }
        })
    }

    const searchByQuery = (value, type, minLetters) => {
      clearInterval(state.searchTimeOut)
      state.searchTimeOut = setTimeout(() => {
        handleQuerySearch(value, type, minLetters)
      }, 500)
    }

    const handleQuerySearch = (value, type, minLetters) => {
      const query = value.toString()
      const minLen = minLetters ? parseInt(minLetters) : 0
      if (
        query.replace(/\s/g, "").length > minLen ||
        query.replace(/\s/g, "").length === 0
      ) {
        state.filterParams.search_query = ""
        if (type && type === "column") {
          state.columnSearchKeys.globalQuery = ""
          for (const key in state.columnSearchKeys) {
            if (Object.hasOwnProperty.call(state.columnSearchKeys, key)) {
              const columnData = state.columnSearchKeys[key]
              if (columnData.value) {
                columnData.column.forEach((col) => {
                  state.filterParams.search_query =
                    state.filterParams.search_query +
                    `${col}:"${columnData.value}", `
                })
              }
            }
          }
          state.filterParams.search_query =
            state.filterParams.search_query.slice(0, -2)
        } else {
          resetSearchQuery()
          state.columnSearchKeys.globalQuery = query
          state.filterParams.search_query = query ? '"' + query + '"' : query
        }
        handleLimitLocations()
      }
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].sortBy
        : column.column
      state.filterParams.sort_query = `${columnName}:${
        column.asc ? "asc" : "desc"
      }`
      handleLimitLocations()
    }

    const handleTableAction = (event, item) => {
      if (event.action == "edit") {
        modal.open(CreateLimitLocationMaxCapForm, {
          title: "Edit Limit Location Max Cap",
          props: {
            editableLimitData: item
          }
        })
      }
      if (event.action == "delete") {
        actionDialog.open(deleteLimitLocation, {
          args: item,
          size: "sm",
          props: { danger: true, title: "Delete", question: "Are you sure?" }
        })
      }
    }

    const deleteLimitLocation = (limit) => {
      store.dispatch("rooms/deleteRoomCapacityLimit", limit.id).then(() => {
        store.commit("rooms/DELETE_ROOM_CAPACITY_LIMIT", limit.id)
        handleLimitLocations(state.pagination.activePage, false, true)
      })
    }

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.pagination.per_page = option
        handleLimitLocations()
      }
    }

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (
            state.isLazyLoadingMode &&
            roomCapacityLimits.value &&
            roomCapacityLimits.value.length &&
            state.pagination.total > roomCapacityLimits.value.length
          ) {
            if (body.offsetHeight + body.scrollTop + 1 >= body.scrollHeight) {
              state.pagination.activePage = state.pagination.activePage + 1
              handleLimitLocations(
                state.pagination.activePage,
                false,
                false,
                true
              )
            }
          }
        }
      }
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      handleLimitLocations(state.pagination.activePage)
      helpers.scrollToTop()
    }

    const resetSearchQuery = () => {
      state.filterParams.search_query = ""
      state.filterParams.sort_query = ""
      state.columnSearchKeys = {
        globalQuery: "",
        room: { value: "", column: ["room.name"], sortBy: "room.name" },
        limit: { value: "", column: ["limit"], sortBy: "limit" }
      }
    }

    return {
      state,
      dataTableFields,
      limitLocationsActions,
      roomCapacityLimits,
      toggleSearchByColumn,
      handleLimitLocations,
      searchByQuery,
      sortByColumn,
      handleTableAction,
      setPerPage,
      setActivePage
    }
  }
}
</script>
