<template>
  <div>
    <CContainer>
      <CRow class="d-flex align-items-center justify-content-center">
        <CCol
          lg="8"
          sm="12"
          class="v3-module-list pt-4 pb-4 rounded-5 bg-white"
        >
          <div>
            <appointment-pass-module-form v-if="isAppPassAval" />
          </div>
          <div>
            <tardy-management-module-form v-if="isTardyCheckInAval" />
          </div>
          <div>
            <VisitorManagementModuleForm v-if="isVisitorManagementAval" />
          </div>
          <div>
            <auto-check-in-module-form v-if="isAutoCheckInAval" />
          </div>
          <div>
            <kiosk-module-form v-if="isKioksAval" />
          </div>
          <div>
            <digital-id-card-form></digital-id-card-form>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import AppointmentPassModuleForm from "../v3components/Forms/ModuleListForms/AppointmentPassModuleForm.vue"
import AutoCheckInModuleForm from "../v3components/Forms/ModuleListForms/AutoCheckInModuleForm.vue"
import KioskModuleForm from "../v3components/Forms/ModuleListForms/KioskModuleForm.vue"
import DigitalIdCardForm from "../v3components/Forms/ModuleListForms/DigitalIdCardForm.vue"
import TardyManagementModuleForm from "../v3components/Forms/ModuleListForms/TardyManagementModuleForm.vue"
import VisitorManagementModuleForm from "../v3components/Forms/ModuleListForms/VisitorManagementModuleForm.vue"
import visitorConstants from "@/constants/visitorConstants.js"
import { shouldVisitorWebAppBeHidden } from "@/helpers/featureFlags.js"
import { useStore } from "vuex"
import { computed } from "vue"

export default {
  name: "ModulesList",
  components: {
    KioskModuleForm,
    AutoCheckInModuleForm,
    AppointmentPassModuleForm,
    DigitalIdCardForm,
    TardyManagementModuleForm,
    VisitorManagementModuleForm
  },
  setup() {
    const store = useStore()

    const availableSchoolModules = computed(
      () => store.getters["schools/availableSchoolModules"]
    )

    const isKioksAval = computed(() => {
      return (
        availableSchoolModules.value &&
        availableSchoolModules.value.includes("Kiosk")
      )
    })

    const isAppPassAval = computed(() => {
      return (
        availableSchoolModules.value &&
        availableSchoolModules.value.includes("Appointmentpass")
      )
    })

    const isAutoCheckInAval = computed(() => {
      return (
        availableSchoolModules.value &&
        availableSchoolModules.value.includes("Auto Check-In")
      )
    })

    const isTardyCheckInAval = computed(() => {
      return (
        availableSchoolModules.value &&
        availableSchoolModules.value.includes("Tardy Management")
      )
    })

    const isVisitorManagementAval = computed(() => {
      const keepVisitorHidden = shouldVisitorWebAppBeHidden()
      return (
        !keepVisitorHidden &&
        availableSchoolModules.value &&
        availableSchoolModules.value.includes(visitorConstants.MODULE) &&
        store.getters["visitorSettings/hasVisitorModuleAccess"]
      )
    })
    return {
      isKioksAval,
      isAppPassAval,
      isAutoCheckInAval,
      isTardyCheckInAval,
      isVisitorManagementAval
    }
  }
}
</script>
