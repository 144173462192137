<template>
  <DatePicker
    v-bind="{ placeholder, minDate, maxDate, disabled }"
    :model-value="modelValue"
    :masks="{ L: 'MM/DD/YYYY' }"
    @update:model-value="onModelUpdate"
    :is-range="isRange"
    :is-custom-select="isCustomSelect"
  >
    <template #default="{ inputValue, togglePopover }">
      <InputField
        :class="inputClass"
        ref="input"
        :model-value="
          !isRange
            ? inputValue
            : inputValue.start && inputValue.end
            ? inputValue.start + '-' + inputValue.end
            : null
        "
        @click="showCalendar(togglePopover, inputValue)"
        append-icon="ri-calendar-line"
        :readonly="true"
        v-bind="{
          innerLabel,
          invalidFeedback,
          disabled,
          isInvalid,
          placeholder
        }"
      >
      </InputField>
    </template>
  </DatePicker>
</template>

<script>
import { watchEffect, ref, nextTick } from "vue"
import { DatePicker } from "v-calendar"
import InputField from "./InputField.vue"

export default {
  components: { DatePicker, InputField },
  emits: ["update:modelValue"],
  props: {
    placeholder: String,
    minDate: [Date, String, Object],
    maxDate: [Date, String, Object],
    modelValue: [String, Date, Object],
    isInvalid: Boolean,
    invalidFeedback: [String, Array],
    disabled: Boolean,
    innerLabel: String,
    inputClass: String,
    isRange: {
      type: Boolean,
      default: false
    },
    isCustomSelect: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const input = ref(null)
    const onModelUpdate = (value) => {
      emit("update:modelValue", value)
    }
    const showCalendar = (togglePopover) => {
      return props.disabled ? null : togglePopover()
    }
    watchEffect(() => {
      if (props.isCustomSelect && input.value?.$el) {
        nextTick(() => {
          input.value.$el.click()
        })
      }
    })
    return { input, onModelUpdate, showCalendar }
  }
}
</script>

<style></style>
