import firebaseConfig from "@/configs/firebaseConfig"
import visitorConstants from "@/constants/visitorConstants"
import getFirebaseApp from "@/helpers/firebaseService"
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported
} from "firebase/messaging"

let fcmService = null

class FCMService {
  constructor() {
    this.app = getFirebaseApp()
    this.messaging = getMessaging()
  }

  async requestPermissionAndGetToken() {
    try {
      if (!("Notification" in window)) {
        throw new Error(
          "This browser does not support notifications. Please use a different browser."
        )
      }

      if (window?.isSecureContext) {
        const permission = await Notification.requestPermission()
        if (permission !== "granted") {
          throw new Error(
            "Firebase requires Notification permission. Please grant Notification permission."
          )
        }
      }

      const isFcmSupported = await isSupported()
      if (!isFcmSupported) {
        throw new Error(
          "This browser does not support Firebase Cloud Messaging. Please use a different browser."
        )
      }

      this.token = await getToken(this.messaging, {
        vapidKey: firebaseConfig.vapidKey
      })
      if (!this?.token || Object.keys(this?.token || {}).length === 0) {
        throw new Error(
          "No registration token available. Reload the page to request permission."
        )
      }

      localStorage.setItem(
        visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.FCM_TOKEN,
        this.token
      )
      return this.token
    } catch (err) {
      throw new Error(
        err?.message || "An error occurred while retrieving firebase token."
      )
    }
  }

  onMessageListener(callback) {
    onMessage(this.messaging, (payload) => {
      if (callback) callback(payload)
    })
  }
}

const getFcmService = () => {
  if (!fcmService && "serviceWorker" in navigator) {
    fcmService = new FCMService()
  }
  return fcmService
}

export default getFcmService
