import axios from "axios"
import { apiV7nocache } from "./axiousWithCache"

const state = {
  notifications: [],
  appointmentPassRemind: {
    show: false,
    data: null
  },
  appointmentPassRun: {
    show: false,
    data: null
  },
  isActiveAppointmentCreated: true,
  isActiveDashboardUpdate: true,
  appointmentSocketsInit: false
}

const getters = {
  notifications(state) {
    return state.notifications
  },
  isActiveAppointmentCreated(state) {
    return state.isActiveAppointmentCreated
  },
  appointmentPassRun(state) {
    return state.appointmentPassRun
  },
  appointmentPassRemind(state) {
    return state.appointmentPassRemind
  },
  isActiveDashboardUpdate(state) {
    return state.isActiveDashboardUpdate
  },
  appointmentSocketsInit(state) {
    return state.appointmentSocketsInit
  }
}

const mutations = {
  SET_APPOINTMENT_PASS_REMIND(state, notification) {
    state.appointmentPassRemind = {
      show: true,
      data: notification
    }
  },
  SET_APPOINTMENT_PASS_RUN(state, notification) {
    state.appointmentPassRun = {
      show: true,
      data: notification
    }
  },
  REMOVE_NOTIFICATION(state, notf) {
    state[notf] = {
      show: false,
      data: null
    }
  },
  TOGGLE_ACTIVE_SOCKET(state, socketName) {
    state[socketName] = !state[socketName]
  },
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications
  },
  TOGGLE_APP_SOCKETS_INIT(state) {
    state.appointmentSocketsInit = !state.appointmentSocketsInit
  }
}

const actions = {
  getNotifications(context) {
    const userRole = context.rootGetters["authentication/getUserRole"]
    return new Promise((resolve, reject) => {
      if (userRole == "student") {
        axios
          .get(`/users/notifications`)
          .then((response) => {
            const data = response.data.data
            if (data) {
              context.commit("SET_NOTIFICATIONS", data)
              resolve(data)
            }
          })
          .catch((err) => {
            reject(err)
          })
      }
      if (userRole == "admin" || userRole == "superadmin") {
        apiV7nocache
          .get(`/users/notifications`)
          .then((response) => {
            const data = response.data.data
            if (data) {
              context.commit("SET_NOTIFICATIONS", data)
              resolve(data)
            }
          })
          .catch((err) => {
            reject(err)
          })
      }
    })
  },
  seenNotifications(context, id) {
    axios.put(`/users/notifications/${id}`).then((response) => {
      const data = response.data.data
      if (data) {
        context.commit("SET_NOTIFICATIONS", data)
      }
    })
  },
  bulkSeenNotifications(context, notifications) {
    const userRole = context.rootGetters["authentication/getUserRole"]
    if (userRole == "student") {
      axios.post(`/users/notifications`, notifications).then(() => {
        context.dispatch("getNotifications")
      })
    }
    if (userRole == "admin" || userRole == "superadmin") {
      apiV7nocache.put(`users/notifications`).then(() => {
        context.dispatch("getNotifications")
      })
    }
  },
  clearNotifications(context) {
    const userRole = context.rootGetters["authentication/getUserRole"]
    if (userRole == "student") {
      axios.delete(`/users/notifications`).then((response) => {
        const data = response.data.data
        if (data) {
          context.dispatch("getNotifications")
        }
      })
    }
    if (userRole == "admin" || userRole == "superadmin") {
      apiV7nocache.delete(`users/notifications`).then((response) => {
        const data = response.data.data
        if (data) {
          context.dispatch("getNotifications")
        }
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
