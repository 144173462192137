<template>
  <div>
    <CContainer>
      <CRow>
        <CCol md="12">
          <CAlert
            v-if="reqResponse.message"
            class="mt-4"
            :color="reqResponse.type"
            :show="!!reqResponse.message"
            close-button
          >
            {{ reqResponse.message }}
          </CAlert>
        </CCol>
        <CCol md="6">
          <h3>Out time</h3>
          <CustomTimePicker
            format="HH:mm"
            v-model="form.out_time"
            placeholder="Out Time"
            :classes="[
              {
                'is-invalid':
                  v$.form.out_time.$error || reqResponse.errors['out_time']
              },
              'mb-3'
            ]"
          >
            <template #footer>
              <div
                v-if="v$.form.$error && v$.form.out_time.required.$invalid"
                class="invalid-feedback"
              >
                This field is required
              </div>
              <div
                v-if="
                  v$.form.$error &&
                  !v$.form.out_time.required.$invalid &&
                  v$.form.out_time.minValue.$invalid
                "
                class="invalid-feedback"
              >
                Start time must be after now
              </div>
              <div
                v-if="reqResponse.errors['out_time']"
                class="invalid-feedback"
              >
                {{ reqResponse.errors["out_time"][0] }}
              </div>
            </template>
          </CustomTimePicker>

          <!-- <div class="form-group cs-time-field">
              <div
                class="form-control"
                :class="{ 'is-invalid': v$.form.out_time.$error || reqResponse.errors['out_time']}">
                    <input
                      type="time"
                      v-model="form.out_time"/>
                    <div class="cs-placeholder">
                      <span class="text">{{form.out_time ? form.out_time : 'Out Time'}}</span>
                      <span class="icon"><i class="fi flaticon-wall-clock me-1"></i></span>
                    </div>
                </div>
                <div class="invalid-feedback" v-if="v$.form.$error && !v$.form.out_time.required">This field is required</div>
                <div class="invalid-feedback" v-if="v$.form.$error && v$.form.out_time.required && !v$.form.out_time.minValue">Start time must be after now</div>
                <div class="invalid-feedback" v-if="reqResponse.errors['out_time']">{{reqResponse.errors['out_time'][0]}}</div>
              </div> -->
        </CCol>
        <CCol md="6">
          <h3>In time</h3>
          <CustomTimePicker
            format="HH:mm"
            v-model="form.in_time"
            placeholder="In Time"
            :classes="[
              {
                'is-invalid':
                  v$.form.in_time.$error || reqResponse.errors['in_time']
              },
              'mb-3'
            ]"
          >
            <template #footer>
              <div
                v-if="v$.form.$error && v$.form.in_time.required.$invalid"
                class="invalid-feedback"
              >
                This field is required
              </div>
              <div
                v-if="
                  v$.form.$error &&
                  !v$.form.in_time.required.$invalid &&
                  v$.form.in_time.minValue.$invalid
                "
                class="invalid-feedback"
              >
                Start time must be after now
              </div>
              <div
                v-if="reqResponse.errors['in_time']"
                class="invalid-feedback"
              >
                {{ reqResponse.errors["in_time"][0] }}
              </div>
            </template>
          </CustomTimePicker>

          <!-- <div class="form-group cs-time-field">
              <div
                class="form-control"
                :class="{ 'is-invalid': v$.form.in_time.$error || reqResponse.errors['in_time']}">
                    <input
                      type="time"
                      v-model="form.in_time"/>
                    <div class="cs-placeholder">
                      <span class="text">{{form.in_time ? form.in_time : 'In Time'}}</span>
                      <span class="icon"><i class="fi flaticon-wall-clock me-1"></i></span>
                    </div>
                </div>
                <div class="invalid-feedback" v-if="v$.form.$error && !v$.form.in_time.required">This field is required</div>
                <div class="invalid-feedback" v-if="v$.form.$error && v$.form.in_time.required && !v$.form.in_time.minValue">Start time must be after now</div>
                <div class="invalid-feedback" v-if="reqResponse.errors['in_time']">{{reqResponse.errors['in_time'][0]}}</div>
              </div> -->
        </CCol>
      </CRow>
      <CRow v-if="pass.child">
        <CCol v-if="pass.child.approved_at" md="6">
          <h3>Out time</h3>
          <CustomTimePicker
            format="HH:mm"
            v-model="form.child_out_time"
            placeholder="Out Time"
            :classes="{
              'is-invalid':
                v$.form.child_out_time.$error ||
                reqResponse.errors['child_out_time']
            }"
          >
            <template #footer>
              <div
                v-if="
                  v$.form.$error && v$.form.child_out_time.required.$invalid
                "
                class="invalid-feedback"
              >
                This field is required
              </div>
              <div
                v-if="
                  v$.form.$error &&
                  !v$.form.child_out_time.required.$invalid &&
                  v$.form.child_out_time.minValue.$invalid
                "
                class="invalid-feedback"
              >
                Start time must be after now
              </div>
              <div
                v-if="reqResponse.errors['child_out_time']"
                class="invalid-feedback"
              >
                {{ reqResponse.errors["child_out_time"][0] }}
              </div>
            </template>
          </CustomTimePicker>

          <!-- <div class="form-group cs-time-field">
              <div
                class="form-control"
                :class="{ 'is-invalid': v$.form.child_out_time.$error || reqResponse.errors['child_out_time']}">
                    <input
                      type="time"
                      v-model="form.child_out_time"/>
                    <div class="cs-placeholder">
                      <span class="text">{{form.child_out_time ? form.child_out_time : 'Out Time'}}</span>
                      <span class="icon"><i class="fi flaticon-wall-clock me-1"></i></span>
                    </div>
                </div>
                <div class="invalid-feedback" v-if="v$.form.$error && !v$.form.child_out_time.required">This field is required</div>
                <div class="invalid-feedback" v-if="v$.form.$error && v$.form.child_out_time.required && !v$.form.child_out_time.minValue">Start time must be after now</div>
                <div class="invalid-feedback" v-if="reqResponse.errors['child_out_time']">{{reqResponse.errors['child_out_time'][0]}}</div>
            </div> -->
        </CCol>
        <CCol v-if="pass.child.completed_at" md="6">
          <h3>In time</h3>
          <CustomTimePicker
            format="HH:mm"
            v-model="form.child_in_time"
            placeholder="In Time"
            :classes="{
              'is-invalid':
                v$.form.child_in_time.$error ||
                reqResponse.errors['child_in_time']
            }"
          >
            <template #footer>
              <div
                v-if="v$.form.$error && v$.form.child_in_time.required.$invalid"
                class="invalid-feedback"
              >
                This field is required
              </div>
              <div
                v-if="
                  v$.form.$error &&
                  !v$.form.child_in_time.required.$invalid &&
                  v$.form.child_in_time.minValue.$invalid
                "
                class="invalid-feedback"
              >
                Start time must be after now
              </div>
              <div
                v-if="reqResponse.errors['child_in_time']"
                class="invalid-feedback"
              >
                {{ reqResponse.errors["child_in_time"][0] }}
              </div>
            </template>
          </CustomTimePicker>

          <!-- <div class="form-group cs-time-field">
                <div
                class="form-control"
                :class="{ 'is-invalid': v$.form.child_in_time.$error || reqResponse.errors['child_in_time']}">
                    <input
                      type="time"
                      v-model="form.child_in_time"/>
                    <div class="cs-placeholder">
                      <span class="text">{{form.child_in_time ? form.child_in_time : 'In Time'}}</span>
                      <span class="icon"><i class="fi flaticon-wall-clock me-1"></i></span>
                    </div>
                </div>
                <div class="invalid-feedback" v-if="v$.form.$error && !v$.form.child_in_time.required">This field is required</div>
                <div class="invalid-feedback" v-if="v$.form.$error && v$.form.child_in_time.required && !v$.form.child_in_time.minValue">Start time must be after now</div>
                <div class="invalid-feedback" v-if="reqResponse.errors['child_in_time']">{{reqResponse.errors['child_in_time'][0]}}</div>
            </div> -->
        </CCol>
      </CRow>
      <CRow>
        <div
          v-if="isUserAllowTardy"
          class="tardy-late-pass d-flex flex-column mt-2"
        >
          <p class="mb-1">Mark this pass as:</p>
          <TardyRadioGroup v-model="form.tardy_badge" />
        </div>
      </CRow>
      <CRow>
        <CCol md="12" class="text-center mt-4 mb-2">
          <button
            type="button"
            class="btn btn bg-gradient-blue text-white me-2"
            @click="changePassHistoryTime()"
          >
            Update
          </button>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core"
import { required } from "vuelidate/lib/validators"
import moment from "moment-timezone"
import TardyRadioGroup from "@/v3components/Forms/TardyRadioGroupOld.vue"
import { mapGetters } from "vuex"

export default {
  name: "ChangePassHistoryTimeForm",
  props: ["pass", "v3dashboard"],
  components: {
    TardyRadioGroup
  },
  emits: ["passTimeChange", "cancel"],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      form: {
        out_time: "",
        in_time: "",
        child_out_time: "",
        child_in_time: "",
        tardy_badge: ""
      },
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      }
    }
  },
  validations: {
    form: {
      out_time: {
        required,
        minValue: (value) => value >= moment().format("HH:mm")
      },
      in_time: {
        required,
        minValue: (value, model) => {
          return value > model.out_time
        }
      },
      child_out_time: {
        // required: requiredIf((model) => {return pass.child && pass.child.approved_at}),
      },
      child_in_time: {
        // required: requiredIf((model) => {return pass.child && pass.child.completed_at}),
      }
    }
  },
  computed: {
    ...mapGetters({
      tardyManagementOptions: "schools/tardyManagementOptions",
      currentUser: "authentication/user"
    }),
    isUserAllowTardy() {
      if (this.tardyManagementOptions != null) {
        if (this.tardyManagementOptions.usage["within-ehp"]) {
          if (this.currentUser.role.name == "admin") {
            return true
          } else if (
            (this.currentUser.role.name == "staff" ||
              this.currentUser.role.name == "teacher") &&
            this.tardyManagementOptions["allow-teacher-staff"]
          ) {
            return true
          } else if (
            this.currentUser.allow_tardy &&
            this.tardyManagementOptions["allow-selected-users-only"]
          ) {
            return true
          }
          return false
        }
        return false
      }
      return false
    },
    passStateTimePeriods() {
      return this.pass.child
        ? [
            this.form.out_time || null,
            this.form.in_time || null,
            this.form.child_out_time || null,
            this.form.child_in_time || null
          ]
        : [this.form.out_time || null, this.form.in_time || null]
    }
  },
  watch: {
    pass: function (pass) {
      this.setCurrentPassTime(pass)
    }
  },
  created() {
    this.setCurrentPassTime(this.pass)
  },
  methods: {
    changePassHistoryTime() {
      if (!this.validTimePeriods()) {
        this.setErrorResponse(
          "Incorrect time periods",
          this.getTimePeriodErrors()
        )
        return
      }
      this.resetResponseMessages()
      // edit -> logged user id == completed by id || approved by id
      const data = {
        passID: this.pass.id,
        newTime: this.form
      }
      this.$store
        .dispatch("passes/changePassHistoryTime", data)
        .then((response) => {
          const data = response.data.data
          this.setSuccessResponse("Successfully updated!")
          setTimeout(() => {
            this.resetResponseMessages()
            this.$emit("passTimeChange", data)
            if (this.v3dashboard) {
              this.$store.commit("dashboardTable/UPDATE_PASS", data)
              this.$store.dispatch("dashboardCounters/getPassesForCounters")
            }
            this.closeModal()
          }, 1000)
        })
        .catch((err) => {
          const response = err.response.data
          this.setErrorResponse(response.message, response.errors)
        })
    },
    setCurrentPassTime(pass) {
      if (pass) {
        this.form.out_time = pass.approved_at
          ? this.$helpers.transformDate(pass.approved_at, "HH:mm")
          : this.form.out_time
        this.form.in_time = pass.completed_at
          ? this.$helpers.transformDate(pass.completed_at, "HH:mm")
          : this.form.in_time
        this.form.tardy_badge = pass.tardy_badge
        if (pass.child) {
          this.form.child_out_time = pass.child.approved_at
            ? this.$helpers.transformDate(pass.child.approved_at, "HH:mm")
            : this.form.child_out_time
          this.form.child_in_time = pass.child.completed_at
            ? this.$helpers.transformDate(pass.child.completed_at, "HH:mm")
            : this.form.child_in_time
        }
      }
    },
    validTimePeriods() {
      return !this.passStateTimePeriods.some(
        (periodValue, index) =>
          periodValue > this.passStateTimePeriods[index + 1]
      )
    },
    getTimePeriodErrors() {
      return {
        out_time: this.form.out_time > this.form.in_time && [
          "Must be before In time"
        ],
        in_time: this.form.in_time < this.form.out_time && [
          "Must be after Out time"
        ],
        ...(this.pass.child && {
          in_time: (this.form.out_time > this.form.in_time ||
            this.form.in_time > this.form.child_out_time) && [
            "Must be after previous Out time & before next Out time"
          ],
          child_out_time: (this.form.child_out_time < this.form.in_time ||
            this.form.child_out_time > this.form.child_in_time) && [
            "Must be after previous In time & before next In time"
          ],
          child_in_time: this.form.child_in_time < this.form.child_out_time && [
            "Must be after Out time"
          ]
        })
      }
    },
    resetResponseMessages() {
      this.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    },
    setSuccessResponse(message) {
      this.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    },
    setErrorResponse(message, errors) {
      this.reqResponse = {
        type: "danger",
        message: message ? message : "Someting went wrong!",
        errors: errors ? errors : {}
      }
    },
    closeModal() {
      this.$emit("cancel")
    }
  }
}
</script>
