import axios from "axios"
import moment from "moment-timezone"
import helpers from "../../../helpers"
import { apiV6nocache } from "../../../store/modules/axiousWithCache"
const nextSevenDays = function () {
  const today = moment().format("MM/DD/YYYY")
  const nextSevenDays = moment().add(7, "days").format("MM/DD/YYYY")
  return [today.toString(), nextSevenDays.toString()]
}
const beyondSevenDays = function () {
  const nextOneYear = moment().add(1, "year").format("MM/DD/YYYY")
  return [
    moment().add(8, "days").format("MM/DD/YYYY").toString(),
    nextOneYear.toString()
  ]
}
const state = {
  appointmentPasses: new Map(),
  editablePass: null,
  activeTab: "nextSevenDays",
  search_query: "",
  sort: null,
  quickFilter: { label: "All Passes", value: "" },
  passType: [{ label: "All Types", value: "" }],
  betweenDates: nextSevenDays(),
  recurrence: false,
  recurrenceOnly: false,
  pagination: {
    activePage: 1,
    total: 0,
    pages: 0,
    per_page: { label: "25", value: 25 }
  },
  todaysPasses: new Map()
}

const getters = {
  appointmentPasses(state) {
    return Array.from(state.appointmentPasses.values())
    // if (state.activeTab == "nextSevenDays") {
    //   return Array.from(state.appointmentPasses.values()).filter((pass) => {
    //     return (
    //       moment(pass.for_date).isBetween(
    //         nextSevenDays()[0],
    //         nextSevenDays()[1]
    //       ) ||
    //       moment(pass.for_date).isSame(nextSevenDays()[0]) ||
    //       moment(pass.for_date).isSame(nextSevenDays()[1])
    //     )
    //   })
    // }
    // return Array.from(state.appointmentPasses.values()).filter((pass) => {
    //   return pass.recurrence_appointment_pass
    //     ? moment(pass.recurrence_appointment_pass.recurrence_end_at).isAfter(
    //         moment().add(7, "days")
    //       )
    //     : moment(pass.for_date).isAfter(moment().add(7, "days"))
    // })
  },
  editablePass(state) {
    return state.editablePass
  },
  activeTab(state) {
    return state.activeTab
  },
  passType(state) {
    return state.passType
  },
  quickFilter(state) {
    return state.quickFilter
  },
  searchQuery(state) {
    return state.search_query
  },
  pagination(state) {
    return state.pagination
  },
  recurrence(state) {
    return state.recurrence
  },
  recurrenceOnly(state) {
    return state.recurrenceOnly
  },
  todaysAppSidebarCounter(state) {
    return Array.from(state.todaysPasses.values()).filter((aptPass) => {
      return (
        !aptPass.waiting_activation &&
        !aptPass.is_ended &&
        !aptPass.is_missed &&
        !aptPass.is_today_waiting_confirmation &&
        !aptPass.is_missed_request &&
        !aptPass.is_activated &&
        !aptPass.is_cancelled &&
        !aptPass.is_for_future &&
        aptPass.confirmed_by_teacher_at != null &&
        !aptPass.canceled_at &&
        moment(aptPass.for_date).isAfter(moment(), "second")
      )
    }).length
  }
}

const mutations = {
  SET_ACTIVE_TAB(state, payload) {
    state.activeTab = payload
    if ("nextSevenDays" == payload) {
      state.betweenDates = nextSevenDays()
      state.recurrence = false
    } else {
      state.betweenDates = beyondSevenDays()
      state.recurrence = true
    }
    state.pagination.activePage = 1
    state.quickFilter = { label: "All Passes", value: "" }
    this.dispatch("studentAptPass/getAppointmentPasses")
  },
  SET_BETWEEN_DATES(state, payload) {
    state.betweenDates = payload
  },
  SET_RECURRENCE(state, payload) {
    state.recurrence = payload
  },
  SET_RECURRENCE_ONLY(state, payload) {
    state.recurrenceOnly = payload
    this.dispatch("studentAptPass/getAppointmentPasses")
  },
  SET_SEARCH_QUERY(state, payload) {
    state.search_query = payload
    state.pagination.activePage = 1
  },
  SET_SORT_BY(state, payload) {
    state.sort = payload
    state.pagination.activePage = 1
    this.dispatch("studentAptPass/getAppointmentPasses")
  },
  SET_APPOINTMENT_PASSES(state, passesCollection, clear = true) {
    if (clear) {
      state.appointmentPasses = new Map()
    }
    passesCollection.forEach((passObj) => {
      passObj = helpers.aptPassStatus(passObj)
      state.appointmentPasses.set(passObj.id, passObj)
    })
    state.appointmentPasses = new Map(state.appointmentPasses)
  },
  SET_ACTIVE_PAGE(state, payload) {
    state.pagination.activePage = payload
    this.dispatch("studentAptPass/getAppointmentPasses")
  },
  SET_PER_PAGE(state, payload) {
    if (payload.value == 75) {
      payload = { label: "All entries", value: 75 }
    }
    state.pagination.activePage = 1
    state.pagination.per_page = payload
    this.dispatch("studentAptPass/getAppointmentPasses")
  },
  SET_PAGINATION(state, payload) {
    if (payload.per_page.value == 75) {
      payload.per_page = { label: "All entries", value: 75 }
    }
    state.pagination = payload
  },
  PUSH_APPOINTMENT_PASSES(state, passesCollection) {
    passesCollection.forEach((passObj) => {
      passObj = helpers.aptPassStatus(passObj)
      state.appointmentPasses.set(passObj.id, passObj)
    })
  },
  UPDATE_APP_PASS(state, passObj) {
    passObj = helpers.aptPassStatus(passObj)
    state.appointmentPasses.set(passObj.id, passObj)
    state.appointmentPasses = new Map(state.appointmentPasses)
  },
  UPDATE_APP_PASS_COMMENTS(state, passObj) {
    const newCollection = state.appointmentPasses.map((pass) => {
      if (pass.id === passObj.id) {
        pass.latest_comment = passObj.latest_comment
      }
      return pass
    })
    state.appointmentPasses = newCollection
  },
  DELETE_APTS_BY_ID(context, ids) {
    ids.forEach((id) => state.appointmentPasses.delete(id))
    state.appointmentPasses = new Map(state.appointmentPasses)
  },
  UPDATE_TODAYS_APP(state, passesCollection) {
    passesCollection.forEach((passObj) => {
      passObj = helpers.aptPassStatus(passObj)
      state.todaysPasses.set(passObj.id, passObj)
    })
    state.todaysPasses = new Map(state.todaysPasses)
  },
  DELETE_TODAYS_APP_BY_ID(context, ids) {
    ids.forEach((id) => state.todaysPasses.delete(id))
    state.todaysPasses = new Map(state.todaysPasses)
  }
}

const actions = {
  createStudentAppointmentPass(context, appointments) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/passes/appointments/request`, appointments)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAppointmentPasses(context) {
    let params = {
      page: context.state.pagination.activePage,
      per_page: context.state.pagination.per_page.value,
      search_query: context.state.search_query,
      sort: context.state.sort,
      quick_filter: context.state.quickFilter.value
        ? context.state.quickFilter.value
        : null,
      pass_type: context.state.passType
        .map((e) => e.value)
        .filter((e) => e != ""),
      between_dates: context.state.betweenDates,
      recurrence: context.state.recurrence,
      recurring_only: context.state.recurrenceOnly
    }
    params = Object.entries(params).reduce(
      (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
      {}
    )
    return new Promise((resolve, reject) => {
      apiV6nocache
        .get(`/passes/appointments`, {
          params: params
        })
        .then((response) => {
          let data = response.data.data
          const meta = response.data.meta
          if (data) {
            data = data.map((e) => helpers.aptPassStatus(e))
            if (meta.per_page == 75 && meta.current_page > 1) {
              context.commit("PUSH_APPOINTMENT_PASSES", data)
            } else {
              context.commit("SET_APPOINTMENT_PASSES", data)
            }
            resolve(response)
          }
          if (meta) {
            context.commit("SET_PAGINATION", {
              total: meta.total,
              activePage: meta.current_page,
              pages: meta.last_page,
              per_page: { label: `${meta.per_page}`, value: meta.per_page },
              from: meta.from,
              to: meta.to
            })
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  cancelAppointmentPassAsStudent(context, pass) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/passes/appointments/${pass.id}/cancel`, pass.message)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getTodaysAppointmentPasses(context) {
    let params = {
      page: 1,
      per_page: 200,
      between_dates: [
        moment().format("MM/DD/YYYY").toString(),
        moment().format("MM/DD/YYYY").toString()
      ],
      recurrence: false
    }
    params = Object.entries(params).reduce(
      (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
      {}
    )
    return new Promise((resolve, reject) => {
      apiV6nocache
        .get(`/passes/appointments`, {
          params: params
        })
        .then((response) => {
          const data = response.data.data
          if (data) {
            context.commit("UPDATE_TODAYS_APP", data)
            resolve(response)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
