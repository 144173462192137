<template>
  <div v-if="pass.user" class="user-history-pass-box d-flex align-items-center">
    <div class="user-history-pass-flag">
      <PassFlag :pass="pass" />
      <div v-if="isPassEditable" class="cursor-pointer" @click="passTimeEdit()">
        <i class="ri-pencil-line"></i>
      </div>
    </div>
    <div class="flex-1">
      <PassStatus class="mb-2" :pass="pass" />
      <div class="user-info d-flex align-items-center">
        <div v-show="showStudentPhotos" class="c-avatar-pass-history me-2">
          <img
            width="40"
            height="40"
            :src="
              pass.user.avatar
                ? pass.user.avatar
                : 'img/avatars/user-avatar.jpeg'
            "
            class="c-avatar-pass-history-img"
          />
        </div>
        <div>{{ pass.user.first_name }} {{ pass.user.last_name }}</div>
        <div v-if="showPassLimitOrEditBadge" class="pass-bottom-right-badges">
          <div
            v-if="isPassLimitExceeded"
            class="limit-exceeded-badge"
            title="Pass Limit Exceeded"
          >
            <i class="ri-arrow-up-double-line"></i>
          </div>
          <div v-if="isPassEdited" class="is-edited-badge">EDITED</div>
        </div>
        <div v-if="showPassLateBadges" class="pass-bottom-left-badges">
          <div
            v-if="isPassLateToSchool"
            class="late-to-school-badge"
            title="Late To School"
          >
            <i class="ri-rest-time-line"></i>
          </div>
          <div
            v-if="isPassLateToClass"
            class="late-to-class-badge"
            title="Late To Class"
          >
            <i class="ri-alarm-line"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone"
import { computed, inject } from "vue"
import { useStore } from "vuex"
import EditPassHistoryTimeForm from "@/v3components/Forms/EditPassHistoryTimeForm.vue"
import PassStatus from "@/v3components/shared/PassComponents/PassStatus.vue"
import PassFlag from "./PassFlag.vue"
export default {
  props: {
    pass: {
      type: Object,
      required: true
    }
  },
  components: {
    PassStatus,
    PassFlag
  },
  setup(props) {
    const store = useStore()
    const modal = inject("modal")

    const activeSchoolTimeZone = computed(() => {
      return (
        store?.getters?.["schools/activeSchool"]?.timezone ||
        moment?.tz?.guess()
      )
    })
    const isPassLateToSchool = computed(
      () => props?.pass?.tardy_badge === "LTS"
    )
    const isPassLateToClass = computed(() => props?.pass?.tardy_badge === "LTC")
    const showPassLateBadges = computed(
      () => isPassLateToSchool.value || isPassLateToClass.value
    )
    const isPassLimitExceeded = computed(
      () => props?.pass?.badge_flags?.limit_exceeded === true
    )
    const isPassEdited = computed(() => !!props?.pass?.edited_at)
    const showPassLimitOrEditBadge = computed(
      () => isPassLimitExceeded.value || isPassEdited.value
    )
    const currentUser = computed(() => store.getters["authentication/user"])
    const autoPassLimits = computed(() => store.getters["rooms/autoPassLimits"])
    const showStudentPhotos = computed(
      () => store.getters["schools/showStudentPhotos"]
    )

    const isPassEditable = computed(() => {
      if (currentUser.value) {
        const currentActiveSchoolDate = moment
          .tz(activeSchoolTimeZone.value)
          .startOf("day")
        const passCompletedDate = moment(props.pass.completed_at)
          .tz(activeSchoolTimeZone.value)
          .startOf("day")
        const diffInDays = currentActiveSchoolDate.diff(
          passCompletedDate,
          "days"
        )
        if (Number(diffInDays) >= 1) {
          return false
        }

        if (props.pass.approved_by === currentUser.value.id) {
          return true
        }
        if (props.pass.completed_by === currentUser.value.id) {
          return true
        }
        if (props.pass.approved_with == "autopass" && autoPassLimits.value) {
          const autoPassesRooms =
            autoPassLimits.value.auto_pass_preference_to_user.map(
              (el) => el.auto_pass.room_id
            )
          if (autoPassesRooms.includes(props.pass.from.id)) {
            return true
          }
          if (autoPassesRooms.includes(props.pass.to.id)) {
            return true
          }
          return false
        }
      }
      return false
    })

    const onPassTimeChange = (pass) => {
      store.commit("passes/UPDATE_PASS_HISTORY", pass)
    }

    const passTimeEdit = () => {
      modal.open(EditPassHistoryTimeForm, {
        size: "lg",
        title: null,
        props: {
          pass: props.pass,
          events: { passTimeChange: onPassTimeChange }
        }
      })
    }

    return {
      isPassEditable,
      passTimeEdit,
      showStudentPhotos,
      showPassLateBadges,
      isPassLateToSchool,
      isPassLateToClass,
      showPassLimitOrEditBadge,
      isPassLimitExceeded,
      isPassEdited
    }
  }
}
</script>
