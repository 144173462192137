import { apiV7nocache } from "../axiousWithCache"

const state = {
  detentionAdults: []
}

const getters = {
  detentionAdults(state) {
    return state.detentionAdults
  }
}

const mutations = {
  SET_DETENTION_ADULTS(state, adults) {
    state.detentionAdults = adults
  },
  DELETE_DETENTION_ADULT(state, id) {
    state.detentionAdults = state.detentionAdults.filter((el) => el.id != id)
  }
}

const actions = {
  createDetentionAdult(context, adult) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .post(`/admin/tardy/detentions/detention-attendance-candidate`, adult)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getDetentionAdults(context) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get("/admin/tardy/detentions/detention-attendance-candidate")
        .then((response) => {
          const data = response.data
          context.commit("SET_DETENTION_ADULTS", data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteDetentionAdult(context, data) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .delete(`/admin/tardy/detentions/detention-attendance-candidate`, {
          params: data
        })
        .then((response) => {
          const data = response.data
          if (data) {
            context.commit("DELETE_DETENTION_ADULT", data.id)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
