<template>
  <div class="adults-datatable-container">
    <DataTable :fields="adultsTableFields" :items="assignAdults">
      <template #first_name="{ item }">
        <td>
          {{ item.user_id.first_name }}
        </td>
      </template>
      <template #last_name="{ item }">
        <td>
          {{ item.user_id.last_name }}
        </td>
      </template>
      <template #action="{ item }">
        <td class="text-end">
          <Actions
            :actions="adultActions"
            @click-action="handleTableAction($event, item.user_id.id)"
          >
          </Actions>
        </td>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import Actions from "@/v3components/shared/DataTable/Actions.vue"
import { useStore } from "vuex"
import { onMounted, computed, reactive, inject } from "vue"

export default {
  name: "AdultsTable",
  components: { DataTable, Actions },
  setup() {
    const store = useStore()
    const actionDialog = inject("actionDialog")

    const state = reactive({
      isProcessing: false
    })

    onMounted(() => {
      store.dispatch("assignAdults/getAssignAdults")
    })

    const assignAdults = computed(
      () => store.getters["assignAdults/assignAdults"]
    )

    const adultsTableFields = [
      { key: "first_name", label: "First name", sorter: false, filter: false },
      { key: "last_name", label: "Last name", sorter: false, filter: false },
      {
        key: "action",
        label: "Action",
        _style: "width: 50px;",
        sorter: false,
        filter: false
      }
    ]

    const adultActions = [
      {
        label: "Delete",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete"
      }
    ]

    const handleTableAction = (event, item) => {
      if (event.action == "delete") {
        actionDialog.open(deleteAssignAdult, {
          args: item,
          props: { danger: true, title: "Delete", question: "Are you sure?" }
        })
      }
    }

    const deleteAssignAdult = (adultId) => {
      state.isProcessing = true
      store.dispatch("assignAdults/deleteAssignAdult", adultId).finally(() => {
        state.isProcessing = false
        store.dispatch("assignAdults/getAssignAdults")
      })
    }

    return {
      state,
      assignAdults,
      adultsTableFields,
      adultActions,
      handleTableAction,
      deleteAssignAdult
    }
  }
}
</script>
