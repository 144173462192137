<template>
  <div class="stat inverse">
    My Passes
    <span>{{ myActivePassesLength }} / {{ myPassesLength }}</span>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"

export default {
  name: "MyPassesOld",
  setup() {
    const store = useStore()

    const myPassesLength = computed(
      () => store.getters["dashboardCounters/myPassesLength"]
    )

    const myActivePassesLength = computed(
      () => store.getters["dashboardCounters/myActivePassesLength"]
    )

    return {
      myPassesLength,
      myActivePassesLength
    }
  }
}
</script>
