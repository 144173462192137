<template>
  <div class="v3-form detentions-form">
    <div class="row mb-3">
      <Label required>Detention</Label>
      <CustomSelect
        v-model="state.form.selectedDetention"
        :filterable="true"
        :close-on-select="true"
        :options="state.detentions"
        type="textarea"
        :disabled="editableDetention !== null"
        placeholder="Select Detention"
        @update:model-value="onChangeSelectedDetention"
        :invalid-feedback="
          errors.selectedDetention || state.serverErrors.selectedDetention
        "
        :class="{
          'col-md-10': editableDetention
        }"
      ></CustomSelect>
      <IconButton
        v-if="editableDetention"
        icon="ri-delete-bin-line"
        @click="onDelete"
      >
      </IconButton>
    </div>

    <div class="row mb-3" v-if="state.form.selectedDetention">
      <div class="col-md-4">
        <Label required>Start time</Label>
        <CustomTimePicker
          format="HH:mm:ss"
          :invalid-feedback="
            errors.startTime || state.serverErrors.default_start_time
          "
          v-model="state.form.selectedDetention.default_start_time"
          readonly
        ></CustomTimePicker>
      </div>
      <div class="col-md-4">
        <Label required>Room</Label>
        <InputField
          :invalid-feedback="errors.room || state.serverErrors.room"
          :rows="4"
          class="w-full"
          disabled
          v-model="state.form.selectedDetention.room.name"
        ></InputField>
      </div>
      <div class="col-md-4">
        <Label required>Max Capacity</Label>
        <InputField
          :invalid-feedback="errors.room || state.serverErrors.room"
          :rows="4"
          class="w-full"
          disabled
          v-model="state.form.selectedDetention.max_cap"
        ></InputField>
      </div>
    </div>
    <div v-if="!isUpdateMode" class="mb-3 d-flex justify-content-between">
      <Label>Bulk Schedule</Label>
      <CustomSwitch
        v-model="state.isBulkAddEnabled"
        test-id="schedule-detention-bulk-switch"
      />
    </div>
    <div class="mb-3">
      <div v-if="!state.isBulkAddEnabled">
        <Label required>Detention Date</Label>
        <CustomDatePicker
          v-model="state.form.detentionDate"
          :min-date="tomorrow"
          :max-date="maxSchoolYearDate"
          placeholder="Select Date"
          :disabled="selectedDate && !editableDetention"
          :invalid-feedback="
            errors.detentionDate || state.serverErrors.detentionDate
          "
        ></CustomDatePicker>
      </div>
      <div v-else>
        <div class="mb-3 d-flex justify-content-between">
          <Label>Range Mode</Label>
          <CustomSwitch
            v-model="state.isRangeModeEnabled"
            test-id="schedule-detention-mode-switch"
          />
        </div>
        <VueDatePicker
          v-if="state.isRangeModeEnabled"
          ref="bulkDetentionDatePickerRange"
          class="bulk-schedule-calendar"
          inline
          week-start="0"
          :range="{ noDisabledRange: true }"
          hide-offset-dates
          prevent-min-max-navigation
          :min-date="tomorrow"
          :max-date="maxSchoolYearDate"
          :disabled-dates="disabledDates"
          :loading="state.isDetentionDataLoading"
          :enable-time-picker="false"
          :action-row="{ showSelect: false, showPreview: false }"
          @internal-model-change="handleSelectedDates"
          @update-month-year="handleMonthChange"
        />
        <VueDatePicker
          v-else
          ref="bulkDetentionDatePickerMulti"
          class="bulk-schedule-calendar"
          inline
          week-start="0"
          multi-dates
          hide-offset-dates
          prevent-min-max-navigation
          :min-date="tomorrow"
          :max-date="maxSchoolYearDate"
          :disabled-dates="disabledDates"
          :loading="state.isDetentionDataLoading"
          :enable-time-picker="false"
          :action-row="{ showSelect: false, showPreview: false }"
          @internal-model-change="handleSelectedDates"
          @update-month-year="handleMonthChange"
        />
      </div>
    </div>

    <div>
      <InfoBox
        v-if="state.serverRes"
        class="mt-4"
        :class="{ danger: Object.keys(state.serverErrors).length }"
        :title="state.serverRes.title"
        >{{ state.serverRes.message }}</InfoBox
      >
    </div>
    <div class="text-center">
      <LoadingButton class="me-2 px-4" @click="onCancel" rounded
        >Cancel</LoadingButton
      >
      <LoadingButton
        :is-loading="state.isProcessing"
        @click="submit(isUpdateMode)"
        class="mt-4 px-4"
        solid
        rounded
        >{{ isUpdateMode ? "Update" : "Add" }}</LoadingButton
      >
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted, inject, watch, ref } from "vue"
import { useStore } from "vuex"
import { helpers, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import InputField from "@/v3components/shared/Form/InputField.vue"
import Label from "@/v3components/shared/Form/Label.vue"
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"
import CustomSwitch from "@/v3components/shared/Form/CustomSwitch.vue"
import CustomTimePicker from "@/v3components/shared/Form/CustomTimePicker.vue"
import CustomDatePicker from "@/v3components/shared/Form/CustomDatePicker.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import IconButton from "@/v3components/shared/Buttons/IconButton.vue"
import helpersJS from "@/helpers/index.js"
import moment from "moment-timezone"

export default {
  name: "CreateTardyDetentionCalendarForm",
  components: {
    InputField,
    Label,
    CustomTimePicker,
    LoadingButton,
    InfoBox,
    CustomDatePicker,
    CustomSwitch,
    CustomSelect,
    IconButton
  },
  props: {
    editableDetention: {
      type: Object,
      default: null
    },
    dailyDetentionsIds: {
      type: Array,
      default: null
    },
    selectedDate: {
      type: Object,
      default: null
    }
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const store = useStore()
    const actionDialog = inject("actionDialog")
    const state = reactive({
      detentions: [],
      isProcessing: false,
      serverErrors: {},
      serverRes: null,

      form: {
        selectedDetention: null,
        detentionDate: null
      },
      isBulkAddEnabled: false,
      isRangeModeEnabled: false,
      isDetentionDataLoading: false
    })
    onMounted(() => {
      if (props.selectedDate) {
        state.form.detentionDate = helpersJS.currTzDate(props.selectedDate)
      }

      if (props.editableDetention) {
        setFormData({
          selectedDetention: props.editableDetention,
          detentionDate: helpersJS.currTzDate(props.selectedDate)
        })
      } else {
        getDetentions()
      }
      resetMonthToCurrent()
    })

    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()

    const validationMessages = {
      required: "This field is required"
    }
    const validations = {
      form: {
        selectedDetention: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        detentionDate: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isFormValid = computed(() => !v$.value.$invalid)

    const isUpdateMode = computed(() => Boolean(props.editableDetention))

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    const setFormData = (data) => {
      state.form = Object.assign(state.form, data)
    }

    const resetFormData = () => {
      setFormData({
        selectedDetention: null,
        detentionDate: null
      })
      v$.value.$reset()
    }
    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }
    const submit = (isUpdate) => {
      if (isFormValid.value) {
        const data = { dates: [] }
        if (state.form.detentionDate.length) {
          state.form.detentionDate.forEach((date) => {
            data.dates.push({
              schedule_date: helpersJS.currTzDate(date, "MM/DD/YYYY"),
              detention_id: state.form.selectedDetention.id
            })
          })
        } else {
          data.dates.push({
            schedule_date: helpersJS.currTzDate(
              state.form.detentionDate,
              "MM/DD/YYYY"
            ),
            detention_id: state.form.selectedDetention.id
          })
        }
        if (isUpdate) {
          updateScheduledDetention({
            dateId: state.form.selectedDetention.date_id,
            data: data
          })
        } else {
          scheduleDetention(data)
        }
      } else {
        v$.value.$touch()
      }
    }

    const scheduleDetention = (data) => {
      state.isProcessing = true
      store
        .dispatch("detentions/scheduleDetention", data)
        .then(() => {
          resetFormData()
          setResponseInfoBox("Success", "Successfully created!")
          state.isProcessing = false
          state.serverErrors = {}
          setTimeout(() => {
            setResponseInfoBox()
            emit("cancel")
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }
    const updateScheduledDetention = (data) => {
      state.isProcessing = true
      store
        .dispatch("detentions/updateScheduledDetention", data)
        .then(() => {
          setResponseInfoBox("Success", "Successfully updated!")
          state.isProcessing = false
          state.serverErrors = {}
          setTimeout(() => {
            setResponseInfoBox()
            emit("cancel")
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }
    const onCancel = () => {
      resetFormData()
      emit("cancel")
    }

    const getDetentions = () => {
      store
        .dispatch("detentions/getDetentions")
        .then((response) => {
          state.detentions = response.data.data.map((detention) => ({
            ...detention,
            label: detention.name,
            value: detention.id
          }))

          // if we schedule detention from a day selected form we need to filter the detentions
          if (props.dailyDetentionsIds) {
            state.detentions = state.detentions.filter((detention) => {
              return !props.dailyDetentionsIds.includes(detention.id)
            })
          }
        })

        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const bulkDetentionDatePickerRange = ref(null)
    const bulkDetentionDatePickerMulti = ref(null)

    const onChangeSelectedDetention = (detention) => {
      state.selectedDetention = detention
      state.form.detentionDate = props.selectedDate
        ? new Date(props.selectedDate)
        : null
      if (state.isRangeModeEnabled) {
        bulkDetentionDatePickerRange.value?.clearValue()
      } else {
        bulkDetentionDatePickerMulti.value?.clearValue()
      }
    }

    const tomorrow = computed(() => {
      return moment().add(1, "days").format("MM/DD/YYYY")
    })

    const maxSchoolYearDate = computed(() => {
      return helpersJS.maxSchoolYearDate()
    })

    const detentionsCalendarData = computed(
      () => store.getters["detentions/detentionsCalendarDataBulkForm"]
    )

    const disabledDates = computed(() => {
      return state.form.selectedDetention
        ? detentionsCalendarData.value
            .filter(
              (detention) => detention.id === state.form.selectedDetention.id
            )
            .map((detention) => moment(detention.date).utc().format("MM/DD/YYYY"))
        : []
    })

    const getDatesInRange = (startDate, endDate) => {
      const date = new Date(startDate.getTime())
      const dates = []
      while (date <= endDate) {
        dates.push(new Date(date))
        date.setDate(date.getDate() + 1)
      }
      return dates
    }

    const handleSelectedDates = (modelData) => {
      if (modelData?.length) {
        state.form.detentionDate = state.isRangeModeEnabled
          ? getDatesInRange(modelData[0], modelData[1])
          : modelData
      }
    }

    const handleMonthChange = ({ month, year }) => {
      const start = moment({year, month}).startOf('month').format("YYYY-MM-DD")
      const end = moment({year, month}).endOf('month').format("YYYY-MM-DD")
      state.isDetentionDataLoading = true

      getDetentionCalendarData(start, end)
    }

    const resetMonthToCurrent = () => {
      handleMonthChange({
        instance: null,
        month: currentMonth,
        year: currentYear
      })
    }

    const getDetentionCalendarData = (startDate, endDate) => {
      const params = {
        startDate: startDate,
        endDate: endDate
      }
      store.dispatch("detentions/getDetentionsCalendarDataBulkForm", params)
    }

    const onDelete = () => {
      actionDialog.open(deleteDetention, {
        props: { danger: true, title: "Delete", question: "Are you sure?" }
      })
    }
    const deleteDetention = () => {
      state.isProcessing = true
      store
        .dispatch(
          "detentions/deleteScheduledDetention",
          props.editableDetention.date_id
        )
        .then(() => {
          setResponseInfoBox("Success", "Successfully deleted!")
          state.isProcessing = false
          state.serverErrors = {}
          setTimeout(() => {
            setResponseInfoBox()
            emit("cancel")
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
        .finally(() => {
          state.isProcessing = false
        })
    }
    watch(
      () => state.isBulkAddEnabled,
      (bulkAddIsEnabled) => {
        if (!bulkAddIsEnabled) {
          state.form.detentionDate = props.selectedDate
            ? new Date(props.selectedDate)
            : null
        } else {
          resetMonthToCurrent()
        }
      }
    )
    watch(
      () => state.isRangeModeEnabled,
      () => {
        state.form.detentionDate = null
        resetMonthToCurrent()
      }
    )
    watch(
      detentionsCalendarData,
      () => {
        state.isDetentionDataLoading = false
      }
    )
    return {
      state,
      v$,
      submit,
      isFormValid,
      errors,
      isUpdateMode,
      onCancel,
      getDetentions,
      onChangeSelectedDetention,
      onDelete,
      tomorrow,
      maxSchoolYearDate,
      disabledDates,
      handleSelectedDates,
      handleMonthChange,
      bulkDetentionDatePickerRange,
      bulkDetentionDatePickerMulti
    }
  }
}
</script>
<style lang="scss">
.dp__outer_menu_wrap {
  .dp__instance_calendar {
    font-family: "Metropolis-Regular";
  }
  .dp__calendar_item {
    display: flex;
    justify-content: center;
  }
}
.bulk-schedule-calendar div:has(.dp__outer_menu_wrap) {
  width: 100%;
}
</style>
