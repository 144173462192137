<template>
  <div class="lazy-load-select">
    <CustomSelect
      :deselect-from-dropdown="true"
      :id="`lazyLoadSelect-${uuid}`"
      v-model="state.selectedValue"
      :class="[classes, { 'ps__child--consume': state.isOpened }]"
      :selected="selected"
      :multiple="isMultiple"
      :options="selectOptions"
      :clearable="false"
      :placeholder="placeholder ? placeholder : 'Select'"
      :autoscroll="false"
      :close-on-select="checkForCloseSelect"
      :filterable="false"
      :reduce="reduce"
      :selectable="isOptionSelectable"
      :title="title"
      :select-only-names="selectOnlyNames"
      :invalid-feedback="invalidFeedback"
      :ref="`lazyLoadSelect-${uuid}`"
      :disabled="disabled"
      @search="onSearch"
      @update:model-value="onChange"
      @open="onOpenSelect"
      @close="onCloseSelect"
    >
      <template #list-footer>
        <div v-if="showLoadingLabel" class="p-2 cursor-pointer text-center">
          Loading more..
        </div>
      </template>
      <template #footer>
        <slot name="footer" />
      </template>
      <template #no-options>
        <span class="text-black-50 pm-1 d-block">
          Sorry, no matching options
        </span>
      </template>
    </CustomSelect>
  </div>
</template>

<script>
import {
  reactive,
  computed,
  watch,
  onMounted,
  ref,
  getCurrentInstance
} from "vue"
import { useStore } from "vuex"
import CustomSelect from "./CustomSelect.vue"

export default {
  components: { CustomSelect },
  name: "LazyLoadSelect",
  props: [
    "classes",
    "isMultiple",
    "placeholder",
    "selected",
    "type",
    "isEmailHidden",
    "reduce",
    "selectable",
    "externalOptions",
    "showInactive",
    "isInKiosk",
    "default",
    "prependItems",
    "disabled",
    "context",
    "onlyBasicData",
    "refreshOnOpen",
    "excludeOptions",
    "forceClearCache",
    "title",
    "reports",
    "invalidFeedback",
    "closeAfterSelect",
    "selectOnlyNames",
    "addedOptions",
    "field",
    "adultPassRequestDisabled"
  ],
  emits: ["changed", "update:model-value"],
  setup(props, { emit }) {
    const store = useStore()

    const state = reactive({
      selectedValue: null,
      options: [],
      isOpened: false,
      params: {
        per_page: 25,
        page: 1
      },
      searchQuery: "",
      last_page: null,
      total: null,
      isProcessing: false,
      searchKeys: {
        locations: ["name"],
        teacher: ["name"],
        staff: ["name"],
        student: ["name", "email"],
        adults: ["name", "email"],
        combined: ["name", "email"],
        appointment: ["name"],
        kiosk: ["name"],
        locations_availability: ["name"],
        my_locations: ["name"],
        frontdesk: ["name"]
      },
      processingTimeOut: null
    })

    const isLastPage = computed(() => {
      return state.last_page && state.last_page <= state.params.page
    })

    const excludedOptionIDs = computed(() => {
      return props.excludeOptions && props.excludeOptions.length
        ? props.excludeOptions.map((el) => el.value.id)
        : null
    })

    const selectOptions = computed(() => {
      const prependOptions = props.prependItems || []
      const filteredOptions = prependOptions.filter(
        (pi) => !state.searchQuery || pi.label.includes(state.searchQuery)
      )
      const options = props.externalOptions
        ? props.externalOptions
        : state.options
      options == excludedOptionIDs.value
        ? options.filter(
            (option) => !excludedOptionIDs.value.includes(option.value.id)
          )
        : options

      return [...filteredOptions, ...options]
    })

    const showLoadingLabel = computed(() => {
      return !isLastPage.value && !props.externalOptions
    })

    const checkForCloseSelect = computed(() => {
      return props.isMultiple ? false : props.closeAfterSelect
    })

    const alreadyAddedOptions = computed(() => {
      return props?.addedOptions || []
    })

    const disabledOptionIds = computed(() => {
      return alreadyAddedOptions.value?.map((el) => el?.value?.id) || []
    })

    watch(
      () => props.selected,
      () => {
        state.selectedValue = props.selected
      }
    )

    const instance = getCurrentInstance()
    const uuid = ref(instance.uid)

    onMounted(() => {
      state.selectedValue =
        props.default || props.selected || state.selectedValue
      // Emit default value value to update model if set
      if (props.default) {
        emit("changed", state.selectedValue)
      }
      const select = document.getElementById(`lazyLoadSelect-${uuid.value}`)
      if (!select) {
        return
      }
      select.addEventListener(
        "scroll",
        () => {
          const dropdown = select.getElementsByTagName("ul")
          if (state.options && state.options.length && !isLastPage.value) {
            if (
              dropdown[0].offsetHeight + dropdown[0].scrollTop >=
              dropdown[0].scrollHeight
            ) {
              loadMore()
            }
          }
        },
        true
      )
    })

    const isOptionSelectable = (option) => {
      return !disabledOptionIds.value?.includes(option?.value?.id)
    }

    const onSearch = (query, loading) => {
      const searchQuery = query.toString()
      if (
        searchQuery.replace(/\s/g, "").length > 1 ||
        searchQuery.replace(/\s/g, "").length === 0
      ) {
        clearTimeout(state.processingTimeOut)
        state.processingTimeOut = setTimeout(() => {
          resetParams()
          state.searchQuery = searchQuery ? searchQuery : ""
          getOptions(state.params, loading)
          state.selectedValue = props.selected
        }, 600)
      }
    }

    const onChange = (value) => {
      emit("changed", value)
      emit("update:model-value", value)
    }

    const loadMore = () => {
      if (state.processingTimeOut) {
        clearTimeout(state.processingTimeOut)
      }
      state.processingTimeOut = setTimeout(() => {
        state.params.page++
        getOptions(state.params, false, true)
      }, 100)
    }

    const onOpenSelect = () => {
      if (
        state.params &&
        state.params.page > 1 &&
        state.selectedValue.length == 0
      ) {
        resetParams()
      }
      if (!props.disabled) {
        getOptions(state.params)
      }
      if (props.refreshOnOpen) {
        refreshSelect()
      }
      state.isOpened = true
    }

    const onCloseSelect = () => {
      state.isOpened = false
    }

    const resetParams = () => {
      state.params = {
        per_page: 25,
        page: 1
      }
    }

    const getOptions = (params, loading, isLoadMore) => {
      if (!props.externalOptions) {
        let search_query = ""
        const newParams = { ...params, type: props.type }

        if (state.searchQuery && state.searchKeys[props.type]) {
          state.searchKeys[props.type].forEach((key) => {
            search_query = search_query + `${key}:"${state.searchQuery}", `
          })
          if (state.searchKeys[props.type].includes("email")) {
            newParams.operator = "OR"
          }
        }
        if (props.showInactive) {
          newParams.active_only = false
        } else {
          newParams.active_only = true
        }
        if (search_query) {
          newParams.search_query = search_query.slice(0, -2)
        } else {
          newParams.search_query = ""
        }
        if (props.type === "appointment") {
          newParams.only_allowed_apt =
            props.field && props.field === "departing_from" ? false : true
        }

        if (props.adultPassRequestDisabled) {
          newParams.adultPassRequestDisabled = true
        }

        if (props.authUuid) {
          newParams.cus_uuid = props.authUuid
        }
        if (loading) {
          loading(true)
          state.isProcessing = true
        }
        if (props.reports) {
          newParams.reports = true
        }

        if (props.context) {
          newParams.context = props.context
        }

        if (props.forceClearCache) {
          store
            .dispatch(
              `${
                props.isInKiosk ? "kiosks" : "search"
              }/searchByTypeAndQueryAxios`,
              newParams
            )
            .then((response) => {
              setData(response, loading, isLoadMore)
            })
        } else {
          store
            .dispatch(
              `${props.isInKiosk ? "kiosks" : "search"}/searchByTypeAndQuery`,
              newParams
            )
            .then((response) => {
              setData(response, loading, isLoadMore)
            })
        }
      }
    }

    const setData = (response, loading, isLoadMore) => {
      const data = response.data.data
      const meta = response.data.meta
      if (data) {
        state.options = isLoadMore ? state.options : []
        if (Array.isArray(data)) {
          pushOptions(data)
        } else {
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              const element = data[key]
              if (element && element.data) {
                pushOptions(element.data)
              }
            }
          }
        }
      }
      if (meta) {
        state.last_page = meta.last_page
        state.total = meta.total
      }
      if (loading) {
        loading(false)
      }
      state.isProcessing = false
    }

    const pushOptions = (data) => {
      data.forEach((option) => {
        if (!option.role_id) {
          if (props.onlyBasicData) {
            state.options.push({
              value: {
                id: option.id,
                type: "App\\Models\\Room"
              },
              label: option.name
            })
          } else {
            state.options.push({
              value: {
                id: option.id,
                type: "App\\Models\\Room",
                comment_type: option.comment_type,
                unavailability: Object.prototype.hasOwnProperty.call(
                  option,
                  "active_unavailability"
                )
                  ? option.active_unavailability
                  : null
              },
              label: option.name,
              capacity: option.capacity ? option.capacity : null,
              owner_pin: option.owner_pin && option.owner_pin.pin,
              restriction: option.restriction ? option.restriction : null
            })
          }
        } else {
          state.options.push({
            value: {
              id: option.id,
              type: "App\\Models\\User",
              passesToday: option.passes_for_today_count,
              unavailability: Object.prototype.hasOwnProperty.call(
                option,
                "active_unavailability"
              )
                ? option.active_unavailability
                : null
            },
            label: option.email
              ? option.first_name + " " + option.last_name + ", " + option.email
              : option.first_name + " " + option.last_name
          })
        }
        state.options.sort((a, b) => {
          return b.value.type.localeCompare(a.value.type)
        })
      })
    }

    const refreshSelect = () => {
      getOptions(state.params, false)
    }

    return {
      uuid,
      state,
      selectOptions,
      showLoadingLabel,
      isLastPage,
      onSearch,
      onChange,
      onOpenSelect,
      onCloseSelect,
      resetParams,
      getOptions,
      setData,
      pushOptions,
      refreshSelect,
      checkForCloseSelect,
      isOptionSelectable
    }
  }
}
</script>
