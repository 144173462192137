.vue<template>
  <div class="my-2" data-test-id="student-create-apt-pass-form">
    <Label class="mt-2" required> Departing From: </Label>
    <LazyLoadSelect
      data-test-id="stu-apt-pass-fromid"
      v-model="state.form.from"
      :invalid-feedback="errors.from"
      :is-multiple="false"
      :selected="state.form.from"
      type="appointment"
      field="departing_from"
      placeholder="Teacher/Location"
      @changed="
        (value) => {
          state.form.from = value
        }
      "
    >
    </LazyLoadSelect>
    <InfoBox class="danger mt-2 mb-2" v-if="state.serverErrors.from_id">
      {{ state.serverErrors.from_id[0] }}
    </InfoBox>

    <FavoriteCarousel
      :key="'departing'"
      :selected="state.form.from"
      :is-editable="true"
      :action="
        (elem) => {
          state.form.from = elem
        }
      "
      help-center-key="to_add_favorite_teacher_txt"
      :isvalid="errors.from"
      class="mb-3"
      section="departing"
    />

    <Label class="mt-2" required> Destination: </Label>

    <LazyLoadSelect
      data-test-id="stu-apt-pass-toid"
      v-model="state.form.to"
      :invalid-feedback="errors.to"
      :is-multiple="false"
      :selected="state.form.to"
      type="appointment"
      :adult-pass-request-disabled="true"
      :force-clear-cache="true"
      placeholder="Teacher/Location"
      @changed="
        (value) => {
          onChangeEvent(value)
        }
      "
    >
      <template #footer> </template>
    </LazyLoadSelect>
    <InfoBox
      v-if="
        state.form.to &&
        state.form.to.value &&
        state.form.to.value.unavailability
      "
      class="danger mt-2 mb-2"
    >
      This destination is unavailable until
      <span
        class="mx-1"
        v-html="
          $helpers.getHTMLDateTime(
            state.form.to.value.unavailability.to_date,
            'MMMM D, YYYY',
            'h:mm A'
          ) + '.'
        "
      />
      <span v-if="state.form.to.value.unavailability.comment"
        >Note: {{ state.form.to.value.unavailability.comment }}</span
      >
    </InfoBox>
    <InfoBox class="danger mt-2 mb-2" v-if="state.serverErrors.to_id">
      {{ state.serverErrors.to_id[0] }}
    </InfoBox>

    <FavoriteCarousel
      :key="'destination'"
      :selected="state.form.to"
      :is-editable="true"
      :show-unavailables="true"
      :action="
        (elem) => {
          onChangeEvent(elem)
        }
      "
      :isvalid="errors.to"
      section="destination"
    />

    <div class="row mt-3">
      <div class="col">
        <Label required>Date</Label>
        <div data-test-id="stu-apt-pass-date">
          <CustomDatePicker
            v-model="state.form.for_date"
            :masks="{ L: 'MM/DD/YYYY' }"
            input-class="d-block"
            placeholder="Date"
            :min-date="minDate"
            :max-date="maxDate"
            @update:model-value="checkUnavailability"
            :invalid-feedback="errors.for_date"
          >
          </CustomDatePicker>
        </div>
      </div>
      <div class="col">
        <Label required>Period</Label>
        <CustomSelect
          data-test-id="stu-apt-pass-period"
          v-if="periods"
          v-model="state.form.period"
          :options="periods"
          placeholder="Select one"
          :clearable="false"
          :filterable="true"
          :close-on-select="true"
          :invalid-feedback="errors.period"
        ></CustomSelect>
      </div>
      <div class="col">
        <Label required>Time</Label>
        <CustomTimePicker
          data-test-id="stu-apt-pass-time"
          format="HH:mm"
          v-model="state.form.time"
          placeholder="Time"
          @update:model-value="checkUnavailability"
          @input="checkUnavailability"
          :invalid-feedback="errors.time"
        >
        </CustomTimePicker>
        <div v-if="state.serverErrors.time" class="invalid-message text-start">
          {{ state.serverErrors.time[0] }}
        </div>
      </div>
    </div>

    <div
      v-if="
        state.form &&
        state.form.to &&
        state.form.to.value &&
        state.form.to.value.comment_type != 'Hidden'
      "
      class="mt-2"
    >
      <Label> Reason </Label>
      <InputField
        data-test-id="stu-apt-pass-comment"
        v-model="state.form.reason"
        :invalid-feedback="errors.reason"
        max-chars="250"
        :rows="5"
        class="w-full"
        type="textarea"
        :placeholder="
          state.form.to.value.comment_type == 'Optional'
            ? 'Reason for pass (optional)'
            : 'Reason for pass (required)'
        "
      />
      <div v-if="state.serverErrors.reason" class="invalid-message text-start">
        {{ state.serverErrors.reason[0] }}
      </div>
    </div>

    <InfoBox
      v-if="state.serverRes"
      data-test-id="stu-apt-pass-form-error"
      class="mt-3"
      :class="{
        danger:
          Object.keys(state.serverErrors).length ||
          state.serverRes.title == 'Error'
      }"
      :title="state.serverRes.title"
      >{{ state.serverRes.message }}</InfoBox
    >

    <div class="form-group form-actions mt-2 text-center">
      <div class="d-inline">
        <LoadingButton
          :is-loading="state.isLoading"
          data-test-id="create-apt-pass-form-submit"
          solid
          rounded
          class="mt-2 btn"
          @click="submit()"
        >
          Submit
        </LoadingButton>
      </div>
    </div>
  </div>
</template>

<script>
import { helpers, required, requiredIf } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import moment from "moment-timezone"
import Label from "@/v3components/shared/Form/Label.vue"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect.vue"
import CustomDatePicker from "@/v3components/shared/Form/CustomDatePicker.vue"
import CustomTimePicker from "@/v3components/shared/Form/CustomTimePicker.vue"
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import FavoriteCarousel from "@/v3components/FavoriteCarousel.vue"
import { reactive, computed, inject, watch } from "vue"
import { useStore } from "vuex"
import helpersJS from "@/helpers/index.js"
import AptPassSuccesModal from "@/v3components/Forms/Students/AptPassSuccesModal.vue"

export default {
  name: "CreateAppointmentPassForm",
  components: {
    LazyLoadSelect,
    FavoriteCarousel,
    Label,
    CustomDatePicker,
    CustomTimePicker,
    CustomSelect,
    InputField,
    InfoBox,
    LoadingButton
  },
  setup() {
    const store = useStore()

    const modal = inject("modal")

    const state = reactive({
      isLoading: false,
      form: {
        from: [],
        to: [],
        reason: "",
        for_date: null,
        time: null
      },
      serverErrors: {},
      serverRes: null,
      requestIntreval: null,
      showAppPassSuccesModal: false
    })

    const periods = computed(() => store.getters["passes/formListPeriods"])
    const minDate = computed(() => {
      return moment().format("MMM DD YYYY")
    })
    const maxDate = computed(() => {
      const oneMonthFromNow = moment().add(1, "M").format("MMM DD YYYY")
      const maxSchoolYearDate = helpersJS.maxSchoolYearDate()
      return moment(oneMonthFromNow).isBefore(moment(maxSchoolYearDate))
        ? oneMonthFromNow
        : maxSchoolYearDate
    })

    const validationMessages = {
      required: "This field is required",
      requiredReason: "Reason for pass is required",
      minTime:
        "The time must be 30 minutes from now and not more than a month!",
      betweenTime: "You must pick a time between 6AM and 5PM."
    }

    const validations = {
      form: {
        from: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        to: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        reason: {
          required: helpers.withMessage(
            validationMessages.requiredReason,
            requiredIf(() => {
              if (state.form.to && state.form.to.value) {
                return state.form.to.value.comment_type == "Optional"
                  ? false
                  : state.form.to.value.comment_type == "Hidden"
                    ? false
                    : true
              }
            })
          )
        },
        time: {
          betweenTime: helpers.withMessage(
            validationMessages.betweenTime,
            () => {
              return moment(state.form.time, "HH:mm a").isBetween(
                moment("05:59 AM", "HH:mm a"),
                moment("05:01 PM", "HH:mm a")
              )
            }
          ),
          minTime: helpers.withMessage(validationMessages.minTime, () => {
            if (
              state.form.for_date &&
              state.form.time &&
              moment()
                .add(30, "minutes")
                .isSameOrBefore(
                  moment(
                    moment(
                      new Date(state.form.for_date).toLocaleDateString("en-GB"),
                      "DD-MM-YYYY"
                    ).format("YYYY-MM-DD") +
                      " " +
                      state.form.time
                  )
                )
            ) {
              return true
            } else {
              return false
            }
          }),

          required: helpers.withMessage(validationMessages.required, required)
        },
        for_date: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        period: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }

    const v$ = useVuelidate(validations.form, state.form)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    const onChangeEvent = (value) => {
      state.form.to = value
      v$.value.$reset()
      setResponseInfoBox()
      checkUnavailability()
    }

    const checkUnavailability = () => {
      if (!state.form.to?.value || !state.form.for_date || !state.form.time) {
        state.form.to?.value && (state.form.to.value.unavailability = null)
        return
      }
      clearTimeout(state.requestIntreval)
      state.requestIntreval = setTimeout(() => {
        const params = {
          type: state.form.to.value.type,
          id: state.form.to.value.id,
          apt_for_time: helpersJS.currTzDateTime(
            state.form.for_date,
            state.form.time
          )
        }
        store
          .dispatch("rooms/getUnavailability", params)
          .then((response) => {
            if (response.data.data) {
              state.form.to.value.unavailability = response.data.data
            } else {
              state.form.to.value.unavailability = null
            }
          })
          .catch(() => {
            state.form.to.value.unavailability = null
          })
      }, 600)
    }

    const submit = () => {
      setResponseInfoBox()
      state.isLoading = true
      const isAptToRoom =
        state.form?.to?.value?.type == "App\\Models\\Room" &&
        v$.value.$silentErrors?.length == 1

      if (isValid.value || isAptToRoom) {
        const appointmentPass = {
          from_id: state.form.from.value.id,
          to_id: state.form.to.value.id,
          from_type: state.form.from.value.type,
          to_type: state.form.to.value.type,
          for_date: helpersJS.currTzDateTime(
            state.form.for_date,
            state.form.time
          ),
          time: helpersJS.currTzDateTime(state.form.for_date, state.form.time),
          reason: state.form.reason,
          period_id: state.form.period.value[0]
        }

        store
          .dispatch(
            "studentAptPass/createStudentAppointmentPass",
            appointmentPass
          )
          .then(() => {
            state.isLoading = true
            state.serverErrors = {}
            setResponseInfoBox(
              "Success",
              "Appointment pass created successfully"
            )

            modal.open(AptPassSuccesModal, {
              size: "lg",
              title: "",
              props: { form: state.form }
            })
          })
          .catch((err) => {
            const res = err.response.data
            state.serverErrors = res.errors
              ? res.errors
              : { errorSubmit: ["Submit failed. Please try again later."] }
            setResponseInfoBox("Error", res.message)
            state.isLoading = false
          })
      } else {
        v$.value.$touch()
        state.isLoading = false
      }
    }

    const closePassSuccess = () => {
      setResponseInfoBox()
      state.form = {
        from: [],
        to: [],
        reason: "",
        for_date: null,
        time: null
      }
      v$.value.$reset()
      state.isLoading = false
    }

    watch(
      () => modal.options.action,
      (isAction) => {
        if (isAction == "closeStudentAptPassSuccessModal") {
          closePassSuccess()
        }
      }
    )

    watch(
      () => modal.options.visible,
      (isVisible) => {
        if (!isVisible) {
          closePassSuccess()
        }
      }
    )

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    return {
      state,
      periods,
      minDate,
      onChangeEvent,
      submit,
      checkUnavailability,
      closePassSuccess,
      errors,
      setResponseInfoBox,
      maxDate
    }
  }
}
</script>

<style scope lang="scss">
.periods {
  #vs5__listbox {
    max-height: 110px !important;
  }
}
.ap-cs-date-picker .input-icon-field .form-control.is-invalid {
  pointer-events: auto;
  // margin-bottom: 7rem !important;
}
</style>
