<template>
  <div class="v3custom-dropdown">
    <div
      class="custom-drop-down"
      :class="{ 'no-bottom-border': state.isVisible }"
    >
      <div class="toggle" @click="toggleDropdown">
        <span>{{ selectedLabel || label }}</span>
        <i :class="iconClass" />
      </div>
    </div>
    <div
      v-if="options"
      :class="{ 'd-block no-top-border': state.isVisible }"
      class="dropdown-menu"
    >
      <div
        v-for="(item, index) in options"
        @click="selectActive(item)"
        :key="index"
        role="menuitem"
        :class="{
          active: selectedItems.length
            ? selectedItems.includes(item)
            : item.label == label
        }"
        target="_self"
        class="dropdown-item"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, computed } from "vue"

export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    selectedLabel: String,
    selectedItems: {
      type: Array,
      default: () => []
    }
  },
  emits: ["itemClick"],
  setup(props, { emit }) {
    const state = reactive({
      isVisible: false,
      selectedOption: null
    })

    const toggleDropdown = () => {
      state.isVisible = !state.isVisible
    }

    const selectActive = (item) => {
      state.selectedOption = item
      state.isVisible = false
      emit("itemClick", item)
    }

    const label = computed(() => {
      return state.selectedOption ? state.selectedOption.label : ""
    })

    const iconClass = computed(() => {
      return state.isVisible ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"
    })

    onMounted(() => {
      state.selectedOption = props.options.length ? props.options[0] : null
      document.addEventListener("click", (e) => {
        if (!e.target.closest(".custom-drop-down")) {
          state.isVisible = false
        }
      })
    })

    return {
      state,
      toggleDropdown,
      selectActive,
      label,
      iconClass
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/v3scss/dropdown/custom-dropdown.scss";
</style>
