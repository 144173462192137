<template>
  <div class="visitor-parent-search-item">
    <!-- PARENT -->
    <div class="visitor-info">
      <i class="ri-user-line"></i>
      <div>
        {{ data.parent_first_name }}
        {{ data.parent_last_name }}
      </div>
    </div>
    <!-- PARENT -->

    <!-- STUDENTS -->
    <div class="visitor-info">
      <i class="ri-group-line"></i>
      <div>
        {{
          data?.students
            ?.split(", ")
            ?.map((student) => {
              return student.split("|").slice(1).join(" ")
            })
            ?.join(", ") || "-"
        }}
      </div>
    </div>
    <!-- STUDENTS -->

    <!-- TELEPHONE -->
    <div class="visitor-info">
      <i class="ri-phone-line"></i>
      <div>{{ phoneNumber || "-" }}</div>
    </div>
    <!-- TELEPHONE -->

    <!-- DATE OF BIRTH -->
    <div class="visitor-info">
      <i class="ri-calendar-line"></i>
      <div>{{ dateOfBirth || "-" }}</div>
    </div>
    <!-- DATE OF BIRTH -->
  </div>
</template>

<script>
import passHelpers from "@/helpers/index"
import moment from "moment-timezone"
import { computed } from "vue"
export default {
  name: "VisitorParentSearchItem",
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const dateOfBirth = computed(() => {
      const isValidDate = moment(props?.data?.date_of_birth).isValid() || false
      return isValidDate
        ? passHelpers.currTzDate(props?.data?.date_of_birth, "MM-DD-YYYY")
        : ""
    })

    const phoneNumber = computed(() => {
      const number = props?.data?.phone_number || null
      return number ? passHelpers.phoneNumberFormatter(number) : ""
    })

    return {
      dateOfBirth,
      phoneNumber
    }
  }
}
</script>
