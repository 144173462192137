<template>
  <div class="container">
    <div class="row help-page justify-content-center text-center mt-4">
      <h1 class="mb-4">Help Desk</h1>
      <iframe
        src="https://docs.google.com/document/d/e/2PACX-1vTgDhe0g6mupHl1cQ1VFUeOGc8TsIubljMBSqZStzY3hcxlwMg_Cxg8RlONbnbADuQpsmjFX9BH9rxJ/pub?embedded=true"
        class="trainings-iframe centered-content-iframe"
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: "HelpDeskPortal"
}
</script>
