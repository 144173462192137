<template>
  <div class="v3-custom-checkbox" :class="'type-' + inputType">
    <input
      :checked="modelValue"
      @change="onChange"
      :type="inputType"
      v-bind="{ name, disabled }"
      :data-test-id="testId"
    />
    <div class="checkbox-toggler"><i class="ri-check-line check-icon"></i></div>
    <div v-if="label" class="checkbox-label flex-1 ms-2">{{ label }}</div>
  </div>
</template>

<script>
import { computed } from "vue"
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "checkbox"
    },
    testId: {
      type: String,
      default: ""
    },
    modelValue: Boolean
  },
  emits: ["change", "update:model-value"],
  setup(props, { emit }) {
    const onChange = (event) => {
      emit("change", event.target.checked)
      emit("update:model-value", event.target.checked)
    }
    const inputType = computed(() => {
      return props.type == "checkbox" ? "checkbox" : "radio"
    })
    return {
      onChange,
      inputType
    }
  }
}
</script>

<style></style>
