<template>
  <div v-if="allowVisitorComponents">
    <slot />
  </div>
</template>

<script>
import { shouldVisitorWebAppBeHidden } from "@/helpers/featureFlags"

export default {
  name: "VisitorWrapper",
  setup() {
    const allowVisitorComponents = !shouldVisitorWebAppBeHidden()
    return { allowVisitorComponents }
  },
  watch: {
    allowVisitorComponents: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.$router.push("/404")
        }
      }
    }
  }
}
</script>

<style scoped></style>
