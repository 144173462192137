import axios from "axios"

const state = {
  comments: []
}

const getters = {
  comments(state) {
    return state.comments
  }
}

const mutations = {
  SET_COMMENTS(state, comentsData) {
    state.comments = comentsData
  }
}

const actions = {
  createComment(context, comment) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/comments/${comment.toID}`, comment.data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getPassComments(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/comments/passes/${data.passId}?commentable_type=${data.commentableType}`
        )
        .then((response) => {
          const data = response.data.data
          const unduplicatedComments = data.filter(
            (elem, index) =>
              data.findIndex(
                (obj) =>
                  obj.created_at === elem.created_at &&
                  obj.commentable_id === elem.commentable_id
              ) === index
          )
          context.commit("SET_COMMENTS", unduplicatedComments)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
