<template>
  <div class="select-filters d-flex align-items-center flex-wrap">
    <div class="d-flex align-items-start align-items-center">
      <CustomCheck
        v-model="recurrence"
        @change="setRecurrenceOnly"
        label="Recurring"
        name="Recurring"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex"
import { computed } from "vue"
import CustomCheck from "@/v3components/shared/Form/CustomCheck.vue"

export default {
  name: "StudentAptPassFilters",
  components: {
    CustomCheck
  },
  setup() {
    const store = useStore()

    const recurrence = computed(
      () => store.getters["studentAptPass/recurrenceOnly"]
    )

    const setRecurrenceOnly = (data) => {
      store.commit("studentAptPass/SET_RECURRENCE_ONLY", data)
    }
    return {
      setRecurrenceOnly,
      recurrence
    }
  }
}
</script>

<style></style>
