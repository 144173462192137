<template>
  <CFormCheck
    v-bind="{ type, label, inline, disabled }"
    :checked="modelValue"
    class="custom-checkbox"
    @update:model-value="onUpdateModelValue"
    @change="onChange"
  />
</template>

<script>
export default {
  emits: ["update:modelValue", "change"],
  props: {
    custom: {
      default: false,
      type: Boolean
    },
    modelValue: {
      default: false,
      type: Boolean
    },
    label: {
      default: "",
      type: String
    },
    inline: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    type: {
      default: "checkbox",
      type: String
    }
  },
  setup(props, { emit }) {
    const onUpdateModelValue = (value) => {
      emit("update:modelValue", value)
    }
    const onChange = (e) => {
      emit("change", e)
    }
    return {
      onUpdateModelValue,
      onChange
    }
  }
}
</script>
