<template>
  <div class="container">
    <div class="row pass-block-datatable justify-content-center mt-4">
      <ActionPanel
        class="mb-3"
        show-search
        show-export
        @on-search="searchByQuery"
        @on-export="handlePassBlocks(state.pagination.activePage, true)"
      ></ActionPanel>
      <div class="d-block mb-3">
        <PerPage
          v-if="passBlocks"
          :pagination="state.pagination"
          :showing-entries="true"
          @on-page-change="setPerPage"
        />
      </div>
      <DataTable
        data-test-id="pass-block-table"
        v-if="passBlocks"
        class="datatable bg-white"
        :items="passBlocks"
        :fields="dataTableFields"
        :sorter="{ external: true }"
        column-filter
        :no-items-view="{
          noResults: 'No items available',
          noItems: 'No items available'
        }"
        @update:sorter-value="sortByColumn"
      >
        <template #search_column>
          <div class="sort-icon-v3">
            <i class="ri-search-line" @click="toggleSearchByColumn"></i>
          </div>
        </template>

        <template #from_date="{ item }">
          <td>
            <div v-if="item.from_date">
              <div>
                {{ $helpers.transformDate(item.from_date, "MM/DD/YYYY") }}
              </div>
              <div>
                {{ $helpers.transformDate(item.from_date, "h:mm A") }}
              </div>
            </div>
          </td>
        </template>
        <template #from_date-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.from_date.value"
            placeholder="Search by date"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column')"
          />
        </template>

        <template #to_date="{ item }">
          <td>
            <div v-if="item.to_date">
              <div>
                {{ $helpers.transformDate(item.to_date, "MM/DD/YYYY") }}
              </div>
              <div>
                {{ $helpers.transformDate(item.to_date, "h:mm A") }}
              </div>
            </div>
          </td>
        </template>
        <template #to_date-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.to_date.value"
            placeholder="Search by date"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column')"
          />
        </template>
        <template #recurrence_days="{ item }">
          <td>
            <div v-if="item.recurrence_type" class="d-flex">
              <div class="me-2 text-lowercase">
                {{ item.recurrence_type }}
              </div>
              <div
                v-if="item.recurrence_days"
                class="weekdays-options-container d-flex"
              >
                <div
                  v-for="(day, index) in item.recurrence_days.days"
                  :key="index"
                  class="weekday-box active me-1 cursor-default"
                >
                  {{ day.substring(0, 2) }}
                </div>
              </div>
            </div>
            <div v-if="item.recurrence_type" class="font-smaller">
              {{ $helpers.transformDate(item.recurrence_start_at, "MM/D/YY") }}
              <i class="ri-arrow-right-s-line v3base-blue"></i>
              {{ $helpers.transformDate(item.recurrence_end_at, "MM/D/YY") }}
            </div>
            <div v-else>does not repeat</div>
          </td>
        </template>
        <template #recurrence_days-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.recurrence_days.value"
            placeholder="Search by recurrence"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column')"
          />
        </template>

        <template #reason="{ item }">
          <td class="comments-column">
            <div class="v3-comments-list comments-list">
              <div class="comment">
                {{ item.reason ? item.reason : "" }}
              </div>
            </div>
          </td>
        </template>
        <template #reason-filter>
          <input
            v-show="state.filter"
            v-model="state.columnSearchKeys.reason.value"
            placeholder="Search by reason"
            class="form-control form-control-sm my-2"
            @input="searchByQuery($event.target.value, 'column')"
          />
        </template>

        <template #action="{ item }">
          <td>
            <div class="d-flex">
              <Actions
                data-test-id="pass-block-actions"
                :actions="filteredPassBlockActions(item)"
                @click-action="handleTableAction($event, item)"
              >
              </Actions>
            </div>
          </td>
        </template>
      </DataTable>
    </div>

    <Pagination
      v-if="
        state.pagination.pages &&
        state.pagination.pages > 1 &&
        !state.isLazyLoadingMode
      "
      class="cs-pagination mt-4"
      :dots="false"
      :double-arrows="true"
      :active-page="state.pagination.activePage"
      :pages="state.pagination.pages"
      align="center"
      size="lg"
      @update:active-page="setActivePage"
    >
    </Pagination>
  </div>
</template>
<script>
import { reactive, computed, onMounted, watch, inject } from "vue"
import { useStore } from "vuex"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel.vue"
import PerPage from "@/v3components/shared/DataTable/Perpage.vue"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import Actions from "@/v3components/shared/DataTable/Actions.vue"
import Pagination from "@/v3components/shared/DataTable/Pagination.vue"
import CreatePassBlockingForm from "@/v3components/Forms/CreatePassBlockingForm.vue"
import helpers from "@/helpers/index.js"
import download from "@/helpers/downloadCSV.js"

export default {
  name: "PassBlockingTable",
  components: {
    ActionPanel,
    PerPage,
    DataTable,
    Actions,
    Pagination
  },
  emits: [],
  setup() {
    const store = useStore()
    const actionDialog = inject("actionDialog")
    const modal = inject("modal")

    const state = reactive({
      isLazyLoadingMode: false,
      pagination: {
        activePage: 1,
        total: 0,
        to: 0,
        pages: 0,
        per_page: { label: "25", value: 25 }
      },
      searchTimeOut: null,
      filter: false,
      filterParams: {
        sort_query: "",
        search_query: ""
      },
      columnSearchKeys: {
        globalQuery: "",
        from_date: {
          value: "",
          column: ["from_date.raw"],
          sortBy: "from_date"
        },
        to_date: { value: "", column: ["to_date.raw"], sortBy: "to_date" },
        recurrence_days: {
          value: "",
          column: ["recurrence_type"],
          sortBy: "recurrence_type"
        },

        reason: { value: "", column: ["reason"], sortBy: "reason" }
      }
    })

    const dataTableFields = [
      { key: "from_date", label: "Start Time" },
      { key: "to_date", label: "End Time" },
      {
        key: "recurrence_days",
        label: "Recurrence",
        _style: "min-width:140px"
      },
      { key: "reason", label: "Reason" },
      { key: "action", label: "Actions", _style: "width:50px", filter: false }
    ]

    const passBlockActions = [
      {
        label: "Edit",
        icon: "ri-edit-line",
        action: "edit"
      },
      {
        label: "Delete",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete"
      }
    ]

    const passBlocks = computed(() => store.getters["passBlocks/passBlocks"])

    watch(
      () => modal.options.action,
      (isAction) => {
        if (isAction == "createPassBlock") {
          handlePassBlocks()
        }
      }
    )

    onMounted(() => {
      initLazyLoading()
      handlePassBlocks()
    })

    const toggleSearchByColumn = () => {
      state.filter = !state.filter
    }

    const filteredPassBlockActions = (item) => {
      return passBlockActions.filter((actionData) => {
        if (actionData.action === "edit") {
          return isExpired(item)
        }
        return true
      })
    }

    const isExpired = (passBlock) => {
      if (
        passBlock.recurrence_type == "WEEKLY" ||
        passBlock.recurrence_type == "DAILY"
      ) {
        return (
          passBlock.recurrence_end_at &&
          helpers.transformDate(
            passBlock.recurrence_end_at,
            "YYYY-MM-DD HH:mm:ss"
          ) >= helpers.date().format("YYYY-MM-DD HH:mm:ss")
        )
      } else {
        return (
          passBlock.to_date &&
          helpers.transformDate(passBlock.to_date, "YYYY-MM-DD HH:mm:ss") >=
            helpers.date().format("YYYY-MM-DD HH:mm:ss")
        )
      }
    }

    const handlePassBlocks = (page, isExport, isDelete, isLazyLoadingMode) => {
      if (isDelete && page) {
        state.pagination.total = state.pagination.total - 1
        state.pagination.pages = Math.ceil(
          state.pagination.total / state.pagination.per_page.value
        )
        state.pagination.activePage =
          page < state.pagination.pages ? page : state.pagination.pages
      } else {
        state.pagination.activePage = page ? page : 1
      }
      const params = {
        page: state.pagination.activePage,
        per_page: state.pagination.per_page.value
      }
      if (state.filterParams.search_query) {
        params.search_query = state.filterParams.search_query
      }
      if (state.filterParams.sort_query) {
        params.sort = state.filterParams.sort_query
      }
      if (isExport) {
        exportCSVPolarities(params)
      } else {
        getPassBlocks(params, isLazyLoadingMode)
      }
    }

    const getPassBlocks = (params, isLazyLoadingMode) => {
      store.dispatch("passBlocks/getPassBlocks", params).then((response) => {
        const data = response.data
        helpers.scrollToTop()
        if (data) {
          if (isLazyLoadingMode) {
            store.commit("passBlocks/PUSH_PASS_BLOCKS", data.data)
          } else {
            store.commit("passBlocks/SET_PASS_BLOCKS", data.data)
          }
          if (data.meta) {
            state.pagination.total = data.meta.total
            state.pagination.from = data.meta.from
            state.pagination.to = data.meta.to
            state.pagination.pages = Math.ceil(
              data.meta.total / data.meta.per_page
            )
            state.pagination.activePage = Number(state.pagination.activePage)
            state.pagination = JSON.parse(JSON.stringify(state.pagination))
          }
        }
      })
    }

    const exportCSVPolarities = (params) => {
      params.per_page = [25, 50, 100].includes(state.pagination.per_page.value)
        ? state.pagination.per_page.value
        : "all"
      store
        .dispatch("passBlocks/getCSVpassBlocksExport", params)
        .then((response) => {
          if (response.data) {
            download.CSVExport(response.data, "pass_blocking")
          }
        })
    }

    const searchByQuery = (value, type) => {
      clearInterval(state.searchTimeOut)
      state.searchTimeOut = setTimeout(() => {
        handleQuerySearch(value, type)
      }, 500)
    }

    const handleQuerySearch = (value, type) => {
      const query = value.toString()
      if (
        query.replace(/\s/g, "").length > 0 ||
        query.replace(/\s/g, "").length === 0
      ) {
        state.filterParams.search_query = ""
        if (type && type === "column") {
          state.columnSearchKeys.globalQuery = ""
          for (const key in state.columnSearchKeys) {
            if (Object.hasOwnProperty.call(state.columnSearchKeys, key)) {
              const columnData = state.columnSearchKeys[key]
              if (columnData.value) {
                columnData.column.forEach((col) => {
                  state.filterParams.search_query =
                    state.filterParams.search_query +
                    `${col}:"${columnData.value}", `
                })
              }
            }
          }
          state.filterParams.search_query =
            state.filterParams.search_query.slice(0, -2)
        } else {
          resetSearchQuery()
          state.columnSearchKeys.globalQuery = query
          state.filterParams.search_query = query ? '"' + query + '"' : query
        }
        handlePassBlocks()
      }
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].sortBy
        : column.column
      state.filterParams.sort_query = `${columnName}:${
        column.asc ? "asc" : "desc"
      }`
      handlePassBlocks()
    }

    const handleTableAction = (event, item) => {
      if (event.action == "edit") {
        modal.open(CreatePassBlockingForm, {
          title: "Edit Pass Blocking",
          props: {
            editablePassBlockData: item
          }
        })
      }
      if (event.action == "delete") {
        actionDialog.open(deletePassBlock, {
          args: item,
          size: "sm",
          props: { danger: true, title: "Delete", question: "Are you sure?" }
        })
      }
    }

    const deletePassBlock = (passBlock) => {
      store.dispatch("passBlocks/deletePassBlock", passBlock.id).then(() => {
        store.commit("passBlocks/DELETE_PASS_BLOCK", passBlock.id)
        handlePassBlocks(state.pagination.activePage, false, true)
      })
    }

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.pagination.per_page = option
        handlePassBlocks()
      }
    }

    const initLazyLoading = () => {
      const body = document.getElementsByClassName("main-scroll-container")[0]
      if (body) {
        body.onscroll = () => {
          if (
            state.isLazyLoadingMode &&
            passBlocks.value &&
            passBlocks.value.length &&
            state.pagination.total > passBlocks.value.length
          ) {
            if (body.offsetHeight + body.scrollTop + 1 >= body.scrollHeight) {
              state.pagination.activePage = state.pagination.activePage + 1
              handlePassBlocks(state.pagination.activePage, false, false, true)
            }
          }
        }
      }
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      handlePassBlocks(state.pagination.activePage)
      helpers.scrollToTop()
    }

    const resetSearchQuery = () => {
      state.filterParams.search_query = ""
      state.filterParams.sort_query = ""
      state.columnSearchKeys = {
        globalQuery: "",
        from_date: {
          value: "",
          column: ["from_date.raw"],
          sortBy: "from_date"
        },
        to_date: { value: "", column: ["to_date.raw"], sortBy: "to_date" },
        recurrence_days: {
          value: "",
          column: ["recurrence_type"],
          sortBy: "recurrence_type"
        },
        reason: { value: "", column: ["reason"], sortBy: "reason" }
      }
    }

    return {
      state,
      dataTableFields,
      passBlockActions,
      passBlocks,
      filteredPassBlockActions,
      toggleSearchByColumn,
      handlePassBlocks,
      searchByQuery,
      sortByColumn,
      handleTableAction,
      setPerPage,
      setActivePage
    }
  }
}
</script>
