<template>
  <div class="no-target">
    <div class="v3-pass-comments-counter comments-column">
      <div class="ended-by">
        <div v-if="passEndedBy" class="mb-2">
          {{ passEndedBy }}
        </div>
      </div>
      <div v-if="pass.latest_comment">
        <div class="d-flex align-items-center me-2" @click="showModal()">
          <div
            v-if="pass.latest_comment.comment && !isBasicView"
            class="comments-latest"
          >
            {{ pass.latest_comment.comment }}
          </div>
          <div v-if="!onlyLatestComment" class="d-flex">
            <b class="comments-counter no-target me-2">{{
              pass.comments_count
            }}</b>
            <i class="ri-chat-new-line"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from "vue"
import { useStore } from "vuex"
import CommentsComponent from "@/v3components/CommentsComponent.vue"

export default {
  name: "PassComments",
  props: {
    pass: {
      type: Object,
      required: true
    },
    commentableType: {
      type: String,
      required: true
    },
    isBasicView: {
      type: Boolean,
      default: false
    },
    onlyLatestComment: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore()
    const modal = inject("modal")
    const activePass = computed(() => {
      return store.getters["dashboardTable/activeEditablePass"]
    })

    const passEndedBy = computed(() => {
      if (
        (props.pass.badge_flags && props.pass.badge_flags.system_completed) ||
        (props.pass.badge_flags &&
          props.pass.badge_flags.extended &&
          props.pass.badge_flags.ended)
      ) {
        return "Ended by System"
      }
      if (props.pass.child) {
        return props.pass.child.completed_by_user &&
          props.pass.child.completed_by_user.name
          ? "Ended by " + props.pass.child.completed_by_user.name
          : ""
      } else {
        return props.pass.completed_by_user
          ? "Ended by " + props.pass.completed_by_user.name
          : ""
      }
    })
    const studentName = computed(() => {
      return activePass.value && activePass.value.user
        ? activePass.value.user.first_name +
            " " +
            activePass.value.user.last_name
        : null
    })

    const showModal = () => {
      if (!props.onlyLatestComment) {
        store.commit("dashboardTable/SET_ACTIVE_EDITABLE_PASS", props.pass)
        modal.open(CommentsComponent, {
          size: "lg",
          title: studentName,
          props: {
            toID: activePass.value ? activePass.value.id : null,
            commentableType: props.commentableType,
            silent: false
          }
        })
      }
    }

    return { passEndedBy, showModal }
  }
}
</script>

<style lang="scss">
.vue-recycle-scroller__item-wrapper {
  .vue-recycle-scroller__item-view[comments-modal] {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 999999999999999 !important;
    z-index: 999999999999999 !important;
    transform: none !important;
    & .t-row {
      border-color: transparent;
    }
    & .t-row > div {
      visibility: hidden;
    }
    & .t-row > div.no-target {
      visibility: visible;
    }
    .comments-column {
      visibility: hidden !important;
    }
    .modal {
      overflow: visible !important;
    }
  }
}
</style>
