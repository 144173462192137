<template>
  <div class="container">
    <div class="row justify-content-center mt-4">
      <div class="col-md-8">
        <div class="v3-data-table full-height-table py-5 px-4">
          <div>
            <StatusBox
              data-test-id="waiting-approval-status-box-label"
              class="mb-2"
              title="waiting approval"
              title-color="#ffffff"
              background-color="#308DEB"
              icon="ri-loader-fill"
              icon-color="#ffffff"
            />
            <div class="d-flex gap-2">
              <div class="w-50">
                <Label>Student Passes</Label>
                <CustomTimePicker
                  data-test-id="waiting-approval-time-picker"
                  format="HH:mm:ss"
                  picker-time-format="HH:mm:ss"
                  :show-seconds="true"
                  v-model="state.form.white_passes_time"
                ></CustomTimePicker>
              </div>
              <div class="w-50">
                <Label>Appointment Passes</Label>
                <CustomTimePicker
                  data-test-id="waiting-approval-apt-time-picker"
                  format="HH:mm:ss"
                  picker-time-format="HH:mm:ss"
                  :show-seconds="true"
                  v-model="state.form.awaiting_apt_time"
                ></CustomTimePicker>
              </div>
            </div>
          </div>

          <div>
            <StatusBox
              class="mt-4 mb-2"
              title="long running"
              background-color="#FEECBC"
              icon-color="#F3D259"
            />
            <div>
              <CustomTimePicker
                data-test-id="long-running-time-picker"
                format="HH:mm:ss"
                picker-time-format="HH:mm:ss"
                :show-seconds="true"
                v-model="state.form.min_time"
              ></CustomTimePicker>
            </div>
          </div>

          <div>
            <StatusBox
              class="mt-4 mb-2"
              title="system ended"
              background-color="#FFE9E2"
              icon-color="#F35531"
            />
            <div>
              <CustomTimePicker
                data-test-id="system-ended-time-picker"
                format="HH:mm:ss"
                picker-time-format="HH:mm:ss"
                :show-seconds="true"
                v-model="state.form.auto_expire_time"
              ></CustomTimePicker>
            </div>
          </div>

          <div>
            <StatusBox
              class="mt-4 mb-2"
              title="extended"
              background-color="#EEECFD"
              icon-color="#645DF0"
            />
            <div>
              <CustomTimePicker
                data-test-id="extended-time-picker"
                format="HH:mm:ss"
                picker-time-format="HH:mm:ss"
                :show-seconds="true"
                v-model="state.form.nurse_expire_time"
              ></CustomTimePicker>
            </div>
          </div>
          <div>
            <InfoBox
              v-if="state.serverRes"
              class="mt-4"
              :class="{ danger: Object.keys(state.serverErrors).length }"
              :title="state.serverRes.title"
              >{{ state.serverRes.message }}</InfoBox
            >
          </div>
          <div>
            <LoadingButton
              data-test-id="save-pass-times-button"
              :is-loading="state.isProcessing"
              @click="submit()"
              class="px-4 mt-4"
              solid
              rounded
              >Save settings</LoadingButton
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue"
import { useStore } from "vuex"
import StatusBox from "@/v3components/shared/Alerts/StatusBox.vue"
import Label from "@/v3components/shared/Form/Label.vue"
import CustomTimePicker from "@/v3components/shared/Form/CustomTimePicker.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"

export default {
  name: "PassTimesForm",
  components: { StatusBox, Label, CustomTimePicker, InfoBox, LoadingButton },
  setup() {
    const store = useStore()
    const state = reactive({
      isProcessing: false,
      form: {
        white_passes_time: "00:05:00",
        min_time: "00:05:00",
        auto_expire_time: "00:05:00",
        nurse_expire_time: "00:05:00",
        awaiting_apt_time: "00:05:00"
      },
      passTimes: {
        white_passes_time: {
          isEditable: false,
          label: "Awaiting Approval (STU Passes)",
          color: "#fff"
        },
        min_time: {
          isEditable: false,
          label: "Long Running",
          color: "#f4e665"
        },
        auto_expire_time: {
          isEditable: false,
          label: "System Ended",
          color: "#DF3C3B"
        },
        nurse_expire_time: {
          isEditable: false,
          label: "Extended",
          color: "#672c91",
          textColor: "#fff"
        },
        awaiting_apt_time: {
          isEditable: false,
          label: "Awaiting Approval (APT Passes)",
          color: "#fff"
        }
      },
      serverErrors: {},
      serverRes: null
    })

    onMounted(() => {
      store.dispatch("passes/getPassesTimeSetting").then((response) => {
        const data = response.data.data
        if (data && data.time_settings) {
          setFormData(data.time_settings)
        }
      })
    })

    const setFormData = (data) => {
      state.form = {
        white_passes_time: data.white_passes,
        min_time: data.min_time,
        auto_expire_time: data.auto_expire_time,
        nurse_expire_time: data.nurse_expire_time,
        awaiting_apt_time: data.awaiting_apt
      }
    }

    const submit = () => {
      state.isProcessing = true
      const params = {}
      for (const key in state.form) {
        if (Object.hasOwnProperty.call(state.form, key)) {
          const element = state.form[key]
          if (element) {
            params[key] = element
          }
        }
      }
      store
        .dispatch("passes/setPassesTimeSetting", params)
        .then(() => {
          setResponseInfoBox("Success", "Successfully saved!")
          state.isProcessing = false
          state.serverErrors = {}
          setTimeout(() => {
            setResponseInfoBox()
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    return {
      state,
      setFormData,
      submit
    }
  }
}
</script>
