<template>
  <button
    class="visitor-emergency-btn"
    :class="{
      'full-rounded': rounded
    }"
  >
    <div class="d-flex align-items-center">
      <div v-if="prependIcon" class="btn-icon prepened lh-normal me-2">
        <i class="lh-normal" :class="prependIcon"></i>
      </div>
      <div class="btn-label lh-normal">
        <slot></slot>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: "EmergencyButton",
  props: {
    prependIcon: {
      type: String,
      default: ""
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
}
</script>
