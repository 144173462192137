<template>
  <div class="v3-form adult-form">
    <div class="row">
      <div>
        <LazyLoadSelect
          :is-multiple="true"
          class="flex-1 me-2"
          :selected="state.form.adults"
          :reduce="(item) => item.value.id"
          type="adults"
          :added-options="addedAdults"
          placeholder="Add teacher/staff"
          @update:model-value="(value) => updateData(value)"
        />
        <div class="info-box mt-4">
          <InfoBox
            message="Specify teachers/staff to be allowed to create Late to School and Late to Class passes."
          />
        </div>
      </div>
      <div>
        <InfoBox
          v-if="state.serverRes"
          class="mt-4"
          :class="{ danger: Object.keys(state.serverErrors).length }"
          :title="state.serverRes.title"
          >{{ state.serverRes.message }}</InfoBox
        >
      </div>
      <div class="text-center">
        <LoadingButton class="me-2 px-4" @click="onCancel" rounded
          >Cancel</LoadingButton
        >
        <LoadingButton
          :is-loading="state.isProcessing"
          @click="submit()"
          class="mt-4 px-4"
          solid
          rounded
          >Allow</LoadingButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect.vue"
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"
import { useStore } from "vuex"
import { reactive, computed, onMounted, ref } from "vue"

export default {
  components: {
    LoadingButton,
    InfoBox,
    LazyLoadSelect
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      isProcessing: false,
      serverErrors: {},
      serverRes: null,
      form: {
        adults: null
      }
    })

    const addedAdults = ref([])

    const validationMessages = {
      required: "This field is required"
    }
    const validations = {
      form: {
        adults: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isFormValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    const activeSchool = computed(() => store.getters["schools/activeSchool"])

    const updateData = (value) => {
      state.form.adults = value
      state.serverErrors = {}
      state.serverRes = null
    }

    const submit = () => {
      if (isFormValid.value) {
        const data = {
          user_ids: state.form.adults,
          school_id: activeSchool.value.id
        }
        createAdults(data)
      }
    }

    const createAdults = (data) => {
      state.isProcessing = true
      store
        .dispatch("assignAdults/createAssignAdult", data)
        .then(() => {
          resetFormData()
          setResponseInfoBox("Success", "Successfully created!")
          //state.isProcessing = false
          state.serverErrors = {}
          setTimeout(() => {
            setResponseInfoBox()
            store.dispatch("assignAdults/getAssignAdults")
            emit("cancel")
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const onCancel = () => {
      resetFormData()
      emit("cancel")
    }

    const resetFormData = () => {
      state.form.adults = null
    }

    onMounted(() => {
      store
        .dispatch("assignAdults/getAssignAdults")
        .then((resp) => {
          const result = resp?.data?.data || []
          addedAdults.value = result
            .map((item) => item?.user_id?.id)
            .filter(Boolean)
            .map((id) => {
              return { label: id, value: { id } }
            })
        })
        .catch(() => {
          addedAdults.value = []
        })
    })

    return {
      state,
      v$,
      updateData,
      submit,
      isFormValid,
      errors,
      onCancel,
      resetFormData,
      setResponseInfoBox,
      addedAdults
    }
  }
}
</script>
