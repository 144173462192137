<template>
  <div class="container">
    <div class="row help-page justify-content-center text-center mt-4">
      <h1 class="mb-4">Training Videos</h1>
      <div v-if="videos">
        <div v-for="(video, index) in videos" :key="index" class="">
          <h2>{{ video.topic }}</h2>
          <p>{{ video.description }}</p>
          <iframe
            :src="video.link"
            class="shorter trainings-iframe centered-content-iframe"
          ></iframe>
        </div>
      </div>
      <div v-else>No tutorials at the moment</div>
    </div>
  </div>
</template>
<script>
import { onMounted, computed } from "vue"
import { useStore } from "vuex"

export default {
  name: "TrainingVideo",
  setup() {
    const store = useStore()

    const videos = computed(() => store.getters["updates/videos"])

    onMounted(() => {
      store.dispatch("updates/getVideos")
    })

    return {
      videos
    }
  }
}
</script>
