import { apiV7nocache } from "../axiousWithCache"

const state = {
  reports: [],
  detailedReports: []
}

const getters = {
  reports(state) {
    return state.reports
  },

  detailedReports(state) {
    return state.detailedReports
  }
}

const mutations = {
  SET_REPORTS(state, reports) {
    state.reports = reports
  },
  SET_DETAILED_REPORTS(state, detailedReports) {
    state.detailedReports = detailedReports
  },
  PUSH_REPORTS(state, reportsCollection = []) {
    if (state.reports && state.reports.length) {
      state.reports = state.reports.concat(reportsCollection)
    } else {
      state.reports = reportsCollection
    }
  },
  PUSH_DETAILED_REPORTS(state, detailedReportsCollection = []) {
    if (state.detailedReports && state.detailedReports.length) {
      state.detailedReports = state.detailedReports.concat(
        detailedReportsCollection
      )
    } else {
      state.detailedReports = detailedReportsCollection
    }
  }
}

const actions = {
  getReports(context, params) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get("/admin/tardy/attendance/reports", { params })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getReportsCSV(context, params) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get("/admin/tardy/attendance/reports/csv", { params: params })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDetailedReports(context, params) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get("/admin/tardy/attendance/reports/detailed", { params })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDetailedReportsCSV(context, params) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get("/admin/tardy/attendance/reports/detailed/csv", { params: params })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
