<template>
  <div v-if="exceededIncidents" class="v3-dashboard-banners-item">
    <div class="text-center">
      <div>
        <h2 class="v3-dashboard-banners-item-title">
          <i class="ri-timer-line"></i> Consequences Ladder
        </h2>
      </div>
      <Transition name="banner">
        <div v-if="active" class="mt-2">
          Student(s) have exceeded your Consequence Ladder.
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConsequencesBanner",
  props: {
    exceededIncidents: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {}
  }
}
</script>
