import { apiV7nocache } from "../axiousWithCache"

const state = {
  attendanceFiles: []
}

const getters = {
  attendanceFiles(state) {
    return state.attendanceFiles
  }
}

const mutations = {
  SET_ATTENDANCE_FILES(state, files) {
    state.attendanceFiles = files
  },
  ADD_ATTENDANCE_FILE(state, file) {
    state.attendanceFiles.unshift(file)
  }
}

const actions = {
  uploadFile(context, data) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .post(`/admin/tardy/attendance/uploads`, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
          const data = response.data.data
          if (data) {
            context.commit("ADD_ATTENDANCE_FILE", data)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAttendanceFiles(context, params) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get("/admin/tardy/attendance/uploads", {
          params: params
        })
        .then((response) => {
          const data = response.data.data
          context.commit("SET_ATTENDANCE_FILES", data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  approveUploadFile(context, data) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .post(`/admin/tardy/attendance/uploads/approve`, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
          const data = response.data.data
          if (data) {
            context.commit("ADD_ATTENDANCE_FILE", data)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
