<script>
import { defineComponent, h, onMounted, ref, resolveComponent } from "vue"
import { RouterLink, useRoute } from "vue-router"

import {
  CBadge,
  CSidebarNav,
  CNavItem,
  CNavGroup,
  CNavTitle
} from "@coreui/vue-pro"

const normalizePath = (path) =>
  decodeURI(path)
    .replace(/#.*$/, "")
    .replace(/(index)?\.(html)$/, "")

const isActiveLink = (route, link) => {
  if (link === undefined) {
    return false
  }

  if (route.hash === link) {
    return true
  }

  const currentPath = normalizePath(route.path)
  const targetPath = normalizePath(link)

  return currentPath === targetPath
}

const isActiveItem = (route, item) => {
  if (isActiveLink(route, item.to)) {
    return true
  }

  if (item.items) {
    return item.items.some((child) => isActiveItem(route, child))
  }

  return false
}

const AppSidebarNav = defineComponent({
  name: "AppSidebarNav",
  components: {
    CNavItem,
    CNavGroup,
    CNavTitle
  },
  props: {
    sidebarItems: { type: Array, default: () => [] }
  },
  setup(props) {
    const route = useRoute()
    const firstRender = ref(true)
    const sidebarItems = props.sidebarItems ? props.sidebarItems : []

    onMounted(() => {
      firstRender.value = false
    })

    const renderItem = (item) => {
      if (item.items) {
        return h(
          CNavGroup,
          {
            ...(firstRender.value && {
              visible: item.items.some((child) => isActiveItem(route, child))
            }),
            ...item._attrs,
            text: item.name
          },
          {
            togglerContent: () => [
              h(resolveComponent("SidebarIcon"), {
                customClassName: "nav-icon",
                name: item.fontIcon
              }),
              item.name
            ],
            default: () => item.items.map((child) => renderItem(child))
          }
        )
      }

      return item.to
        ? h(
            resolveComponent(item.component),
            {
              active: props.isActive,
              classes: item.addLinkClasses,
              text: item.name,
              // onClick: () => props.navigate(),
              ...item._attrs
            },
            {
              default: () =>
                h(
                  RouterLink,
                  {
                    to: item.to,
                    class: "nav-link"
                  },

                  {
                    default: () => [
                      item.fontIcon &&
                        h(resolveComponent("SidebarIcon"), {
                          customClassName: "nav-icon",
                          name: item.fontIcon
                        }),
                      item.name,
                      item.badge &&
                        h(
                          CBadge,
                          {
                            class: "ms-auto " + item.badge.color
                          },
                          {
                            default: () => item.badge.text
                          }
                        )
                    ]
                  }
                )
            }
          )
        : h(
            resolveComponent(item.component),
            {
              ...item._attrs
            },
            {
              default: () => item.name
            }
          )
    }

    return () =>
      h(
        CSidebarNav,
        {},
        {
          default: () => sidebarItems.map((item) => renderItem(item))
        }
      )
  }
})
export default AppSidebarNav
</script>
