<template>
  <AutoPassTable />
</template>
<script>
import AutoPassTable from "@/v3components/Datatables/AutoPassTable.vue"
export default {
  name: "AutoPass",
  components: { AutoPassTable }
}
</script>
