<template>
  <div class="rooms-settings">
    <Loader :is-processing="state.loading" classes="position-absolute" />
    <div class="d-flex justify-content-center">
      <div class="d-flex w-50">Room name</div>
      <div class="d-flex w-50 justify-content-end me-4 pe-2">Limit</div>
    </div>
    <div class="list">
      <div v-for="room in rooms" :key="room">
        <div>
          <div class="data-row d-flex" :id="'row-' + room.id">
            <div class="d-flex w-50">
              <span>{{ room.room.name }}</span>
            </div>
            <div class="d-flex w-50 align-items-center justify-content-end">
              <InputField
                class="w-25"
                :model-value="
                  room.room_advance.limit ? room.room_advance.limit : '-'
                "
                @update:model-value="updateLimit(room, $event)"
              ></InputField>
              <i
                @click="updateLimitLocation(room)"
                class="ri-save-line save-icon ms-1 cursor-pointer"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <InfoBox
        v-if="state.serverRes"
        :class="{ danger: Object.keys(state.serverErrors).length }"
        :title="state.serverRes.title"
        >{{ state.serverRes.message }}</InfoBox
      >
    </div>
  </div>
</template>

<script>
import InputField from "@/v3components/shared/Form/InputField.vue"
import { reactive, onMounted, computed } from "vue"
import { useStore } from "vuex"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import Loader from "@/v3components/shared/Loader/Loader.vue"

export default {
  name: "RoomSettingsForm",
  components: {
    InputField,
    InfoBox,
    Loader
  },
  props: {
    rooms: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const store = useStore()

    const state = reactive({
      rooms: [],
      serverErrors: {},
      serverRes: null,
      loading: false
    })
    onMounted(() => {
      state.rooms = JSON.parse(JSON.stringify(props.rooms))
    })
    const updateLimit = (room, event) => {
      state.rooms.forEach((el, index) => {
        if (el.room.id == room.room.id) {
          state.rooms[index].room.limit = event
        }
      })
    }

    const autoPassesRooms = computed(
      () => store.getters["dashboardTable/staffSchedules"]
    )

    const updateLimitLocation = (room) => {
      state.loading = true
      let dataLimit
      state.rooms.find((el) => {
        if (el.room.id == room.room.id) {
          dataLimit = {
            limit:
              el.room.limit && el.room.limit !== "-" && el.room.limit !== ""
                ? el.room.limit
                : null,
            id:
              el.room_advance && el.room_advance.id
                ? el.room_advance.id
                : el.room.id
          }
        }
      })
      if (room && room.room_advance && room.room_advance.id) {
        updateExistingLimitLocation(dataLimit)
      } else {
        createLimitLocation(dataLimit)
      }
    }

    const createLimitLocation = (dataLimit) => {
      const data = {
        limit: dataLimit.limit,
        room_ids: [dataLimit.id]
      }
      store
        .dispatch("rooms/createRoomCapacityLimit", data)
        .then((response) => {
          const dataResponse = response.data.data
          setResponseInfoBox("Success", "Successfully saved!")
          state.loading = false
          state.serverErrors = {}
          store.commit("rooms/UPDATE_ROOM_CAPACITY_LIMIT", dataResponse)
          store.dispatch("dashboardTable/getDashboard").then(() => {
            state.rooms = JSON.parse(JSON.stringify(autoPassesRooms.value))
          })
          setTimeout(() => {
            setResponseInfoBox()
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.loading = false
        })
    }

    const updateExistingLimitLocation = (dataLimit) => {
      store
        .dispatch("rooms/updateRoomCapacityLimit", dataLimit)
        .then((response) => {
          setResponseInfoBox("Success", "Successfully updated!")
          state.loading = false
          state.serverErrors = {}
          const newData = response.data.data
          if (newData) {
            store.commit("rooms/UPDATE_ROOM_CAPACITY_LIMIT", newData)
          }
          setTimeout(() => {
            setResponseInfoBox()
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.loading = false
        })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    return { state, updateLimit, updateLimitLocation }
  }
}
</script>

<style></style>
