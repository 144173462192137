<template>
  <div class="container">
    <div class="row help-page justify-content-center text-center mt-4">
      <h1 class="mb-4">Documents</h1>
      <div v-if="trainings">
        <div v-for="(training, index) in trainings" :key="index" class="">
          <h2>{{ training.topic }}</h2>
          <p>{{ training.description }}</p>
          <iframe
            :src="training.link"
            class="shorter trainings-iframe centered-content-iframe"
          ></iframe>
        </div>
      </div>
      <div v-else>No tutorials at the moment</div>
    </div>
  </div>
</template>
<script>
import { onMounted, computed } from "vue"
import { useStore } from "vuex"

export default {
  name: "Documents",
  setup() {
    const store = useStore()

    const trainings = computed(() => store.getters["updates/trainings"])

    onMounted(() => {
      store.dispatch("updates/getTrainings")
    })

    return {
      trainings
    }
  }
}
</script>
