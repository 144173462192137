<template>
  <CSidebar
    class="help-center-sidebar"
    overlaid
    :unfoldable="false"
    placement="end"
    :visible="$store.state.layout.asideShow"
    color-scheme="light"
    size="lg"
    @visible-change="
      (value) => $store.commit('layout/set', ['asideShow', value])
    "
  >
    <CCloseButton @click="closeAsideBar()" />
    <div
      v-if="helpCenterContent"
      data-test-id="cy-help-center-sidebar"
      class="help-center-content pt-5"
      tabindex="-1"
    >
      <CustomScrollbar>
        <div class="ps-4 pe-5" v-html="helpCenterContent"></div>
      </CustomScrollbar>
    </div>
  </CSidebar>
</template>

<script>
import { reactive, computed } from "vue"
import { useStore } from "vuex"

export default {
  name: "TheAside",
  setup() {
    const store = useStore()

    const state = reactive({
      scrollbarSettings: {
        suppressScrollY: false,
        suppressScrollX: true
      }
    })

    const helpCenterContent = computed(
      () => store.getters["helpCenter/getHelpCenterContent"]
    )

    const closeAsideBar = () => {
      store.commit("layout/toggle", "asideShow")
      store.commit("helpCenter/SET_CONTENT", "")
    }

    return {
      state,
      helpCenterContent,
      closeAsideBar
    }
  }
}
</script>

<style>
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: calc(100vh - 200px);
}
</style>
