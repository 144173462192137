<template>
  <div
    v-if="hasMessage"
    class="visitor-firebase-notifier"
    :class="{
      bold: bold,
      center: center,
      small: small,
      large: large,
      [textClasses]: true
    }"
  >
    {{ message }}
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref } from "vue"
export default {
  name: "VisitorFirebaseNotifier",
  props: {
    message: {
      type: String,
      default: ""
    },
    textClasses: {
      type: String,
      default: ""
    },
    bold: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    }
  },
  emits: ["done"],
  setup(props, { emit }) {
    const timeoutId = ref(null)

    const hasMessage = computed(() => {
      return props?.message?.trim()?.length > 0 || false
    })

    onMounted(() => {
      timeoutId.value = setTimeout(() => {
        emit("done")
      }, 5000)
    })

    onUnmounted(() => {
      if (timeoutId.value) clearTimeout(timeoutId.value)
    })

    return {
      hasMessage
    }
  }
}
</script>

<style scoped></style>
