<template>
  <div class="v3-icon-carousel">
    <Carousel
      pagination-active-color="#0073E6"
      pagination-color="#EAEEF2"
      :per-page="perPage"
      :mouse-drag="mouseDrag"
    >
      <Slide
        v-for="(icon, index) in iconList"
        :key="index"
        class="v3-icon-carousel-slide"
      >
        <div
          :class="index === state.selectedIcon && 'active'"
          class="v3-icon-carousel-slide-icon-wrapper"
        >
          <i @click="onClickIcon($event, index)" :class="icon"></i>
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import { reactive, computed, onMounted } from "vue"
import { Carousel, Slide } from "@jambonn/vue-concise-carousel"
import "@jambonn/vue-concise-carousel/lib/vue-concise-carousel.css"

export default {
  components: {
    Carousel,
    Slide
  },
  props: {
    customIcons: {
      type: Array,
      default: () => []
    },
    mouseDrag: {
      type: Boolean,
      default: true
    },
    perPage: {
      type: Number,
      default: 10
    },
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const state = reactive({
      selectedIcon: ""
    })

    const defaultIcons = [
      "flaticon-washroom",
      "flaticon-waitroom",
      "flaticon-volumedon",
      "flaticon-volumedoff",
      "flaticon-tennis",
      "flaticon-swimming",
      "flaticon-studentworkarea",
      "flaticon-securityoffice",
      "flaticon-printer1",
      "flaticon-painting",
      "flaticon-nurse1",
      "flaticon-music1",
      "flaticon-locker",
      "flaticon-library",
      "flaticon-gym",
      "flaticon-guidanceoffice",
      "flaticon-guidance",
      "flaticon-football",
      "flaticon-drinkingwater",
      "flaticon-discipline",
      "flaticon-computer",
      "flaticon-camera",
      "flaticon-cafeteria",
      "flaticon-auditorium",
      "flaticon-attendance",
      "flaticon-admin",
      "flaticon-dashboard_user",
      "flaticon-idea-gray",
      "flaticon-15-minutes",
      "flaticon-alarm",
      "flaticon-alarm-1",
      "flaticon-alarm-1,", //this class correspondent with class in DB
      "flaticon-book",
      "flaticon-book-1",
      "flaticon-briefcase",
      "flaticon-calculator",
      "flaticon-chat",
      "flaticon-clip",
      "flaticon-coffee-cup",
      "flaticon-coloring-tool",
      "flaticon-compass",
      "flaticon-cut",
      "flaticon-dashboard",
      "flaticon-diagram",
      "flaticon-diamond",
      "flaticon-disabled",
      "flaticon-dumbell-1",
      "flaticon-file",
      "flaticon-flag",
      "flaticon-flag-1",
      "flaticon-flag-2",
      "flaticon-followers",
      "flaticon-fountain-1",
      "flaticon-graduation-cap",
      "flaticon-headphones",
      "flaticon-heart",
      "flaticon-highlighter",
      "flaticon-home",
      "flaticon-hour-glass",
      "flaticon-information",
      "flaticon-information-circle",
      "flaticon-joke",
      "flaticon-key",
      "flaticon-light-bulb",
      "flaticon-lightbulb",
      "flaticon-lockers",
      "flaticon-lockers-1",
      "flaticon-medal",
      "flaticon-megaphone",
      "flaticon-megaphone-1",
      "flaticon-megaphone-2",
      "flaticon-microphone",
      "flaticon-money",
      "flaticon-monitor",
      "flaticon-moon",
      "flaticon-move",
      "flaticon-music",
      "flaticon-nurse",
      "flaticon-open-book",
      "flaticon-paste",
      "flaticon-photo-camera",
      "flaticon-picture",
      "flaticon-pie-chart",
      "flaticon-piggy-bank",
      "flaticon-printer",
      "flaticon-profile-user",
      "flaticon-push-pin",
      "flaticon-puzzle",
      "flaticon-remove-user",
      "flaticon-rotating-arrows-circle",
      "flaticon-safari",
      "flaticon-scale",
      "flaticon-shield",
      "flaticon-shopping-cart",
      "flaticon-shuffle",
      "flaticon-smartphone",
      "flaticon-smile",
      "flaticon-snowflake",
      "flaticon-star-2",
      "flaticon-sun",
      "flaticon-switch-1",
      "flaticon-tag",
      "flaticon-thermometer",
      "flaticon-thunderbolt",
      "flaticon-toilet",
      "flaticon-tools",
      "flaticon-umbrella",
      "flaticon-user",
      "flaticon-user-1",
      "flaticon-video",
      "flaticon-video-camera",
      "flaticon-volume",
      "flaticon-volume-1",
      "flaticon-wall-clock",
      "flaticon-warning-1",
      "flaticon-warning-2",
      "flaticon-weightlifting-silhouette",
      "flaticon-wind"
    ]

    const iconList = computed(() => {
      return props.customIcons.length ? props.customIcons : defaultIcons
    })

    onMounted(() => {
      setTimeout(() => {
        onEditIcon(iconList.value.indexOf(props.modelValue))
      }, 100)
    })

    const onClickIcon = (event, index) => {
      state.selectedIcon = index
      emit("update:modelValue", event.target.className)
    }

    const onEditIcon = (index) => {
      state.selectedIcon = index
    }

    return {
      state,
      iconList,
      onClickIcon
    }
  }
}
</script>
