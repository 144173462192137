import axios from "axios"
import { api } from "./axiousWithCache"

const state = {
  clearCache: false
}

const getters = {}

const mutations = {
  SET_CLEAR_CACHE(state, clearCache = false) {
    state.clearCache = clearCache
  }
}

const actions = {
  searchByTypeAndQuery(context, params) {
    return new Promise((resolve, reject) => {
      api
        .get(`/search`, {
          params,
          clearCacheEntry: context.state.clearCache
        })
        .then((response) => {
          if (context.state.clearCache) {
            context.commit("SET_CLEAR_CACHE", false)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  searchByTypeAndQueryAxios(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/search`, {
          params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
