<template>
  <CDropdown
    placement="bottom-end"
    class="c-header-nav-item notification-dropdown"
    @hide="markNotificationsAsSeen"
  >
    <NotificationsToggle />
    <NotificationsList ref="notificationsList" />
  </CDropdown>
</template>
<script>
import NotificationsToggle from "./Notifications/layout/NotificationsToggle.vue"
import NotificationsList from "./Notifications/layout/NotificationsList.vue"
import { ref } from "vue"

export default {
  name: "TheHeaderDropdownNotif",
  components: { NotificationsToggle, NotificationsList },
  setup() {
    const notificationsList = ref(null)

    const markNotificationsAsSeen = () => {
      notificationsList.value.markNotificationsAsSeen()
    }

    return {
      notificationsList,
      markNotificationsAsSeen
    }
  }
}
</script>
