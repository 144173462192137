import { apiV7nocache } from "../axiousWithCache"

const state = {
  detentions: [],
  appointments: []
}

const getters = {
  detentions(state) {
    return state.detentions
  },
  appointments(state) {
    return state.appointments
  }
}

const mutations = {
  SET_DETENTIONS(state, detentions) {
    state.detentions = detentions
  },
  SET_APPOINTMENTS(state, appointments) {
    state.appointments = appointments
  }
}

const actions = {
  getDetentions(context, params) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get(`/admin/tardy/delays`, {
          params: params
        })
        .then((response) => {
          const data = response.data
          if (params.type === "DETENTION") {
            context.commit("SET_DETENTIONS", data.data)
          } else {
            context.commit("SET_APPOINTMENTS", data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  exportFile(context, params) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get("/admin/tardy/delays/csv", {
          params: params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
