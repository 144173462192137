<template>
  <div class="v3-form-label" :class="{ required }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style></style>
