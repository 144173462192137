<template>
  <PassTimesForm />
</template>
<script>
import PassTimesForm from "@/v3components/Forms/PassTimesForm.vue"
export default {
  name: "PassTimes",
  components: { PassTimesForm }
}
</script>
