<template>
  <div>
    <CCheckBox
      v-for="(option, index) in repeatOptions"
      :key="index"
      :label="option.label"
      :value="option.value"
      @change="(e) => changeModel(e.target.value)"
      @click="checkModel(option.value)"
      :checked="data.model === option.value"
      type="radio"
      class="custom-radio"
      :custom="true"
    />
  </div>
</template>

<script>
import { reactive } from "vue"

export default {
  name: "TardyRadioGroupOld",
  props: ["value", "modelValue"],
  emits: ["update:modelValue", "input"],
  setup(props, { emit }) {
    const data = reactive({
      model: props.modelValue
    })

    const repeatOptions = [
      {
        label: "Late to School",
        value: "LTS"
      },
      {
        label: "Late to Class",
        value: "LTC"
      }
    ]

    const changeModel = (event) => {
      data.model = event
      emit("input", data.model)
      emit("update:modelValue", data.model)
    }

    const checkModel = (value) => {
      if (data.model == value) {
        changeModel("")
      }
    }

    return {
      data,
      repeatOptions,
      props,
      changeModel,
      checkModel
    }
  }
}
</script>
