import { WebSocketPlugin } from "@/events/providers/websocket/websocket"

const eventProviders = {
  websocket: WebSocketPlugin
}

const realtimeEvents =
  eventProviders[import.meta.env.VITE_REALTIME_EVENTS_PROVIDER]()

export default realtimeEvents
