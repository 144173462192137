<template>
  <div class="v3-form favorites-form">
    <div class="row">
      <div v-if="fromFavorites">
        <SelectList
          :data="state.form.favorites"
          select-placeholder="Add favorite"
          select-type="combined"
          :counter-max="10"
          counter-icon="ri-star-smile-fill"
          counter-icon-class="simple"
          :draggable="true"
          :favorite-mode="true"
          :invalid-feedback="state.serverErrors"
          @update-list-data="(value) => updateData(value)"
        />
      </div>
      <div>
        <InfoBox
          v-if="state.serverRes"
          class="mt-4"
          :class="{ danger: Object.keys(state.serverErrors).length }"
          :title="state.serverRes.title"
          >{{ state.serverRes.message }}</InfoBox
        >
      </div>
      <div class="text-center">
        <LoadingButton class="me-2 px-4" @click="onCancel" rounded
          >Cancel</LoadingButton
        >
        <LoadingButton
          data-test-id="save-favorites-button"
          :is-loading="state.isProcessing"
          @click="submit()"
          class="mt-4 px-4"
          solid
          rounded
          >Save</LoadingButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted } from "vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import SelectList from "@/v3components/shared/Form/SelectList.vue"
import { useStore } from "vuex"
export default {
  components: {
    LoadingButton,
    InfoBox,
    SelectList
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      isProcessing: false,
      form: {
        favorites: null
      },
      serverErrors: {},
      serverRes: null
    })

    const fromFavorites = computed(
      () => store.getters["favorites/fromFavorites"]
    )

    onMounted(() => {
      favoriteList()
    })

    const favoriteList = () => {
      state.form.favorites = fromFavorites.value.map((e) => {
        return {
          value: {
            id: e.favorable_id,
            type: e.favorable_type
          },
          label: e.favorable.name,
          icon: e.favorable.icon,
          avatar: e.favorable.avatar
        }
      })
    }

    const updateData = (value) => {
      state.form.favorites = value
      state.serverErrors = {}
      state.serverRes = null
    }

    const submit = () => {
      const favoritesList = state.form.favorites.map((e, i) => {
        if (e.value) {
          return {
            id: e.value.id,
            type: e.value.type,
            position: i
          }
        }
      })
      const data = {
        elements: favoritesList,
        section: "destination",
        global: true
      }
      editFavorites(data)
    }

    const editFavorites = (data) => {
      state.isProcessing = true
      store
        .dispatch("favorites/updateFavoriteBulk", data)
        .then(() => {
          resetFormData()
          setResponseInfoBox("Success", "Successfully saved!")
          state.isProcessing = false
          state.serverErrors = {}
          setTimeout(() => {
            setResponseInfoBox()
            store.dispatch("rooms/getRooms")
            emit("cancel")
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const onCancel = () => {
      resetFormData()
      emit("cancel")
    }

    const resetFormData = () => {
      state.form.favorites = null
    }

    return {
      state,
      fromFavorites,
      updateData,
      submit,
      onCancel
    }
  }
}
</script>
