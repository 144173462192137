<template>
  <div>
    <CContainer>
      <AdultAppointmentPassTable v-if="user.role.name !== 'student'" />
    </CContainer>

    <StudentAptPassDataTable v-if="user.role.name === 'student'" />
  </div>
</template>
<script>
import AdultAppointmentPassTable from "@/v3components/Datatables/AdultAptPassDataTable/AdultAppointmentPassTable.vue"
import StudentAptPassDataTable from "@/v3components/Datatables/StudentAptPassDataTable/StudentAptPassDataTable.vue"
import { useStore } from "vuex"
import { computed } from "vue"

export default {
  name: "AppointmentPass",
  components: {
    AdultAppointmentPassTable,
    StudentAptPassDataTable
  },
  setup() {
    const store = useStore()

    const user = computed(() => store.getters["authentication/user"])

    return {
      user
    }
  }
}
</script>
