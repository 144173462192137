<template>
  <div class="v3-data-table">
    <div v-if="itemsPerPageSelect || haveFilterOption" class="row my-2 mx-0">
      <div v-if="haveFilterOption" class="col-sm-6 form-inline p-0">
        <template v-if="tableFilter">
          <label class="me-2">{{ tableFilterData.label }}</label>
          <input
            class="form-control"
            type="text"
            :placeholder="tableFilterData.placeholder"
            :value="state.tableFilterState"
            aria-label="table filter input"
            @input="tableFilterChange($event.target.value, 'input')"
            @change="tableFilterChange($event.target.value, 'change')"
          />
        </template>
        <slot name="cleaner" :clean="clean" :is-filtered="isFiltered">
          <template v-if="cleaner">
            <CIcon v-if="cleaner" v-bind="cleanerProps" @click="clean" />
          </template>
        </slot>
      </div>

      <div
        v-if="itemsPerPageSelect"
        class="col-sm-6 p-0"
        :class="{ 'offset-sm-6': !haveFilterOption }"
      >
        <div class="form-inline justify-content-sm-end">
          <label class="me-2">{{ paginationSelect.label }}</label>
          <select
            class="form-control"
            aria-label="changes number of visible items"
            @change="paginationChange"
          >
            <option value="" selected disabled hidden>
              {{ state.perPageItems }}
            </option>
            <option
              v-for="(number, key) in paginationSelect.values"
              :key="key"
              :val="number"
            >
              {{ number }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <slot name="over-table" />
    <CustomScrollbar
      :scroller-id="'dataTableTopScrollContainer-' + uuid"
      v-if="state.showCustomScrollbar"
      :swicher="state.showCustomScrollbar"
      class="gradient-scrollbar top-scroller"
      @ps-scroll-x="updateScrollers"
    >
      <div :style="`width: ${state.dataTable.width}px; height: 13px;`" />
    </CustomScrollbar>
    <div
      id="main-datatable-container"
      :class="`position-relative main-datatable-container ${
        responsive ? 'table-responsive' : ''
      }`"
    >
      <CustomScrollbar
        :scroller-id="'dataTableScrollContainer-' + uuid"
        :suppress-scroll-y="true"
        class="gradient-scrollbar"
        :swicher="state.showCustomScrollbar"
        @ps-scroll-x="updateScrollers"
      >
        <div
          :id="'dataTable-' + uuid"
          :class="tableClasses"
          data-test-id="dashboard-table"
        >
          <div class="t-header">
            <slot name="thead-top"></slot>
            <div v-if="header" class="t-row ps-2 pe-2">
              <template v-for="(name, index) in columnNames" :key="index">
                <div
                  :class="[headerClass(index), sortingIconStyles]"
                  :style="headerStyles(index)"
                >
                  <div
                    class="d-flex flex-row"
                    :class="name === 'Action' ? 'flex-row-reverse' : ''"
                  >
                    <slot :name="`${rawColumnNames[index]}-header`">
                      <div v-if="name">{{ name }}</div>
                    </slot>
                    <slot
                      v-if="isSortable(index)"
                      name="sorting-icon"
                      :state="getIconState(index)"
                      :classes="iconClasses(index)"
                    >
                      <div class="sort-icon-v3">
                        <i
                          class="ri-arrow-up-down-line"
                          @click="changeSort(rawColumnNames[index], index)"
                        ></i>
                      </div>
                    </slot>
                  </div>
                </div>
              </template>
            </div>

            <div v-if="columnFilter" class="table-sm t-row">
              <template v-for="(colName, index) in rawColumnNames" :key="index">
                <div :class="headerClass(index)">
                  <slot :name="`${rawColumnNames[index]}-filter`">
                    <input
                      v-if="!fields || fields[index].filter !== false"
                      class="form-control form-control-sm my-2"
                      :value="state.columnFilterState[colName]"
                      :aria-label="`column name: '${colName}' filter input`"
                      @input="
                        columnFilterEvent(colName, $event.target.value, 'input')
                      "
                      @change="
                        columnFilterEvent(
                          colName,
                          $event.target.value,
                          'change'
                        )
                      "
                    />
                  </slot>
                </div>
              </template>
            </div>
          </div>

          <DynamicScroller
            id="scroller"
            class="scroller"
            :items="currentItems"
            :min-item-size="43"
            key-field="id"
            :page-mode="state.isDesktopView"
            :buffer="100"
            :prerender="10"
            :style="`min-width:${fullWidth}`"
          >
            <template #default="{ item, index, active }">
              <DynamicScrollerItem
                :item="item"
                :active="active"
                :size-dependencies="[item._classes, basicView]"
                :data-index="index"
              >
                <div class="t-body">
                  <div
                    class="t-row"
                    data-test-id="dashboard-table-row"
                    :class="item._classes"
                    @click="rowClicked(item, item.id + firstItemIndex, $event)"
                  >
                    <template v-for="(colName, i) in rawColumnNames">
                      <slot
                        v-if="$slots[colName]"
                        :name="colName"
                        :item="item"
                        :index="item.id + firstItemIndex"
                        :styles="cellStyles(i)"
                      />
                      <div
                        v-else
                        :key="i"
                        :class="cellClass(item, colName, i)"
                        class="pass-time-info in-time align-top"
                        :style="cellStyles(i)"
                      >
                        {{ String(item[colName]) }}
                      </div>
                    </template>
                  </div>
                  <div
                    v-if="$slots.details"
                    :key="'details' + itemIndex"
                    class="border-none p-0"
                    @click="
                      rowClicked(item, itemIndex + firstItemIndex, $event, true)
                    "
                  >
                    <div :colspan="colspan" class="border-none p-0">
                      <slot
                        name="details"
                        :item="item"
                        :index="itemIndex + firstItemIndex"
                      />
                    </div>
                  </div>
                </div>
              </DynamicScrollerItem>
            </template>
            <template #empty>
              <div v-if="!currentItems.length && !loading" class="no-items-cnt">
                <div :colspan="colspan">
                  <slot name="no-items-view">
                    <div class="text-center my-5">
                      <h2>
                        {{ noItemsText }}
                        <CIcon
                          width="30"
                          :content="icon.cilBan"
                          class="text-danger mb-2"
                        />
                      </h2>
                    </div>
                  </slot>
                </div>
              </div>
            </template>
          </DynamicScroller>

          <tfoot v-if="footer && currentItems.length > 0">
            <tr>
              <template v-for="(name, index) in columnNames" :key="index">
                <th
                  :class="[headerClass(index), sortingIconStyles]"
                  :style="headerStyles(index)"
                  @click="changeSort(rawColumnNames[index], index)"
                >
                  <slot :name="`${rawColumnNames[index]}-header`">
                    <div>{{ name }}</div>
                  </slot>
                  <slot
                    v-if="isSortable(index)"
                    name="sorting-icon"
                    :state="getIconState(index)"
                  >
                    <CIcon
                      width="18"
                      :content="icon.cilArrowTop"
                      :class="iconClasses(index)"
                    />
                  </slot>
                </th>
              </template>
            </tr>
          </tfoot>
          <slot name="footer" :items-amount="currentItems.length" />
          <slot name="caption" />
        </div>
      </CustomScrollbar>
      <slot v-if="loading" name="loading">
        <CElementCover
          :boundaries="[
            { sides: ['top'], query: 'td' },
            { sides: ['bottom'], query: 'tbody' }
          ]"
        />
      </slot>
    </div>

    <slot name="under-table" />

    <Pagination
      v-if="pagination"
      v-show="totalPages > 1"
      :active-page="page"
      :pages="totalPages"
      v-bind="typeof pagination === 'object' ? pagination : null"
    />
  </div>
</template>

<script>
import {
  reactive,
  computed,
  onMounted,
  watch,
  nextTick,
  ref,
  getCurrentInstance
} from "vue"
import "vue-virtual-scroller/dist/vue-virtual-scroller.css"
import CIcon from "@coreui/icons-vue"
import * as icon from "@coreui/icons"

export default {
  name: "DataTableOptimized",
  components: {
    CIcon
  },
  props: {
    items: Array,
    fields: Array,
    itemsPerPage: {
      type: Number,
      default: 10
    },
    activePage: Number,
    pagination: [Boolean, Object],
    addTableClasses: [String, Array, Object],
    responsive: {
      type: Boolean,
      default: true
    },
    size: String,
    dark: Boolean,
    striped: Boolean,
    fixed: Boolean,
    hover: Boolean,
    border: Boolean,
    outlined: Boolean,
    itemsPerPageSelect: [Boolean, Object],
    sorter: [Boolean, Object],
    tableFilter: [Boolean, Object],
    columnFilter: [Boolean, Object],
    sorterValue: {
      type: Object,
      default: () => {
        return {}
      }
    },
    tableFilterValue: String,
    columnFilterValue: Object,
    header: {
      type: Boolean,
      default: true
    },
    footer: Boolean,
    loading: Boolean,
    clickableRows: Boolean,
    noItemsView: Object,
    cleaner: Boolean,
    basicView: Boolean
  },
  emits: [
    "pages-change",
    "page-change",
    "filtered-items-change",
    "update:sorter-value",
    "update:column-filter-value",
    "update:table-filter-value",
    "row-clicked",
    "pagination-change"
  ],
  setup(props, { emit }) {
    const state = reactive({
      tableFilterState: props.tableFilterValue,
      columnFilterState: {},
      sorterState: {
        column: null,
        asc: true
      },
      page: props.activePage || 1,
      perPageItems: props.itemsPerPage,
      passedItems: props.items || [],
      showCustomScrollbar: true,
      dataTable: {
        container: null,
        topScrollContainer: null,
        bottomScrollContainer: null,
        width: null
      },
      lastClickedRow: 1,
      isDesktopView: false
    })

    const columnFiltered = computed(() => {
      let items = state.passedItems
      if (props.columnFilter && props.columnFilter.external) {
        return items
      }
      Object.entries(state.columnFilterState).forEach(([key, value]) => {
        const columnFilter = String(value).toLowerCase()
        if (columnFilter && rawColumnNames.value.includes(key)) {
          items = items.filter((item) => {
            return String(item[key]).toLowerCase().includes(columnFilter)
          })
        }
      })
      return items
    })

    const itemsDataColumns = computed(() => {
      return rawColumnNames.value.filter((name) => {
        return generatedColumnNames.value.includes(name)
      })
    })

    const tableFiltered = computed(() => {
      let items = columnFiltered.value
      if (
        !state.tableFilterState ||
        (props.tableFilter && props.tableFilter.external)
      ) {
        return items
      }
      const filter = state.tableFilterState.toLowerCase()
      const hasFilter = (item) => String(item).toLowerCase().includes(filter)
      items = items.filter((item) => {
        return itemsDataColumns.value.filter((key) => hasFilter(item[key]))
          .length
      })
      return items
    })

    const sortedItems = computed(() => {
      const col = state.sorterState.column
      if (
        !col ||
        !rawColumnNames.value.includes(col) ||
        props.sorter.external
      ) {
        return tableFiltered.value
      }

      //if values in column are to be sorted by numeric value they all have to be type number
      const flip = state.sorterState.asc ? 1 : -1
      return tableFiltered.value.slice().sort((item, item2) => {
        const value = item[col]
        const value2 = item2[col]
        const a =
          typeof value === "number" ? value : String(value).toLowerCase()
        const b =
          typeof value2 === "number" ? value2 : String(value2).toLowerCase()
        return a > b ? 1 * flip : b > a ? -1 * flip : 0
      })
    })

    const firstItemIndex = computed(() => {
      return (computedPage.value - 1) * state.perPageItems || 0
    })

    const paginatedItems = computed(() => {
      return sortedItems.value.slice(
        firstItemIndex.value,
        firstItemIndex.value + state.perPageItems
      )
    })

    const currentItems = computed(() => {
      return computedPage.value ? paginatedItems.value : sortedItems.value
    })

    const totalPages = computed(() => {
      return Math.ceil(sortedItems.value.length / state.perPageItems) || 1
    })

    const computedPage = computed(() => {
      return props.pagination ? state.page : props.activePage
    })

    const generatedColumnNames = computed(() => {
      return Object.keys(state.passedItems[0] || {}).filter(
        (el) => el.charAt(0) !== "_"
      )
    })

    const rawColumnNames = computed(() => {
      return props.fields.map((el) => el.key || el)
    })

    const columnNames = computed(() => {
      return props.fields.map((f) => {
        return f.label !== undefined ? f.label : pretifyName(f.key || f)
      })
    })

    const tableClasses = computed(() => {
      return [
        "table",
        props.addTableClasses,
        {
          [`table-${props.size}`]: props.size,
          "table-dark": props.dark,
          "table-striped": props.striped,
          "table-fixed": props.fixed,
          "table-hover": props.hover,
          "table-bordered": props.border,
          border: props.outlined
        }
      ]
    })

    const sortingIconStyles = computed(() => {
      return { "position-relative ": props.sorter }
    })

    const colspan = computed(() => {
      return rawColumnNames.value.length
    })

    const tableFilterData = computed(() => {
      return {
        label: props.tableFilter.label || "Filter:",
        placeholder: props.tableFilter.placeholder || "type string..."
      }
    })

    const paginationSelect = computed(() => {
      return {
        label: props.itemsPerPageSelect.label || "Items per page:",
        values: props.itemsPerPageSelect.values || [5, 10, 20, 50]
      }
    })

    const noItemsText = computed(() => {
      const customValues = props.noItemsView || {}
      if (state.passedItems.length) {
        return customValues.noResults || "No filtering results"
      }
      return customValues.noItems || "No items"
    })

    const isFiltered = computed(() => {
      return (
        state.tableFilterState ||
        Object.values(state.columnFilterState).join("") ||
        state.sorterState.column
      )
    })

    const cleanerProps = computed(() => {
      return {
        content: icon.cilFilterX,
        class: `ms-2 ${isFiltered.value ? "text-danger" : "transparent"}`,
        role: isFiltered.value ? "button" : null,
        tabindex: isFiltered.value ? 0 : null
      }
    })

    const haveFilterOption = computed(() => {
      return props.tableFilter || props.cleaner
    })

    const fullWidth = computed(() => {
      const width = props.fields.map((e) => e.width).reduce((a, b) => a + b, 0)
      return width ? width + "px" : "auto"
    })

    watch(
      () => props.itemsPerPage,
      (val) => {
        state.perPageItems = val
      }
    )

    watch(
      () => props.sorterValue,
      (val) => {
        const asc = val.asc === false ? false : true
        state.sorterState = Object.assign({}, { asc, column: val.column })
      },
      { immediete: true }
    )

    watch(
      () => props.columnFilterValue,
      (val) => {
        state.columnFilterState = Object.assign({}, val)
      },
      { immediete: true }
    )

    watch(
      () => props.tableFilterValue,
      (val) => {
        state.tableFilterState = val
      }
    )

    watch(
      () => props.items,
      (val, oldVal) => {
        if (val && oldVal && objectsAreIdentical(val, oldVal)) {
          return
        }
        state.passedItems = val || []
      },
      { deep: true }
    )

    watch(
      () => totalPages.value,
      (val) => {
        emit("pages-change", val)
      },
      { immediete: true }
    )

    watch(
      () => computedPage.value,
      (val) => {
        emit("page-change", val)
      }
    )

    watch(
      () => sortedItems.value,
      (val, oldVal) => {
        if (val && oldVal && objectsAreIdentical(val, oldVal)) {
          return
        }
        emit("filtered-items-change", val)
      },
      { immediete: true }
    )

    onMounted(() => {
      window.addEventListener(
        "resize",
        () => {
          nextTick(() => {
            state.showCustomScrollbar = false
          })
          nextTick(() => {
            state.showCustomScrollbar = true
          })
          initTopScrollElements()
        },
        false
      )

      const deviceWidth =
        window.innerWidth > 0 ? window.innerWidth : screen.width
      state.showCustomScrollbar = !deviceWidth || deviceWidth > 700
      initTopScrollElements()
    })

    const changeSort = (column, index) => {
      if (!isSortable(index)) {
        return
      }
      //if column changed or sort was descending change asc to true
      const stater = state.sorterState
      const columnRepeated = stater.column === column
      if (!props.sorter || !props.sorter.resetable) {
        stater.column = column
      } else {
        stater.column = columnRepeated && stater.asc === false ? null : column
      }
      stater.asc = !(columnRepeated && stater.asc)
      emit("update:sorter-value", state.sorterState)
    }

    const columnFilterEvent = (colName, value, type) => {
      const isLazy = props.columnFilter && props.columnFilter.lazy === true
      if ((isLazy && type === "input") || (!isLazy && type === "change")) {
        return
      }
      Object.assign(state.columnFilterState, colName, value)
      emit("update:column-filter-value", state.columnFilterState)
    }

    const tableFilterChange = (value, type) => {
      const isLazy = props.tableFilter && props.tableFilter.lazy === true
      if ((isLazy && type === "input") || (!isLazy && type === "change")) {
        return
      }
      state.tableFilterState = value
      emit("update:table-filter-value", state.tableFilterState)
    }

    const pretifyName = (name) => {
      return name
        .replace(/[-_.]/g, " ")
        .replace(/ +/g, " ")
        .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    }

    const cellClass = (item, colName, index) => {
      const classes = []
      if (item._cellClasses && item._cellClasses[colName]) {
        classes.push(item._cellClasses[colName])
      }
      if (props.fields && props.fields[index]._classes) {
        classes.push(props.fields[index]._classes)
      }
      return classes
    }

    const isSortable = (index) => {
      return (
        props.sorter &&
        (!props.fields || props.fields[index].sorter !== false) &&
        itemsDataColumns.value.includes(rawColumnNames.value[index])
      )
    }

    const headerClass = (index) => {
      const fields = props.fields
      return fields && fields[index]._classes ? fields[index]._classes : ""
    }

    const headerStyles = (index) => {
      let style = "vertical-align:middle;overflow:hidden;"
      if (isSortable(index)) {
        style += `cursor:pointer;`
      }
      if (props.fields && props.fields[index] && props.fields[index].width) {
        style += `width:${props.fields[index].width}px;`
      }
      return style
    }

    const cellStyles = (index) => {
      return `width:${props.fields[index].width}px;`
    }

    const rowClicked = (item, index, e, detailsClick = false) => {
      emit("row-clicked", item, index, getClickedColumnName(e, detailsClick), e)
    }

    const getClickedColumnName = (e, detailsClick) => {
      if (detailsClick) {
        return "details"
      } else {
        if (e.target.closest("div.t-row")) {
          const children = Array.from(e.target.closest("div.t-row").children)
          const clickedCell = children.filter((child) =>
            child.contains(e.target)
          )[0]
          return rawColumnNames.value[children.indexOf(clickedCell)]
        }
        return "details"
      }
    }

    const getIconState = (index) => {
      const direction = state.sorterState.asc ? "asc" : "desc"
      return rawColumnNames.value[index] === state.sorterState.column
        ? direction
        : 0
    }

    const iconClasses = (index) => {
      const stater = getIconState(index)
      return [
        "icon-transition position-absolute arrow-position",
        {
          transparent: !stater,
          "rotate-icon": stater === "desc"
        }
      ]
    }

    const paginationChange = (e) => {
      emit("pagination-change", Number(e.target.value))
      if (props.itemsPerPageSelect.external) {
        return
      }
      state.perPageItems = Number(e.target.value)
    }

    const objectsAreIdentical = (obj1, obj2) => {
      nextTick(() => {
        return (
          obj1.length === obj2.length &&
          JSON.stringify(obj1) === JSON.stringify(obj2)
        )
      })
    }

    const clean = () => {
      state.tableFilterState = ""
      state.columnFilterState = {}
      state.sorterState = { column: "", asc: true }
    }

    const updateScrollers = (e) => {
      if (e?.target?.scrollLeft) {
        state.dataTable.topScrollContainer.scrollLeft = e.target.scrollLeft
        state.dataTable.bottomScrollContainer.scrollLeft = e.target.scrollLeft
      }
      state.dataTable.width = state.dataTable.container
        ? state.dataTable.container.offsetWidth
        : state.dataTable.width
    }

    const instance = getCurrentInstance()
    const uuid = ref(instance.uid)

    const initTopScrollElements = () => {
      state.dataTable.topScrollContainer = document.getElementById(
        "dataTableTopScrollContainer-" + uuid.value
      )
      state.dataTable.bottomScrollContainer = document.getElementById(
        "dataTableScrollContainer-" + uuid.value
      )
      state.dataTable.container = document.getElementById("scroller")
      if (state.dataTable.container && state.dataTable.width !== null) {
        state.dataTable.width = state.dataTable.container.offsetWidth
      }
    }

    return {
      icon,
      state,
      columnFiltered,
      itemsDataColumns,
      tableFiltered,
      sortedItems,
      firstItemIndex,
      paginatedItems,
      currentItems,
      totalPages,
      computedPage,
      generatedColumnNames,
      rawColumnNames,
      columnNames,
      tableClasses,
      sortingIconStyles,
      colspan,
      tableFilterData,
      paginationSelect,
      noItemsText,
      isFiltered,
      cleanerProps,
      haveFilterOption,
      fullWidth,
      changeSort,
      columnFilterEvent,
      tableFilterChange,
      pretifyName,
      cellClass,
      isSortable,
      headerClass,
      headerStyles,
      cellStyles,
      rowClicked,
      getClickedColumnName,
      getIconState,
      iconClasses,
      paginationChange,
      clean,
      uuid,
      updateScrollers
    }
  }
}
</script>

<style lang="scss">
.scroller {
  height: 100%;
  width: 100%;
}
.t-header {
  border-bottom: 1px solid #dad7df;
  .t-row {
    display: flex;
    width: 100%;
    & > div {
      display: flex;
      flex-shrink: 0;
      overflow: hidden;
      padding: 12px 20px !important;
      margin-bottom: 8px;
      align-items: center;
      align-items: center;
      font-family: "Metropolis-Regular", sans-serif !important;
      font-size: 12px;
      font-weight: 700 !important;
      border-right: 1px solid #dad7df;
      flex-shrink: 0;
      flex-grow: 1;
      &:first-of-type {
        flex-grow: 0 !important;
      }
      &:last-of-type {
        flex-grow: 1;
        border-right: 0;
      }
      div {
        display: flex;
        .sort-icon-v3 {
          i {
            cursor: pointer;
            font-size: 12px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
.dashboard-table .vue-recycle-scroller__item-view {
  padding: 0rem 0.5rem !important;
}
.t-body {
  .t-row {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dad7df;
    min-height: 125px;
    padding: 8px 0;
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      flex-shrink: 0;
      padding: 20px;
      font-family: "Metropolis-Regular";
      font-weight: 700;
      min-width: auto !important;
      flex-grow: 1;
      &:first-of-type {
        flex-direction: row !important;
        flex-grow: 0 !important;
      }
      &:last-of-type {
        flex-grow: 1;
      }
      .pass-type-icon {
        width: 30px;
      }
    }
  }
}
.basic-view {
  .t-body {
    .t-row {
      min-height: auto;
      & > div {
        padding: 10px 20px !important;
        min-width: auto !important;
        &:nth-of-type(1) {
          padding: 0 !important;
        }
        &:last-of-type {
          padding: 0 !important;
        }
        &.pass-type-icon-container {
          padding: 0px 20px !important;
        }
        .comments-column {
          .comments-count {
            transform: scale(0.8);
          }
        }
      }
    }
  }
}
.no-items-cnt {
  width: 100%;
}
.transparent {
  opacity: 0.4;
}
.icon-transition {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.arrow-position {
  right: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.rotate-icon {
  -ms-transform: translateY(-50%) rotate(-180deg);
  transform: translateY(-50%) rotate(-180deg);
}
</style>
