<template>
  <div>
    <CModal backdrop="static" v-if="showPopup" :visible="showPopup">
      <template #header-wrapper>
        <div
          class="pt-4 px-3 create-popup-header d-flex justify-content-between align-items-center w-100"
        ></div>
      </template>
      <div class="modal-height d-flex flex-column">
        <h2 class="text-center mb-3 mt-0">New releases</h2>
        <div class="overflow-auto h-100">
          <div
            v-for="(release, index) in newReleases"
            :key="index"
            class="mb-5 modal-height"
          >
            <h3 class="text-info">
              Pass Updates {{ release.version }}
              {{ $helpers.transformDate(release.created_at, "MMMM,DD") }}
            </h3>
            <div v-html="release.content"></div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center p-3">
        <CButton class="btn btn-sm border-info" @click="closeModal()">
          Close
        </CButton>
        <div class="form-check mt-3">
          <input
            id="flexCheckDefault"
            v-model="state.markseen"
            class="form-check-input border-info"
            type="checkbox"
          />
          <label class="form-check-label" for="flexCheckDefault">
            Turn off new release popup for the next time.
          </label>
        </div>
      </div>
    </CModal>

    <CModal backdrop="static" :visible="state.showAnnouncementsModal">
      <div class="pt-4 px-4 create-popup-header">
        <div
          class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
          @click="closeAnnouncementsModal()"
        >
          <span class="close-icon"></span>
        </div>
      </div>
      <div
        v-if="announcements && announcements.length"
        class="modal-height d-flex flex-column"
      >
        <div class="overflow-auto h-100">
          <div
            v-for="(announcement, index) in announcements"
            :key="index"
            class="mb-5 modal-height text-center"
          >
            <h3 class="text-info">
              {{ announcement.title }}
            </h3>
            <div v-html="announcement.html"></div>
          </div>
        </div>
      </div>
      <template #footer-wrapper>
        <div class="d-flex flex-column align-items-center"></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import { reactive, computed, watch, onMounted } from "vue"
import { useStore } from "vuex"

export default {
  name: "TheFooter",
  setup() {
    const store = useStore()

    const state = reactive({
      alreadypresented: false,
      markseen: false,
      showAnnouncementsModal: false
    })

    const releases = computed(() => store.getters["updates/releases"])

    const announcements = computed(() => store.getters["updates/announcements"])

    const newReleases = computed(() => {
      return releases.value && releases.value.filter((e) => e.seen == false)
    })

    const showPopup = computed(() => {
      return releases.value
        ? releases.value.filter((e) => e.seen == false).length > 0 &&
            !state.alreadypresented
        : false
    })

    watch(
      () => announcements.value,
      (value) => {
        if (value && value.length) {
          state.showAnnouncementsModal = true
        }
      }
    )

    onMounted(() => {
      if (!releases.value) {
        store.dispatch("updates/getAllReleases")
      }
      if (localStorage.getItem("showAnnouncements")) {
        store.dispatch("updates/getAnnouncement")
      }
    })

    const closeModal = () => {
      state.alreadypresented = true
      if (state.markseen) {
        newReleases.value.forEach((e) => {
          store.dispatch("updates/markReleasesSeen", e.id)
        })
      }
    }

    const closeAnnouncementsModal = () => {
      state.showAnnouncementsModal = false
      localStorage.removeItem("showAnnouncements")
      setTimeout(() => {
        store.commit("updates/SET_ANNOUNCEMENTS", null)
      }, 500)
    }

    return {
      state,
      releases,
      announcements,
      newReleases,
      showPopup,
      closeModal,
      closeAnnouncementsModal
    }
  }
}
</script>

<style scoped lang="scss">
.modal-height {
  max-height: 300px;
}

.form-check-label {
  padding-top: 2px;
}
</style>
