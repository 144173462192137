<template>
  <nav aria-label="pagination" ref="root">
    <ul :class="computedClasses">
      <li v-if="doubleArrows" :class="backArrowsClasses">
        <CLink
          class="page-link"
          @click="setPage(1)"
          :disabled="activePage === 1"
          aria-label="Go to first page"
          :aria-disabled="activePage === 1"
        >
          <slot name="first-button">&laquo;</slot>
        </CLink>
      </li>
      <li v-if="arrows" :class="backArrowsClasses">
        <CLink
          class="page-link"
          @click="setPage(activePage - 1)"
          :disabled="activePage === 1"
          aria-label="Go to previous page"
          :aria-disabled="activePage === 1"
        >
          <slot name="previous-button">&lsaquo;</slot>
        </CLink>
      </li>
      <li v-if="beforeDots" role="separator" class="page-item disabled">
        <span class="page-link">…</span>
      </li>

      <li
        v-for="item in items"
        :key="item"
        :class="[{ active: activePage === item }, 'page-item']"
      >
        <CLink
          class="page-link c-page-link-number"
          @click="setPage(item, $event)"
          :aria-label="
            activePage === item ? `Current page ${item}` : `Go to page ${item}`
          "
        >
          {{ item }}
        </CLink>
      </li>

      <li v-if="afterDots" role="separator" class="page-item disabled">
        <span class="page-link">…</span>
      </li>
      <li v-if="arrows" :class="nextArrowsClasses">
        <CLink
          class="page-link"
          @click="setPage(activePage + 1)"
          :disabled="activePage === pages"
          aria-label="Go to next page"
          :aria-disabled="activePage === pages"
        >
          <slot name="next-button">&rsaquo;</slot>
        </CLink>
      </li>
      <li v-if="doubleArrows" :class="nextArrowsClasses">
        <CLink
          class="page-link"
          @click="setPage(pages)"
          :disabled="activePage === pages"
          aria-label="Go to last page"
          :aria-disabled="activePage === pages"
        >
          <slot name="last-button">&raquo;</slot>
        </CLink>
      </li>
    </ul>
  </nav>
</template>

<script>
import { computed, watch, ref } from "vue"

export default {
  name: "PaginationOld",
  props: {
    activePage: {
      type: Number,
      default: 1
    },
    pages: {
      type: Number,
      default: 10
    },
    size: {
      type: String,
      validator: (val) => ["", "sm", "lg"].includes(val)
    },
    align: {
      type: String,
      default: "start",
      validator: (val) => ["start", "center", "end"].includes(val)
    },
    limit: {
      type: Number,
      default: 5
    },
    dots: {
      type: Boolean,
      default: true
    },
    arrows: {
      type: Boolean,
      default: true
    },
    doubleArrows: {
      type: Boolean,
      default: true
    }
  },
  emits: ["update:activePage", "update:modelValue"],
  setup(props, { emit }) {
    const root = ref(null)

    const backArrowsClasses = computed(() => {
      return ["page-item", { disabled: props.activePage === 1 }]
    })

    const nextArrowsClasses = computed(() => {
      return ["page-item", { disabled: props.activePage === props.pages }]
    })

    const computedClasses = computed(() => {
      const sizeClass = props.size ? `pagination-${props.size}` : ""
      return `pagination ${sizeClass} justify-content-${props.align}`
    })

    const showDots = computed(() => {
      return props.dots && props.limit > 4 && props.limit < props.pages
    })

    const maxPrevItems = computed(() => {
      return Math.floor((props.limit - 1) / 2)
    })

    const maxNextItems = computed(() => {
      return Math.ceil((props.limit - 1) / 2)
    })

    const beforeDots = computed(() => {
      return showDots.value && props.activePage > maxPrevItems.value + 1
    })

    const afterDots = computed(() => {
      return (
        showDots.value && props.activePage < props.pages - maxNextItems.value
      )
    })

    const computedLimit = computed(() => {
      return props.limit - afterDots.value - beforeDots.value
    })

    const range = computed(() => {
      return props.activePage + maxNextItems.value
    })

    const lastItem = computed(() => {
      return range.value >= props.pages
        ? props.pages
        : range.value - afterDots.value
    })

    const itemsAmount = computed(() => {
      return props.pages < computedLimit.value
        ? props.pages
        : computedLimit.value
    })

    const items = computed(() => {
      if (props.activePage - maxPrevItems.value <= 1) {
        return Array.from({ length: itemsAmount.value }, (v, i) => i + 1)
      } else {
        return Array.from({ length: itemsAmount.value }, (v, i) => {
          return lastItem.value - i
        }).reverse()
      }
    })

    watch(
      () => props.pages,
      (val) => {
        if (val && val < props.activePage) {
          emit("update:activePage", val, true)
          emit("update:modelValue", val)
        }
      },
      { immediate: true }
    )

    const setPage = (number, e = null) => {
      if (number === props.activePage) {
        return
      }
      emit("update:activePage", number, false)
      emit("update:modelValue", number)
      if (e) {
        changeFocus(e)
      }
    }

    const changeFocus = (e) => {
      const items = root.value.getElementsByClassName("c-page-link-number")
      const focused = Number(e.target.innerHTML)
      nextTick(() => {
        for (let i = 0; i < items.length; i++) {
          if (Number(items[i].innerHTML) === focused) {
            items[i].focus()
          }
        }
      })
    }

    return {
      root,
      backArrowsClasses,
      nextArrowsClasses,
      computedClasses,
      showDots,
      beforeDots,
      afterDots,
      maxPrevItems,
      maxNextItems,
      lastItem,
      computedLimit,
      range,
      itemsAmount,
      items,
      setPage,
      changeFocus
    }
  }
}
</script>
