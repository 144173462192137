<template>
  <CContainer :fluid="true">
    <CRow>
      <CCol class="ms-1 me-3">
        <div v-if="stats" class="counters-main-container">
          <BuildingPasses class="ms-2 mt-1" :basic="show"></BuildingPasses>
          <div
            class="ms-1 mt-1"
            :class="{
              'counters-container': !show,
              'counters-basic-container': show
            }"
          >
            <MyPasses class=" mt-1" :basic="show"> </MyPasses>
            <FlaggedPasses class="mt-1" :basic="show"></FlaggedPasses>
            <AutoPassRooms class="mt-1" :basic="show"></AutoPassRooms>
            <MyActiveKiosks class="mt-1" :basic="show"></MyActiveKiosks>
            <RoomStats class="mt-1" :basic="show"></RoomStats>
          </div>
        </div>
      </CCol>
    </CRow>
    <RowToggler :active="!show" @click="toggleView()" />
  </CContainer>
</template>

<script>
import { onMounted, computed, reactive, watch } from "vue"
import { useStore } from "vuex"
import BuildingPasses from "@/v3components/Dashboard/BuildingPasses.vue"
import MyPasses from "@/v3components/Dashboard/MyPasses.vue"
import FlaggedPasses from "@/v3components/Dashboard/FlaggedPasses.vue"
import AutoPassRooms from "@/v3components/Dashboard/AutoPassRooms.vue"
import MyActiveKiosks from "@/v3components/Dashboard/MyActiveKiosks.vue"
import RoomStats from "@/v3components/Dashboard/RoomStats.vue"
import RowToggler from "@/v3components/Dashboard/RowToggler.vue"

export default {
  name: "DashboardCounters",
  components: {
    MyPasses,
    FlaggedPasses,
    BuildingPasses,
    AutoPassRooms,
    MyActiveKiosks,
    RoomStats,
    RowToggler
  },
  setup() {
    const store = useStore()
    const state = reactive({
      activePassLimit: null,
      modal: {
        isVisible: {
          autoPassLimit: false,
          limitActivePass: false
        }
      }
    })

    const isActiveAppoinmentModule = computed(
      () => store.getters["schools/isActiveAppoinmentModule"]
    )

    const isActiveKioskModule = computed(
      () => store.getters["schools/isActiveKioskModule"]
    )

    const waitingApprovalLength = computed(
      () => store.getters["dashboardCounters/waitingApprovalLength"]
    )

    const myActivePassesLength = computed(
      () => store.getters["dashboardCounters/myActivePassesLength"]
    )

    const stats = computed(() => store.getters["dashboardTable/stats"])

    onMounted(() => {
      setPageTitle()
    })

    watch([waitingApprovalLength, myActivePassesLength], () => {
      setPageTitle()
    })

    const setActiveModal = (modal) => {
      if (modal) {
        state.modal.isVisible[modal] = true
      }
    }

    const setActivePassLimit = (limit) => {
      state.activePassLimit = limit
    }

    const closeModal = () => {
      state.modal = {
        isVisible: {
          autoPassLimit: false,
          limitActivePass: false
        }
      }
    }

    const resetAutoPassLimitForm = () => {
      if (this.$refs.CreateAutoPassLimit) {
        this.$refs.CreateAutoPassLimit.refreshForm()
      }
    }

    const setPageTitle = () => {
      document.title = `(${waitingApprovalLength.value}|${myActivePassesLength.value}) securlypass`
    }

    const show = computed(() => {
      return store.state.layout.showCounters
    })

    const toggleView = () => {
      store.commit("layout/set", ["showCounters", !show.value])
    }

    return {
      state,
      isActiveAppoinmentModule,
      isActiveKioskModule,
      waitingApprovalLength,
      myActivePassesLength,
      stats,
      setActiveModal,
      setActivePassLimit,
      closeModal,
      resetAutoPassLimitForm,
      setPageTitle,
      show,
      toggleView
    }
  }
}
</script>
