<template>
  <BaseButton
    v-bind="{ prependIcon, appendIcon }"
    :class="{
      active,
      rounded,
      'rounded-start': roundedStart,
      'rounded-end': roundedEnd
    }"
    class="v3-pill-button"
  >
    <slot></slot>
    <div v-if="showSubTitle" class="subtitle">{{ subTitle }}</div>
  </BaseButton>
</template>

<script>
import BaseButton from "./BaseButton.vue"

export default {
  components: { BaseButton },
  props: {
    prependIcon: String,
    appendIcon: String,
    active: Boolean,
    rounded: Boolean,
    roundedStart: Boolean,
    roundedEnd: Boolean,
    showSubTitle: Boolean,
    subTitle: String
  }
}
</script>

<style>
.subtitle {
  font-size: 10px;
}
</style>
