<template>
  <div class="h-100 pt-4 pb-1">
    <div class="text-center kiosk-login-auth-box">
      <CCol> <span class="from-text"> From:</span></CCol>
      <CCol class="img-box">
        <img
          class="img"
          :src="
            activeKiosk.user.avatar
              ? activeKiosk.user.avatar
              : '/img/avatars/user-avatar.jpeg'
          "
          alt="user-avatar"
        />
      </CCol>
      <CCol class="kiosk-login-auth-box-text">
        {{ activeKiosk.user.first_name + " " + activeKiosk.user.last_name }} ({{
          activeKiosk.room
            ? activeKiosk.room.name
            : activeKiosk.user
            ? activeKiosk.user.name
            : ""
        }})
      </CCol>
    </div>
    <CRow class="w-auto m-auto mt-3">
      <BaseButton
        data-test-id="kiosk-new-pass-button"
        class="d-flex align-items-center justify-content-center"
        solid
        rounded
        @click="setActiveTab('createPass')"
      >
        New Pass
      </BaseButton>
    </CRow>
    <CRow class="w-auto m-auto mt-3">
      <BaseButton
        class="d-flex align-items-center justify-content-center"
        rounded
        @click="setActiveTab('endPass')"
      >
        End Pass
      </BaseButton>
    </CRow>
    <div class="d-flex align-items-center justify-content-center mt-5">
      <a
        class="kiosk-terms-and-privacy-text"
        target="_blank"
        href="https://eduspiresolutions.org/terms-of-use/"
        >Terms of Use</a
      >
      <span class="mx-2">|</span>
      <a
        class="kiosk-terms-and-privacy-text"
        target="_blank"
        href="https://eduspiresolutions.org/privacy-policy/"
        >Privacy policy</a
      >
    </div>
  </div>
</template>
<script>
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"

export default {
  name: "KioskMainScreen",
  components: { BaseButton },
  props: ["activeKiosk"],
  emits: ["tabChanged"],
  methods: {
    setActiveTab(tab) {
      this.$emit("tabChanged", tab)
    }
  }
}
</script>
