.vue<template>
  <CContainer>
    <CRow class="justify-content-center mt-4 mb-4">
      <CCol md="12" class="text-start">
        <CRow>
          <CCol class="d-flex">
            <div class="v3-module-list-title">Digital ID Card</div>
            <HelpCenterButton
              classes="ms-2"
              content-key="module_digital_id_card"
            />
          </CCol>
        </CRow>
        <div class="v3-module-list-box">
          <CCol class="v3-module-list-checkbox d-flex align-items-center">
            <CustomCheck
              label="QR Code As Default"
              :model-value="state.form.qrCode"
              test-id="qrcode-checkbox"
              @change="checkQr"
            ></CustomCheck>
          </CCol>
          <CCol class="v3-module-list-checkbox d-flex align-items-center mt-2">
            <CustomCheck
              label="Barcode As Default"
              :model-value="state.form.barcode"
              test-id="barcode-checkbox"
              @change="checkBarcode"
            ></CustomCheck>
          </CCol>
          <CCol class="v3-module-list-checkbox d-flex align-items-center mt-2">
            <CustomCheck
              label="National Suicide Prevention Hotline"
              :model-value="state.form.hotLine"
              test-id="suicide-prevention-hotline-checkbox"
              @change="checkHotLine"
            ></CustomCheck>
          </CCol>
          <span class="small ms-4">
            (Include a link to the website and call/text number on student
            IDs)</span
          >
        </div>
        <CAlert
          v-if="state.reqResponse.message"
          class="mt-4"
          :color="state.reqResponse.type"
          :show="!!state.reqResponse.message"
        >
          {{ state.reqResponse.message }}
        </CAlert>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import HelpCenterButton from "../../HelpCenterButton.vue"
import CustomCheck from "@/v3components/shared/Form/CustomCheck.vue"
import { useStore } from "vuex"
import { onMounted, computed, reactive, ref } from "vue"

export default {
  name: "DigitalIdCardForm",
  components: { HelpCenterButton, CustomCheck },
  setup() {
    const store = useStore()

    const state = reactive({
      form: {
        qrCode: false,
        barcode: false,
        hotLine: false
      },
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      }
    })

    const isLoading = ref(false)

    const options = computed(() => {
      const activeSchoolModules = store.state.schools.activeSchoolModules
      const index = activeSchoolModules.findIndex(
        (module) => module.name === "Digital ID Card"
      )
      return index !== -1
        ? JSON.parse(activeSchoolModules[index].pivot.option_json)
        : false
    })

    onMounted(() => {
      if (options.value) {
        state.form.qrCode = Boolean(options.value.qr_code_as_default)
        state.form.barcode = Boolean(options.value.barcode_as_default)
        state.form.hotLine = Boolean(
          options.value.national_suicide_prevention_hotline
        )
      }
    })

    const checkBarcode = (value) => {
      state.form.barcode = value
      state.form.qrCode = false
      submit()
    }

    const checkQr = (value) => {
      state.form.qrCode = value
      state.form.barcode = false
      submit()
    }

    const checkHotLine = (value) => {
      state.form.hotLine = value
      submit()
    }

    const submit = () => {
      setTimeout(() => {
        resetResponseMessages()
        const inputsData = JSON.stringify({
          barcode_as_default: state.form.barcode,
          qr_code_as_default: state.form.qrCode,
          national_suicide_prevention_hotline: state.form.hotLine
        })
        isLoading.value = true
        store
          .dispatch("modules/updateIdCardModule", { option_json: inputsData })
          .then(() => {
            setSuccessResponse("Successfully updated.")
            store
              .dispatch("schools/getActiveModules", {
                clearCacheEntry: true
              })
              .then(() => {
                state.form.barcode = Boolean(options.value.barcode_as_default)
                state.form.qrCode = Boolean(options.value.qr_code_as_default)
                state.form.hotLine = Boolean(
                  options.value.national_suicide_prevention_hotline
                )
              })
            isLoading.value = false
            setTimeout(() => {
              resetResponseMessages()
            }, 850)
          })
          .catch((err) => {
            const response = err.response.data
            setErrorResponse(response.message, response.errors)
            isLoading.value = false
          })
      }, 100)
    }

    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const setSuccessResponse = (message) => {
      state.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Something went wrong!",
        errors: errors ? errors : {}
      }
    }

    return {
      state,
      checkBarcode,
      checkQr,
      checkHotLine,
      submit
    }
  }
}
</script>
