<template>
  <div v-if="pass.badge_flags && !pass.badge_flags.ended">
    <div class="table-actions">
      <div
        v-if="isVisibleActionSection"
        v-show="actionsStatus === 1"
        class="mt-2"
      >
        <button
          class="btn btn-comfirm mb-1"
          data-test-id="dashboard-row-approve-btn"
          @click="setPassStatus(1, null, pass.id)"
        >
          Approve
        </button>
        <button
          class="btn btn-cancel"
          data-test-id="dashboard-row-cancel-btn"
          @click="cancelPass()"
        >
          Cancel
        </button>
      </div>

      <div
        v-if="isVisibleActionSection"
        v-show="actionsStatus === 2"
        class="mt-2"
      >
        <div
          v-if="
            pass.to_type == 'App\\Models\\User' ||
            (pass.to_type == 'App\\Models\\Room' &&
              pass.to.trip_type == 'Layover')
          "
          class="d-flex align-items-center mb-1"
        >
          <button
            class="btn btn-comfirm me-1"
            @click="setPassStatus(3, null, pass.id)"
          >
            Arrived
          </button>
          <button
            class="btn btn-comfirm in-out"
            @click="setPassStatus(4, 'inout', pass.id)"
          >
            In/Out
          </button>
        </div>
        <button
          class="btn btn-cancel"
          data-test-id="dashboard-row-end-keep-btn"
          @click="setPassStatus(2, null, pass.id)"
        >
          End/Keep
        </button>
      </div>

      <div
        v-if="isVisibleActionSection"
        v-show="actionsStatus === 3"
        class="mt-2"
      >
        <button
          class="btn btn-comfirm no-icon me-1 mb-1"
          @click="setPassStatus(4, 'return', pass.child.id)"
        >
          <span class="flaticon-back-arrow me-1 icon-15"></span> Returning
        </button>
        <button
          class="btn btn-cancel"
          @click="setPassStatus(2, null, pass.child.id, true)"
        >
          End
        </button>
      </div>

      <div
        v-if="isVisibleActionSection"
        v-show="actionsStatus === 4"
        class="mt-2"
      >
        <button
          class="btn btn-cancel"
          @click="setPassStatus(2, null, pass.child.id, true)"
        >
          End
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from "vue"
import { useStore } from "vuex"

export default {
  name: "PassActionButtons",
  props: {
    pass: {
      type: Object,
      required: true
    },
    actionsStatus: {
      type: Number,
      default: 1
    }
  },
  emits: ["passEnded", "cancelPass"],
  setup(props, { emit }) {
    const store = useStore()

    const state = reactive({
      isProcessing: false
    })

    const isVisibleActionSection = computed(() => {
      switch (props.actionsStatus) {
        case 1:
          return !props.pass.approved_at && props.pass.pass_status
        case 2:
          return props.pass.approved_at && props.pass.pass_status
        case 3:
          return (
            props.pass.child &&
            !props.pass.child.approved_at &&
            props.pass.child.pass_status
          )
        case 4:
          return (
            props.pass.child &&
            props.pass.child.approved_at &&
            props.pass.child.pass_status
          )
        default:
          return null
      }
    })

    const setPassStatus = (status, return_type, passId, isChild) => {
      if (!state.isProcessing) {
        state.isProcessing = true
        const data = {
          id: passId,
          data: {
            action: status
          }
        }
        if (return_type) {
          data.data.return_type = return_type
        }
        if (isChild) {
          data.data.child = true
        }

        store
          .dispatch("passes/setPassStatus", data)
          .then(() => {
            if (status == 2) {
              emit("passEnded")
            }
            state.isProcessing = false
          })
          .catch(() => {
            state.isProcessing = false
          })
      }
    }

    const cancelPass = () => {
      store.dispatch("passes/cancelPass", props.pass.id)
      emit("cancelPass")
    }

    return {
      state,
      isVisibleActionSection,
      setPassStatus,
      cancelPass
    }
  }
}
</script>
