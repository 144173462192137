<template>
  <VisitorWrapper>
    <CContainer>
      <CRow class="justify-content-center mt-4">
        <CCol md="12" class="text-start">
          <CRow>
            <CCol class="d-flex">
              <div class="v3-module-list-title">{{ visitorModuleName }}</div>
              <HelpCenterButton
                classes="ms-2"
                content-key="visitor_management_module"
              />
            </CCol>
            <CCol class="d-flex flex-column align-items-end">
              <CustomSwitch
                type="radio"
                :model-value="state.isActive"
                @change="toggleVisitorModule(state.isActive)"
              />
            </CCol>
          </CRow>
          <div class="v3-module-list-box" v-if="state.isActive">
            <div
              class="d-flex flex-column gap-2"
              :class="{
                'visitor-loading': isLoading
              }"
            >
              <p class="text-start">
                Front Desk Users - In addition to all the Admins:
              </p>
              <div class="v3-module-list-checkbox d-flex">
                <CustomCheck
                  :model-value="state.form.allowTeacherStaffAsFrontDesk"
                  label="Allow all teachers/staff as front desk users"
                  @change="onAllowAll"
                />
              </div>
              <div class="v3-module-list-checkbox d-flex">
                <CustomCheck
                  :model-value="state.form.allowSelectedUsersOnly"
                  label="Only allow specific teachers/staff as front desk users"
                  @change="onAllowSpecific"
                >
                </CustomCheck>
              </div>
              <div class="d-flex justify-content-start">
                <BaseButton
                  v-if="state.form.allowSelectedUsersOnly"
                  rounded
                  @click="redirectToVisitorAdultPage"
                >
                  Add Adults
                </BaseButton>
              </div>
            </div>
          </div>
          <CAlert
            v-if="state.response.message"
            class="mt-4"
            :color="state.response.type"
            :show="!!state.response.message"
          >
            {{ state.response.message }}
          </CAlert>
        </CCol>
      </CRow>
    </CContainer>
  </VisitorWrapper>
</template>

<script>
import VisitorWrapper from "@/v3components/VisitorWrapper.vue"
import HelpCenterButton from "@/v3components/HelpCenterButton.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import CustomCheck from "@/v3components/shared/Form/CustomCheck.vue"
import CustomSwitch from "@/v3components/shared/Form/CustomSwitch.vue"
import visitorConstants from "@/constants/visitorConstants"
import visitorConfigs from "@/configs/visitorConfigs"
import { useRouter } from "vue-router"
import { computed, onMounted, reactive, ref } from "vue"
import { useStore } from "vuex"

export default {
  name: "VisitorManagementModuleForm",
  components: {
    HelpCenterButton,
    CustomSwitch,
    CustomCheck,
    BaseButton,
    VisitorWrapper
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const isLoading = ref(false)

    const state = reactive({
      form: {
        allowTeacherStaffAsFrontDesk: false,
        allowSelectedUsersOnly: false
      },
      response: {
        type: "",
        message: "",
        errors: {}
      },
      isActive: true
    })

    const options = computed(() => {
      const visitorModule = store.state.schools.activeSchoolModules.find(
        (module) => module.name === visitorConstants.MODULE
      )
      return visitorModule ? JSON.parse(visitorModule.pivot.option_json) : null
    })

    const active = computed(() => {
      const tardyModule = store.state.schools.activeSchoolModules.find(
        (module) => module.name === visitorConstants.MODULE
      )
      return tardyModule ? Boolean(tardyModule.pivot.status) : false
    })

    const resetResponse = () => {
      state.response = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const setSuccessResponse = (message) => {
      state.response = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.response = {
        type: "danger",
        message: message ? message : "Something went wrong!",
        errors: errors ? errors : {}
      }
    }

    const setForm = () => {
      state.form.allowTeacherStaffAsFrontDesk =
        options.value["allow-teacher-staff-as-frontdesk"]
      state.form.allowSelectedUsersOnly =
        options.value["allow-selected-users-only"]
    }

    const redirectToVisitorAdultPage = () => {
      router.push(visitorConfigs.pages.adultsPage().path)
    }

    const toggleVisitorModule = async (status) => {
      if (isLoading.value) return

      isLoading.value = true
      try {
        resetResponse()
        if (Number(status) === 0) {
          await store.dispatch("schools/createVisitorSettingsForSchool")
        }
        await store.dispatch("modules/toggleModule", {
          id: visitorConstants.VISITOR_MODULE_ID,
          moduleStatus: { status: status ? 0 : 1 }
        })
        state.isActive = !state.isActive
        await store.dispatch("schools/getActiveModules", {
          clearCacheEntry: true
        })
        setSuccessResponse("Module updated.")
      } catch (error) {
        const response = error.response ? error.response.data : {}
        setErrorResponse(response.message, response.errors)
      } finally {
        isLoading.value = false
        setTimeout(() => {
          resetResponse()
        }, 850)
      }
    }

    const onAllowAll = async (value) => {
      if (isLoading.value) return

      state.form.allowTeacherStaffAsFrontDesk = value
      state.form.allowSelectedUsersOnly = !value

      if (value) {
        await updateAllUsersAsFrontDesk()
      }
      await updateVisitorModuleOptions()
    }

    const onAllowSpecific = async (value) => {
      if (isLoading.value) return

      state.form.allowSelectedUsersOnly = value
      state.form.allowTeacherStaffAsFrontDesk = !value
      if (value) {
        await updateSpecificUsersAsFrontDesk()
      }
      await updateVisitorModuleOptions()
    }

    const updateVisitorModuleOptions = async () => {
      if (isLoading.value) return

      try {
        resetResponse()
        const optionAsJson = JSON.stringify({
          "allow-teacher-staff-as-frontdesk":
            state.form.allowTeacherStaffAsFrontDesk,
          "allow-selected-users-only": state.form.allowSelectedUsersOnly
        })
        isLoading.value = true
        await store.dispatch("modules/updateVisitorModule", {
          option_json: optionAsJson
        })
        setSuccessResponse("Module updated.")
        await store.dispatch("schools/getActiveModules", {
          clearCacheEntry: true
        })
        setForm()
      } catch (error) {
        const response = error.response ? error.response.data : {}
        setErrorResponse(response.message, response.errors)
      } finally {
        isLoading.value = false
        setTimeout(() => {
          resetResponse()
        }, 850)
      }
    }

    const updateAllUsersAsFrontDesk = async () => {
      if (isLoading.value) return

      try {
        resetResponse()
        const optionAsJson = JSON.stringify({
          "allow-teacher-staff-as-frontdesk": true,
          "allow-selected-users-only": false
        })
        isLoading.value = true
        await store.dispatch("visitorSettings/updateVisitorAdultsFlag", {
          option_json: optionAsJson
        })
        setSuccessResponse("Module updated.")
        await store.dispatch("schools/getActiveModules", {
          clearCacheEntry: true
        })
        setForm()
      } catch (error) {
        const response = error.response ? error.response.data : {}
        setErrorResponse(response.message, response.errors)
      } finally {
        isLoading.value = false
        setTimeout(() => {
          resetResponse()
        }, 850)
      }
    }

    const updateSpecificUsersAsFrontDesk = async () => {
      if (isLoading.value) return

      try {
        resetResponse()
        const optionAsJson = JSON.stringify({
          "allow-teacher-staff-as-frontdesk": false,
          "allow-selected-users-only": true
        })
        isLoading.value = true
        await store.dispatch("visitorSettings/updateVisitorAdultsFlag", {
          option_json: optionAsJson
        })
        setSuccessResponse("Module updated.")
        await store.dispatch("schools/getActiveModules", {
          clearCacheEntry: true
        })
        setForm()
      } catch (error) {
        const response = error.response ? error.response.data : {}
        setErrorResponse(response.message, response.errors)
      } finally {
        isLoading.value = false
        setTimeout(() => {
          resetResponse()
        }, 850)
      }
    }

    onMounted(() => {
      if (options.value) {
        setForm()
      }
      state.isActive = active.value
    })

    return {
      isLoading,
      visitorModuleName: visitorConstants.MODULE,
      state,
      options,
      toggleVisitorModule,
      redirectToVisitorAdultPage,
      onAllowAll,
      onAllowSpecific
    }
  }
}
</script>

<style scoped></style>
