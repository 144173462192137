<template>
  <div>
    <CContainer>
      <TardyReportsTable></TardyReportsTable>
    </CContainer>
  </div>
</template>
<script>
import TardyReportsTable from "@/v3components/Datatables/TardyReportsTable.vue"
export default {
  name: "TardyManagementReports",
  components: { TardyReportsTable },
  setup() {
    return {}
  }
}
</script>
