import visitorConstants from "@/constants/visitorConstants"
import axios from "axios"

const state = {}

const getters = {}

const mutations = {}

const actions = {
  updateAppointmentPassModule(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/modules/1`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateKioskModule(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/modules/3`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateIdCardModule(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/modules/4`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateTardyModule(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/modules/5`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  toggleModule(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/modules/${data.id}/status`, data.moduleStatus)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateVisitorModule(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/modules/${visitorConstants.VISITOR_MODULE_ID}`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
