<template>
  <div>
    <DashboardCounters />
    <Banners />
    <div class="pt-0 p-sm-2">
      <DashboardDataTableNew />
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue"
import { useStore } from "vuex"
import Banners from "@/v3components/Dashboard/Banners.vue"
import DashboardDataTableNew from "@/v3components/Datatables/DashboardDataTable/DashboardDataTable.vue"
import DashboardCounters from "@/v3components/Dashboard/DashboardCounters.vue"
export default {
  name: "Dashboard",
  components: {
    DashboardCounters,
    Banners,
    DashboardDataTableNew
  },

  setup() {
    const store = useStore()

    const userRole = computed(() => {
      return store.getters["authentication/getUserRole"]
    })

    const isActiveTardyModule = computed(() => {
      return store.getters["schools/isActiveTardyModule"]
    })

    onMounted(() => {
      store.dispatch("dashboardCounters/getPassesForCounters")
      store.dispatch("dashboardTable/getDashboard")
      if (isActiveTardyModule.value && userRole.value === "admin") {
        store.dispatch("exceededIncidents/getExceededIncidents")
      }
    })

    return {
      userRole
    }
  }
}
</script>
