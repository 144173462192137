<template>
  <div>
    <CContainer class="digital-id" data-test-id="digital-id-card">
      <CCol class="digital-id-top p-4">
        <CRow>
          <CCol>
            <div class="digital-id-student-id" id="student-id">Digital ID</div>
          </CCol>
          <CCol>
            <div class="digital-id-top-box content-center"></div>
          </CCol>
          <CCol> </CCol>
        </CRow>
        <CRow class="digital-id-top-images mt-2">
          <CCol v-if="showStudentPhotos">
            <img
              width="75"
              height="75"
              :src="user.avatar ? user.avatar : 'img/avatars/user-avatar.jpeg'"
              class="digital-id-top-avatar"
          /></CCol>
          <CCol class="text-end">
            <img
              width="100%"
              height="auto"
              src="@/assets/images/securlypass-logo.png"
              alt="securlypass-logo"
              id="logo-ehp"
              class="small-logo"
          /></CCol>
        </CRow>
        <CRow class="pt-2">
          <CCol>
            <h2 class="digital-id-top-name" data-test-id="digital-id-card-name">
              {{ user.name }}
            </h2>
            <h4 class="digital-id-top-role" id="student-role">
              {{ role }}
            </h4>
          </CCol>
        </CRow>
        <CRow class="mt-3">
          <CCol>
            <div class="digital-id-top-title">ID</div>
            <h5 class="digital-id-top-info" data-test-id="digital-id-card-id">
              {{ user.student_sis_id ? user.student_sis_id : "N/A" }}
            </h5>
          </CCol>
          <CCol class="text-end">
            <div class="digital-id-top-title">GRAD YEAR</div>
            <h5
              class="digital-id-top-info"
              data-test-id="digital-id-card-grad-year"
            >
              {{ user.grade_year ? user.grade_year : "N/A" }}
            </h5>
          </CCol>
        </CRow>
      </CCol>
      <CCol class="digital-id-bottom bg-white">
        <CRow
          v-if="state.qrCode && user.student_sis_id"
          class="content-center justify-content-center"
        >
          <QrcodeVue
            v-if="state.valueQR"
            class="mt-4"
            :value="state.valueQR"
            :size="state.size"
            data-test-id="digital-id-card-qrcode"
            level="H"
          ></QrcodeVue>
        </CRow>
        <CRow
          v-if="state.barCode && user.student_sis_id"
          class="content-center justify-content-center"
        >
          <div
            class="digital-id-svg-barcode"
            data-test-id="digital-id-card-barcode"
          >
            <barcode
              tag="svg"
              :options="{ width: '2', height: '100', fontSize: 18 }"
              :value="state.valueBar"
            >
            </barcode>
          </div>
        </CRow>
        <div
          v-if="!user.student_sis_id"
          class="digital-id-bottom-missing-id px-3"
        >
          This student does not have a Student Number in the system.
        </div>
        <CRow v-if="state.hotLine">
          <div
            class="text-center mt-4 px-4"
            data-test-id="digital-id-card-suicide-prevention-hotline"
          >
            National Suicide Prevention Hotline -
            <a href="https://988lifeline.org/" target="_blank"
              >988lifeline.org</a
            >
            or call/text 988
          </div>
        </CRow>
        <hr v-if="horizontalLine" class="solid" />
        <CRow
          class="digital-id-bottom-school justify-content-center"
          :class="{ 'digital-id-bottom-school-no-line': !horizontalLine }"
          data-test-id="digital-id-card-school-name"
        >
          {{ activeSchool.name }}
        </CRow>
      </CCol>
      <CCol class="go-back content-center mt-4">
        <div class="text-center mb-3">
          <span
            class="font-lg fw-bolder mx-3 cursor-pointer"
            @click="downloadStudentID()"
            >Download Digital ID</span
          >
        </div>
        <div class="text-center mb-3">
          <span class="font-lg fw-bolder mx-3 cursor-pointer" @click="goBack()"
            >Go back</span
          >
        </div>
      </CCol>
    </CContainer>
    <Loader :is-processing="state.isLoading" />
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue"
import VueBarcode from "@chenfengyuan/vue-barcode"
import Loader from "@/v3components/shared/Loader/Loader.vue"
import { reactive, computed, onMounted, watch } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"

export default {
  name: "DigitalID",
  components: { QrcodeVue, barcode: VueBarcode, Loader },
  setup() {
    const store = useStore()

    const route = useRouter()

    const state = reactive({
      valueQR: null,
      valueBar: null,
      size: 210,
      qrCode: false,
      barCode: false,
      hotLine: false,
      isLoading: false
    })

    const user = computed(() => store.getters["authentication/user"])
    const userRole = computed(() => store.getters["authentication/getUserRole"])
    const activeSchool = computed(() => store.getters["schools/activeSchool"])
    const activeSchoolModules = computed(
      () => store.getters["schools/activeSchoolModules"]
    )
    const showStudentPhotos = computed(
      () => store.getters["schools/showStudentPhotos"]
    )

    const options = computed(() => {
      const activeSchoolModules = store.state.schools.activeSchoolModules
      const index = activeSchoolModules.findIndex(
        (module) => module.name === "Digital ID Card"
      )
      return index !== -1
        ? JSON.parse(activeSchoolModules[index].pivot.option_json)
        : false
    })
    const role = computed(() => {
      return userRole.value.charAt(0).toUpperCase() + userRole.value.slice(1)
    })
    const horizontalLine = computed(() => {
      return state.qrCode || state.barCode || !user.value.student_sis_id
    })

    watch(user, () => {
      state.barCode = false
      state.qrCode = false
      state.hotLine = false
      if (
        options.value &&
        options.value.qr_code_as_default &&
        user.value.student_sis_id
      ) {
        state.qrCode = true
        state.valueQR = user.value.student_sis_id.toString()
      }
      if (
        options.value &&
        options.value.barcode_as_default &&
        user.value.student_sis_id
      ) {
        state.barCode = true
        state.valueBar = user.value.student_sis_id.toString()
      }
      if (options.value && options.value.national_suicide_prevention_hotline) {
        state.hotLine = true
        state.valueBar = user.value.student_sis_id.toString()
      }
    })

    watch(activeSchoolModules, () => {
      state.barCode = false
      state.qrCode = false
      state.hotLine = false
      if (
        options.value &&
        options.value.qr_code_as_default &&
        user.value.student_sis_id
      ) {
        state.qrCode = true
        state.valueQR = user.value.student_sis_id.toString()
      }
      if (
        options.value &&
        options.value.barcode_as_default &&
        user.value.student_sis_id
      ) {
        state.barCode = true
        state.valueBar = user.value.student_sis_id.toString()
      }
      if (options.value && options.value.national_suicide_prevention_hotline) {
        state.hotLine = true
      }
    })

    onMounted(() => {
      if (
        options.value &&
        options.value.qr_code_as_default &&
        user.value.student_sis_id
      ) {
        state.qrCode = true
        state.valueQR = user.value.student_sis_id.toString()
      }
      if (
        options.value &&
        options.value.barcode_as_default &&
        user.value.student_sis_id
      ) {
        state.barCode = true
        state.valueBar = user.value.student_sis_id.toString()
      }
      if (options.value && options.value.national_suicide_prevention_hotline) {
        state.hotLine = true
      }
    })

    const goBack = () => {
      route.back()
    }

    const downloadStudentID = () => {
      state.isLoading = true
      store
        .dispatch("users/generateSpecificStudentIDCard", user.value.id)
        .then((response) => {
          const data = response.data?.data
          if (data && data.url) {
            window.open(data.url, "_blank")
          }
        })
        .finally(() => {
          state.isLoading = false
        })
    }

    return {
      state,
      user,
      userRole,
      activeSchool,
      activeSchoolModules,
      showStudentPhotos,
      options,
      role,
      horizontalLine,
      goBack,
      downloadStudentID
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/scss/_variables";
.digital-id {
  max-width: 400px !important;
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  &-top {
    background-image: url("../assets/images/digital-id.png");
    background-size: cover;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-color: $black;
    border-style: solid;
    border-width: 1px 1px 0 1px;
    color: $white;
    &-avatar {
      border: 3px solid $white;
      border-radius: 8px;
    }
    #student-id {
      color: $white !important;
    }
    &-student-id {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
    }
    &-name {
      font-weight: 900;
      font-size: 36px;
      line-height: 38px;
    }
    #student-role {
      color: $digital-id-role !important;
    }
    &-role {
      font-weight: 700;
      font-size: 21px;
      line-height: 16px;
    }
    &-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
    }
    &-info {
      margin-top: 3px;
      font-weight: 800;
      font-size: 21px;
      line-height: 16px;
    }
    &-box {
      height: 16px;
      width: 100%;
      background: $digital-bg-white;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 3rem;
    }
    &-images {
      height: 80px;
    }
  }
  &-bottom {
    border-color: $black;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    &-missing-id {
      padding: 15px 5px 5px 5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
    }
    &-school {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      padding-bottom: 10px;
      color: $gray-dark;
      &-no-line {
        padding-top: 10px;
      }
    }
  }
  .go-back {
    font-weight: 800;
    font-size: 18px;
    line-height: 18px;
    color: $accent-blue-dark;
  }
  #logo-ehp {
    min-width: 140px;
    min-height: 45px;
  }
  &-svg-barcode {
    text-align: center;
    svg {
      width: 150px;
      height: 150px;
    }
  }
}
</style>
