<template>
  <div
    class="custom-scrollbar-container"
    :class="{ 'custom-scroll-accent': !defaultStyle }"
  >
    <perfect-scrollbar
      :id="scrollerId"
      :class="scrollComponentClass"
      :options="settings"
      @ps-scroll-y="emitEvent"
      @ps-scroll-x="emitEvent"
    >
      <slot></slot>
    </perfect-scrollbar>
  </div>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object,
      default: function () {
        return {}
      }
    },
    scrollComponentClass: [Array, String],
    scrollerId: String,
    defaultStyle: Boolean
  },
  setup(props, { emit }) {
    const emitEvent = (event) => {
      emit(event.type, event)
    }

    return {
      emitEvent
    }
  }
}
</script>
