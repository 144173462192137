<template>
  <VisitorWrapper>
    <CContainer v-if="!isSchoolSettingsLoading">
      <!-- SERVER ERROR -->
      <VisitorErrorHandler
        v-if="schoolSettingServerError != null"
        :error="schoolSettingServerError"
        @done="schoolSettingServerError = null"
      />
      <!-- SERVER ERROR -->

      <!-- KIOSK SETTING LIST -->
      <CRow
        v-for="setting in settingList"
        class="justify-content-center mt-4 bg-white rounded-30 p-4 d-flex flex-column gap-2"
        :class="{
          'visitor-loading': isLoading
        }"
        :key="setting.settingsKey"
      >
        <CCol md="12 text-start">
          <!-- SETTING CARD -->
          <VisitorSettingsControl
            :settings-key="setting.settingsKey"
            :heading="setting.heading"
            :description="setting.description"
            :show-description="setting.showDescription"
            :has-learn-more="setting.hasLearnMore"
            :learn-more="setting.learnMore"
            :has-edit="setting.hasEdit"
            :show-edit="setting.showEdit"
            :has-switch="setting.hasSwitch"
            :show-switch="setting.showSwitch"
            :options="setting.options"
            :edit="setting.edit"
            :toggle="setting.toggle"
            :error="
              activeSettingKey === setting.settingsKey ? serverError : null
            "
            @done="(serverError = null), (activeSettingKey = null)"
          >
          </VisitorSettingsControl>
          <!-- SETTING CARD -->
        </CCol>
      </CRow>
      <!-- KIOSK SETTING LIST -->
    </CContainer>
    <CContainer v-else>
      <CRow
        v-for="n in 4"
        :key="n"
        class="justify-content-center align-content-center mt-4 bg-white rounded-30 p-4 d-flex gap-2"
      >
        <CSpinner color="primary" />
      </CRow>
    </CContainer>
  </VisitorWrapper>
</template>
<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import VisitorWrapper from "@/v3components/VisitorWrapper.vue"
import VisitorSettingsControl from "@/v3components/Forms/Visitor/VisitorSettingsControl.vue"
import visitorConstants from "@/constants/visitorConstants"
import { cloneDeep } from "lodash"
import { computed, defineAsyncComponent, onMounted, ref, inject } from "vue"
import { useStore } from "vuex"

const VisitorEditReasonsForm = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/KioskSettings/VisitorEditReasonsForm.vue"
    )
)
const VisitorEditDestinationsForm = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/KioskSettings/VisitorEditDestinationsForm.vue"
    )
)

const VisitorEditHealthQuestionsForm = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/KioskSettings/VisitorEditHealthQuestionsForm.vue"
    )
)

const VisitorTranslationMissing = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/KioskSettings/VisitorTranslationMissing.vue"
    )
)

export default {
  name: "VisitorKioskSettings",
  components: {
    VisitorSettingsControl,
    VisitorWrapper,
    VisitorErrorHandler
  },
  setup() {
    const store = useStore()
    const modal = inject("modal")

    const school = computed(() => store.getters["visitorSettings/school"])

    const visitorSchoolData = computed(() => {
      return school?.value?.data || null
    })

    const isSchoolSettingsLoading = ref(true)
    const isLoading = ref(false)

    const activeSettingKey = ref(null)
    const serverError = ref(null)
    const schoolSettingServerError = ref(null)

    /**
     * List of settings to be displayed on kiosk settings page
     */
    const settingList = computed(() => [
      {
        settingsKey: "id-scanning",
        heading: "Required ID scanning (ID scanning)",
        description:
          "Allows visitors to scan their ID/license at the kiosk to check in.",
        showDescription: false,
        hasLearnMore: true,
        learnMore: "visitor_kiosk_settings_id_scanning_learn_more",
        hasEdit: false,
        showEdit: false,
        hasSwitch: true,
        showSwitch: true,
        hasBody: false,
        options: {
          enabled: isSchoolSettingActive(visitorConstants.SETTINGS.ID_SCANNING),
          restricted: false
        },
        edit: () => ({}),
        toggle: async () => {
          activeSettingKey.value = "id-scanning"
          try {
            isLoading.value = true
            await toggleSchoolSetting(
              visitorConstants.SETTINGS.ID_SCANNING,
              isSchoolSettingActive(visitorConstants.SETTINGS.ID_SCANNING)
            )
          } catch (error) {
            activeSettingKey.value = "id-scanning"
            serverError.value = error
          } finally {
            isLoading.value = false
          }
        }
      },
      {
        settingsKey: "enable-visitor-self-checkout",
        heading: "Enable visitor self check out",
        description: "Allow visitors to check themselves out with the kiosk.",
        showDescription: false,
        hasLearnMore: true,
        learnMore: "visitor_kiosk_settings_self_checkout_learn_more",
        hasEdit: false,
        showEdit: false,
        hasSwitch: true,
        showSwitch: true,
        hasBody: false,
        options: {
          enabled: isSchoolSettingActive(
            visitorConstants.SETTINGS.SELF_CHECKOUT
          ),
          restricted: false
        },
        edit: () => ({}),
        toggle: async () => {
          activeSettingKey.value = "enable-visitor-self-checkout"
          try {
            isLoading.value = true
            await toggleSchoolSetting(
              visitorConstants.SETTINGS.SELF_CHECKOUT,
              isSchoolSettingActive(visitorConstants.SETTINGS.SELF_CHECKOUT)
            )
          } catch (error) {
            activeSettingKey.value = "enable-visitor-self-checkout"
            serverError.value = error
          } finally {
            isLoading.value = false
          }
        }
      },

      {
        settingsKey: "dymo-printer-self-serve",
        heading: "Dymo printer: Visitor self-serve badge printing",
        description:
          "Enable or disable visitors to print their own badge when using the kiosk.",
        showDescription: false,
        hasLearnMore: true,
        learnMore: "visitor_kiosk_settings_dymo_printer_learn_more",
        hasEdit: false,
        showEdit: false,
        hasSwitch: true,
        showSwitch: true,
        hasBody: false,
        options: {
          enabled: isSchoolSettingActive(
            visitorConstants.SETTINGS.SELF_BADGE_PRINT
          ),
          restricted: false
        },
        edit: () => ({}),
        toggle: async () => {
          activeSettingKey.value = "dymo-printer-self-serve"
          try {
            isLoading.value = true
            await toggleSchoolSetting(
              visitorConstants.SETTINGS.SELF_BADGE_PRINT,
              isSchoolSettingActive(visitorConstants.SETTINGS.SELF_BADGE_PRINT)
            )
          } catch (error) {
            activeSettingKey.value = "dymo-printer-self-serve"
            serverError.value = error
          } finally {
            isLoading.value = false
          }
        }
      },
      {
        settingsKey: "multilingual-support",
        heading: "Spanish translation (Multilingual support)",
        description:
          "Enable or disable Spanish support for the Visitor iPad app. Visitors can select their language during check-in.",
        showDescription: false,
        hasLearnMore: true,
        learnMore: "visitor_kiosk_settings_multilingual_support_learn_more",
        hasEdit: false,
        showEdit: false,
        hasSwitch: true,
        showSwitch: true,
        hasBody: false,
        options: {
          enabled: isSchoolSettingActive(
            visitorConstants.SETTINGS.MULTILINGUAL_SUPPORT
          ),
          restricted: isMultiLingualRestricted()
        },
        edit: () => ({}),
        toggle: async () => {
          const notActive = !isSchoolSettingActive(
            visitorConstants.SETTINGS.MULTILINGUAL_SUPPORT
          )
          if (notActive) {
            const areReasonsInvalid = checkIfReasonListInvalid()
            const areDestinationsInvalid = checkIfDestinationListInvalid()
            const areHealthQuestionsInvalid = checkIfHealthQuestionInvalid()
            if (
              areReasonsInvalid ||
              areDestinationsInvalid ||
              areHealthQuestionsInvalid
            ) {
              const fields = []

              if (areReasonsInvalid) {
                fields.push(
                  visitorConstants.KIOSK_SETTINGS
                    .CUSTOMIZE_EDIT_REASONS_MODAL_TITLE
                )
              }

              if (areDestinationsInvalid) {
                fields.push(
                  visitorConstants.KIOSK_SETTINGS
                    .CUSTOMIZE_EDIT_DESTINATIONS_MODAL_TITLE
                )
              }

              if (areHealthQuestionsInvalid) {
                fields.push(
                  visitorConstants.KIOSK_SETTINGS
                    .CUSTOMIZE_HEALTH_SCREENING_MODAL_TITLE
                )
              }

              modal.open(VisitorTranslationMissing, {
                size: "sm",
                props: {
                  body: fields.join(", "),
                  areReasonsInvalid,
                  areDestinationsInvalid,
                  areHealthQuestionsInvalid,
                  add: () => {
                    if (areReasonsInvalid) {
                      modal.open(VisitorEditReasonsForm, {
                        size: "lg",
                        title:
                          visitorConstants.KIOSK_SETTINGS
                            .CUSTOMIZE_EDIT_REASONS_MODAL_TITLE,
                        props: {
                          overrideMultiLingualEnabled: true,
                          areDestinationsInvalid,
                          areHealthQuestionsInvalid
                        }
                      })
                    } else if (areDestinationsInvalid) {
                      modal.open(VisitorEditDestinationsForm, {
                        size: "lg",
                        title:
                          visitorConstants.KIOSK_SETTINGS
                            .CUSTOMIZE_EDIT_DESTINATIONS_MODAL_TITLE,
                        props: {
                          overrideMultiLingualEnabled: true,
                          areHealthQuestionsInvalid
                        }
                      })
                    } else if (areHealthQuestionsInvalid) {
                      modal.open(VisitorEditHealthQuestionsForm, {
                        size: "lg",
                        title:
                          visitorConstants.KIOSK_SETTINGS
                            .CUSTOMIZE_HEALTH_SCREENING_MODAL_TITLE,
                        props: {
                          overrideMultiLingualEnabled: true
                        }
                      })
                    }
                  }
                }
              })
              return
            }
          }

          activeSettingKey.value = "multilingual-support"
          try {
            isLoading.value = true
            await toggleSchoolSetting(
              visitorConstants.SETTINGS.MULTILINGUAL_SUPPORT,
              isSchoolSettingActive(
                visitorConstants.SETTINGS.MULTILINGUAL_SUPPORT
              )
            )
          } catch (error) {
            activeSettingKey.value = "multilingual-support"
            serverError.value = error
          } finally {
            isLoading.value = false
          }
        }
      }
    ])

    const getSchoolSetting = (key) => {
      return (
        visitorSchoolData.value?.building_settings?.find(
          (setting) => setting?.settings_id === key
        ) || null
      )
    }

    const isSchoolSettingActive = (key) => {
      return getSchoolSetting(key)?.value === "true" || false
    }

    const toggleSchoolSetting = async (key, value) => {
      const buildingSetting = cloneDeep(getSchoolSetting(key))
      if (!buildingSetting) {
        return
      }
      buildingSetting.value = value ? "false" : "true"
      await store.dispatch("visitorSettings/updateSchoolSettings", {
        building_settings: [buildingSetting]
      })
      await store.dispatch("visitorSettings/getSchoolSettings")
    }

    const checkIfReasonListInvalid = () => {
      const data = visitorSchoolData?.value || null

      const pickUpFormActive =
        Number(
          data?.building_settings?.find((setting) => {
            return (
              setting.settings_id === visitorConstants.SETTINGS.FORM_PICK_UP
            )
          })?.active
        ) === 1 || false

      if (pickUpFormActive) {
        const pickUpSettingIds = [
          visitorConstants.SETTINGS.PICK_1,
          visitorConstants.SETTINGS.PICK_2,
          visitorConstants.SETTINGS.PICK_3
        ]

        for (const settingId of pickUpSettingIds) {
          const setting = data?.building_settings?.find((setting) => {
            return setting.settings_id === settingId
          })
          const isSettingSelected = Number(setting?.active === 1) || false
          const hasEnglishReason = setting?.value?.trim()?.length > 0
          const hasSpanishReason = setting?.spanish_value?.trim()?.length > 0
          if (isSettingSelected && (!hasEnglishReason || !hasSpanishReason)) {
            return true
          }
        }
      }

      const dropOffFormActive =
        Number(
          data?.building_settings?.find((setting) => {
            return (
              setting.settings_id === visitorConstants.SETTINGS.FORM_DROP_OFF
            )
          })?.active
        ) === 1 || false

      if (dropOffFormActive) {
        const dropOffSettingIds = [
          visitorConstants.SETTINGS.DROP_1,
          visitorConstants.SETTINGS.DROP_2,
          visitorConstants.SETTINGS.DROP_3
        ]

        for (const settingId of dropOffSettingIds) {
          const setting = data?.building_settings?.find((setting) => {
            return setting.settings_id === settingId
          })
          const isSettingSelected = Number(setting?.active) === 1 || false
          const hasEnglishReason = setting?.value?.trim()?.length > 0
          const hasSpanishReason = setting?.spanish_value?.trim()?.length > 0
          if (isSettingSelected && (!hasEnglishReason || !hasSpanishReason)) {
            return true
          }
        }
      }

      const customSettingIds = [
        visitorConstants.SETTINGS.CUSTOM_1,
        visitorConstants.SETTINGS.CUSTOM_2,
        visitorConstants.SETTINGS.CUSTOM_3,
        visitorConstants.SETTINGS.CUSTOM_4,
        visitorConstants.SETTINGS.CUSTOM_5
      ]

      for (const settingId of customSettingIds) {
        const setting = data?.building_settings?.find((setting) => {
          return setting.settings_id === settingId
        })
        const isSettingSelected = Number(setting?.active) === 1 || false
        const hasEnglishReason = setting?.value?.trim()?.length > 0
        const hasSpanishReason = setting?.spanish_value?.trim()?.length > 0
        if (isSettingSelected && (!hasEnglishReason || !hasSpanishReason)) {
          return true
        }
      }

      return false
    }

    const checkIfDestinationListInvalid = () => {
      const destinations = visitorSchoolData.value?.destination || []

      for (const destination of destinations) {
        const isDestinationSelected = Number(destination?.active) === 1 || false
        const hasEnglishDestination =
          destination?.destination?.trim()?.length > 0
        const hasSpanishDestination = destination?.spanish?.trim()?.length > 0
        if (
          isDestinationSelected &&
          (!hasEnglishDestination || !hasSpanishDestination)
        ) {
          return true
        }
      }

      return false
    }

    const checkIfHealthQuestionInvalid = () => {
      const healthQuestions =
        visitorSchoolData.value?.health_screening_questions || []

      for (const question of healthQuestions) {
        const isQuestionSelected = Number(question?.active) === 1 || false
        const hasEnglishQuestion = question?.question?.trim()?.length > 0
        const hasSpanishQuestion =
          question?.spanish_question?.trim()?.length > 0
        const hasAnswer = [0, 1].includes(Number(question?.correct_answer))
        if (
          isQuestionSelected &&
          (!hasEnglishQuestion || !hasSpanishQuestion || !hasAnswer)
        ) {
          return true
        }
      }

      return false
    }

    const isMultiLingualRestricted = () => {
      const alreadyEnabled = isSchoolSettingActive(
        visitorConstants.SETTINGS.MULTILINGUAL_SUPPORT
      )
      if (alreadyEnabled) {
        return false
      }
      const areReasonsInvalid = checkIfReasonListInvalid()
      const areDestinationsInvalid = checkIfDestinationListInvalid()
      const areHealthQuestionsInvalid = checkIfHealthQuestionInvalid()
      return (
        areReasonsInvalid || areDestinationsInvalid || areHealthQuestionsInvalid
      )
    }

    onMounted(async () => {
      isSchoolSettingsLoading.value = true
      try {
        await store.dispatch("visitorSettings/getSchoolSettings")
      } catch (error) {
        schoolSettingServerError.value = error
      } finally {
        isSchoolSettingsLoading.value = false
      }
    })

    return {
      isLoading,
      isSchoolSettingsLoading,
      schoolSettingServerError,
      serverError,
      activeSettingKey,
      settingList
    }
  }
}
</script>
