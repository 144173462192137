<template>
  <i class="c-sidebar-nav-icon" :class="name"></i>
</template>

<script>
export default {
  props: {
    name: String
  }
}
</script>