import axios from "axios"
import { api, configure, configure6 } from "./axiousWithCache"

const state = {
  releases: null,
  trainings: null,
  videos: null,
  announcements: null,
  releasesDoc: null
}

const mutations = {
  SET_RELEASES(state, releases) {
    state.releases = releases
  },
  SET_TRAININGS(state, trainings) {
    state.trainings = trainings
  },
  SET_VIDEOS(state, videos) {
    state.videos = videos
  },
  SET_ANNOUNCEMENTS(state, announcements) {
    state.announcements = announcements
  },
  SET_RELEASES_DOC(state, releasesDoc) {
    state.releasesDoc = releasesDoc
  }
}

const getters = {
  releases(state) {
    return state.releases
  },
  trainings(state) {
    return state.trainings
  },
  videos(state) {
    return state.videos
  },
  announcements(state) {
    return state.announcements
  },
  releasesDoc(state) {
    return state.releasesDoc
  }
}
const actions = {
  getNewReleases(context) {
    return new Promise((resolve, reject) => {
      configure6()
        .then(async (api) => {
          const response = await api.get(`/releases`)
          if (response.data.data) {
            context.commit("SET_RELEASES_DOC", response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllReleases(context, config) {
    return new Promise((resolve, reject) => {
      configure()
        .then(async (api) => {
          const response = await api.get(`/releases`, config)
          context.commit("SET_RELEASES", response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getRelease() {
    return new Promise((resolve, reject) => {
      api
        .get(`/releases/show`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  markReleasesSeen(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/releases/seen/${id}`)
        .then((response) => {
          if (response.data.data) {
            context.dispatch("getAllReleases", { clearCacheEntry: true })
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTrainings(context) {
    return new Promise((resolve, reject) => {
      configure()
        .then(async (api) => {
          const response = await api.get(`/static-content/trainings`)
          if (response.data.data) {
            context.commit("SET_TRAININGS", response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getVideos(context) {
    return new Promise((resolve, reject) => {
      configure()
        .then(async (api) => {
          const response = await api.get(`/static-content/videos`)
          if (response.data.data) {
            context.commit("SET_VIDEOS", response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAnnouncement(context) {
    return new Promise((resolve, reject) => {
      configure()
        .then(async (api) => {
          const response = await api.get(`/static-content/announcements`)
          if (response.data.data) {
            context.commit("SET_ANNOUNCEMENTS", response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
