<template>
  <div>
    <SubstituteUsersDataTable />
  </div>
</template>
<script>
import SubstituteUsersDataTable from "@/v3components/Datatables/SubstituteUsersDataTable.vue"

export default {
  name: "SubstituteUsers",
  components: { SubstituteUsersDataTable }
}
</script>
