<template>
  <ContactControlTable />
</template>
<script>
import ContactControlTable from "@/v3components/Datatables/ContactControlTable.vue"

export default {
  name: "ContactControl",
  components: {
    ContactControlTable
  }
}
</script>
