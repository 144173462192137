<template>
  <div v-if="status" class="v3-pass-status-box" :class="status?.class">
    <i v-if="!hideIcon && status.icon" class="me-1" :class="status.icon"></i>
    <span>{{ status.label }}</span>
  </div>
</template>

<script>
import { computed } from "vue"
export default {
  name: "PassStatus",
  props: {
    pass: {
      type: Object,
      required: true
    },
    hideIcon: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const status = computed(() => {
      if (props.pass.badge_flags) {
        if (
          props.pass.badge_flags.ended ||
          props.pass.badge_flags.system_completed ||
          props.pass.badge_flags.min_time
        ) {
          return { label: "Ended", class: "ended", icon: "ri-stop-circle-line" }
        }
        if (props.pass.badge_flags.missed) {
          return {
            label: "Missed Apt",
            class: "missed-apt",
            icon: "ri-ghost-line"
          }
        }
        if (props.pass.badge_flags.canceled) {
          return {
            label: "Canceled",
            class: "canceled",
            icon: "ri-calendar-check-line"
          }
        }
        if (props.pass.badge_flags.missed_request) {
          return {
            label: "Missed Request",
            class: "missed",
            icon: "ri-alert-line"
          }
        }
      }
      return ""
    })

    return { status }
  }
}
</script>

<style></style>
