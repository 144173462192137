<template>
  <!-- HEADER AND EMERGENCY BUTTON -->
  <div class="d-flex justify-content-between rounded-30 gap-2 flex-wrap">
    <h3>{{ pageTitle }}</h3>
    <EmergencyButton
      prepend-icon="ri-alarm-warning-line"
      rounded
      @click="onEmergencyButton"
    >
      Emergency
    </EmergencyButton>
  </div>
  <!-- HEADER AND EMERGENCY BUTTON -->
</template>

<script>
import EmergencyButton from "@/v3components/shared/Buttons/EmergencyButton.vue"
import visitorConstants from "@/constants/visitorConstants"
import visitorConfigs from "@/configs/visitorConfigs"
import { defineAsyncComponent, inject, computed } from "vue"
import { useStore } from "vuex"

const VisitorSendAlertPopUp = defineAsyncComponent(
  () =>
    import("@/v3components/Forms/Visitor/CheckIns/VisitorSendAlertPopUp.vue")
)

const staticData = {
  emergency: {
    settingId: visitorConstants.SETTINGS.EMERGENCY,
    alertType: visitorConstants.ALERTS.EMERGENCY
  },
  offender: {
    settingId: visitorConstants.SETTINGS.SEX_OFFENDER,
    alertType: visitorConstants.ALERTS.SEX_OFFENDER
  },
  watchlist: {
    settingId: visitorConstants.SETTINGS.WATCHLIST,
    alertType: visitorConstants.ALERTS.WATCHLIST
  }
}

export default {
  name: "VisitorCheckInsAndLogsHeader",
  components: {
    EmergencyButton
  },
  setup() {
    const store = useStore()
    const modal = inject("modal")

    const currentMatch = computed(
      () => store?.getters?.["visitorManage/matchType"] || "emergency"
    )

    const school = computed(() => store.getters["visitorSettings/school"])

    const visitorSchoolData = computed(() => {
      return school?.value?.data || null
    })

    const alertSetting = computed(() => {
      return visitorSchoolData.value?.building_settings?.find(
        (setting) =>
          setting.settings_id === staticData[currentMatch.value].settingId
      )
    })

    const isAlertSettingActive = computed(() => {
      return alertSetting.value?.value === "true"
    })

    const capitalizeCurrentMatch = computed(() => {
      return currentMatch.value
        ? currentMatch.value.charAt(0).toUpperCase() +
            currentMatch.value.slice(1)
        : ""
    })

    const setCurrentMatchType = (type = "emergency") => {
      store.commit(
        "visitorManage/SET_MATCH_TYPE",
        type?.trim()?.toLowerCase() || "emergency"
      )
    }

    const onEmergencyButton = () => {
      setCurrentMatchType("emergency")

      const mapForArticle = {
        emergency: "an",
        offender: "an",
        watchlist: "a"
      }
      modal.open(VisitorSendAlertPopUp, {
        size: "sm",
        props: {
          isAlertActive: isAlertSettingActive.value,
          title: isAlertSettingActive.value
            ? `${capitalizeCurrentMatch.value} alert`
            : "Notification not sent",
          body: isAlertSettingActive.value
            ? `Do you want to send ${mapForArticle?.[currentMatch.value] || ""} ${currentMatch.value} alert? This cannot be undone.`
            : `Please check alert configurations. Contact your admin to update Security Alerts (settings)`,
          type: currentMatch.value
        }
      })
    }

    return {
      pageTitle: visitorConfigs.pages.checkInLogsPage().name,
      onEmergencyButton,
      capitalizeCurrentMatch
    }
  }
}
</script>

<style scoped></style>
