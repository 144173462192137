<template>
  <div class="v3-form polarity-message-form">
    <div class="row">
      <div>
        <Label required>Student A</Label>
        <LazyLoadSelect
          :classes="'mb-3'"
          :selected="state.form.studentA"
          type="student"
          placeholder="Find Student"
          :invalid-feedback="state.serverErrors.studentA || errors.studentA"
          @changed="
            (value) => {
              state.form.studentA = value
            }
          "
        ></LazyLoadSelect>
      </div>
      <div>
        <Label required>Student B</Label>
        <LazyLoadSelect
          :classes="'mb-3'"
          :selected="state.form.studentB"
          type="student"
          placeholder="Find Student"
          :invalid-feedback="state.serverErrors.studentB || errors.studentB"
          @changed="
            (value) => {
              state.form.studentB = value
            }
          "
        ></LazyLoadSelect>
      </div>
      <div>
        <Label>Admin note</Label>
        <InputField
          type="textarea"
          v-model="state.form.note"
          class="d-block"
          :rows="5"
          :invalid-feedback="errors.note"
        ></InputField>
      </div>
      <div>
        <InfoBox
          v-if="state.serverRes"
          class="mt-4"
          :class="{ danger: Object.keys(state.serverErrors).length }"
          :title="state.serverRes.title"
          >{{ state.serverRes.message }}</InfoBox
        >
      </div>
      <div
        class="d-flex align-items-center justify-content-center mt-4 text-center"
      >
        <LoadingButton
          v-if="!isHeaderCreateButton"
          class="me-2 px-4"
          @click="onCancel"
          rounded
          >Cancel</LoadingButton
        >
        <LoadingButton
          :is-loading="state.isProcessing"
          @click="submit()"
          class="px-4 me-3"
          solid
          rounded
          >Create</LoadingButton
        >
        <router-link
          v-if="isHeaderCreateButton"
          class="text-graident-blue d-flex flex-row-reverse"
          to="/limits/ab-polarity"
        >
          <div class="d-flex align-items-center" @click="onCancel()">
            <img
              width="18"
              class="m-0 me-2"
              src="@/assets/images/icons/gradient-link.png"
            />
            <span class="text-graident-blue"
              ><span class="text-graident-blue">Go to full menu</span></span
            >
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed, inject } from "vue"
import { useStore } from "vuex"
import { helpers, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import Label from "@/v3components/shared/Form/Label.vue"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"

export default {
  name: "CreateContactControlForm",
  components: {
    Label,
    LazyLoadSelect,
    InputField,
    InfoBox,
    LoadingButton
  },
  props: {
    isHeaderCreateButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ["cancel", "closeModal"],
  setup(props, { emit }) {
    const store = useStore()
    const modal = inject("modal")

    const state = reactive({
      isProcessing: false,
      form: {
        studentA: null,
        studetnB: null,
        note: ""
      },
      serverErrors: {},
      serverRes: null
    })

    const validationMessages = {
      required: "This field is required"
    }
    const validations = {
      form: {
        studentA: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        studentB: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isFormValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    const submit = () => {
      if (isFormValid.value) {
        const data = {
          first_user_id: state.form.studentA
            ? state.form.studentA.value.id
            : state.form.studentA,
          second_user_id: state.form.studentB
            ? state.form.studentB.value.id
            : state.form.studentB,
          status: false,
          message: state.form.note
        }
        createPolarity(data)
      } else {
        v$.value.$touch()
      }
    }

    const createPolarity = (data) => {
      state.isProcessing = true
      store
        .dispatch("polarities/createPolarity", data)
        .then(() => {
          setResponseInfoBox("Success", "Successfully created!")
          state.isProcessing = false
          state.serverErrors = {}
          modal.setAction("createContactControl")
          setTimeout(() => {
            store.dispatch("polarities/getPolarities").then((response) => {
              const data = response.data
              if (data) {
                store.commit("polarities/SET_POLARITIES", data.data)
              }
            })
            setResponseInfoBox()
            emit("cancel")
            emit("closeModal")
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.message ? res.message : {}
          setResponseInfoBox(res.status ? res.status : "Error", res.message)
          state.isProcessing = false
        })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const onCancel = () => {
      resetFormData()
      emit("cancel")
      emit("closeModal")
    }

    const resetFormData = () => {
      state.form = {
        studentA: null,
        studetnB: null,
        note: ""
      }
    }

    return {
      state,
      v$,
      isFormValid,
      errors,
      submit,
      setResponseInfoBox,
      onCancel,
      resetFormData
    }
  }
}
</script>
