<template>
  <div
    class="v3-file-upload ps__child--consume"
    :class="{
      'is-invalid': invalidFeedback
    }"
  >
    <div class="v3-file-upload-input-container mb-1">
      <label class="custom-file-upload">
        <div class="icon-file-upload">
          <i class="ri-file-2-line" />
        </div>

        <div class="label-file-upload text-center">Select a file to upload</div>
        <input type="file" @change="onChange" />
      </label>
    </div>
    <div v-if="invalidFeedbackMessage" class="invalid-message text-start">
      {{ invalidFeedbackMessage }}
    </div>
    <div
      v-if="downloadExampleFilePath != ''"
      class="v3-file-upload-download"
      @click="downloadCSVSample(downloadExampleFilePath)"
    >
      <i class="ri-download-2-line download-icon" />
      Download Example
    </div>
    <div v-if="state.uploadedFileNames">
      <div
        v-for="(filename, index) in state.uploadedFileNames"
        :key="index"
        class="mt-2"
      >
        <hr />
        <b>{{ filename ? filename : "" }}</b>
        <div class="v3-file-upload-delete-icon" @click="removeFile(filename)">
          <i class="ri-delete-bin-line"></i>
        </div>
        <hr />
      </div>
    </div>
    <HelperBox v-if="helpText != ''">
      {{ helpText }}
    </HelperBox>
  </div>
</template>

<script>
import { computed, reactive } from "vue"
import HelperBox from "../Alerts/HelperBox.vue"

export default {
  components: { HelperBox },
  props: {
    invalidFeedback: [String, Array],
    uploadedFile: File,
    downloadExampleFilePath: {
      type: String,
      default: ""
    },
    helpText: {
      type: String,
      default: ""
    },
    multipleFiles: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue", "remove-file"],
  setup(props, { emit }) {
    const state = reactive({
      uploadedFileNames: []
    })
    const onChange = (event) => {
      const files = event.target.files
      if (props.multipleFiles) {
        state.uploadedFileNames.push(files[0].name)
      } else {
        state.uploadedFileNames = [files[0].name]
      }
      emit("update:modelValue", event)
    }

    const invalidFeedbackMessage = computed(() => {
      return props.invalidFeedback && Array.isArray(props.invalidFeedback)
        ? props.invalidFeedback[0]
        : props.invalidFeedback
    })

    const removeFile = (filename) => {
      const indexOfFile = state.uploadedFileNames.indexOf(filename)
      state.uploadedFileNames.splice(indexOfFile, 1)
      emit("remove-file", filename)
    }

    const downloadCSVSample = (data) => {
      const splitData = data.split("/")
      const link = document.createElement("a")
      link.href = data
      link.setAttribute("download", splitData[2])
      link.click()
    }

    return {
      state,
      onChange,
      removeFile,
      invalidFeedbackMessage,
      downloadCSVSample
    }
  }
}
</script>

<style></style>
