import axios from "axios"

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  setGlobalTime: null,
  bannersShow: true,
  showCounters: false
}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : "responsive"
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : "responsive"
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
  toggle(state, variable) {
    state[variable] = !state[variable]
  },
  setGlobalTime(state, value) {
    state.setGlobalTime = value
  }
}

const getters = {
  globalTime: (state) => {
    return state.setGlobalTime
  }
}
const actions = {
  toggleNotificationSounds(context, role_id) {
    let endpoint = ""
    //if student
    if (role_id == 1) {
      endpoint = "/users/me/audio"
    } else {
      endpoint = "/admin/users/profile/audio"
    }
    axios.put(endpoint).then((response) => {
      const data = response.data.data.audio_enabled
      context.commit("authentication/TOGGLE_USER_AUDIO", data, {
        root: true
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
