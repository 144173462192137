<template>
  <div :class="classes" class="v3-row-toggler">
    <i :class="active ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'"></i>
  </div>
</template>

<script>
export default {
  name: "RowToggler",
  props: { classes: [Array, String], active: Boolean }
}
</script>
