<template>
  <CDropdownMenu
    data-test-id="notification-center-list"
    class="user-notifications-dropdown pt-0 pb-0"
  >
    <div v-if="notifications.length" class="user-notifications-list px-3 py-4">
      <CDropdownItem
        v-for="(notification, index) in notifications"
        :key="index"
        class="p-0"
      >
        <component
          :is="notificationMappings[notification.type]"
          :notification="notification"
        />
      </CDropdownItem>

      <button
        class="btn mt-3 action-btn text-white py-2 px-4 fw-bold"
        @click="clearNotifications"
      >
        Clear all notifications
      </button>
    </div>
    <div v-if="!notifications.length" class="p-3 text-center">
      No new notifications
    </div>
  </CDropdownMenu>
</template>

<script>
import { notificationComponents, notificationMappings } from "../index"
import { useStore } from "vuex"
import { computed, onBeforeMount } from "vue"

export default {
  name: "NotificationsList",
  components: {
    ...notificationComponents
  },
  expose: ["markNotificationsAsSeen"],
  setup() {
    const store = useStore()

    onBeforeMount(() => {
      store.dispatch("sockets/getNotifications")
    })

    const notifications = computed(() => store.getters["sockets/notifications"])

    const notSeen = computed(() => {
      return notifications.value.filter((e) => !e.seen_at)
    })

    const clearNotifications = () => {
      store.dispatch("sockets/clearNotifications")
    }

    const markNotificationsAsSeen = () => {
      store.dispatch("sockets/getNotifications").then(() => {
        const notifications = []
        notSeen.value.forEach((e) => {
          notifications.push(e.id)
        })
        if (notifications.length > 0) {
          store.dispatch("sockets/bulkSeenNotifications", {
            notifications: notifications
          })
        }
      })
    }

    return {
      notificationMappings,
      notifications,
      notSeen,
      clearNotifications,
      markNotificationsAsSeen
    }
  }
}
</script>
