<template>
  <div class="v3-dashboard-datatable-action-panel">
    <div class="action d-flex align-items-left justify-content-between mb-1">
      <div class="action-left d-flex align-items-left">
        <div
          class="action-panel-tabs"
          :class="{ active: state.activeTab === 'passes' }"
          @click="setActiveTab('passes')"
        >
          <i class="ri-door-open-line me-1"></i>Passes<span class="ms-1"
            ><i class="ri-loader-line me-1"></i
            >{{ stats.waitingApprovalLength }}</span
          >
        </div>
        <div
          v-if="isActiveAppoinmentModule"
          class="action-panel-tabs"
          :class="{ active: state.activeTab === 'appointments' }"
          @click="setActiveTab('appointments')"
        >
          <i class="ri-calendar-check-line me-1"></i>Appointments
          <span class="purple ms-1"
            ><i class="ri-calendar-check-line me-1"></i
            >{{ stats.todayAppointmentsCount }}</span
          ><span v-if="activeSchoolHasAppoitmentPasses" class="ms-1"
            ><i class="ri-loader-line me-1"></i
            >{{ stats.awaitingTodayAppointmentsCount }}</span
          >
          <span v-if="activeSchoolHasAppoitmentPasses" class="orange ms-1"
            ><i class="ri-calendar-2-line me-1"></i
            >{{ stats.futureAppointmentsCount }}</span
          >
        </div>
      </div>
      <div class="action-right">
        <slot name="right-content"></slot>
        <div v-if="showExport" class="action-panel-sort ms-1">
          <IconButton
            @click="onExportClick"
            icon="ri-download-2-line"
          ></IconButton>
        </div>
        <slot name="right-last-content"></slot>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <div class="action action-left d-flex align-items-left">
        <div v-if="showTopLeftSlot" class="action-panel-left-slot ms-1">
          <DashboardTabs
            :active-tab="state.activeTab ? state.activeTab : ''"
          ></DashboardTabs>
        </div>
        <div class="d-flex align-items-left">
          <div v-if="showSearch" class="action-panel-search ms-1">
            <InputField
              prepend-icon="ri-search-line"
              placeholder="Search by student or room name"
              :model-value="searchVModel"
              @update:model-value="onSearch"
            ></InputField>
          </div>
          <div v-if="showSort" class="action-panel-sort ms-1">
            <IconButton @click="onSort" icon="ri-filter-line"></IconButton>
          </div>
        </div>

        <slot name="left-content"></slot>
        <HelpCenterButton
          v-if="helpButton"
          classes="ms-2"
          :content-key="helpButtonContentKey"
          :is-old="true"
        />
      </div>
    </div>
    <slot name="bottom-content"></slot>
  </div>
</template>

<script>
import InputField from "@/v3components/shared/Form/InputField.vue"
import IconButton from "@/v3components/shared/Buttons/IconButton.vue"
import DashboardTabs from "@/v3components/Datatables/DashboardDataTable/TabsAndFilter/DashboardTabs.vue"
import { reactive, onMounted, computed, watch } from "vue"
import HelpCenterButton from "@/v3components/HelpCenterButton.vue"
import { useStore } from "vuex"

export default {
  props: {
    showSearch: {
      type: Boolean,
      default: false
    },
    showSort: {
      type: Boolean,
      default: false
    },
    showExport: {
      type: Boolean,
      default: false
    },
    helpButton: {
      type: Boolean,
      default: false
    },
    helpButtonContentKey: {
      type: String,
      default: ""
    },
    searchVModel: {
      type: String,
      default: ""
    },
    showTopLeftSlot: {
      type: Boolean,
      default: false
    }
  },
  emits: ["tabClicked", "onSearch", "onSort", "onExport"],
  components: { InputField, IconButton, HelpCenterButton, DashboardTabs },
  setup(props, { emit }) {
    const state = reactive({
      activeTab: null
    })

    const activePassType = computed(() => {
      return store.getters["dashboardTable/getType"]
    })

    onMounted(() => {
      setActiveTab(activePassType.value)
    })

    const setActiveTab = (tab) => {
      let tabMemory = ""
      tab == "my" || tab == "all" || tab == "passes"
        ? (tabMemory = "passes")
        : (tabMemory = "appointments")

      state.activeTab = tabMemory
      emit("tabClicked", tabMemory)
    }
    const onSearch = (value) => {
      emit("onSearch", value)
    }
    const onSort = (value) => {
      emit("onSort", value)
    }
    const onExportClick = (value) => {
      emit("onExport", value)
    }

    //  counters
    const store = useStore()
    const appTodays = computed(
      () => store.getters["dashboardCounters/appTodays"]
    )
    const appTodaysWaiting = computed(
      () => store.getters["dashboardCounters/appTodaysWaiting"]
    )
    const appFeatureWaiting = computed(
      () => store.getters["dashboardCounters/appFeatureWaiting"]
    )
    const aptPassesCounters = computed(
      () => store.getters["dashboardCounters/aptPassesCounters"]
    )

    const waitingApprovalLength = computed(
      () => store.getters["dashboardCounters/waitingApprovalLength"]
    )

    const stats = computed(() => ({
      todayAppointmentsCount: appTodays.value,
      awaitingTodayAppointmentsCount: appTodaysWaiting.value,
      futureAppointmentsCount: appFeatureWaiting.value,
      waitingApprovalLength: waitingApprovalLength.value
    }))

    const globalTime = computed(() => store.getters["layout/globalTime"])

    watch(globalTime, () => {
      store.commit(
        "dashboardCounters/UPDATE_APP_COUNTERS",
        aptPassesCounters.value
      )
    })

    const activeSchoolHasAppoitmentPasses = computed(
      () => store.getters["schools/activeSchoolHasAppoitmentPasses"]
    )

    const isActiveAppoinmentModule = computed(() => {
      return store.getters["schools/isActiveAppoinmentModule"]
    })

    return {
      setActiveTab,
      onSearch,
      onSort,
      onExportClick,
      state,
      activePassType,
      stats,
      isActiveAppoinmentModule,
      activeSchoolHasAppoitmentPasses
    }
  }
}
</script>
