<template>
  <div
    data-test-id="sidebar-sound-btn"
    class="sound-icon d-flex align-items-center"
    @click="toggleNotfSound()"
  >
    <i :class="isMuted ? 'ri-volume-mute-fill' : 'ri-volume-up-fill'" />
    <div>
      <audio id="notifications-audio">
        <source src="@/assets/sounds/pristine-609.mp3" type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  name: "TheHeaderSoundBtn",
  props: ["user"],
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      isMuted: "authentication/isMuted"
    })
  },
  watch: {
    isMuted: function (from, to) {
      if (to) {
        this.$helpers.playNotificationSound()
      } else {
        this.$helpers.stopNotificationSound()
      }
    }
  },
  methods: {
    toggleNotfSound() {
      this.$store.dispatch("layout/toggleNotificationSounds", this.user.role.id)
    }
  }
}
</script>
