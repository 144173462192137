<template>
  <div
    v-if="pass.user"
    class="user-apt-pass-box d-flex align-items-center"
    :class="statusApt?.class || statusDashboard?.class"
  >
    <div
      v-if="dashboard && isBasicView"
      class="v3-dashboard-mini-status-box me-2"
      :class="statusDashboard?.class"
    ></div>

    <div
      v-if="dashboard"
      class="d-flex align-items-center me-2"
      :class="!isBasicView ? 'flex-column' : 'flex-row'"
    >
      <span
        v-if="getFlagStatusColor != 'transparent'"
        class="status-flag"
        :style="'color:' + getFlagStatusColor + ';font-size: 17px;'"
        ><i class="flaticon-flag-2"></i>
      </span>
      <div
        v-if="isPassEditable"
        class="cursor-pointer"
        @click="showModal('passTimeEdit')"
      >
        <i class="ri-pencil-line"></i>
      </div>
    </div>

    <div class="flex-1">
      <div
        v-if="!isBasicView && (statusApt || statusDashboard)"
        class="v3-pass-status-box"
        :class="statusApt?.class || statusDashboard?.class"
      >
        <i
          v-if="statusApt.icon || statusDashboard.icon"
          class="me-1"
          :class="statusApt.icon || statusDashboard.icon"
        ></i>
        <span>{{ statusApt.label || statusDashboard.label }}</span>
      </div>
      <div
        class="user-info d-flex align-items-center"
        :class="!isBasicView ? 'mt-2' : ''"
      >
        <div
          v-if="showStudentPhotos && !isBasicView"
          class="c-avatar-pass-history me-2"
        >
          <img
            width="40"
            height="40"
            :src="
              pass.user.avatar
                ? pass.user.avatar
                : 'img/avatars/user-avatar.jpeg'
            "
            class="c-avatar-pass-history-img"
          />
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex">
            <div>{{ pass.user.first_name }} {{ pass.user.last_name }}</div>
            <div v-if="appointments && !isBasicView">
              <i v-show="pass.acknowledge_at" class="ri-thumb-up-line"></i>
              <i
                v-show="pass.acknowledged_by_mail_at"
                class="ri-thumb-up-line"
              ></i>
            </div>
          </div>
          <div v-if="dashboard && !isBasicView" class="d-flex align-items-end">
            <div class="cursor-pointer" @click="showModal('todaysHistory')">
              <strong class="me-1">{{
                pass.user.passes_for_today_count
                  ? pass.user.passes_for_today_count
                  : 0
              }}</strong
              ><i class="ri-door-open-line"></i>
            </div>
            <span
              v-show="pass.edited_at && !isBasicView"
              class="is-edited ms-2"
              style="font-size: 10px"
            >
              EDITED<i class="fi flaticon-edit ms-1"></i>
            </span>
          </div>
        </div>
        <div v-if="pass.tardy_badge" class="is-late">
          <i v-if="pass.tardy_badge === 'LTC'" class="ri-alarm-line"></i>
          <i v-if="pass.tardy_badge === 'LTS'" class="ri-rest-time-line"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from "vue"
import { useStore } from "vuex"
import helpers from "@/helpers/index"
import StudentPassHistory from "@/v3components/Forms/Students/StudentPassHistory.vue"
import PassTimeEdit from "@/v3components/Forms/EditPassHistoryTimeForm.vue"

export default {
  props: {
    dashboard: {
      type: Boolean,
      default: false
    },
    appointments: {
      type: Boolean,
      default: false
    },
    pass: {
      type: Object,
      required: true
    },
    isBasicView: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  setup(props) {
    const store = useStore()
    const modal = inject("modal")

    const currentUser = computed(() => store.getters["authentication/user"])

    const showStudentPhotos = computed(
      () => store.getters["schools/showStudentPhotos"]
    )

    const passesTimeSettings = computed(
      () => store.getters["passes/passesTimeSettings"]
    )

    const autoPassLimits = computed(
      () => store.getters["passes/rooms/autoPassLimits"]
    )

    const statusDashboard = computed(() => {
      let status = ""
      if (props.dashboard && props.pass && props.pass.badge_flags) {
        if (props.pass.badge_flags.waiting_approval) {
          status = {
            class: "waiting-approval",
            label: "WAITING APPROVAL",
            icon: "ri-loader-line"
          }
        }
        if (props.pass.badge_flags.active) {
          status = {
            class: "activated",
            label: "ACTIVE",
            icon: "ri-checkbox-circle-line"
          }
        }
        if (props.pass.badge_flags.min_time) {
          status = {
            label: passesTimeSettings.value
              ? helpers.formatDate(
                  passesTimeSettings.value.min_time,
                  "hh:mm:ss",
                  "m"
                ) + "+ MIN"
              : "Min time",
            class: "min-time",
            icon: "ri-time-line"
          }
        }
        if (props.pass.badge_flags.ended || props.pass.system_completed) {
          status = {
            class: "ended",
            label: "ENDED",
            icon: "ri-stop-circle-line"
          }
        }
      }
      return status
    })

    const statusApt = computed(() => {
      let status = ""
      if (props.appointments && props.pass) {
        if (
          !props.pass.confirmed_at &&
          !props.pass.confirmed_by_teacher_at &&
          !props.pass.is_missed_request &&
          !props.pass.is_canceled &&
          !props.pass.is_for_future &&
          !props.pass.is_for_today &&
          !props.pass.is_ended &&
          !props.pass.ended_at
        ) {
          status = {
            class: "waiting-confirmation",
            label: "WAITING CONF",
            icon: "ri-loader-line"
          }
        }
        if (props.pass.is_canceled) {
          status = {
            class: "canceled",
            label: "CANCELLED APT",
            icon: "ri-calendar-check-line"
          }
        }
        if (props.pass.is_activated) {
          return (status = {
            class: "activated",
            label: "ACTIVATED",
            icon: "ri-checkbox-circle-line"
          })
        }
        if (props.pass.is_for_future) {
          status = {
            class: "future-apt",
            label: "FUTURE APT",
            icon: "ri-calendar-check-line"
          }
        }
        if (props.pass.is_for_today) {
          status = {
            class: "today-pass",
            label: "TODAY APT",
            icon: "ri-calendar-check-line"
          }
        }
        if (props.pass.is_long_running) {
          status = {
            label: passesTimeSettings.value
              ? helpers.formatDate(
                  passesTimeSettings.value.min_time,
                  "hh:mm:ss",
                  "m"
                ) + "+ MIN"
              : "Min time",
            class: "min-time",
            icon: "ri-time-line"
          }
        }

        if (props.pass.is_ended || props.pass.ended_at) {
          status = {
            class: "ended",
            label: "ENDED",
            icon: "ri-stop-circle-line"
          }
        }
        if (props.pass.waiting_activation) {
          status = {
            class: "waiting-activation",
            label: "WAITING ACTIVATION",
            icon: "ri-loader-line"
          }
        }
        if (props.pass.is_missed) {
          status = {
            class: "missed-apt",
            label: "MISSED",
            icon: "ri-ghost-line"
          }
        }
        if (props.pass.is_missed_request && !props.pass.is_for_future) {
          status = {
            class: "missed-request",
            label: "MISSED REQUEST",
            icon: "ri-alert-line"
          }
        }
        if (props.pass.is_in_another_pass) {
          status = {
            class: "on-other-pass",
            label: "ON OTHER PASS",
            icon: "ri-walk-line"
          }
        }
      }
      return status
    })

    const getFlagStatusColor = computed(() => {
      if (props.pass.badge_flags) {
        if (props.pass.badge_flags.system_completed) {
          return "#DF3C3B"
        }
        if (props.pass.badge_flags.min_time) {
          return "#f3d259"
        }
        if (props.pass.badge_flags.extended) {
          return "#672C91"
        }
      }
      return "transparent"
    })

    const isPassEditable = computed(() => {
      if (
        statusDashboard.value &&
        statusDashboard.value.label != "WAITING APPROVAL" &&
        currentUser.value &&
        props.pass.badge_flags &&
        !props.pass.badge_flags.active
      ) {
        if (props.pass.approved_by === currentUser.value.id) {
          return true
        }
        if (props.pass.completed_by === currentUser.value.id) {
          return true
        }
        if (props.pass.to_id === currentUser.value.id) {
          return true
        }
        if (props.pass.from.id === currentUser.value.id) {
          return true
        }
        if (props.pass.approved_with == "autopass") {
          const autoPassesRooms =
            autoPassLimits.value.auto_pass_preference_to_user.map(
              (el) => el.auto_pass.room_id
            )
          if (autoPassesRooms.includes(props.pass.from.id)) {
            return true
          }
          if (autoPassesRooms.includes(props.pass.to.id)) {
            return true
          }
          return false
        }
      }
      return false
    })

    const showModal = (propsModal) => {
      if (propsModal === "todaysHistory") {
        modal.open(StudentPassHistory, {
          size: "lg",
          title: "Today's History",
          props: {
            setData: true,
            userId: props.pass.user.id
          },
          help: "todays_history"
        })
      }
      if (propsModal === "passTimeEdit") {
        modal.open(PassTimeEdit, {
          size: "lg",
          props: {
            pass: props.pass,
            v3dashboard: true
          }
        })
      }
    }

    return {
      statusDashboard,
      statusApt,
      showStudentPhotos,
      getFlagStatusColor,
      isPassEditable,
      showModal
    }
  }
}
</script>
