import axios from "axios"
import { Promise } from "core-js"
import _ from "underscore"
import appConfig from "@/helpers/appConfig.js"

const state = {
  kiosks: [],
  active_kiosk: null,
  access_token: null,
  createNewPassData: null,
  activePass: null,
  passHistories: [],
  isImportSuccessful: false,
  PCsFileName: null,
  kioskImportPasscodes: []
}

const kioskInstance = axios.create({
  baseURL: appConfig.api + "v5",
  withCredentials: false
})

const kioskInstanceV6 = axios.create({
  baseURL: appConfig.api + "v6",
  withCredentials: false
})

const getters = {
  kiosks(state) {
    return state.kiosks
  },
  activeKiosk(state) {
    return state.active_kiosk
  },
  activePass(state) {
    return state.activePass
  },
  accessToken(state) {
    return state.access_token
  },
  createNewPassData(state) {
    return state.createNewPassData
  },
  passHistories(state) {
    return state.passHistories
  },
  isImportSuccessful(state) {
    return state.isImportSuccessful
  },
  PCsFileName(state) {
    return state.PCsFileName
  },
  kioskImportPasscodes(state) {
    return state.kioskImportPasscodes
  }
}

const mutations = {
  SET_KIOSKS(state, kiosksCollection) {
    state.kiosks = kiosksCollection
  },
  SET_ACTIVE_KIOSK(state, kiosk) {
    state.active_kiosk = kiosk
  },
  SET_ACTIVE_KIOSK_LATES(state, kiosk) {
    state.active_kiosk.allowed_late_passes = kiosk
  },
  SET_ACTIVE_PASS(state, pass) {
    if (pass) {
      state.activePass = { ...state.activePass, ...pass }
    } else {
      state.activePass = null
    }
  },
  SET_KIOSK_ACCESS_TOKEN(state, accessToken) {
    state.access_token = accessToken
    kioskInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${accessToken}`
    localStorage.setItem("kioskAccessToken", accessToken)
  },
  UPDATE_KIOSK(state, newKiosk) {
    const oldKiosk = _.findIndex(state.kiosks, { id: newKiosk.id })
    state.kiosks.splice(oldKiosk, 1, newKiosk)
  },
  ADD_KIOSK(state, newKiosk) {
    state.kiosks.unshift(newKiosk)
  },
  DEACTIVATE_KIOSK(state, kiosk) {
    state.kiosks = state.kiosks.filter((oldKiosk) => oldKiosk.id !== kiosk.id)
  },
  SET_CREATE_NEW_PASS_DATA(state, data) {
    state.createNewPassData = data
  },
  SET_PASS_HISTORIES(state, collection) {
    state.passHistories = collection
  },
  SET_IS_IMPORT_SUCCESSFUL(state, data) {
    state.isImportSuccessful = data
  },
  SET_KIOSK_PASSCODES_NAME(state, data) {
    state.PCsFileName = data
  },
  SET_KIOSK_PASSCODE(state, data) {
    state.kioskImportPasscodes = data
  }
}

const actions = {
  getKiosks(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/kiosk", { params })
        .then((response) => {
          const data = response.data.data
          if (data) {
            context.commit("SET_KIOSKS", data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPasses() {
    return new Promise((resolve, reject) => {
      kioskInstance
        .get("/kiosk/passes")
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createKiosk(context, kioskData) {
    return new Promise((resolve, reject) => {
      axios
        .post("/kiosk", kioskData)
        .then((response) => {
          const data = response.data.data
          if (data) {
            context.commit("ADD_KIOSK", data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  directLogin(context, data) {
    return new Promise((resolve, reject) => {
      kioskInstanceV6
        .post("/kiosk/direct", data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  userLogin(context, data) {
    return new Promise((resolve, reject) => {
      kioskInstance
        .post("/kiosk/login", data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  logOut() {
    kioskInstance.post("/kiosk/logout")
  },
  userLogout(context) {
    context.dispatch("logOut")
    context.commit("SET_KIOSK_ACCESS_TOKEN", null)
    context.commit("SET_ACTIVE_PASS", null)
    context.commit("SET_CREATE_NEW_PASS_DATA", null)
    kioskInstance.defaults.headers.common["Authorization"] = ``
    kioskInstanceV6.defaults.headers.common["Authorization"] = ``
    localStorage.removeItem("kioskAccessToken")
  },
  createKioskPass(context, pass) {
    kioskInstanceV6.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("kioskAccessToken")}`
    return new Promise((resolve, reject) => {
      kioskInstanceV6
        .post(`/kiosk/passes`, pass)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updatePass(context, { id, data }) {
    return new Promise((resolve, reject) => {
      kioskInstance
        .put(`/kiosk/passes/${id}`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deactivateKiosk(context, kiosk_id) {
    return new Promise((resolve, reject) => {
      axios
        .post("/kiosk/" + kiosk_id + "/deactivate")
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendCode(context, kiosk_id) {
    return new Promise((resolve, reject) => {
      axios
        .post("/kiosk/" + kiosk_id + "/email")
        .then((response) => resolve(response))
        .catch((error) => {
          reject(error)
        })
    })
  },
  regeneratePasscode(context, kiosk_id) {
    return new Promise((resolve, reject) => {
      axios
        .post("/kiosk/" + kiosk_id + "/passcode/regenerate")
        .then((response) => {
          const data = response.data.data
          if (data) {
            context.commit("UPDATE_KIOSK", data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  searchByTypeAndQuery(context, params) {
    return new Promise((resolve, reject) => {
      kioskInstanceV6
        .get(`/kiosk/search`, {
          params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  importKioskPasscodes(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/admin/users/imports/kiosk", data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  finalimportKioskPasscodes(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/admin/users/imports/kiosk/${data.fileName}/final`,
          data.excludedItems
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  autoApprovePass(context, data) {
    return new Promise((resolve, reject) => {
      kioskInstance
        .put(`/kiosk/passes/${data.passId}/auto-approve`, {
          action: data.action,
          kiosk_id: data.kiosk_id
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getFavorites(context, contextID) {
    return new Promise((resolve, reject) => {
      kioskInstanceV6
        .get(`/kiosk/favorites?context=${contextID}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPassHistories() {
    return new Promise((resolve, reject) => {
      kioskInstance
        .get(`/kiosk/history`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getKioskImportedPasscodes(context, { fileName, filterData }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/users/imports/kiosk/result/${fileName}`, {
          params: filterData
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  postEditedPasscode(context, { id, passcode }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/users/imports/kiosk/${id}`, passcode)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
