<template>
  <ContactTracingTable />
</template>

<script>
import ContactTracingTable from "@/v3components/Datatables/ContactTracingTable.vue"
export default {
  name: "ContactTracing",
  components: { ContactTracingTable }
}
</script>
