<template>
  <div>
    <CContainer>
      <CRow class="justify-content-center mt-4">
        <CCol md="12 text-center">
          <StudentConsequencesLadderTable></StudentConsequencesLadderTable>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import StudentConsequencesLadderTable from "@/v3components/Datatables/StudentConsequencesLadderTable.vue"
export default {
  components: { StudentConsequencesLadderTable },
  name: "ConsequencesLadder",
  computed: {}
}
</script>
