<template>
  <div class="d-flex flex-column gap-4">
    <!-- SETTING CARD HEADER -->
    <div
      class="d-flex flex-wrap justify-content-between align-items-start gap-2"
    >
      <!-- HEADER LEFT -->
      <div class="d-flex flex-column gap-2">
        <!-- SETTING NAME -->
        <h4>
          {{ heading }}
          <!-- LEARN MORE LINK - HELP CENTER ICON -->
          <span v-if="hasLearnMoreLink" class="ms-2">
            <HelpCenterButton :content-key="learnMore" />
          </span>
          <!-- LEARN MORE LINK - HELP CENTER ICON -->
        </h4>
        <!-- SETTING NAME -->

        <!-- SETTING DESCRIPTION -->
        <p v-if="showDescription">
          {{ description }}
        </p>
        <!-- SETTING DESCRIPTION -->
      </div>
      <!-- HEADER LEFT -->

      <!-- HEADER RIGHT -->
      <div>
        <div v-if="hasControlSlot">
          <slot name="control" />
        </div>
        <div
          v-else
          class="d-flex justify-content-end align-content-start gap-4"
        >
          <BaseButton v-if="hasEdit && showEdit" rounded solid @click="edit">
            Edit
          </BaseButton>
          <template v-if="options?.restricted && !options?.enabled">
            <div @click.stop="toggle">
              <CustomSwitch
                v-if="hasSwitch && showSwitch"
                class="pt-2.5 pe-none"
                type="radio"
                :model-value="false"
              />
            </div>
          </template>
          <template v-else>
            <CustomSwitch
              v-if="hasSwitch && showSwitch"
              class="pt-2.5"
              type="radio"
              :model-value="options?.enabled"
              @change="toggle"
            />
          </template>
        </div>
      </div>
      <!-- HEADER RIGHT -->
    </div>
    <!-- SETTING CARD HEADER -->

    <!-- SERVER ERROR -->
    <VisitorErrorHandler
      v-if="error != null"
      :error="error"
      @done="$emit('done')"
    />
    <!-- SERVER ERROR -->

    <!-- SETTING CARD BODY -->
    <div v-if="hasDefaultSlot">
      <slot />
    </div>
    <!-- SETTING CARD BODY -->
  </div>
</template>

<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import CustomSwitch from "@/v3components/shared/Form/CustomSwitch.vue"
import HelpCenterButton from "@/v3components/HelpCenterButton.vue"
import { computed } from "vue"
export default {
  name: "VisitorSettingsControl",
  components: {
    BaseButton,
    CustomSwitch,
    VisitorErrorHandler,
    HelpCenterButton
  },
  props: {
    settingsKey: {
      type: String,
      default: ""
    },
    heading: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    showDescription: {
      type: Boolean,
      default: true
    },
    hasLearnMore: {
      type: Boolean,
      default: false
    },
    learnMore: {
      type: String,
      default: ""
    },
    hasEdit: {
      type: Boolean,
      default: false
    },
    showEdit: {
      type: Boolean,
      default: false
    },
    hasSwitch: {
      type: Boolean,
      default: false
    },
    showSwitch: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({})
    },
    edit: {
      type: Function,
      default: () => ({})
    },
    toggle: {
      type: Function,
      default: () => ({})
    },
    error: {
      type: Object,
      default: null
    }
  },
  emits: ["done"],
  setup(props, { slots }) {
    const hasDefaultSlot = computed(() => !!slots.default)
    const hasControlSlot = computed(() => !!slots.control)
    const hasLearnMoreLink = computed(
      () => props?.hasLearnMore && props?.learnMore?.trim()?.length > 0
    )

    return {
      hasDefaultSlot,
      hasControlSlot,
      hasLearnMoreLink
    }
  }
}
</script>

<style scoped>
.pt-2\.5 {
  padding-top: 0.72rem;
}
</style>
