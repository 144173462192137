<template>
  <div v-if="actions && actions.length" class="visitor-row-action">
    <IconButton
      icon="ri-more-2-fill"
      @click.stop="toggleMenu"
      class="visitor-row-action-icon-button"
      :class="{ active: state.openModal }"
    />
    <div v-if="state.openModal" class="overlay" @click.stop="closeMenu"></div>
    <div v-if="state.openModal" class="visitor-row-action-dropdown">
      <div
        v-for="action in actions"
        :key="action.id"
        class="visitor-row-action-item"
        @click.stop="onClickAction(action)"
      >
        <div class="visitor-row-action-icon">
          <i :class="action.icon"></i>
        </div>
        <div class="visitor-row-action-title">
          <span>{{ action.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue"
import IconButton from "@/v3components/shared/Buttons/IconButton.vue"

export default {
  name: "VisitorRowAction",
  components: { IconButton },
  props: {
    actions: {
      type: Array,
      required: true
    }
  },
  emits: ["clickAction"],
  setup(props, { emit }) {
    const state = reactive({
      openModal: false
    })

    const toggleMenu = () => {
      state.openModal = !state.openModal
    }

    const closeMenu = () => {
      state.openModal = false
    }

    const onClickAction = (action) => {
      emit("clickAction", action)
      closeMenu()
    }

    return {
      state,
      toggleMenu,
      closeMenu,
      onClickAction
    }
  }
}
</script>
