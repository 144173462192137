<template>
  <div class="v3kiosk-view">
    <KioskLoginForm />
  </div>
</template>
<script>
import KioskLoginForm from "@/v3components/Forms/KioskForms/KioskLoginForm.vue"

export default {
  name: "KioskLogin",
  components: {
    KioskLoginForm
  }
}
</script>
