import { localNoCache } from "./axiousWithCache"

const state = {
  content: null
}

const mutations = {
  SET_CONTENT(state, content) {
    state.content = content
  }
}

const getters = {
  getHelpCenterContent(state) {
    return state.content
  }
}
const actions = {
  getHelpCenterContent(context, keyData) {
    localNoCache
      .get("/helpCenter/" + keyData + ".html")
      .then((res) => {
        if (res.data) {
          context.commit("SET_CONTENT", res.data)
        } else {
          context.commit("SET_CONTENT", "No Information or this key")
        }
      })
      .catch((err) => {
        context.commit("SET_CONTENT", err)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
