<template>
  <div class="d-flex justify-content-end align-items-end">
    Showing

    <div class="bg-white mx-2 cs-dropdown-select dropdown">
      <button
        @click="state.isVisible = !state.isVisible"
        type="button"
        aria-expanded="false"
        aria-haspopup="true"
        class="btn dropdown-toggle"
      >
        {{ state.selectedOption.label }}
      </button>
      <div
        :class="{ 'd-block': state.isVisible }"
        class="dropdown-menu"
        style=""
      >
        <div
          v-for="(item, index) in options"
          @click="action(item), selecteActive(item)"
          :key="index"
          role="menuitem"
          href="#"
          target="_self"
          class="dropdown-item"
        >
          {{ item.label }}
        </div>
      </div>
    </div>

    <div
      v-if="pagination.total > 0 && pagination.per_page.label != 'All entries'"
    >
      <span class="d-inline-block me-2">entries</span> Showing
      {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }}
    </div>
    <div
      v-if="pagination.total > 0 && pagination.per_page.label == 'All entries'"
    >
      Showing 1 to {{ pagination.to }} of {{ pagination.total }}
    </div>
  </div>
</template>

<script>
import { reactive } from "vue"

export default {
  name: "PerPageSelector",
  props: {
    pagination: {
      type: Object
    },
    options: {
      type: Array,
      deep: true,
      default: function () {
        return [
          {
            label: "25",
            value: 25
          },
          {
            label: "50",
            value: 50
          },
          {
            label: "100",
            value: 100
          },
          {
            label: "All entries",
            value: 75
          }
        ]
      }
    },
    action: {
      type: Function
    }
  },
  setup() {
    const state = reactive({
      isVisible: false,
      selectedOption: {
        label: "25",
        value: 25
      }
    })

    const selecteActive = (item) => {
      state.selectedOption = item
      state.isVisible = false
    }

    return {
      state,
      selecteActive
    }
  }
}
</script>
