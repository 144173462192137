<template>
  <HistoryTable />
</template>
<script>
import HistoryTable from "@/v3components/Datatables/History/HistoryTable.vue"
export default {
  name: "History",
  components: { HistoryTable }
}
</script>
