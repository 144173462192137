const CryptoJS = require("crypto-js")
import cookieService from "./cookies"

const tokenService = {
  saveToken(token) {
    if (token) {
      localStorage.setItem(
        "accessToken",
        CryptoJS.AES.encrypt(
          token,
          navigator.userAgent + cookieService.getCookie("tlasphe")
        ).toString()
      )
      return
    }
  },
  getToken() {
    try {
      const token = window.localStorage.getItem("accessToken")
      return CryptoJS.AES.decrypt(
        token,
        navigator.userAgent + cookieService.getCookie("tlasphe")
      ).toString(CryptoJS.enc.Utf8)
    } catch (error) {
      return ""
    }
  },
  saveDevToken(token) {
    localStorage.setItem("devAccessToken", token)
  },
  getDevToken() {
    return localStorage.getItem("devAccessToken")
      ? localStorage.getItem("devAccessToken")
      : null
  }
}

export default tokenService
