<template>
  <div class="custom-select-datepicker">
    <CustomSelect
      v-if="!state.showDatepicker"
      v-model="state.selectedRange"
      :options="rangeOptions"
      :filterable="false"
      :searchable="false"
      :close-on-select="true"
      :append-icon="'ri-calendar-line'"
      placeholder="Select Date Range"
      class="custom-select-datepicker-date-select"
      @update:model-value="onOptionSelect($event)"
    >
    </CustomSelect>
    <CustomDatePicker
      v-if="state.showDatepicker"
      v-model="state.selectedRangeCalendar"
      :is-range="true"
      :min-date="calendarMinDate"
      :max-date="calendarMaxDate"
      :is-custom-select="state.showDatepicker"
      :placeholder="'Select Date Range'"
      @update:model-value="onCalendarClose"
    >
    </CustomDatePicker>
  </div>
</template>
<script>
import { reactive, computed, watch } from "vue"
import helpers from "@/helpers/index.js"
import moment from "moment-timezone"
import CustomSelect from "./CustomSelect.vue"
import CustomDatePicker from "./CustomDatePicker.vue"

export default {
  name: "CustomSchoolYearSelectDatePicker",
  components: { CustomSelect, CustomDatePicker },
  emits: ["update:modelValue"],
  props: {
    rangeOptions: Array,
    modelValue: String,
    limitMaxDate: {
      type: Boolean,
      default: false
    },
    isCurrentSchoolYearSelected: {
      type: Boolean,
      default: true
    },
    selectedSchoolYearLimit: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      showDatepicker: false,
      selectedRange: props.rangeOptions ? props.rangeOptions[0].range : "",
      selectedRangeCalendar: null,
      startDate: null,
      endDate: null
    })

    const calendarMinDate = computed(() => {
      return props.selectedSchoolYearLimit.value
        ? new Date(props.selectedSchoolYearLimit.value[0], 6, 1)
        : helpers.minSchoolYearDate()
    })

    const calendarMaxDate = computed(() => {
      if (props.limitMaxDate) {
        const today = moment()
        return new Date(helpers.currTzDate(today))
      } else {
        return props.selectedSchoolYearLimit.value
          ? new Date(props.selectedSchoolYearLimit.value[1], 5, 30)
          : helpers.maxSchoolYearDate()
      }
    })

    watch(
      () => props.modelValue,
      (newRange) => {
        state.selectedRange = newRange.range ? newRange.range : newRange
        const calendarRange = newRange.split(" - ")
        state.selectedRangeCalendar = {
          start: calendarRange[0],
          end: calendarRange[1]
        }
        if (props.isCurrentSchoolYearSelected) {
          state.showDatepicker = false
        } else {
          state.showDatepicker = true
          const rangeLimits = newRange.range
            ? newRange.range.split(" - ")
            : newRange.split(" - ")
          state.startDate = rangeLimits[0]
          state.endDate = rangeLimits[1]
        }
      }
    )

    const onOptionSelect = (option) => {
      if (option.id == "custom") {
        state.startDate = state.selectedRange.startDate
          ? state.selectedRange.startDate
          : null
        state.endDate = state.selectedRange.endDate
          ? state.selectedRange.endDate
          : null
        state.showDatepicker = true
        state.selectedRangeCalendar = {
          start: moment()._d,
          end: moment()._d
        }
      } else {
        state.selectedRange = option.range
        emit("update:modelValue", state.selectedRange)
      }
    }

    const onCalendarClose = () => {
      state.selectedRange =
        helpers.currTzDate(state.selectedRangeCalendar.start) +
        " - " +
        helpers.currTzDate(state.selectedRangeCalendar.end)

      if (
        moment(helpers.currTzDate(state.selectedRangeCalendar.start)).isAfter(
          helpers.currTzDate(helpers.minSchoolYearDate())
        ) &&
        moment(helpers.currTzDate(state.selectedRangeCalendar.end)).isBefore(
          helpers.currTzDate(helpers.maxSchoolYearDate())
        )
      ) {
        state.showDatepicker = false
      } else {
        state.showDatepicker = true
      }
      emit("update:modelValue", state.selectedRange)
    }

    return {
      state,
      calendarMinDate,
      calendarMaxDate,
      onOptionSelect,
      onCalendarClose
    }
  }
}
</script>
