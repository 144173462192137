<template>
  <div class="container">
    <div class="row justify-content-center mt-4">
      <div class="my-3 p-0">
        <div class="d-flex justify-content-end">
          <PerPage
            v-if="isPaginationAvailable"
            :pagination="allowAutopassLocations.meta"
            :showing-entries="true"
            @on-page-change="setPerPage"
          />
        </div>
      </div>
      <DataTable
        v-if="allowAutopassLocations"
        :fields="autoPassTableFields"
        :items="allowAutopassLocations.data"
      >
        <template #locations="{ item }">
          <td>{{ item.room.name }}</td>
        </template>
        <template #action="{ item }">
          <td class="text-end">
            <Actions
              :actions="autoPassActions"
              @click-action="handleTableAction($event, item.room.id)"
            >
            </Actions>
          </td>
        </template>
      </DataTable>
    </div>
    <Pagination
      v-if="isPaginationAvailable && calcPages > 1"
      class="cs-pagination mt-4"
      :dots="false"
      :double-arrows="true"
      :active-page="state.pagination.activePage"
      :pages="calcPages"
      align="center"
      size="lg"
      @update:active-page="setActivePage"
    >
    </Pagination>
  </div>
</template>

<script>
import { onMounted, computed, reactive, inject } from "vue"
import { useStore } from "vuex"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import PerPage from "@/v3components/shared/DataTable/Perpage.vue"
import Actions from "@/v3components/shared/DataTable/Actions.vue"
import Pagination from "@/v3components/shared/DataTable/Pagination.vue"
import helpers from "@/helpers/index.js"

export default {
  name: "AutoPassTable",
  components: { DataTable, PerPage, Actions, Pagination },
  setup() {
    const store = useStore()
    const actionDialog = inject("actionDialog")

    const state = reactive({
      isProcessing: false,
      pagination: {
        activePage: 1,
        per_page: { label: "25", value: 25 }
      }
    })

    onMounted(() => {
      getTableData()
    })

    const allowAutopassLocations = computed(
      () => store.getters["rooms/autoPassLimits"]
    )

    const isPaginationAvailable = computed(
      () =>
        allowAutopassLocations.value &&
        allowAutopassLocations.value.meta &&
        allowAutopassLocations.value.meta.total
    )

    const calcPages = computed(() =>
      isPaginationAvailable.value
        ? Math.ceil(
            allowAutopassLocations.value.meta.total /
              allowAutopassLocations.value.meta.per_page
          )
        : 0
    )

    const autoPassTableFields = [
      {
        key: "locations",
        label: "Locations (rooms)",
        sorter: false,
        filter: false
      },
      {
        key: "action",
        label: "Action",
        _style: "width: 50px;",
        sorter: false,
        filter: false
      }
    ]

    const autoPassActions = [
      {
        label: "Delete",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete"
      }
    ]

    const setPerPage = (option) => {
      if (option) {
        state.pagination.per_page = option
        getTableData()
      }
    }

    const setActivePage = (page) => {
      state.pagination.activePage = page
      getTableData(state.pagination.activePage)
      helpers.scrollToTop()
    }

    const getTableData = (page) => {
      state.isProcessing = true
      state.pagination.activePage = page ? page : 1
      const params = {
        page: state.pagination.activePage,
        per_page: state.pagination.per_page.value
      }
      store
        .dispatch("rooms/getAutoPassLimitV7", params)
        .then(() => {
          state.isProcessing = false
        })
        .catch(() => {
          state.isProcessing = false
        })
    }

    const handleTableAction = (event, item) => {
      if (event.action == "delete") {
        actionDialog.open(deleteLocation, {
          args: item,
          props: { danger: true, title: "Delete", question: "Are you sure?" }
        })
      }
    }

    const deleteLocation = (locationId) => {
      state.isProcessing = true
      store.dispatch("rooms/deleteAutoPassLimit", locationId).finally(() => {
        state.isProcessing = false
        getTableData()
      })
    }

    return {
      state,
      allowAutopassLocations,
      calcPages,
      autoPassTableFields,
      autoPassActions,
      setPerPage,
      setActivePage,
      handleTableAction,
      isPaginationAvailable
    }
  }
}
</script>
