<template>
  <div class="dashboard-counters" v-if="!basic">
    <div class="d-flex justify-content-center">
      <div class="dashboard-counters-main-box">
        <div class="label">My active passes</div>
        <div class="dashboard-counters-main-box-passes">
          <span>{{ myActivePassesLength }}</span>
        </div>
        <div class="settings">
          <span class="settings-number">{{ myPassesLength }}</span>
          <span class="settings-text-purple ms-1"> My total passes</span>
          <span class="settings-account ms-1">
            <i class="ri-user-shared-fill account-icon ms-1"></i>
            <span class="settings-number"> {{ waitingApprovalLength }}</span>
          </span>
        </div>
      </div>
      <div class="dashboard-counters-secondary-box">
        <i class="ri-door-open-line"></i>
      </div>
    </div>
  </div>
  <div class="dashboard-basic-counters" v-else>
    <div class="dashboard-basic-counters-box">
      <div class="dashboard-basic-counters-box-passes">
        <span class="active-passes">{{ myActivePassesLength }}</span
        ><span class="total-passes">/{{ myPassesLength }}</span>
      </div>
      <div class="label ms-1">My active passes</div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex"
import { computed } from "vue"

export default {
  name: "MyPasses",
  props: {
    basic: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore()

    const myPassesLength = computed(
      () => store.getters["dashboardCounters/myPassesLength"]
    )
    const myActivePassesLength = computed(
      () => store.getters["dashboardCounters/myActivePassesLength"]
    )

    const waitingApprovalLength = computed(
      () => store.getters["dashboardCounters/waitingApprovalLength"]
    )

    return {
      myPassesLength,
      myActivePassesLength,
      waitingApprovalLength
    }
  }
}
</script>

<style lang="scss" scoped></style>
