import { reactive } from "vue"

const actionDialogPlugin = (app) => {
  const actions = {
    open: (onComfirmCallback, options = { args: null, props: null }) => {
      if (!onComfirmCallback) {
        throw new Error("Please provide a callback function for the diaolog!")
      }
      config.isVisible = true
      config.isConfirmed = null
      config.onComfirmCallback = onComfirmCallback
      config.options = options
    },
    close: () => {
      config.isVisible = false
    },
    confirm: () => {
      try {
        config.isConfirmed = true
        config.onComfirmCallback(config.options.args)
      } catch (error) {
        config.close()
      }
      config.close()
    }
  }
  const config = reactive({
    isVisible: false,
    onComfirmCallback: null,
    options: null,
    isConfirmed: null,
    ...actions
  })
  app.provide("actionDialog", config)
}

export default actionDialogPlugin
