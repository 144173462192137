<template>
  <VisitorWrapper>
    <CContainer>
      <!-- GET SCHOOL SETTINGS ERROR -->
      <VisitorErrorHandler
        v-if="serverError != null"
        :error="serverError"
        @done="serverError = null"
      />
      <!-- GET SCHOOL SETTINGS ERROR -->

      <!-- WATCHLIST -->
      <CRow
        v-if="!isSchoolSettingsLoading"
        class="justify-content-center mt-4 bg-white rounded-30 p-4 d-flex flex-column gap-2"
      >
        <VisitorWatchListTable />
      </CRow>
      <!-- WATCHLIST -->

      <!-- WATCHLIST LOADING -->
      <CRow
        v-else
        class="d-flex align-content-center justify-content-center mt-4 bg-white rounded-30 p-5 d-flex flex-column gap-2"
      >
        <CSpinner color="primary" />
      </CRow>
      <!-- WATCHLIST LOADING -->

      <!-- SECURITY STAFF -->
      <CRow
        v-if="!isSchoolSettingsLoading"
        class="justify-content-center mt-6 bg-white rounded-30 p-4 d-flex flex-column gap-2"
      >
        <VisitorSecurityStaffTable />
      </CRow>
      <!-- SECURITY STAFF -->

      <!-- SECURITY STAFF LOADING -->
      <CRow
        v-else
        class="d-flex align-content-center justify-content-center mt-4 bg-white rounded-30 p-5 d-flex flex-column gap-2"
      >
        <CSpinner color="primary" />
      </CRow>
      <!-- SECURITY STAFF LOADING -->

      <!-- EMERGENCY CONTACT -->
      <CRow
        v-if="!isSchoolSettingsLoading"
        class="justify-content-center mt-6 bg-white rounded-30 p-4 d-flex flex-column gap-2"
      >
        <VisitorEmergencyContactTable />
      </CRow>
      <!-- EMERGENCY CONTACT -->

      <!-- EMERGENCY CONTACT LOADING -->
      <CRow
        v-else
        class="d-flex align-content-center justify-content-center mt-4 bg-white rounded-30 p-5 d-flex flex-column gap-2"
      >
        <CSpinner color="primary" />
      </CRow>
      <!-- EMERGENCY CONTACT LOADING -->

      <!-- PRE-APPROVED LIST -->
      <CRow
        v-if="isPreApprovedSettingActive && !isSchoolSettingsLoading"
        class="justify-content-center mt-6 bg-white rounded-30 p-4 d-flex flex-column gap-2"
      >
        <VisitorPreApprovedListTable />
      </CRow>
      <!-- PRE-APPROVED LIST -->

      <!-- PRE-APPROVED LIST LOADING -->
      <CRow
        v-else-if="isPreApprovedSettingActive && isSchoolSettingsLoading"
        class="d-flex align-content-center justify-content-center mt-4 bg-white rounded-30 p-5 d-flex flex-column gap-2"
      >
        <CSpinner color="primary" />
      </CRow>
      <!-- PRE-APPROVED LIST LOADING -->
    </CContainer>
  </VisitorWrapper>
</template>
<script>
import VisitorWrapper from "@/v3components/VisitorWrapper.vue"
import VisitorWatchListTable from "@/v3components/Datatables/Visitor/ListSettings/VisitorWatchListTable.vue"
import VisitorSecurityStaffTable from "@/v3components/Datatables/Visitor/ListSettings/VisitorSecurityStaffTable.vue"
import VisitorEmergencyContactTable from "@/v3components/Datatables/Visitor/ListSettings/VisitorEmergencyContactTable.vue"
import VisitorPreApprovedListTable from "@/v3components/Datatables/Visitor/ListSettings/VisitorPreApprovedListTable.vue"
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import visitorConstants from "@/constants/visitorConstants"
import { onMounted, ref, computed } from "vue"
import { useStore } from "vuex"

export default {
  name: "VisitorListSettings",
  components: {
    VisitorErrorHandler,
    VisitorWatchListTable,
    VisitorSecurityStaffTable,
    VisitorEmergencyContactTable,
    VisitorPreApprovedListTable,
    VisitorWrapper
  },
  setup() {
    const store = useStore()
    const isSchoolSettingsLoading = ref(true)
    const serverError = ref(null)

    const school = computed(() => store.getters["visitorSettings/school"])

    const visitorSchoolData = computed(() => {
      return school?.value?.data || null
    })

    const preApprovedSetting = computed(() => {
      return (
        visitorSchoolData.value?.building_settings?.find(
          (setting) =>
            setting?.settings_id ===
            visitorConstants.SETTINGS.PRE_APPROVED_VISITOR
        ) || null
      )
    })

    const isPreApprovedSettingActive = computed(() => {
      return preApprovedSetting.value?.value === "true"
    })

    onMounted(async () => {
      try {
        isSchoolSettingsLoading.value = true
        await store.dispatch("visitorSettings/getSchoolSettings")
      } catch (error) {
        serverError.value = error
      } finally {
        isSchoolSettingsLoading.value = false
      }
    })

    return {
      isSchoolSettingsLoading,
      isPreApprovedSettingActive,
      serverError
    }
  }
}
</script>

<style scoped></style>
