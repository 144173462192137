<template>
  <div
    class="v3-label-pill d-inline-flex align-items-center justify-content-center"
  >
    <div v-if="icon" class="v3-label-pill-icon me-1">
      <i :class="icon"></i>
    </div>
    <div class="v3-label-pill-label">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ""
    }
  }
}
</script>

<style></style>
