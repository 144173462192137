.vue<template>
  <CContainer>
    <CRow class="justify-content-center">
      <CCol md="12" class="text-start">
        <CRow>
          <CCol class="d-flex">
            <div class="v3-module-list-title">Appointment Pass</div>
            <HelpCenterButton
              classes="ms-2"
              content-key="modules_appointment_pass_and_request"
            />
          </CCol>
          <CCol class="d-flex flex-row-reverse align-items-center">
            <CustomSwitch
              type="radio"
              test-id="appointment-switch"
              :model-value="state.isAppModuleActive"
              @change="toggleStatus(state.isAppModuleActive)"
            ></CustomSwitch>
          </CCol>
        </CRow>
        <div class="v3-module-list-box" v-if="state.isAppModuleActive">
          <p class="mb-3">Email Notifications</p>
          <div class="ms-3 mb-3">
            <div class="v3-module-list-checkbox d-flex mb-3">
              <CustomCheck
                v-model="state.form.emailNotification"
                :label="'Turn ON email reminders to students (in addition to 20 and 5 minute in-application notifications)'"
                @change="submit"
              ></CustomCheck>
            </div>
            <CRow class="d-flex">
              <p class="mb-3">Turn OFF Morning Agenda emails for:</p>
              <CCol class="v3-module-list-checkbox d-flex align-items-center">
                <CustomCheck
                  test-id="appointment-agenda-email-all-checkbox"
                  label="All"
                  v-model="state.aptAgendaEmailsAllCheckbox"
                  @change="submit"
                ></CustomCheck>
              </CCol>
              <CCol class="v3-module-list-checkbox d-flex align-items-center">
                <CustomCheck
                  test-id="appointment-agenda-email-adults-checkbox"
                  label="Adults"
                  v-model="state.form.apt_agenda_emails.adults"
                  :disabled="state.aptAgendaEmailsAllCheckbox"
                  @change="submit"
                ></CustomCheck>
              </CCol>
              <CCol class="v3-module-list-checkbox d-flex align-items-center">
                <CustomCheck
                  test-id="appointment-agenda-email-students-checkbox"
                  label="Students"
                  v-model="state.form.apt_agenda_emails.students"
                  :disabled="state.aptAgendaEmailsAllCheckbox"
                  @change="submit"
                ></CustomCheck>
              </CCol>
            </CRow>
          </div>
          <p class="mb-3">Options</p>
          <div class="ms-3">
            <div class="v3-module-list-checkbox d-flex mb-3">
              <CustomCheck
                v-model="state.form.adminEdit"
                :label="'Turn OFF Admin\'s ability to edit other users\' Appointment Passes'"
                @change="submit"
              ></CustomCheck>
            </div>
            <CRow class="d-flex">
              <p class="mb-3">Turn ON Student Appointment Requests for:</p>
              <CCol class="v3-module-list-checkbox d-flex align-items-center">
                <CustomCheck
                  test-id="appointment-checkbox"
                  label="Teachers"
                  v-model="state.form.teachers"
                  @change="submit"
                ></CustomCheck>
              </CCol>
              <CCol class="v3-module-list-checkbox d-flex align-items-center">
                <CustomCheck
                  test-id="appointment-checkbox"
                  label="Admins"
                  v-model="state.form.admins"
                  @change="submit"
                ></CustomCheck>
              </CCol>
              <CCol class="v3-module-list-checkbox d-flex align-items-center">
                <CustomCheck
                  test-id="appointment-checkbox"
                  label="Staff"
                  v-model="state.form.staff"
                  @change="submit"
                ></CustomCheck>
              </CCol>
              <CCol class="v3-module-list-checkbox d-flex align-items-center">
                <CustomCheck
                  test-id="appointment-checkbox"
                  label="Locations"
                  v-model="state.form.locations"
                  @change="submit"
                ></CustomCheck>
              </CCol>
            </CRow>
          </div>
          <CAlert
            v-if="state.reqResponse.message"
            class="mt-4"
            :color="state.reqResponse.type"
            :show="!!state.reqResponse.message"
          >
            {{ state.reqResponse.message }}
          </CAlert>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import HelpCenterButton from "../../HelpCenterButton.vue"
import CustomCheck from "@/v3components/shared/Form/CustomCheck.vue"
import CustomSwitch from "@/v3components/shared/Form/CustomSwitch.vue"
import { useStore } from "vuex"
import { onMounted, computed, reactive, ref, watch } from "vue"

export default {
  name: "AppointmentPassModuleForm",
  components: { HelpCenterButton, CustomCheck, CustomSwitch },
  setup() {
    const store = useStore()

    const state = reactive({
      form: {
        apt_agenda_emails: {
          adults: false,
          students: false
        },
        emailNotification: false,
        adminEdit: false,
        teachers: false,
        admins: false,
        staff: false,
        locations: false
      },
      aptAgendaEmailsAllCheckbox: false,
      isAppModuleActive: false,
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      }
    })

    const isLoading = ref(false)

    const options = computed(() => {
      const activeSchoolModules = store.state.schools.activeSchoolModules
      const index = activeSchoolModules.findIndex(
        (module) => module.name === "Appointmentpass"
      )
      return index !== -1
        ? JSON.parse(activeSchoolModules[index].pivot.option_json)
        : false
    })

    const active = computed(() => {
      const activeSchoolModules = store.state.schools.activeSchoolModules
      const index = activeSchoolModules.findIndex(
        (module) => module.name === "Appointmentpass"
      )
      return index !== -1
        ? Boolean(activeSchoolModules[index].pivot.status)
        : false
    })

    const adultsAndStudentsSelected = computed(() => 
      state.form.apt_agenda_emails.adults &&
      state.form.apt_agenda_emails.students
    )

    onMounted(() => {
      if (options.value) {
        state.form.emailNotification = !!options.value.email
        state.form.adminEdit = !!options.value.admin_edit
        state.form.teachers = !!options.value.teacher
        state.form.admins = !!options.value.admin
        state.form.staff = !!options.value.staff
        state.form.locations = !!options.value.location
        if (options.value.turn_off_agenda_emails_for_appointments_for) {
          state.form.apt_agenda_emails = options.value.turn_off_agenda_emails_for_appointments_for
        }
      }
      state.isAppModuleActive = active.value
    })

    const submit = () => {
      setTimeout(() => {
        resetResponseMessages()
        const inputsData = JSON.stringify({
          peer: null,
          sms: null,
          email: state.form.emailNotification,
          admin: state.form.admins,
          staff: state.form.staff,
          location: state.form.locations,
          teacher: state.form.teachers,
          admin_edit: state.form.adminEdit,
          turn_off_agenda_emails_for_appointments_for: state.form.apt_agenda_emails
        })
        isLoading.value = true
        store
          .dispatch("modules/updateAppointmentPassModule", {
            option_json: inputsData
          })
          .then(() => {
            setSuccessResponse("Module updated.")
            store.dispatch("schools/getActiveModules", {
              clearCacheEntry: true
            })
            isLoading.value = false
            setTimeout(() => {
              resetResponseMessages()
            }, 850)
          })
          .catch((err) => {
            const response = err.response.data
            setErrorResponse(response.message, response.errors)
            isLoading.value = false
          })
      }, 100)
    }

    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const setSuccessResponse = (message) => {
      state.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Something went wrong!",
        errors: errors ? errors : {}
      }
    }

    const toggleStatus = (status) => {
      store
        .dispatch("modules/toggleModule", {
          id: 1,
          moduleStatus: { status: status ? 0 : 1 }
        })
        .then(() => {
          state.isAppModuleActive = !state.isAppModuleActive
          store.dispatch("schools/getActiveModules", {
            clearCacheEntry: true
          })
        })
    }

    watch(
      adultsAndStudentsSelected,
      (areAdultsAndStudentsSelected) => {
        state.aptAgendaEmailsAllCheckbox =
        areAdultsAndStudentsSelected ? true : false
      }
    )

    watch(
      () => state.aptAgendaEmailsAllCheckbox,
      (allCheckboxIsSelected) => {
        state.form.apt_agenda_emails.adults = allCheckboxIsSelected ? true : false
        state.form.apt_agenda_emails.students = allCheckboxIsSelected ? true : false
      }
    )

    return {
      state,
      submit,
      toggleStatus
    }
  }
}
</script>
