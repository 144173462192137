<template>
  <div
    class="v3-input-field"
    :class="{
      'is-invalid': isInvalid || invalidFeedback,
      'with-prepend': prependIcon,
      'with-append': appendIcon || appendText
    }"
  >
    <div class="v3-input-wrap">
      <div v-if="prependIcon" class="prepended-icon">
        <i :class="prependIcon"></i>
      </div>
      <textarea
        :rows="rows"
        v-if="type == 'textarea'"
        :maxlength="maxChars"
        :disabled="disabled"
        :readonly="readonly"
        @blur="onBlur"
        :value="inputValue"
        @input="onModelUpdate"
        :placeholder="placeholder"
        type="text"
      ></textarea>
      <input
        v-else
        ref="customInput"
        :autocomplete="autocomplete"
        :v-noautofillinput="vNoAutoFillInput"
        :disabled="disabled"
        :readonly="readonly"
        @blur="onBlur"
        :value="inputValue"
        @input="onModelUpdate"
        :placeholder="placeholder"
        :type="inputType"
        :minlength="minLength"
        :maxlength="maxLength"
        :min="minNumber"
        :max="maxNumber"
        :data-test-id="testId"
      />
      <div v-if="maxChars" class="text-end mt-1">
        {{ maxChars }} characters max
      </div>
      <div
        v-if="appendIcon || appendText"
        @click="onClickAppendIcon()"
        class="prepended-icon append"
        style="-webkit-text-security: none"
      >
        <i :class="appendIcon"></i>
        <span class="appended-text" v-if="appendText">{{ appendText }}</span>
      </div>
      <div
        v-if="appendButton"
        @click="onClickAppendButton()"
        class="appended-button"
        style="-webkit-text-security: none"
      >
        <i :class="appendButton.icon" class="appended-button-icon"></i>
        <span class="appended-button-text ms-1">{{ appendButton.text }}</span>
      </div>
    </div>
    <div v-if="invalidFeedbackMessage" class="invalid-message text-start">
      {{ invalidFeedbackMessage }}
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref } from "vue"
export default {
  props: {
    placeholder: String,
    modelValue: [String, Array, Number, Object],
    isInvalid: Boolean,
    invalidFeedback: [String, Array],
    prependIcon: String,
    appendIcon: String,
    innerLabel: String,
    disabled: Boolean,
    vNoAutoFillInput: Boolean,
    autocomplete: Boolean,
    type: {
      type: String,
      default: "input"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rows: {
      type: [String, Number],
      default: 1
    },
    appendText: {
      type: String,
      default: ""
    },
    appendButton: {
      type: Object,
      default: null
    },
    maxChars: {
      type: String,
      default: null
    },
    inputType: {
      type: String,
      default: "text"
    },
    minLength: {
      type: String,
      default: null
    },
    maxLength: {
      type: String,
      default: null
    },
    minNumber: {
      type: String,
      default: null
    },
    maxNumber: {
      type: String,
      default: null
    },
    isFocused: {
      type: Boolean,
      default: false
    },
    testId: {
      type: String,
      default: ""
    }
  },
  emits: ["update:modelValue", "blur", "clickAppendIcon", "clickAppendButton"],
  setup(props, { emit }) {
    const onModelUpdate = (value) => {
      emit("update:modelValue", value.target.value)
    }
    const onBlur = (event) => {
      emit("blur", event)
    }
    const invalidFeedbackMessage = computed(() => {
      return props.invalidFeedback && Array.isArray(props.invalidFeedback)
        ? props.invalidFeedback[0]
        : props.invalidFeedback
    })
    const inputValue = computed(() => {
      return props.innerLabel
        ? props.innerLabel + " " + props.modelValue
        : props.modelValue
    })

    const onClickAppendIcon = () => {
      emit("clickAppendIcon")
    }

    const onClickAppendButton = () => {
      emit("clickAppendButton")
    }

    const customInput = ref(null)

    onMounted(() => {
      if (props.isFocused && customInput.value) {
        customInput.value.focus()
      }
    })

    return {
      onModelUpdate,
      onBlur,
      invalidFeedbackMessage,
      inputValue,
      onClickAppendIcon,
      onClickAppendButton,
      customInput
    }
  }
}
</script>
