import axios from "axios"
import { api, apiV7nocache } from "./axiousWithCache"

const state = {
  passBlocks: null,
  editablePassBlock: null,
  activePassBlock: null
}

const getters = {
  passBlocks(state) {
    return state.passBlocks
  },
  editablePassBlock(state) {
    return state.editablePassBlock
  },
  activePassBlock(state) {
    return state.activePassBlock
  }
}

const mutations = {
  SET_PASS_BLOCKS(state, passBlocksCollection) {
    state.passBlocks = passBlocksCollection
  },
  PUSH_PASS_BLOCKS(state, passBlocksCollection) {
    if (state.passBlocks && state.passBlocks.length) {
      state.passBlocks = state.passBlocks.concat(passBlocksCollection)
    } else {
      state.passBlocks = passBlocksCollection
    }
  },
  DELETE_PASS_BLOCK(state, id) {
    if (state.passBlocks && state.passBlocks.length > 0) {
      state.passBlocks = state.passBlocks.filter((el) => el.id != id)
    }
    if (state.activePassBlock && state.activePassBlock.id == id) {
      state.activePassBlock = null
    }
  },
  UPDATE_PASS_BLOCK(state, passBlock) {
    const newCollection = state.passBlocks.map((el) => {
      if (el.id === passBlock.id) {
        return passBlock
      }
      return el
    })
    state.passBlocks = newCollection
  },
  SET_EDITABLE_PASS_BLOCK(state, passBlock) {
    state.editablePassBlock = passBlock
  },
  PUSH_PASS_BLOCK(state, passBlock) {
    if (state.passBlocks != null && state.passBlocks.length) {
      const isTheSame = state.passBlocks.filter((el) => el.id == passBlock.id)
      if (!isTheSame.length) {
        state.passBlocks.push(passBlock)
      }
    } else {
      state.passBlocks = [passBlock]
    }
  },
  SET_ACTIVE_PASS_BLOCK(state, activePassBlock) {
    state.activePassBlock = activePassBlock
  },
  DELETE_ACTIVE_PASS_BLOCK(state, activePassBlock) {
    state.activePassBlock = activePassBlock
  }
}

const actions = {
  createPassBlock(context, passBlock) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .post(`/admin/pass-blocks`, passBlock)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deletePassBlock(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admin/pass-blocks/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updatePassBlock(context, data) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .put(`/admin/pass-blocks/${data.id}`, data.data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getPassBlocks(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/pass-blocks`, {
          params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCSVpassBlocksExport(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/pass-blocks/export/csv`, { params: params })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getGradeYears() {
    return new Promise((resolve, reject) => {
      api
        .get(`/static-content/grade-years`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
