import axios from "axios"
import { api } from "./axiousWithCache"
const state = {
  polarities: null,
  polarityAdvancedReports: null,
  polarityMessage: null
}

const getters = {
  polarities(state) {
    return state.polarities
  },
  polarityAdvancedReports(state) {
    return state.polarityAdvancedReports
  },
  polarityMessage(state) {
    return state.polarityMessage
  }
}

const mutations = {
  SET_POLARITIES(state, polaritiesCollection) {
    state.polarities = polaritiesCollection
  },
  PUSH_POLARITIES(state, polaritiesCollection) {
    if (state.polarities && state.polarities.length) {
      state.polarities = state.polarities.concat(polaritiesCollection)
    } else {
      state.polarities = polaritiesCollection
    }
  },
  SET_POLARITY_ADVANCED_REPORTS(state, reportsCollection) {
    state.polarityAdvancedReports = reportsCollection
  },
  PUSH_POLARITY_ADVANCED_REPORTS(state, reportsCollection) {
    if (state.polarityAdvancedReports && state.polarityAdvancedReports.length) {
      state.polarityAdvancedReports =
        state.polarityAdvancedReports.concat(reportsCollection)
    } else {
      state.polarityAdvancedReports = reportsCollection
    }
  },
  DELETE_POLARITY(state, id) {
    state.polarities = state.polarities.filter((el) => el.id != id)
  },
  UPDATE_POLARITY_STATUS(state, polarity) {
    const newCollection = state.polarities.map((el) => {
      if (el.id === polarity.id) {
        el.status = polarity.status
        return el
      }
      return el
    })
    state.polarities = newCollection
  },
  SET_POLARITY_MESSAGE(state, message) {
    state.polarityMessage = message
  }
}

const actions = {
  createPolarity(_, polarity) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/polarities`, polarity)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createPolarityMessage(_, message) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/polarities/message`, message)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getPolarityMessage() {
    return new Promise((resolve, reject) => {
      api
        .get(`/admin/polarities/message`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deletePolarity(_, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admin/polarities/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updatePolarityStatus(_, id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/polarities/${id}/status`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getPolarities(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/polarities`, {
          params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCSVpolaritiesExport(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/polarities/export/csv`, { params })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getPolarityAdvancedReports(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/polarities/reports`, {
          params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
