<template>
  <div>
    <CContainer>
      <CRow class="justify-content-center mt-4">
        <CCol md="12 text-center">
          <TardyConsequencesLadderTable></TardyConsequencesLadderTable>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import TardyConsequencesLadderTable from "@/v3components/Datatables/TardyConsequencesLadderTable.vue"
export default {
  name: "TardyConsequencesLadder",
  components: { TardyConsequencesLadderTable }
}
</script>
