<template>
  <div class="select-list-wrapper">
    <div class="row">
      <div v-if="selectLabel" class="col-md-12 mb-2">
        <label class="select-label">{{ selectLabel }}</label>
      </div>
      <div class="col-md-10">
        <LazyLoadSelect
          data-test-id="add-favorites-select-list"
          :selected="state.selectedData"
          :placeholder="selectPlaceholder"
          :type="selectType"
          :exclude-options="state.data"
          :close-after-select="closeAfterSelect"
          @update:model-value="
            (value) => {
              pushData(value)
            }
          "
          :invalid-feedback="requiredFeedback"
        ></LazyLoadSelect>
      </div>
      <div class="selected-counter col-md-2">
        <span>{{ state.data.length }}</span>
        <span v-if="counterMax" class="selected-counter-max"
          ><span>/</span>{{ counterMax }}</span
        >
        <span :class="counterIconClass ? counterIconClass : 'circle'">
          <i :class="counterIcon"></i>
        </span>
      </div>
    </div>
    <div v-if="infoText" class="info-box mt-4">
      <InfoBox>{{ infoText }}</InfoBox>
    </div>
    <div
      v-if="activeColumnNames"
      class="column-name d-flex justify-content-between mt-3"
    >
      <div v-if="firstColumnName && state.data.length">
        {{ firstColumnName }}
      </div>
      <div v-if="lastColumnName && state.data.length" class="column-name-last">
        {{ lastColumnName }}
      </div>
    </div>
    <div v-if="state.data.length" class="list">
      <draggable
        :disabled="!draggable"
        v-model="state.data"
        item-key="id"
        @end="dragging = dragData()"
      >
        <template #item="{ element }">
          <div
            :key="element + 'tg-2'"
            class="data-row"
            :style="draggable ? 'cursor:pointer;' : ''"
            :id="'row-' + element.value.id"
          >
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center">
                <div v-if="draggable" class="icon-draggable">
                  <i class="ri-drag-move-fill"></i>
                </div>
                <div v-if="favoriteMode" class="icon-image">
                  <div v-if="element.value.type == 'App\\Models\\Room'">
                    <i v-if="element.icon" :class="element.icon"></i>
                    <i v-else class="flaticon-star"></i>
                  </div>
                  <div v-else>
                    <img v-if="element.avatar" :src="element.avatar" />
                    <img v-else src="/img/avatars/user-avatar.jpeg" />
                  </div>
                </div>
              </div>
              <span>{{ element.label }}</span>
            </div>
            <div class="d-flex">
              <div v-if="activeFavoriteRoomPin" class="d-flex">
                <InputField
                  placeholder="PIN"
                  max-length="6"
                  v-model="element.pin"
                  :disabled="!element.isDisabled"
                  class="mx-2"
                ></InputField>
                <IconButton
                  :icon="'ri-edit-2-line'"
                  @click="editPin(element)"
                  class="action-edit-pin"
                />
              </div>
              <div>
                <IconButton
                  data-test-id="remove-favorites-select-list"
                  :icon="'ri-delete-bin-line'"
                  @click="removeData(element.value.id)"
                  class="action-delete"
                />
              </div>
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <div v-if="Object.keys(invalidFeedback).length !== 0" class="error-list">
      <div
        v-for="(errors, errorIndex) in invalidFeedback"
        :key="errorIndex"
        class="invalid-message text-start"
      >
        <span v-for="errorMessages in errors" :key="errorMessages.id">
          <span
            v-if="errorMessages.includes('room_ids_and_pins.0.pin')"
            class="mt-1"
            >The pin may not be greater than 6 characters.</span
          >
        </span>
        <p v-for="(label, index) in state.data" :key="index">
          <span
            v-if="
              'user_ids.' + index == errorIndex ||
              'room_ids.' + index == errorIndex ||
              'autopass_rooms.' + index == errorIndex
            "
            >{{ label.label }}
            <span
              v-for="errorMessages in errors"
              :key="errorMessages.id"
              class="errorMessageCase"
            >
              {{ errorMessages }}
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed, watch, onMounted } from "vue"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect.vue"
import IconButton from "../Buttons/IconButton.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"
import draggable from "vuedraggable"

export default {
  name: "SelectList",
  components: {
    LazyLoadSelect,
    IconButton,
    InfoBox,
    InputField,
    draggable
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    favoriteMode: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    },
    selectLabel: {
      type: String,
      default: ""
    },
    selectPlaceholder: {
      type: String,
      default: ""
    },
    selectType: {
      type: String,
      default: ""
    },
    invalidFeedback: {
      type: Object,
      default: () => ({})
    },
    requiredFeedback: {
      type: String,
      default: ""
    },
    reduce: {
      type: Function,
      default: (item) => item
    },
    counterMax: {
      type: Number,
      default: null
    },
    counterIcon: {
      type: String,
      default: ""
    },
    counterIconClass: {
      type: String,
      default: "circle"
    },
    infoText: {
      type: String,
      default: ""
    },
    firstColumnName: {
      type: String,
      default: ""
    },
    lastColumnName: {
      type: String,
      default: ""
    },
    activeFavoriteRoomPin: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Function
    },
    closeAfterSelect: {
      type: Boolean,
      default: true
    }
  },
  emits: ["updateListData", "selectedElement"],
  setup(props, { emit }) {
    const state = reactive({
      data: [],
      selectedData: [],
      form: {
        pin: null
      },
      disabledPinField: true
    })

    onMounted(() => {
      if (props.data.length) {
        state.data = props.data
      }
    })

    watch(
      () => props.data,
      (value) => {
        if (value) {
          state.data = value
        }
      }
    )

    const activeColumnNames = computed(() => {
      return props.firstColumnName || props.lastColumnName
    })

    const pushData = (value) => {
      if (props.counterMax) {
        if (state.data.length < props.counterMax) {
          state.data.push(value)
          state.selectedData = value
          emit("selectedElement", value.value.id)
          sendData()
        }
      } else {
        state.data.push(value)
        state.selectedData = value
        emit("selectedElement", value.value.id)
        sendData()
      }
      setTimeout(() => {
        state.selectedData = []
      }, 500)
    }

    const dragData = () => {
      sendData()
    }
    const editPin = (element) => {
      element.isDisabled = !element.isDisabled
    }

    const removeData = (dataId) => {
      state.data = state.data.filter((e) => e.value.id !== dataId)
      sendData()
    }

    const sendData = () => {
      emit("updateListData", state.data.map(props.reduce))
    }

    return {
      state,
      props,
      activeColumnNames,
      pushData,
      dragData,
      editPin,
      removeData,
      sendData
    }
  }
}
</script>
