<template>
  <div
    class="d-flex flex-column gap-2 mt-2 flex-wrap"
    :class="{
      'visitor-loading': state.isLoading || isImportStatusLoading
    }"
  >
    <!-- SERVER ERROR -->
    <VisitorErrorHandler
      v-if="serverError != null"
      :error="serverError"
      @done="serverError = null"
    />
    <!-- SERVER ERROR -->

    <!-- HEADER AND UPLOAD CSV ACTION -->
    <div class="d-flex justify-content-between align-content-center flex-wrap">
      <!-- LEFT SECTION -->
      <div class="d-flex flex-column gap-2">
        <!-- HEADER -->
        <div class="d-flex justify-content-start align-content-center gap-3">
          <h4>Guardian/student matrix (Parent/student matrix)</h4>
          <HelpCenterButton
            content-key="visitor_guardian_student_matrix_best_practices"
          />
        </div>
        <!-- HEADER -->

        <!-- DESCRIPTION -->
        <div>Upload a CSV of Guardian/student data</div>
        <!-- DESCRIPTION -->
      </div>
      <!-- LEFT SECTION -->

      <!-- RIGHT SECTION -->
      <div>
        <!-- BUTTONS -->
        <div class="d-flex justify-content-end align-content-center gap-2">
          <!-- RELOAD BUTTON -->
          <BaseButton
            v-if="isImportPending"
            rounded
            :disabled="state.isLoading || isImportStatusLoading"
            @click="onFetchImportStatus"
          >
            Reload
          </BaseButton>
          <!-- RELOAD BUTTON -->

          <!-- UPLOAD CSV ACTION -->
          <BaseButton rounded solid @click="onUploadCSV">Upload CSV</BaseButton>
          <!-- UPLOAD CSV ACTION -->
        </div>
        <!-- BUTTONS -->
      </div>
      <!-- RIGHT SECTION -->
    </div>
    <!-- HEADER AND UPLOAD CSV ACTION -->

    <template
      v-if="hasImportStatus && !isImportStatusLoading && !state.isLoading"
    >
      <!-- PICK UP LIST ERROR DESCRIPTION -->
      <div v-if="hasErrors" class="d-flex flex-column gap-2">
        <span class="visitor-import-error-log" @click="onDownloadErrorLog">
          There are errors in the uploaded file. Please download the error log.
          {{ errors?.length || 0 }} errors found.
        </span>
      </div>
      <!-- PICK UP LIST ERROR DESCRIPTION -->

      <!-- ENTRY AND UPLOAD DATE -->
      <div
        v-if="
          importInfoText.length > 0 ||
          (hasValidLastImportAtDate && lastImportDate.length > 0)
        "
        class="d-flex justify-content-between align-content-center gap-4 flex-wrap"
      >
        <div v-if="importInfoText.length > 0">
          {{ importInfoText }}
        </div>

        <div v-if="hasValidLastImportAtDate && lastImportDate.length > 0">
          {{ lastImportDate }}
        </div>
      </div>
      <!-- ENTRY AND UPLOAD DATE -->
    </template>
  </div>
</template>
<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import HelpCenterButton from "@/v3components/HelpCenterButton.vue"
import passHelpers from "@/helpers/index"
import downloadCSVHelpers from "@/helpers/downloadCSV"
import {
  computed,
  inject,
  defineAsyncComponent,
  reactive,
  onMounted,
  ref
} from "vue"
import moment from "moment-timezone"
import { useStore } from "vuex"
import visitorConfigs from "@/configs/visitorConfigs"

const VisitorMatrixCSVUploadPopUp = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/GuardianStudentMatrix/VisitorMatrixCSVUploadPopUp.vue"
    )
)

export default {
  name: "VisitorMatrixSetting",
  components: {
    BaseButton,
    VisitorErrorHandler,
    HelpCenterButton
  },
  setup() {
    const modal = inject("modal")
    const store = useStore()
    const state = reactive({
      isLoading: false
    })
    const serverError = ref(null)

    const isImportStatusLoading = computed(() => {
      return store?.state?.visitorSettings?.importStatus?.isLoading || false
    })

    const importStatus = computed(() => {
      return store?.state?.visitorSettings?.importStatus?.data || null
    })

    const hasImportStatus = computed(() => {
      return importStatus?.value != null
    })

    const isImportPending = computed(() => {
      return ["queue", "inprogress"].includes(
        importStatus?.value?.lastImportLog?.status
          ?.toString()
          ?.trim()
          ?.toLowerCase()
      )
    })

    const importInfoText = computed(() => {
      return `${importStatus?.value?.uniqueGuardians} unique guardians from ${importStatus?.value?.totalMappings} total entries found and uploaded`
    })

    const hasValidLastImportAtDate = computed(() => {
      return moment(importStatus?.value?.lastImportAt).isValid()
    })

    const lastImportDate = computed(() => {
      if (!hasValidLastImportAtDate?.value) {
        return ""
      }
      return `Uploaded on: ${formatDateTime(importStatus?.value?.lastImportAt)}`
    })

    const hasErrors = computed(() => {
      return importStatus?.value?.lastImportLog?.errors?.length > 0
    })

    const errors = computed(() => {
      return importStatus?.value?.lastImportLog?.errors || []
    })

    const formatDateTime = (date) => {
      const datePart = date?.split(" ")?.[0] || ""
      const timePart = date?.split(" ")?.[1] || ""
      return visitorConfigs?.useCustomVisitorTimeFunction
        ? passHelpers.convertToCurrentTimezone(date)
        : passHelpers.currTzDateTime(datePart, timePart, "MM-DD-YYYY h:mm A")
    }

    const onUploadCSV = () => {
      modal.open(VisitorMatrixCSVUploadPopUp, {
        hideCloseButton: true,
        size: "sm",
        title: "Upload a CSV of Guardian/student data",
        props: {}
      })
    }

    const onFetchImportStatus = async () => {
      try {
        state.isLoading = true
        await store.dispatch("visitorSettings/getGuardianStudentImportStatus")
      } catch (error) {
        serverError.value = error
      } finally {
        state.isLoading = false
      }
    }

    const onDownloadErrorLog = async () => {
      downloadCSVHelpers.CSVExport(
        errors?.value?.join("\n"),
        "Guardian_Student_Matrix_Error_Log_" + new Date().getTime()
      )
    }

    onMounted(async () => {
      await onFetchImportStatus()
    })

    return {
      state,
      serverError,
      isImportStatusLoading,
      isImportPending,
      hasImportStatus,
      hasErrors,
      errors,
      importInfoText,
      hasValidLastImportAtDate,
      lastImportDate,
      onDownloadErrorLog,
      onUploadCSV,
      onFetchImportStatus
    }
  }
}
</script>

<style scoped>
.pt-2\.5 {
  padding-top: 0.72rem;
}
</style>
