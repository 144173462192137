<template>
  <div
    data-test-id="go-to-digital-id"
    @click="goToDigitalID"
    class="digital-id"
  >
    <div class="fi flaticon-profile-line content-center"></div>
  </div>
</template>

<script>
export default {
  name: "TheHeaderDigitalID",
  data() {
    return {}
  },
  computed: {},
  methods: {
    goToDigitalID() {
      this.$router.push("/id-card")
    }
  }
}
</script>

<style lang="scss" scoped>
.digital-id {
  scale: 1.75;
  color: grey;
}
</style>
