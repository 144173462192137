<template>
  <div v-if="isActiveKioskModule">
    <div class="dashboard-counters" v-if="!basic">
      <div class="d-flex justify-content-center">
        <div class="dashboard-counters-main-box">
          <div class="label">My active kiosks</div>
          <div class="dashboard-counters-main-box-passes">
            <span>{{ stats.myOpenKiosksCount }}</span>
          </div>
        </div>
        <div class="dashboard-counters-secondary-box">
          <i class="ri-device-line"></i>
        </div>
      </div>
    </div>
    <div class="dashboard-basic-counters" v-else>
      <div class="dashboard-basic-counters-box">
        <div class="dashboard-basic-counters-box-passes">
          <span class="total-passes">{{ stats.myOpenKiosksCount }}</span>
        </div>
        <div class="gray-label ms-1">My active kiosks</div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"

export default {
  name: "MyActiveKiosks",
  props: {
    basic: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore()

    const stats = computed(() => store.getters["dashboardTable/stats"])

    const isActiveKioskModule = computed(
      () => store.getters["schools/isActiveKioskModule"]
    )

    return { stats, isActiveKioskModule }
  }
}
</script>

<style></style>
