import detentions from "./detentions"
import detentionAdults from "./detentionAdults"
import consequencesLadder from "./consequencesLadder"
import attendance from "./attendance"
import detentionsAppointments from "./detentionsAppointments"
import assignAdults from "./assignAdults"
import reports from "./reports"
import exceededIncidents from "./exceededIncidents"

const tardyModules = {
  detentions,
  detentionAdults,
  attendance,
  detentionsAppointments,
  consequencesLadder,
  assignAdults,
  reports,
  exceededIncidents
}
export default tardyModules
