<template>
  <div
    v-if="state.actions && userData.role.name != state.actions[0].exludeForRole"
    class="v3-header-action-button"
  >
    <BaseButton
      data-test-id="header-action-button"
      v-for="(action, i) in state.actions"
      v-show="isActionVisible(action.actionVisibleDependency)"
      :key="i"
      :class="action.class"
      rounded
      class="me-2"
      :prepend-icon="action.icon ? action.icon : 'ri-add-line'"
      @click="onActionClick(action)"
      >{{ action.title }}</BaseButton
    >
  </div>
</template>

<script>
import BaseButton from "../shared/Buttons/BaseButton.vue"
import { onBeforeRouteUpdate, useRoute } from "vue-router"
import { onMounted, computed, reactive, inject } from "vue"
import { useStore } from "vuex"

export default {
  components: { BaseButton },
  setup() {
    const store = useStore()
    const state = reactive({
      actions: null,
      activeAction: null
    })
    const route = useRoute()

    const modal = inject("modal")

    const userData = computed(() => store.getters["authentication/user"])

    onMounted(() => {
      state.actions = route.meta.routeActions ? route.meta.routeActions : null
    })

    onBeforeRouteUpdate((to) => {
      state.actions = to.meta.routeActions ? to.meta.routeActions : null
    })

    const isActionVisible = (getter) => {
      return getter ? store.getters[getter] : true
    }

    const onActionClick = (action) => {
      if (action.component) {
        modal.open(action.component, {
          visible: true,
          size: action.modalSize || "sm",
          title: action.modalTitle || action.title,
          help: action.helpMark,
          props: action.props || {}
        })
        state.activeAction = action
      } else if (action.eventName) {
        //TODO: Create communication between the actions here and the components where they must happen. There are 2 approches;
        // 1. emit event or
        // 2. use the store
        // 1.1 Need to be implemented something like custom event bus(use "mitt" or "tiny-emitter" plugins) for communication between the components
        // 1.2 It must be created an Event Listener into the component where the action need to happen and emit the event from here.
        // 2.1 Create state somewhere in the store and use it for setting the incoming action names (event names)
        // 2.2 Watch this state in the componentas where you need to implement action after the header button click
        // 3 update: Also, it can be implemented with vue inject
      }
    }

    return {
      state,
      userData,
      isActionVisible,
      onActionClick
    }
  }
}
</script>
