<template>
  <div class="container">
    <div class="row help-page justify-content-center text-center mt-4">
      <h1 class="mb-4">New Releases</h1>
      <iframe
        v-if="updatesDoc && updatesDoc.release_doc_url"
        :src="updatesDoc.release_doc_url"
        class="updates-iframe"
      ></iframe>
      <div v-if="!updates">No releases</div>
      <div v-else>
        <div
          v-for="(release, index) in updates"
          :key="index"
          class="mb-5 modal-height"
        >
          <h3 class="text-info">
            Pass Updates {{ release.version }}
            {{ helpers.transformDate(release.created_at, "MMMM,DD") }}
          </h3>
          <div v-html="release.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, computed } from "vue"
import { useStore } from "vuex"
import helpers from "@/helpers/index.js"

export default {
  name: "Updates",
  setup() {
    const store = useStore()

    const updates = computed(() => store.getters["updates/releases"])
    const updatesDoc = computed(() => store.getters["updates/releasesDoc"])

    onMounted(() => {
      store.dispatch("updates/getAllReleases")
      store.dispatch("updates/getNewReleases")
    })

    return {
      updates,
      updatesDoc,
      helpers
    }
  }
}
</script>
