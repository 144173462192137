<template>
  <div v-if="!isAppoinmentMode" class="d-flex flex-wrap">
    <div class="ms-1 mt-1">Filters:</div>
    <CustomCheck
      :disabled="activePassState === 'ended'"
      label="Waiting Approval"
      :model-value="filterData.waiting_approval"
      name="waiting_approval"
      class="edited ms-2"
      @update:model-value="
        updateFilters({ name: 'waiting_approval', checked: $event })
      "
    />
    <CustomCheck
      v-if="passesTimeSettings && passesTimeSettings.min_time"
      :label="
        $helpers.formatDate(passesTimeSettings.min_time, 'hh:mm:ss', 'mm') +
        '+Min'
      "
      :model-value="filterData.yellow_time"
      name="yellow_time"
      class="min-10 ms-2"
      @update:model-value="
        updateFilters({ name: 'yellow_time', checked: $event })
      "
    />
    <CustomCheck
      :disabled="activePassState === 'active'"
      label="System Ended"
      :model-value="filterData.system_ended"
      name="system_ended"
      class="system-ended ms-2"
      @update:model-value="
        updateFilters({ name: 'system_ended', checked: $event })
      "
    />
    <CustomCheck
      :disabled="activePassState === 'active'"
      label="Extended Passes"
      :model-value="filterData.extended_time"
      name="extended_time"
      class="extended-passes ms-2"
      @update:model-value="
        updateFilters({ name: 'extended_time', checked: $event })
      "
    />
    <CustomCheck
      :disabled="activePassState === 'active'"
      label="Edited"
      :model-value="filterData.edited"
      name="edited"
      class="gray ms-2"
      @update:model-value="updateFilters({ name: 'edited', checked: $event })"
    />
  </div>
</template>

<script>
import { useStore } from "vuex"
import { computed } from "vue"
import CustomCheck from "@/v3components/shared/Form/CustomCheck.vue"

export default {
  name: "DashboardTimeFilters",
  components: {
    CustomCheck
  },
  setup() {
    const store = useStore()

    const isActiveAppoinmentModule = computed(
      () => store.getters["schools/isActiveAppoinmentModule"]
    )
    const passesTimeSettings = computed(
      () => store.getters["passes/passesTimeSettings"]
    )
    const activeSchoolHasAppoitmentPasses = computed(
      () => store.getters["schools/activeSchoolHasAppoitmentPasses"]
    )
    const filterData = computed(
      () => store.getters["dashboardTable/filterData"]
    )
    const activePassState = computed(
      () => store.getters["dashboardTable/getStatus"]
    )
    const activePassType = computed(
      () => store.getters["dashboardTable/getType"]
    )

    const isAppoinmentMode = computed(() =>
      activePassType.value.includes("appointment")
    )

    const updateFilters = (event) => {
      store.commit("dashboardTable/UPDATE_FILTERS_DATA", {
        filter: event.name,
        value: event.checked
      })
    }

    return {
      isActiveAppoinmentModule,
      passesTimeSettings,
      activeSchoolHasAppoitmentPasses,
      filterData,
      activePassState,
      activePassType,
      isAppoinmentMode,
      updateFilters
    }
  }
}
</script>
