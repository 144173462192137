<template>
  <div class="v3-custom-time-picker custom-vue-time-picker ps__child--consume">
    <VueDatePicker
      class="v3-input-field"
      :class="[classes, { 'is-invalid': invalidFeedback }]"
      ref="datepicker"
      id="cs-picker"
      :model-type="format ? format : 'hh:mm'"
      autocomplete="off"
      auto-apply
      :format="pickerTimeFormat ? pickerTimeFormat : 'hh:mm aa'"
      :is-24="!!showSeconds"
      text-input
      time-picker
      :clearable="false"
      :hide-input-icon="hideIcon"
      input-read-only
      @update:model-value="onValueChange"
      :model-value="modelValue"
      :placeholder="placeholder"
      :enable-seconds="!!showSeconds"
      :disabled="readonly"
      @open="onFocus"
      @blur="onBlur"
      :on-click-outside="onClickOutside"
    >
      <template #input-icon>
        <i class="ri-time-line"></i>
      </template>
      <template #arrow-up>
        <i class="fi flaticon-up-arrow"></i>
      </template>
      <template #arrow-down>
        <i class="fi flaticon-down-arrow"></i>
      </template>
      <template #clock-icon> </template>
    </VueDatePicker>
    <div v-if="invalidFeedbackMessage" class="invalid-message text-start">
      {{ invalidFeedbackMessage }}
    </div>
    <slot name="footer"> </slot>
  </div>
</template>
<script>
import { ref, watch, computed } from "vue"
import moment from "moment-timezone"
import "@vuepic/vue-datepicker/dist/main.css"

export default {
  name: "CustomTimePicker",
  props: [
    "value",
    "placeholder",
    "classes",
    "readonly",
    "format",
    "hideIcon",
    "innerLabel",
    "disableSetFocus",
    "modelValue",
    "show",
    "showSeconds",
    "pickerTimeFormat",
    "invalidFeedback"
  ],
  emits: ["update:modelValue", "input", "change", "focus", "blur"],
  setup(props, { emit }) {
    //refs
    const datepicker = ref()

    //watcher
    watch(
      () => props.show,
      (value) => {
        setTimeout(() => {
          if (value) {
            datepicker.value.openMenu()
          }
        }, 0)
      }
    )

    //methods
    const onValueChange = (value) => {
      emit("update:modelValue", value)
      emit("change", value)
    }
    const onBlur = () => {
      emit("blur")
    }
    const onFocus = () => {
      if (!props.modelValue) {
        emit(
          "update:modelValue",
          moment().format(props.format ? props.format : "h:mm A")
        )
        emit("change", moment().format(props.format ? props.format : "h:mm A"))
      }
    }

    const onClickOutside = () => {
      datepicker.value.closeMenu()
      emit("blur")
    }

    const invalidFeedbackMessage = computed(() => {
      return props.invalidFeedback && Array.isArray(props.invalidFeedback)
        ? props.invalidFeedback[0]
        : props.invalidFeedback
    })

    return {
      onValueChange,
      onFocus,
      onBlur,
      datepicker,
      onClickOutside,
      invalidFeedbackMessage
    }
  }
}
</script>
<style lang="scss">
@import "src/assets/scss/_variables";
.custom-vue-time-picker {
  &.is-invalid {
  }
  .dp__instance_calendar {
    max-height: 200px;
  }
  .dp__time_display {
    font-size: 20px !important;
    padding: 20px 10px !important;
  }
  .dp__pm_am_button {
    background-color: $accent-blue-dark !important;
    padding: 8px 12px !important;
    border-radius: 15px !important;
  }
  .dp__inc_dec_button {
    font-size: 20px;
    padding: 22px;
    border-radius: 0px;
    &:hover {
      color: $accent-blue-dark;
    }
  }
  .dp__overlay_container {
    height: 100% !important;
  }
  .dp__button.dp__overlay_action {
    display: none;
  }
  .form-control {
    border-color: $accent-blue-dark !important;
    padding: $input-padding-y $input-padding-x !important;
    border-radius: $input-border-radius;
    border-width: $input-border-width;
    font-size: $formfieldsFontSize;
    font-weight: normal;
    line-height: 1.5;
    font-family: "Metropolis-Regular";
    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }
  .dp__input_icon {
    color: $accent-blue-dark !important;
    left: auto;
    right: 15px;
    display: flex;
    align-items: center;
  }

  &.is-invalid {
    .form-control {
      border-color: #e55353 !important;
    }

    .invalid-feedback {
      display: block;
      z-index: 1;
    }
  }

  .timepicker {
    z-index: 999;

    .btn {
      box-shadow: none !important;

      &.btn-primary {
        padding: 12px;
      }
    }
  }

  .bootstrap-datetimepicker-widget a {
    padding: 0 !important;
  }
}
</style>
