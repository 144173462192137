<template>
  <div>
    <router-view />
    <!-- Appoitment pass ready  notf -->
    <CModal
      :visible="appointmentPassRun.show"
      backdrop="static"
      add-content-classes="bg-white"
    >
      <template #header-wrapper>
        <div class="d-flex" />
      </template>
      <CRow class="justify-content-center">
        <CCol v-if="appointmentPassReady" md="9" class="bg-white p-4">
          <div class="d-flex align-items-center">
            <span class="font-size70 flaticon-checked_optimized mr-2"
              ><span class="path1" /><span class="path2" /><span
                class="path3" /><span class="path4"
            /></span>
            <div class="text-black-50 ml-2 font-weight-bolder font-xl">
              Your Appointment Pass is now ready
              <span class="d-block text-value-sm font-weight-bold">
                {{
                  $helpers.transformDate(
                    appointmentPassReady.for_date,
                    "MMM D, Y"
                  )
                }}
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-between mt-2 font-sm">
            <div class="text-black-50 font-weight-bold text-value-sm font-sm">
              Time
            </div>
            <div class="text-black-50 font-weight-bold text-value-sm font-sm">
              From
              {{
                appointmentPassReady.from_type === "App\\Models\\User"
                  ? "Teacher"
                  : "Location"
              }}
            </div>
            <div class="text-black-50 font-weight-bold text-value-sm font-sm">
              To
              {{
                appointmentPassReady.from_type === "App\\Models\\User"
                  ? "Teacher"
                  : "Location"
              }}
            </div>
          </div>
          <hr class="font-size2 bg-gradient-blue" />
          <div class="d-flex justify-content-between">
            <div class="text-black-50 font-weight-bold text-value-sm font-sm">
              {{
                $helpers.transformDate(appointmentPassReady.for_date, "h:mm A")
              }}
            </div>
            <div
              v-if="appointmentPassReady.from"
              class="text-black-50 font-weight-bold text-value-sm font-sm"
            >
              {{
                appointmentPassReady.from_type === "App\\Models\\User"
                  ? appointmentPassReady.from.first_name +
                    " " +
                    appointmentPassReady.from.last_name
                  : appointmentPassReady.from.name
              }}
            </div>
            <div v-else class="text-black-50">System</div>
            <div
              v-if="appointmentPassReady.to"
              class="text-black-50 font-weight-bold text-value-sm font-sm"
            >
              {{
                appointmentPassReady.to_type === "App\\Models\\User"
                  ? appointmentPassReady.to.first_name +
                    " " +
                    appointmentPassReady.to.last_name
                  : appointmentPassReady.to.name
              }}
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <CButton
              class="user-table-custom-btn d-flex align-items-center px-4 py-2"
              @click="goToPass()"
            >
              <span class="font-size30 flaticon-checked_optimized"
                ><span class="path1" /><span class="path2" /><span
                  class="path3" /><span class="path4"
              /></span>
              <span
                class="dashboard-custom-green text-uppercase font-weight-bolder mx-3"
                >Go To Pass</span
              >
            </CButton>
          </div>
        </CCol>
      </CRow>
      <template #footer-wrapper>
        <div class="d-flex" />
      </template>
    </CModal>
    <!-- Acknowladge pass notf -->
    <CModal
      backdrop="static"
      :visible="appointmentPassRemind.show"
      add-content-classes="bg-white"
    >
      <template #header-wrapper>
        <div class="d-flex" />
      </template>
      <CRow v-if="acknowledgePassNotf" class="justify-content-center">
        <CCol md="9" class="bg-white p-4">
          <div class="d-flex align-items-center px-4">
            <div class="reminder-icon">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1024 725.08"
              >
                <path
                  d="M907.93,292l33.52-55.07-210.69-9.52L836,410.17l26.21-43.06c25.79,21.69,48.67,50.51,48.67,84.44,0,88.13-169.13,186.32-411.82,186.32S87.23,539.67,87.23,451.55c0-18.32,6.9-36.83,20.48-55a176.55,176.55,0,0,1,25.22-26.84l-37.79-81.4c-22.87,17.1-42.23,35.84-57.32,56C12.72,377.91,0,414,0,451.55c0,78.72,55.88,150.29,157.35,201.53,92,46.44,213.36,72,341.71,72s249.7-25.59,341.7-72c101.47-51.25,157.35-122.82,157.35-201.53,0-58.68-31.63-114-90.19-159.56ZM757.74,72.41C760.93,68,768.57,62,774.3,62c4.25,0,7.22,2.12,7.22,9.77v87.9h26.76V72.41C811.68,68,820,62,825.05,62c4.25,0,7,2.12,7,9.77v87.9H858.8V72.62c0-20.81-8.28-30.57-24.42-30.57-11.67,0-20.17,8.28-28.45,17.41-3.18-10.62-10.4-17.41-20.38-17.41-11.26,0-20,7.43-28.67,15.28V44.17H731V159.68h26.75V72.41ZM887.68,0h26.76v24.2H887.68V0Zm0,44.16h26.76V159.68H887.68Zm55.63,115.51h26.76V72.41C975.8,67.31,982,62,987.48,62c7.22,0,9.76,5.1,9.76,12.74v84.93H1024V71.77c0-21.44-8.49-29.72-24.84-29.72-11,0-20.18,6.79-29.94,15.29V44.17H943.31Z"
                  fill="#005DBA"
                />
                <text
                  transform="translate(937.33 390.55) scale(0.94 1)"
                  font-size="12"
                  font-family="MyriadPro-Regular, Myriad Pro"
                  style="isolation: isolate"
                >
                  005DBA
                </text>
                <text
                  v-if="getTimeLeft >= 10"
                  transform="translate(177.67 483.7) scale(0.94 1)"
                  font-size="505"
                  fill="#005DBA"
                  font-family="Avenir-Roman, Avenir"
                  letter-spacing="-0.1em"
                  style="isolation: isolate"
                >
                  {{ getTimeLeft }}
                </text>
                <text
                  v-if="getTimeLeft < 10"
                  transform="translate(320 493.7) scale(0.94 1)"
                  font-size="505"
                  fill="#005DBA"
                  font-family="Avenir-Roman, Avenir"
                  letter-spacing="-0.1em"
                  style="isolation: isolate"
                >
                  {{ getTimeLeft }}
                </text>
              </svg>
            </div>
            <div class="text-black-50 ml-4 font-weight-bolder font-xl">
              Reminder
              <span class="d-block text-value-sm font-weight-bold">
                {{
                  $helpers.transformDate(
                    acknowledgePassNotf.for_date,
                    "MMM D, Y"
                  )
                }}
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-between border-bottom mt-2">
            <div class="text-black-50 font-weight-bold text-value-sm">Time</div>
            <div class="text-black-50 font-weight-bold text-value-sm">
              From
              {{
                acknowledgePassNotf.from_type === "App\\Models\\User"
                  ? "Teacher"
                  : "Location"
              }}
            </div>
            <div class="text-black-50 font-weight-bold text-value-sm">
              To
              {{
                acknowledgePassNotf.to_type === "App\\Models\\User"
                  ? "Teacher"
                  : "Location"
              }}
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="text-black-50 font-weight-bold text-value-sm">
              {{
                $helpers.transformDate(acknowledgePassNotf.for_date, "h:mm A")
              }}
            </div>
            <div
              v-if="acknowledgePassNotf.from"
              class="text-black-50 font-weight-bold text-value-sm"
            >
              {{
                acknowledgePassNotf.from_type === "App\\Models\\User"
                  ? acknowledgePassNotf.from.first_name +
                    " " +
                    acknowledgePassNotf.from.last_name
                  : acknowledgePassNotf.from.name
              }}
            </div>
            <div v-else class="text-black-50">System</div>
            <div
              v-if="acknowledgePassNotf.to"
              class="text-black-50 font-weight-bold text-value-sm"
            >
              {{
                acknowledgePassNotf.to_type === "App\\Models\\User"
                  ? acknowledgePassNotf.to.first_name +
                    " " +
                    acknowledgePassNotf.to.last_name
                  : acknowledgePassNotf.to.name
              }}
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <CButton
              class="user-table-custom-btn d-flex align-items-center px-4 py-2"
              @click="acknowledgePass()"
            >
              <span class="font-size30 flaticon-checked_optimized"
                ><span class="path1" /><span class="path2" /><span
                  class="path3" /><span class="path4"
              /></span>
              <span
                class="dashboard-custom-green text-uppercase font-weight-bolder mx-3"
                >Acknowledge</span
              >
            </CButton>
          </div>
        </CCol>
      </CRow>
      <template #footer-wrapper>
        <div class="d-flex" />
      </template>
    </CModal>

    <CModal
      backdrop="static"
      :visible="isPassBlockModalVisible"
      add-content-classes="bg-white"
    >
      <template #header-wrapper>
        <div class="d-flex" />
      </template>
      <CRow v-if="isPassBlockModalVisible" class="justify-content-center">
        <CCol md="9" class="bg-white p-4">
          <div class="d-flex align-items-center">
            <div class="text-black-50 ml-2 font-l">
              <span class="font-weight-bolder d-block">
                Pass Creation is blocked up to
                {{
                  $helpers.transformDate(
                    activePassBlock.to_date,
                    "MMM D, Y h:mm A"
                  )
                }}
              </span>

              <span class="text-value-m font-weight-bold">
                Student Instructions:
              </span>
              <span> {{ activePassBlock.message }} </span>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-4">
            <CButton
              class="user-table-custom-btn d-flex align-items-center px-4 py-2"
              @click="closePassBlockModal()"
            >
              <span
                class="dashboard-custom-green text-uppercase font-weight-bolder mx-3"
                >Close</span
              >
            </CButton>
          </div>
        </CCol>
      </CRow>
      <template #footer-wrapper>
        <div class="d-flex" />
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import moment from "moment-timezone"
export default {
  name: "App",
  data() {
    return {
      counter: 1
    }
  },
  computed: {
    ...mapGetters({
      appointmentPassRun: "sockets/appointmentPassRun",
      appointmentPassRemind: "sockets/appointmentPassRemind",
      activePassBlock: "passBlocks/activePassBlock",
      activePass: "passes/activePass"
    }),
    appointmentPassReady() {
      return this.appointmentPassRun.data
    },
    acknowledgePassNotf() {
      return this.appointmentPassRemind.data
    },
    passBlockModalVisible() {
      return this.activePassBlock !== null
    },
    isPassBlockModalVisible() {
      return (
        this.activePass &&
        this.activePass.pass_status != 0 &&
        this.activePassBlock &&
        ((this.activePass.type == "APT" && this.activePassBlock.appointments) ||
          (this.activePass.type == "STU" && this.activePassBlock.students) ||
          (this.activePass.type == "TCH" && this.activePassBlock.proxy) ||
          (this.activePass.type == "KSK" && this.activePassBlock.kiosk))
      )
    },
    getTimeLeft() {
      if (
        this.acknowledgePassNotf &&
        moment(this.acknowledgePassNotf.for_date).diff(moment(), "minutes") >
          0 &&
        this.counter
      ) {
        return Math.ceil(
          moment(this.acknowledgePassNotf.for_date).diff(
            moment(),
            "minutes",
            true
          )
        )
      } else {
        return 0
      }
    }
  },
  watch: {
    getTimeLeft() {
      if (this.getTimeLeft == 0) {
        this.$store.commit(
          "sockets/REMOVE_NOTIFICATION",
          "appointmentPassRemind"
        )
      }
    },
    $route: {
      handler: function (newRoute) {
        this.$store.commit("routerDependencies/SET_ACTIVE_ROUTE", newRoute)
      }
    }
  },
  beforeUnmount() {
    clearInterval(window.notfpass)
  },
  mounted() {
    clearInterval(window.notfpass)
    if (this.acknowledgePassNotf) {
      window.notfpass = setInterval(() => {
        this.counter++
      }, 1000)
    }
  },
  methods: {
    goToPass() {
      clearInterval(window.notfpass)
      this.$store.commit("sockets/REMOVE_NOTIFICATION", "appointmentPassRun")
      this.$router.push({
        path: "/passes/activepass"
      })
    },
    acknowledgePass() {
      this.$store.dispatch(
        "passes/acknowledgeStudentAppointmentPass",
        this.acknowledgePassNotf.id
      )
      clearInterval(window.notfpass)
      this.$store.commit("sockets/REMOVE_NOTIFICATION", "appointmentPassRemind")
    },
    closePassBlockModal() {
      this.$store.commit("passBlocks/SET_ACTIVE_PASS_BLOCK", null)
    }
  }
}
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
@import "assets/v3scss/index";
</style>
