<template>
  <div>
    <DashboardCounters />
    <RoomStats
      v-if="
        userRole === 'admin' || userRole === 'staff' || userRole === 'teacher'
      "
    />
    <Banners />

    <CContainer>
      <CRow>
        <CCol md="12" class="text-end py-2">
          <HelpCenterButton
            classes="me-2"
            content-key="dashboard_overview"
            :is-old="true"
          />
        </CCol>
      </CRow>
    </CContainer>
    <div class="pt-0 p-sm-2">
      <DashboardDataTable />
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue"
import { useStore } from "vuex"
import helpers from "@/helpers/index"
import RoomStats from "@/v3components/Dashboard/RoomStatsOld.vue"
import DashboardDataTable from "@/v3components/Datatables/DashboardDataTable/DashboardDataTableOld.vue"
import HelpCenterButton from "@/v3components/HelpCenterButton.vue"
import DashboardCounters from "@/v3components/Dashboard/DashboardCountersOld.vue"
import Banners from "@/v3components/Dashboard/Banners.vue"

export default {
  name: "DashboardOld",
  components: {
    RoomStats,
    HelpCenterButton,
    DashboardCounters,
    DashboardDataTable,
    Banners
  },

  setup() {
    const store = useStore()

    const studentIdCardsLink = computed(() => {
      return store.getters["users/studentIdCardsLink"]
    })

    const userRole = computed(() => {
      return store.getters["authentication/getUserRole"]
    })

    const isUserUnavailable = computed(() => {
      return store.getters["authentication/isUserUnavailable"]
    })

    const unavailable = computed(() => {
      return store.getters["dashboardTable/unavailable"]
    })

    const passBlock = computed(() => {
      return store.getters["dashboardTable/passBlock"]
    })

    const user = computed(() => {
      return store.getters["authentication/user"]
    })

    const exceededIncidents = computed(() => {
      return store.getters["exceededIncidents/exceededIncidents"]
    })

    const isActiveTardyModule = computed(() => {
      return store.getters["schools/isActiveTardyModule"]
    })

    const itemsBlocked = computed(() => {
      return passBlock.value ? passBlock.value : {}
    })

    const showExceededIncidentsBanner = computed(() => {
      return exceededIncidents.value && userRole.value === "admin"
    })

    const getAllStudentIDCards = () => {
      store.dispatch("users/getAllStudentIDCardsPDF")
    }

    onMounted(() => {
      store.dispatch("dashboardCounters/getPassesForCounters")
      store.dispatch("dashboardTable/getDashboard")
      if (isActiveTardyModule.value && userRole.value === "admin") {
        store.dispatch("exceededIncidents/getExceededIncidents")
      }
      if (userRole.value === "admin" || userRole.value === "superadmin") {
        getAllStudentIDCards()
      }
    })

    const closeUnavailableAlert = () => {
      if (
        window.confirm("Are you sure you want to delete this Out Of Office?")
      ) {
        endUnavailable(unavailable.value)
      }
    }

    const endUnavailable = (unavailable) => {
      if (unavailable && unavailable.id && unavailable.status) {
        store.dispatch("outOfOfficePasses/updateOutOfOfficeStatus", {
          id: unavailable.id,
          params: { dashboard: true }
        })
      }
    }

    return {
      helpers,
      userRole,
      isUserUnavailable,
      unavailable,
      passBlock,
      user,
      exceededIncidents,
      isActiveTardyModule,
      itemsBlocked,
      showExceededIncidentsBanner,
      closeUnavailableAlert,
      endUnavailable,
      studentIdCardsLink
    }
  }
}
</script>
