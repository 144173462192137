<template>
  <div>
    <Loader :is-processing="isLoading" />
    <CContainer>
      <CRow class="mt-4">
        <CCol class="d-flex flex-start">
          <h4>{{ currentUser.name }}</h4>
        </CCol>
        <CCol class="d-flex flex-row-reverse">
          <h4>
            {{ activeSchool.name }}
          </h4>
        </CCol>
      </CRow>
      <CRow class="d-flex flex-start">
        <CCol>
          <h5>{{ currentUser.email }}</h5>
        </CCol>
      </CRow>
      <CRow class="justify-content-center mt-3 overflow-auto" id="locked-row">
        <CCol md="5" class="text-center mt-5" id="locked-col">
          <h1>You are Locked!</h1>
          <div class="flaticon-padlock" />
          <div class="py-4" v-if="allowUserToUnlock">
            After 5 incorrect PINs were added to this device, it has been
            locked. To unlock, a valid PIN must be entered below.
          </div>
          <div class="py-4" v-if="!allowUserToUnlock">
            Your device is locked due to an excessive number of incorrect PINs.
          </div>
          <table v-if="attempts" class="attempts mb-4">
            <thead>
              <tr>
                <td>No.</td>
                <td>Date</td>
                <td>Time</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in attempts" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ $helpers.transformDate(item.created_at) }}</td>
                <td>
                  {{ $helpers.transformDate(item.created_at, "hh:mm A") }}
                </td>
              </tr>
            </tbody>
          </table>
          <CFormInput
            v-if="allowUserToUnlock"
            v-model="teacherPin"
            placeholder="Enter valid PIN here"
            class="mb-2"
            :class="{
              'is-invalid':
                v$.teacherPin.$error ||
                (responseMessageTeacherApproval &&
                  responseMessageTeacherApproval.type == 'danger')
            }"
            type="password"
            @input="responseMessageTeacherApproval = null"
          >
          </CFormInput>
          <div
            v-if="v$.teacherPin.$error && v$.teacherPin.required.$invalid"
            class="invalid-feedback"
          >
            This field is required
          </div>
          <div
            v-if="
              responseMessageTeacherApproval &&
              responseMessageTeacherApproval.message == 'Invalid pin.'
            "
            class="invalid-feedback"
          >
            INVALID PIN.This attempt has been logged and reported. For security
            purposes, you will be logged out after the next INVALID PIN
          </div>

          <CAlert
            v-if="responseMessageTeacherApproval"
            class="mt-4"
            :color="responseMessageTeacherApproval.type"
            :show="!!responseMessageTeacherApproval.message"
          >
            {{ responseMessageTeacherApproval.message }}
          </CAlert>
          <CButton
            v-if="allowUserToUnlock"
            class="bg-gradient-blue text-white mt-2 mb-4"
            @click="unLock"
          >
            Submit
          </CButton>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import moment from "moment-timezone"
import Loader from "@/v3components/shared/Loader/Loader.vue"
import useVuelidate from "@vuelidate/core"
import { required } from "vuelidate/lib/validators"
import { mapGetters } from "vuex"
export default {
  name: "Locked",
  components: {
    Loader
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      isLoading: false,
      teacherPin: null,
      allowUserToUnlock: false,
      responseMessageTeacherApproval: null
    }
  },
  validations: {
    teacherPin: {
      required
    }
  },
  computed: {
    ...mapGetters({
      attempts: "authentication/attempts",
      currentUser: "authentication/user",
      activeSchool: "schools/activeSchool"
    })
  },
  created() {
    this.$store.dispatch("authentication/getAttempts")
  },
  mounted() {
    if (this.currentUser?.next_unlock_attempt_after) {
      this.allowUserToUnlockCalculation()
      setInterval(() => {
        this.allowUserToUnlockCalculation()
      }, 60000)
    } else {
      this.allowUserToUnlock = true
    }
  },

  methods: {
    allowUserToUnlockCalculation() {
      const futureTime = moment
        .utc(this.currentUser?.next_unlock_attempt_after)
        .unix()

      const currentTime = moment.utc(moment.now()).unix()

      if (futureTime - currentTime <= 0) {
        this.allowUserToUnlock = true
      } else {
        this.allowUserToUnlock = false
      }
    },

    checkIfValid(fieldName) {
      const field = this.v$[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !field.$invalid
    },
    unLock() {
      this.v$.$touch()
      if (!this.checkIfValid("teacherPin")) {
        return
      }
      this.responseMessageTeacherApproval = null
      this.isLoading = true
      this.$store
        .dispatch("authentication/unlock", this.teacherPin)
        .then(() => {
          window.location.replace("/passes/create")
        })
        .catch((err) => {
          this.responseMessageTeacherApproval = {
            type: "danger",
            message: err.response.data.message
          }

          const logout = err.response.data?.data?.logout || null
          if (logout) {
            this.$store.dispatch("authentication/logOut")
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
<style scoped lang="scss">
.flaticon-padlock {
  margin: 40px 20px;
  font-size: 100px;
}

#locked-row {
  height: 100vh;
}
#locked-col {
  margin-bottom: 100px;
}

.attempts {
  width: 80%;
  margin: 0 auto;

  thead {
    font-family: "Metropolis-Bold";
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.7;
    letter-spacing: 0.2px;
    text-align: center;
    color: #464a53;
  }

  tbody {
    text-align: center;
    font-size: 18px;

    tr:nth-of-type(4) {
      color: red;
    }

    tr:nth-of-type(5) {
      color: red;
    }
  }
}
</style>
