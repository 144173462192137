<template>
  <div class="dashboard-counters" v-if="!basic">
    <div class="d-flex justify-content-center">
      <div class="dashboard-counters-main-box">
        <div class="label">Room capacities</div>
        <div class="dashboard-counters-main-box-passes">
          <span>{{ autoPassesRooms ? autoPassesRooms.length : "0" }}</span>
        </div>
        <div class="settings">
          <span class="settings-number">{{
            userAssignedRooms ? userAssignedRooms.length : "0"
          }}</span>
          <span class="settings-text-gray ms-1"> My rooms </span>
          <i
            @click="openRoomSettingsModal"
            class="ri-settings-2-line settings-icon ms-1 cursor-pointer"
          ></i>
        </div>
      </div>
      <div class="dashboard-counters-secondary-box">
        <i class="ri-team-line"></i>
      </div>
    </div>
  </div>
  <div class="dashboard-basic-counters" v-else>
    <div class="dashboard-basic-counters-box">
      <div class="dashboard-basic-counters-box-passes">
        <span class="total-passes">{{
          autoPassesRooms ? autoPassesRooms.length : "0"
        }}</span>
      </div>
      <div class="gray-label ms-1">Room capacities</div>
    </div>
  </div>
</template>

<script>
import RoomSettingsForm from "@/v3components/Forms/RoomSettingsForm.vue"

import { useStore } from "vuex"
import { computed, inject } from "vue"
export default {
  name: "RoomStats",
  props: {
    basic: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore()
    const modal = inject("modal")

    const autoPassesRooms = computed(
      () => store.getters["dashboardTable/staffSchedules"]
    )

    const userAssignedRooms = computed(
      () => store.getters["rooms/userAssignRooms"]
    )

    const openRoomSettingsModal = () => {
      modal.open(RoomSettingsForm, {
        size: "sm",
        title: "Room settings",
        props: { rooms: autoPassesRooms.value }
      })
    }

    return { autoPassesRooms, userAssignedRooms, openRoomSettingsModal }
  }
}
</script>

<style></style>
