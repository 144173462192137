import { apiV7nocache } from "../axiousWithCache"

const state = {
  assignAdults: []
}

const getters = {
  assignAdults(state) {
    return state.assignAdults
  }
}

const mutations = {
  SET_ASSIGN_ADULTS(state, adults) {
    state.assignAdults = adults
  },
  DELETE_ASSIGN_ADULT(state, id) {
    state.assignAdults = state.assignAdults.filter((el) => el.id != id)
  }
}

const actions = {
  createAssignAdult(context, adult) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .post(`/admin/tardy/pass-creation-adults`, adult)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAssignAdults(context) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get("/admin/tardy/pass-creation-adults")
        .then((response) => {
          const data = response.data
          context.commit("SET_ASSIGN_ADULTS", data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteAssignAdult(context, data) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .delete(`/admin/tardy/pass-creation-adults/${data}`)
        .then((response) => {
          const data = response.data
          if (data.data) {
            context.commit("DELETE_ASSIGN_ADULT", data.data.id)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
