import axios from "axios"
import { Promise } from "core-js"

const state = {
  contactTracings: []
}

const getters = {
  contactTracings(state) {
    return state.contactTracings
  }
}

const mutations = {
  SET_CONTACT_TRACINGS(state, contactTracingCollection) {
    state.contactTracings = contactTracingCollection
  },
  PUSH_CONTACT_TRACINGS(state, contactTracingCollection) {
    if (state.contactTracings && state.contactTracings.length) {
      state.contactTracings = state.contactTracings.concat(
        contactTracingCollection
      )
    } else {
      state.contactTracings = contactTracingCollection
    }
  }
}

const actions = {
  getContactTracing(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/reports/contactTracing/search`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getContactTracingSearchResults(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/reports/contactTracing/results`, {
          params: data
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  exportContactTracing(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/admin/reports/contactTracing/csv?export_as=${data.export_as}`,
          data
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
