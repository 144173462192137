<template>
  <div class="visitor-photo-root">
    <div v-if="data.form !== null" :title="takePhotoTooltip">
      <div
        v-if="hasNoProfileImageAndImageData"
        class="visitor-check-in-photo-container"
        @click="openModal"
      >
        <div v-if="!isPhotoCaptureDeviceApp" class="d-flex flex-column">
          <i class="ri-camera-2-line"></i>
          <span>Take photo</span>
        </div>
        <div v-else class="d-flex flex-column">
          <i class="ri-send-plane-fill"></i>
          <span>Send capture request</span>
        </div>
      </div>
      <div
        v-else
        @click="openModal"
        class="visitor-check-in-captured-image-container"
      >
        <img
          :src="data?.form?.imageData || data?.form?.profileImage || ''"
          class="visitor-check-in-captured-image"
          alt="Profile Image"
        />
        <i
          v-if="!isPhotoCaptureDeviceApp"
          class="ri-camera-2-line visitor-check-in-captured-image-icon"
        ></i>
        <i
          v-else
          class="ri-send-plane-fill visitor-check-in-captured-image-icon"
        ></i>
      </div>
    </div>
    <VisitorCaptureViaMenu
      @notify="$emit('notify', $event)"
      @device-select="onDeviceSelect"
    />
  </div>
</template>

<script>
import VisitorCaptureViaMenu from "@/v3components/Forms/Visitor/CheckIns/VisitorCaptureViaMenu.vue"
import {
  defineAsyncComponent,
  inject,
  ref,
  computed,
  reactive,
  onMounted,
  watch
} from "vue"
import visitorConstants from "@/constants/visitorConstants"
const VisitorPhotoCapturePopUp = defineAsyncComponent(
  () =>
    import("@/v3components/Forms/Visitor/CheckIns/VisitorPhotoCapturePopUp.vue")
)

export default {
  name: "VisitorTakePhoto",
  components: {
    VisitorCaptureViaMenu
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ["capture", "notify"],
  setup(props, { emit }) {
    const modal = inject("modal")
    const imageData = ref(null)
    const photoCaptureDevice = ref("")
    const data = reactive({
      form: null
    })

    const hasProfileImage = computed(() => {
      return props?.form?.profileImage?.trim()?.length > 0
    })

    const hasImageData = computed(() => {
      return !!props?.form?.imageData
    })

    const hasNoProfileImageAndImageData = computed(() => {
      return !hasProfileImage.value && !hasImageData.value
    })

    const isPhotoCaptureDeviceApp = computed(() => {
      return [
        visitorConstants.VISITOR_CAMERA_TYPES.IOS,
        visitorConstants.VISITOR_CAMERA_TYPES.ANDROID
      ].includes(photoCaptureDevice.value)
    })

    const takePhotoTooltip = computed(() => {
      return isPhotoCaptureDeviceApp.value
        ? "Click to send photo capture request to the app"
        : "Click to take photo"
    })

    const openModal = () => {
      if (isPhotoCaptureDeviceApp.value) {
        emit("notify", photoCaptureDevice.value)
        return
      }

      modal.open(VisitorPhotoCapturePopUp, {
        size: "sm",
        title: visitorConstants.CHECK_IN_FORM.MODAL_TITLE_TAKE_PHOTO,
        props: {
          onCapture: (photoData) => {
            imageData.value = photoData
            emit("capture", photoData)
          }
        }
      })
    }

    const onDeviceSelect = (device) => {
      photoCaptureDevice.value = device
    }

    onMounted(() => {
      data.form = props.form
    })

    watch(
      () => props.data,
      (value) => {
        data.form = value
      }
    )

    return {
      hasNoProfileImageAndImageData,
      takePhotoTooltip,
      isPhotoCaptureDeviceApp,
      data,
      openModal,
      onDeviceSelect
    }
  }
}
</script>
