<template>
  <div
    class="d-flex justify-content-start align-content-start text-capitalize gap-2"
    :class="{
      'visitor-status-green':
        visitor?.health_check_status?.trim()?.toLowerCase() === 'pass',
      'visitor-status-red cursor-pointer':
        visitor?.health_check_status?.trim()?.toLowerCase() !== 'pass'
    }"
    @click="onClick"
  >
    {{ visitor?.health_check_status }}
  </div>
</template>

<script>
export default {
  name: "VisitorHealthCheckStatusColumn",
  props: {
    visitor: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ["onFailHealthCheck"],
  setup(props, { emit }) {
    const onClick = () => {
      if (
        props?.visitor?.health_check_status?.trim()?.toLowerCase() !== "pass"
      ) {
        emit("onFailHealthCheck", props?.visitor)
      }
    }

    return {
      onClick
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
