import axios from "axios"
import helpers from "../../../helpers"
import { api, apiV6nocache } from "../../../store/modules/axiousWithCache"

const state = {
  stats: {},
  unavailable: null,
  passes: new Map(),
  autoPassesRooms: null,
  staffSchedules: null,
  socketCounter: 0,
  usersAssignedRooms: {
    rooms: [],
    users: []
  },
  allowedInActivePasses: [],
  isActiveDashboardUpdate: true,
  listStyle: "list", //list, grid
  type: window.localStorage.getItem("dashboardPassesType")
    ? window.localStorage.getItem("dashboardPassesType")
    : "my", //my, all, appointments
  status: window.localStorage.getItem("dashboardPassesStatus")
    ? window.localStorage.getItem("dashboardPassesStatus")
    : "active", // both, ended, active
  filterData: {
    type: "my", //my, all, appointments
    status: "active", // both, ended, active
    search_query: null, // keyword
    sort: null, // sort by column:acs,desc
    by_students: [], //Ids
    by_teachers: [], //Ids
    by_rooms: [], //Ids
    waiting_approval: false,
    extended_time: false,
    system_ended: false,
    edited: false,
    yellow_time: false,
    global_search: false,
    student_sis_id: false,
    late_to_school: false,
    late_to_class: false,
    limit_exceeded: false
  },
  aptFilterData: {
    student_sis_id: false
  },
  passBlock: {},
  activeEditablePass: null,
  lockedStudentAttempts: null
}

const getters = {
  stats(state) {
    return state.stats
  },
  passes(state) {
    const allPasses = Array.from(state.passes.values())
    let filteredPasses = []
    const filters = {
      waiting_approval: (pass) => pass.badge_flags.waiting_approval == true,
      extended_time: (pass) => pass.badge_flags.extended == true,
      system_ended: (pass) => pass.badge_flags.system_completed == true,
      edited: (pass) => pass.badge_flags.edited == true,
      yellow_time: (pass) =>
        pass.badge_flags.min_time == true &&
        pass.badge_flags.system_completed == false,
      limit_exceeded: (pass) => pass?.badge_flags?.limit_exceeded === true,
      late_to_class: (pass) => pass?.tardy_badge === "LTC",
      late_to_school: (pass) => pass?.tardy_badge === "LTS"
    }
    if (
      !state.filterData.waiting_approval &&
      !state.filterData.system_ended &&
      !state.filterData.extended_time &&
      !state.filterData.edited &&
      !state.filterData.yellow_time &&
      !state.filterData.late_to_school &&
      !state.filterData.late_to_class &&
      !state.filterData.limit_exceeded
    ) {
      filteredPasses = [...allPasses]
    } else {
      Object.keys(filters).forEach((key) => {
        if (state.filterData[key]) {
          filteredPasses = [
            ...filteredPasses,
            ...allPasses.filter(filters[key])
          ]
        }
      })
      filteredPasses = [
        ...new Map(filteredPasses.map((item) => [item["id"], item])).values()
      ]
    }
    if (state.type === "my") {
      filteredPasses = filteredPasses.filter((pass) => helpers.isMyPass(pass))
    }

    return filteredPasses
      .filter((a) => helpers.filterByTab(a) && a.badge_flags.missed == false)
      .sort((a, b) => helpers.sortPassesByColumn(state.filterData.sort, a, b))
  },
  autoPassesRooms(state) {
    return state.autoPassesRooms
  },
  staffSchedules(state) {
    return state.staffSchedules
  },
  unavailable(state) {
    return state.unavailable
  },
  formListAutoPassesRooms(state) {
    if (state.autoPassesRooms) {
      return state.autoPassesRooms.map((room) => {
        return {
          value: { id: room.room.id, type: "App\\Models\\Room" },
          type: "App\\Models\\Room",
          id: room.room.id,
          label: room.room.name
        }
      })
    }
  },
  usersAssignedRooms(state) {
    return state.usersAssignedRooms
  },
  isActiveDashboardUpdate(state) {
    return state.isActiveDashboardUpdate
  },
  getListStyle(state) {
    return state.listStyle
  },
  isActivePassStatus(state) {
    return state.isActivePassStatus
  },
  filterData(state) {
    return state.filterData
  },
  aptFilterData(state) {
    return state.aptFilterData
  },
  getType(state) {
    return state.type
  },
  getStatus(state) {
    return state.status
  },
  passBlock(state) {
    return state.passBlock
  },
  allowedInActivePasses(state) {
    return state.allowedInActivePasses
  },
  activeEditablePass(state) {
    return state.activeEditablePass
  },
  lockedStudentAttempts(state) {
    return state.lockedStudentAttempts
  }
}
const mutations = {
  SET_STATS(state, statsData) {
    state.stats = { ...state.stats, ...statsData }
  },
  SET_UNAVAILABLE(state, unavailable) {
    state.unavailable = unavailable
  },
  SET_PASSES(state, passesCollection) {
    state.passes = new Map()
    passesCollection.forEach((passObj) => state.passes.set(passObj.id, passObj))
  },
  UPDATE_PASSES(state, passesCollection) {
    passesCollection.forEach((passObj) => state.passes.set(passObj.id, passObj))
    state.passes = new Map(state.passes)
  },
  SET_AUTO_PASSES_ROOMS(state, data) {
    state.autoPassesRooms = data
  },
  SET_STAFF_SCHEDULES(state, data) {
    state.staffSchedules = data
  },
  UPDATE_AUTO_PASS_LIMIT(state, passLimit) {
    if (state.staffSchedules) {
      const newCollection = state.staffSchedules.map((limit) => {
        if (
          (limit.room_id && limit.room_id == passLimit.room.id) ||
          (limit.room && limit.room.id == passLimit.room.id)
        ) {
          return passLimit
        }
        return limit
      })
      state.staffSchedules = newCollection
    }
  },
  UPDATE_AUTO_PASS_LIMIT_LISTENER(state, passLimit) {
    if (state.staffSchedules) {
      const newCollection = state.staffSchedules.map((limit) => {
        if (
          (limit.room_id && limit.room_id == passLimit.locationId) ||
          (limit.room && limit.room.id == passLimit.locationId)
        ) {
          return {
            ...limit,
            limit:
              passLimit.limit != null || passLimit.limit == -1
                ? passLimit.limit
                : "-",
            room_advance: {
              ...limit.room_advance,
              limit:
                passLimit.limit != null || passLimit.limit == -1
                  ? passLimit.limit
                  : "-"
            }
          }
        }
        return { ...limit }
      })
      state.staffSchedules = newCollection
    }
  },
  UPDATE_PASS(state, passObj) {
    passObj.badge_flags = helpers.getBadgeFlags(passObj)
    passObj.total_time = helpers.getTotalTime(passObj)
    passObj = helpers.setUserAssignedRoomsPerPass(passObj)
    if (!state.isActiveDashboardUpdate) {
      if (state.passes.has(passObj.id)) {
        state.passes.set(passObj.id, passObj)
      }
    } else {
      state.passes.set(passObj.id, passObj)
    }
    state.passes = new Map(state.passes)
  },
  SET_USERS_ASSIGNED_ROOMS(state, data) {
    const inverted = {}
    Object.keys(data).forEach((e) => {
      data[e].forEach((a) => {
        if (!inverted[a]) {
          inverted[a] = [parseInt(e)]
        } else {
          inverted[a].push(parseInt(e))
        }
      })
    })
    state.usersAssignedRooms = {
      users: data,
      rooms: inverted
    }
  },
  SET_DASHBOARD_LIST_STYLE(state, data) {
    state.listStyle = data
  },
  SET_ARCHIVED_USERS_DUE_TO_WRONG_PIN(state, data) {
    state.lockedStudentAttempts = data
  },
  SET_DASHBOARD_UPDATES(state, data) {
    state.isActiveDashboardUpdate = data
    if (data == false) {
      state.allowedInActivePasses = Array.from(state.passes.values())
        .filter(
          (pass) =>
            pass.pass_status == 1 || (pass.child && pass.child.pass_status)
        )
        .map((e) => e.id)
    } else {
      state.allowedInActivePasses = []
    }
  },
  SET_PASS_BLOCK(state, data) {
    let blocked = []
    if (data) {
      blocked = data.map((item) => {
        const el = {}
        const itemsBlocked = []
        item.students ? itemsBlocked.push("Student Pass") : ""
        item.kiosk ? itemsBlocked.push("Kiosk pass") : ""
        item.appointments ? itemsBlocked.push("Appointment pass") : ""
        item.proxy ? itemsBlocked.push("Teacher Pass") : ""
        el.items = itemsBlocked
        el.reason = item.reason
        el.to_date = item.to_date
        return el
      })
    }
    state.passBlock = blocked
  },
  UPDATE_TYPE(state, data) {
    state.type = data
    state.filterData.search_query = ""
    window.localStorage.setItem("dashboardPassesType", data)
  },
  UPDATE_STATUS(state, data) {
    state.status = data
    state.filterData.waiting_approval = false
    state.filterData.extended_time = false
    state.filterData.system_ended = false
    state.filterData.edited = false
    state.filterData.yellow_time = false
    window.localStorage.setItem("dashboardPassesStatus", data)
  },

  UPDATE_FILTERS_DATA(state, elements) {
    if (elements instanceof Array) {
      elements.forEach((data) => {
        state.filterData[data.filter] = data.value
      })
    } else {
      state.filterData[elements.filter] = elements.value
    }
  },
  UPDATE_APT_FILTERS_DATA(state, elements) {
    if (elements instanceof Array) {
      elements.forEach((data) => {
        state.aptFilterData[data.filter] = data.value
      })
    } else {
      state.aptFilterData[elements.filter] = elements.value
    }
  },
  DECREMENT_STAT(state, type) {
    let statsValue = type
    if (type === "appointments_awaiting") {
      statsValue = "awaitingTodayAppointmentsCount"
    }
    if (type === "appointments_future_awaiting") {
      statsValue = "futureAppointmentsCount"
    }
    if (state.stats[statsValue] > 0) {
      state.stats[statsValue]--
    }
  },
  INCREMENT_STAT(state, type) {
    state.stats[type]++
  },
  SET_ACTIVE_EDITABLE_PASS(state, pass) {
    state.activeEditablePass = pass
  }
}

const actions = {
  getDashboard(context) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/users/dashboard")
        .then((response) => {
          resolve(response)
          const data = response.data.data
          if (data.students && data.students.length) {
            context.commit("users/SET_USERS", data.students, { root: true })
          }
          if (Object.prototype.hasOwnProperty.call(data, "stats")) {
            context.commit("SET_STATS", data.stats)
          }
          if (Object.prototype.hasOwnProperty.call(data, "autoPassesRooms")) {
            context.commit("SET_AUTO_PASSES_ROOMS", data.autoPassesRooms)
          }
          if (Object.prototype.hasOwnProperty.call(data, "staffSchedules")) {
            context.commit("SET_STAFF_SCHEDULES", data.staffSchedules)
          }
          if (Object.prototype.hasOwnProperty.call(data, "passBlocks")) {
            context.commit("SET_PASS_BLOCK", data.passBlocks)
          }
          if (
            Object.prototype.hasOwnProperty.call(data, "lockedStudentAttempts")
          ) {
            context.commit(
              "SET_ARCHIVED_USERS_DUE_TO_WRONG_PIN",
              data.lockedStudentAttempts
            )
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDashboardPassesActiveData(context) {
    return new Promise((resolve, reject) => {
      const params = {
        type: "all", //my, all, appointments
        status: "active" // both, ended, active
      }
      params.search_query = context.state.filterData.search_query
      params.by_rooms = context.state.filterData.by_rooms.map((e) => e.value.id)
      params.by_students = context.state.filterData.by_students.map(
        (e) => e.value.id
      )
      params.by_teachers = context.state.filterData.by_teachers.map(
        (e) => e.value.id
      )
      apiV6nocache
        .get("/admin/users/dashboard/filter", {
          params: params
        })
        .then((response) => {
          let data = response.data.data
          const dataFromSocket = Array.from(state.passes.values())

          if (data && dataFromSocket) {
            const socketDataMap = new Map(
              dataFromSocket.map((item) => [item.id, item])
            )
            data = data.map((el) => socketDataMap.get(el.id) || el)
            data = JSON.parse(JSON.stringify(data))
          }
          if (data) {
            data = data.map((e) => {
              const isExceeding = helpers.isPassExceedingSystemEndTime(e)
              if (isExceeding) {
                const completeTime = helpers.getPassEndTime(e)
                const target = e.child || e
                target.pass_status = 0
                target.system_completed = 1
                target.completed_at = completeTime
              }
              e.badge_flags = helpers.getBadgeFlags(e)
              e.total_time = helpers.getTotalTime(e)
              return helpers.setUserAssignedRoomsPerPass(e)
            })
          }
          context.commit("UPDATE_PASSES", data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDashboardPassesData(context) {
    return new Promise((resolve, reject) => {
      const params = {
        type: "all", //my, all, appointments
        status: "both" // both, ended, active
      }
      params.search_query = context.state.filterData.search_query
      params.by_rooms = context.state.filterData.by_rooms.map((e) => e.value.id)
      params.by_students = context.state.filterData.by_students.map(
        (e) => e.value.id
      )
      params.by_teachers = context.state.filterData.by_teachers.map(
        (e) => e.value.id
      )
      if (context.state.filterData.global_search) {
        params.global_search = context.state.filterData.global_search
      }
      apiV6nocache
        .get("/admin/users/dashboard/filter", {
          params: params
        })
        .then((response) => {
          let data = response.data.data
          const dataFromSocket = Array.from(state.passes.values())

          if (data && dataFromSocket) {
            const socketDataMap = new Map(
              dataFromSocket.map((item) => [item.id, item])
            )
            data = data.map((el) => socketDataMap.get(el.id) || el)
            data = JSON.parse(JSON.stringify(data))
          }
          if (data) {
            data = data.map((e) => {
              const isExceeding = helpers.isPassExceedingSystemEndTime(e)
              if (isExceeding) {
                const completeTime = helpers.getPassEndTime(e)
                const target = e.child || e
                target.pass_status = 0
                target.system_completed = 1
                target.completed_at = completeTime
              }
              e.badge_flags = helpers.getBadgeFlags(e)
              e.total_time = helpers.getTotalTime(e)
              return helpers.setUserAssignedRoomsPerPass(e)
            })
          }
          context.commit("SET_PASSES", data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDashboardPassesExport(context) {
    const params = JSON.parse(JSON.stringify(context.state.filterData))
    params.type = context.state.type
    params.status = context.state.status
    return new Promise((resolve, reject) => {
      apiV6nocache
        .get("/admin/users/dashboard/export/csv", {
          params: params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getUsersAssignedRooms(context) {
    return new Promise((resolve, reject) => {
      api
        .get("/admin/rooms/assign?for_dashboard=true")
        .then((response) => {
          context.commit("SET_USERS_ASSIGNED_ROOMS", response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
