import axios from "axios"

const state = {
  passLimits: null,
  editablePassLimit: null,
  passLimitFiles: null
}

const getters = {
  passLimits(state) {
    return state.passLimits
  },
  editablePassLimit(state) {
    return state.editablePassLimit
  },
  passLimitFiles(state) {
    return state.passLimitFiles
  }
}

const mutations = {
  SET_PASS_LIMITS(state, passLimits) {
    state.passLimits = passLimits
  },
  PUSH_PASS_LIMITS(state, passLimits) {
    if (state.passLimits && state.passLimits.length) {
      state.passLimits = state.passLimits.concat(passLimits)
    } else {
      state.passLimits = passLimits
    }
  },
  SET_EDITABLE_PASS_LIMITS(state, editablePassLimit) {
    state.editablePassLimit = editablePassLimit
  },
  DELETE_PASS_LIMIT(state, passID) {
    state.passLimits = state.passLimits.filter((el) => el.id != passID)
  },
  UPDATE_PASS_LIMIT(state, passObj) {
    const newCollection = state.passLimits.map((pass) => {
      if (pass.id === passObj.id) {
        return passObj
      }
      return pass
    })
    state.passLimits = newCollection
  },
  PUSH_PASS_LIMIT(state, passLimit) {
    if (state.passLimits && state.passLimits.length) {
      state.passLimits.push(passLimit)
    } else {
      state.passLimits = [passLimit]
    }
  },

  SET_PASS_LIMIT_IMPORT_LOGS(state, passLimitFiles) {
    state.passLimitFiles = passLimitFiles
  },
  PUSH_PASS_LIMIT_IMPORT_LOGS(state, passLimitFiles) {
    if (state.passLimitFiles && state.passLimitFiles.length) {
      state.passLimitFiles = state.passLimitFiles.concat(passLimitFiles)
    } else {
      state.passLimitFiles = passLimitFiles
    }
  },
  UPDATE_PASS_LIMIT_FILE_FROM_LISTENER(state, log) {
    const newCollection = state.passLimitFiles?.map((file) => {
      if (file.id == log.id) {
        return Object.assign(file, log)
      } else {
        return file
      }
    })
    state.passLimitFiles = newCollection
  }
}

const actions = {
  getPassLimits(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/pass-limits`, {
          params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCSVpassLimitsExport(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/pass-limits/export/csv`, {
          params: params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createPassLimit(_, passLimit) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/pass-limits`, passLimit)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deletePassLimit(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admin/pass-limits/${id}`)
        .then((response) => {
          const data = response.data.data
          if (data) {
            context.commit("DELETE_PASS_LIMIT", data.id)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deletePassLimits(context, ids) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/pass-limits/delete/bulk`, { pass_limit_ids: ids })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updatePassLimit(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/pass-limits/${data.id}`, data.params)
        .then((response) => {
          const data = response.data.data
          if (data) {
            context.commit("UPDATE_PASS_LIMIT", data)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getPassLimitsImportLogs(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/pass-limits/import-logs`, {
          params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
