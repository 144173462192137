<template>
  <InputField
    type="text"
    :id="id"
    :placeholder="placeholder"
    :invalid-feedback="invalidFeedback"
    :value="formattedValue"
    @update:model-value="handleInput"
    @keydown="validateNumber"
  />
</template>

<script>
import InputField from "./InputField.vue"
import { computed } from "vue"

export default {
  name: "PhoneInput",
  components: {
    InputField
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    invalidFeedback: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const modelValue = computed(() => props.value)

    const formattedValue = computed(() => {
      if (!modelValue.value) return ""
      const digitsOnly = modelValue.value.replace(/\D/g, "")
      let formattedNumber = "(" + digitsOnly.substring(0, 3)
      if (digitsOnly.length > 3) {
        formattedNumber += ") " + digitsOnly.substring(3, 6)
      }
      if (digitsOnly.length > 6) {
        formattedNumber += "-" + digitsOnly.substring(6, 10)
      }
      if (digitsOnly.length > 10) {
        formattedNumber += digitsOnly.substring(10)
      }
      return formattedNumber
    })

    const handleInput = (value) => {
      const digitsOnly = value.replace(/\D/g, "")
      let formattedNumber = "(" + digitsOnly.substring(0, 3)
      if (digitsOnly.length > 3) {
        formattedNumber += ") " + digitsOnly.substring(3, 6)
      }
      if (digitsOnly.length > 6) {
        formattedNumber += "-" + digitsOnly.substring(6, 10)
      }
      if (digitsOnly.length > 10) {
        formattedNumber += digitsOnly.substring(10)
      }
      emit("update:modelValue", formattedNumber)
    }

    const validateNumber = (event) => {
      const input = event.target.value
      const inputLength = input.replace(/\D/g, "").length
      const keyCode = event.keyCode

      const excludedKeys = [8, 37, 39, 46]

      // Check if the first number is 0
      if (inputLength === 0 && (keyCode === 48 || keyCode === 96)) {
        event.preventDefault()
        return
      }

      // Allow only numbers and specific control keys, and prevent more than 10 digits
      if (inputLength >= 10 && !excludedKeys.includes(keyCode)) {
        event.preventDefault()
      } else if (
        !(
          (
            (keyCode >= 48 && keyCode <= 57) || // Numbers on the main keyboard
            (keyCode >= 96 && keyCode <= 105) || // Numbers on the numeric keypad
            excludedKeys.includes(keyCode)
          ) // Control keys
        )
      ) {
        event.preventDefault()
      }
    }

    return {
      formattedValue,
      handleInput,
      validateNumber
    }
  }
}
</script>
