import visitorConstants from "@/constants/visitorConstants"

const pages = {
  settingsPage() {
    return {
      name: "Visitor Settings",
      path: visitorConstants.PATHS.SETTINGS,
      roles: visitorConstants.VISITOR_ADMIN_ROLES,
      module: visitorConstants.MODULE,
      dependency: visitorConstants.ROUTE_DEPENDENCY.SETTINGS
    }
  },
  adultsPage() {
    return {
      name: "Visitor Adults",
      path: visitorConstants.PATHS.ADULTS,
      roles: visitorConstants.VISITOR_ADMIN_ROLES,
      module: visitorConstants.MODULE,
      dependency: visitorConstants.ROUTE_DEPENDENCY.ADULTS,
      helpKey: "visitor_adults",
      action: {
        assign: {
          title: "Assign (Add staff)",
          modalTitle: "Assign adults who can check in visitors"
        }
      }
    }
  },
  guardianStudentMatrixPage() {
    return {
      name: "Visitor Guardian/Student Matrix (Parent/Student Matrix)",
      path: visitorConstants.PATHS.GUARDIAN_STUDENT_MATRIX,
      roles: visitorConstants.VISITOR_ADMIN_ROLES,
      module: visitorConstants.MODULE,
      dependency: visitorConstants.ROUTE_DEPENDENCY.GUARDIAN_STUDENT_MATRIX,
      helpKey: "visitor_guardian_student_matrix"
    }
  },
  securityPage() {
    return {
      name: "Security Alerts (settings)",
      path: visitorConstants.PATHS.SECURITY,
      roles: visitorConstants.VISITOR_ADMIN_ROLES,
      module: visitorConstants.MODULE,
      dependency: visitorConstants.ROUTE_DEPENDENCY.SECURITY,
      helpKey: "visitor_security_settings"
    }
  },
  kioskPage() {
    return {
      name: "Kiosk Settings",
      path: visitorConstants.PATHS.KIOSK,
      roles: visitorConstants.VISITOR_ADMIN_ROLES,
      module: visitorConstants.MODULE,
      dependency: visitorConstants.ROUTE_DEPENDENCY.KIOSK,
      helpKey: "visitor_kiosk_settings"
    }
  },
  generalPage() {
    return {
      name: "General Settings",
      path: visitorConstants.PATHS.GENERAL,
      roles: visitorConstants.VISITOR_ADMIN_ROLES,
      module: visitorConstants.MODULE,
      dependency: visitorConstants.ROUTE_DEPENDENCY.GENERAL,
      helpKey: "visitor_general_settings"
    }
  },
  listPage() {
    return {
      name: "List Settings",
      path: visitorConstants.PATHS.LIST,
      roles: visitorConstants.VISITOR_ADMIN_ROLES,
      module: visitorConstants.MODULE,
      dependency: visitorConstants.ROUTE_DEPENDENCY.LIST,
      helpKey: "visitor_list_settings"
    }
  },
  managementPage() {
    return {
      name: "Visitor Management",
      path: visitorConstants.PATHS.MANAGEMENT,
      roles: visitorConstants.VISITOR_ROLES,
      module: visitorConstants.MODULE,
      dependency: visitorConstants.ROUTE_DEPENDENCY.MANAGEMENT
    }
  },
  checkInLogsPage() {
    return {
      name: "Check-ins and Reports",
      path: visitorConstants.PATHS.CHECK_IN_LOGS,
      roles: visitorConstants.VISITOR_ROLES,
      module: visitorConstants.MODULE,
      dependency: visitorConstants.ROUTE_DEPENDENCY.CHECK_IN_LOGS,
      helpKey: "visitor_check_in"
    }
  },
  printerPage() {
    return {
      name: "Printer Setup",
      path: visitorConstants.PATHS.PRINTER,
      roles: visitorConstants.VISITOR_ROLES,
      module: visitorConstants.MODULE,
      dependency: visitorConstants.ROUTE_DEPENDENCY.PRINTER,
      helpKey: "visitor_printer_settings"
    }
  }
}

const useCustomVisitorTimeFunction = true

export default {
  pages,
  useCustomVisitorTimeFunction
}
