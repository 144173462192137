const tabs = [
  { label: "Now Reports", value: "nowReport" },
  { label: "Background Reports", value: "backgroundReports" }
]

const tableDropdowns = {
  column: [
    {
      value: "student_id",
      label: "Student ID"
    }
  ]
}

const aggregateByOptions = [
  {
    value: "highest_pass_destination",
    label: "Locations (pass destination)"
  },
  {
    value: "highest_pass_granters",
    label: "Adults (pass granters)"
  },
  {
    value: "highest_pass_takers",
    label: "Students (pass takers)"
  }
]

const passTypesOptions = [
  {
    value: "ALL",
    label: "All Passes"
  },
  {
    value: "STU",
    label: "Student Created Pass (STU)"
  },
  {
    value: "AUP",
    label: "Auto Pass (AUP)"
  },
  {
    value: "TCH",
    label: "Teacher Pass (TCH)"
  },
  {
    value: "APT",
    label: "Appointment Pass (APT)"
  },
  {
    value: "KSK",
    label: "Kiosk Pass (KSK)"
  }
]

const columnSearchKeys = {
  globalQuery: "",
  // NOW REPORTS tab
  student_sis_id: {
    value: "",
    column: ["student_sis_id"],
    sortBy: "student_sis_id"
  },
  destination: {
    value: "",
    column: ["destination.sortable_name"],
    sortBy: "destination.sortable_name"
  },
  total_passes: {
    value: "",
    column: ["total_passes"],
    sortBy: "total_passes.raw_int"
  },
  total_time_all: {
    value: "",
    column: ["total_time_all"],
    sortBy: "total_time_all"
  },
  total_time_student: {
    value: "",
    column: ["total_time_student"],
    sortBy: "total_time_student"
  },
  total_time_aup: {
    value: "",
    column: ["total_time_aup"],
    sortBy: "total_time_aup"
  },
  total_time_proxy: {
    value: "",
    column: ["total_time_proxy"],
    sortBy: "total_time_proxy"
  },
  total_time_apt: {
    value: "",
    column: ["total_time_apt"],
    sortBy: "total_time_apt"
  },
  total_time_ksk: {
    value: "",
    column: ["total_time_ksk"],
    sortBy: "total_time_ksk"
  },
  // BACKGROUND REPORTS tab
  aggregate_by_label: {
    value: "",
    column: ["aggregate_by_label"],
    sortBy: "aggregate_by_label"
  },
  teachers: {
    value: "",
    column: ["teachers"],
    sortBy: "teachers"
  },
  students: {
    value: "",
    column: ["students"],
    sortBy: "students"
  },
  rooms: {
    value: "",
    column: ["rooms"],
    sortBy: "rooms"
  },
  by_pass_type: {
    value: "",
    column: ["by_pass_type"],
    sortBy: "by_pass_type"
  },
  by_grad_years: {
    value: "",
    column: ["by_grad_years"],
    sortBy: "by_grad_years"
  },
  by_school_year: {
    value: "",
    column: ["by_school_year"],
    sortBy: "by_school_year"
  },
  dates: {
    value: "",
    column: ["dates"],
    sortBy: "dates"
  },
  requested_at: {
    value: "",
    column: ["requested_at"],
    sortBy: "requested_at"
  },
  runned_at: {
    value: "",
    column: ["runned_at"],
    sortBy: "runned_at"
  },
  requested_by: {
    value: "",
    column: ["requested_by"],
    sortBy: "requested_by"
  },
  runned_by: {
    value: "",
    column: ["runned_by"],
    sortBy: "runned_by"
  },
  status: {
    value: "",
    column: ["status"],
    sortBy: "status"
  },
  action: {
    filter: false,
    sorter: false,
    value: "",
    column: ["action"],
    sortBy: "action"
  }
}

const dataTableFields = [
  // NOW REPORTS tab
  {
    key: "student_sis_id",
    filter: false,
    sorter: false,
    label: "Student number",
    _style: "min-width:150px"
  },
  {
    key: "destination",
    label: "Destination/Name",
    type: "tabular",
    _style: "min-width:150px"
  },
  {
    key: "total_passes",
    label: "Total Pass",
    type: "tabular",
    _style: "min-width:150px"
  },
  {
    key: "total_time_all",
    label: "Total Time",
    type: "tabular",
    _style: "min-width:150px"
  },
  {
    key: "total_time_student",
    label: "STU",
    type: "tabular"
  },
  {
    key: "total_time_aup",
    label: "Auto Pass(STU)",
    type: "tabular"
  },
  {
    key: "total_time_proxy",
    label: "TCH(PRX)",
    type: "tabular"
  },
  {
    key: "total_time_apt",
    label: "APT",
    type: "tabular"
  },
  {
    key: "total_time_ksk",
    label: "KSK",
    type: "tabular"
  }
]

const dataTableFieldsBackgroundReports = [
  // BACKGROUND REPORTS tab
  {
    key: "aggregate_by_label",
    label: "Report Type",
    _style: "min-width:150px"
  },
  {
    key: "teachers",
    label: "Teacher",
    _style: "min-width:150px",
    sorter: false
  },
  {
    key: "students",
    label: "Students",
    type: "tabular",
    _style: "min-width:150px",
    sorter: false
  },
  {
    key: "rooms",
    label: "Destination",
    type: "tabular",
    _style: "min-width:150px",
    sorter: false
  },
  {
    key: "by_pass_type",
    label: "Pass Type",
    type: "tabular"
  },
  {
    key: "by_grad_years",
    label: "Grad year",
    type: "tabular",
    sorter: false
  },
  {
    key: "by_school_year",
    label: "School year",
    type: "tabular",
    sorter: false
  },
  {
    key: "dates",
    label: "Dates",
    type: "tabular",
    sorter: false
  },
  {
    key: "requested_at",
    label: "Request Date",
    type: "tabular"
  },
  {
    key: "runned_at",
    label: "Last run Date",
    type: "tabular",
    _style: "min-width:120px"
  },
  {
    key: "requested_by",
    label: "Request By",
    type: "tabular"
  },
  {
    key: "runned_by",
    label: "Run By",
    type: "tabular"
  },
  {
    key: "status",
    label: "Status",
    type: "tabular"
  },
  {
    key: "action",
    label: "Action",
    type: "tabular",
    _style: "width: 50px;"
  }
]

const reportRequestAction = [
  {
    label: "Rerun",
    icon: "ri-restart-line",
    class: "activate",
    action: "rerun"
  },
  { label: "Download", icon: "ri-download-2-line", action: "download" },
  { label: "Duplicate", icon: "ri-file-copy-2-line", action: "duplicate" },
  {
    label: "Delete",
    icon: "ri-delete-bin-line",
    class: "important",
    action: "delete"
  }
]

export default {
  tabs,
  tableDropdowns,
  aggregateByOptions,
  passTypesOptions,
  columnSearchKeys,
  dataTableFields,
  dataTableFieldsBackgroundReports,
  reportRequestAction
}
