import axios from "axios"
import { apiV7nocache } from "./axiousWithCache"

const state = {
  proxyPasses: null
}

const getters = {
  proxyPasses(state) {
    return state.proxyPasses
  }
}

const mutations = {
  SET_PROXY_PASSES(state, passesCollection) {
    state.proxyPass = passesCollection
  }
}

const actions = {
  createProxyPass(context, proxyPass) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .post(`/admin/passes/proxy-passes`, proxyPass)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getProxyPassInfo(context, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/users/student-limit-info/${userId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
