<template>
  <div class="color-picker-wrapper">
    <div class="color-input w-full">
      <div v-if="inputLabel" class="mt-2">{{ inputLabel }}</div>
      <InputField
        @click="toggleModal"
        @blur="toggleModal"
        readonly
        class="color-palette"
        :style="'background-color: ' + selectedColorHex"
        :invalid-feedback="invalidFeedback"
      ></InputField>
    </div>
    <div v-show="state.openModal" ref="target" class="color-modal">
      <div class="colors">
        <div
          v-for="color in colorPalette"
          :key="color.id"
          class="color-item"
          :class="{ active: selectedColorHex == color.hex }"
          :style="'background-color:' + color.hex"
          @click="setColor(color.hex)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from "vue"
import InputField from "./InputField.vue"

export default {
  name: "ColorPicker",
  components: { InputField },
  emits: ["update:model-value"],
  props: {
    inputLabel: String,
    invalidFeedback: [String, Array],
    modelValue: String
  },
  setup(props, { emit }) {
    const state = reactive({
      openModal: false
    })

    const colorPalette = [
      { label: "Color1", hex: "#B8D8F8" },
      { label: "Color2", hex: "#7986CB" },
      { label: "Color3", hex: "#FFC8B7" },
      { label: "Color4", hex: "#567593" },
      { label: "Color5", hex: "#33B679" },
      { label: "Color6", hex: "#F6C026" },
      { label: "Color7", hex: "#039BE5" },
      { label: "Color8", hex: "#A19AF5" },
      { label: "Color9", hex: "#E67C73" },
      { label: "Color10", hex: "#616161" },
      { label: "Color12", hex: "#008566" },
      { label: "Color13", hex: "#F5511D" },
      { label: "Color14", hex: "#3F51B5" },
      { label: "Color15", hex: "#8E24AA" },
      { label: "Color16", hex: "#D60000" }
    ]

    const toggleModal = (data) => {
      setTimeout(() => {
        if (state.openModal == true) {
          state.openModal = !state.openModal
        } else {
          if (data.type !== "blur") {
            state.openModal = !state.openModal
          }
        }
      }, 200)
    }

    const setColor = (color) => {
      emit("update:model-value", color)
    }

    const selectedColorHex = computed(() => props.modelValue || "#fff")

    return {
      state,
      colorPalette,
      toggleModal,
      setColor,
      selectedColorHex
    }
  }
}
</script>
