<template>
  <div :class="`status-pill ${getStatusClass()}`" class="text-center">
    <i
      v-if="statusIcon"
      :class="`${statusIcon} ${getStatusClass()}`"
      class="circle"
    ></i>
    {{ getStatusText() }}
  </div>
</template>

<script>
export default {
  name: "StatusPill",
  props: {
    status: {
      type: String
    },
    statusIcon: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const getStatusClass = () => {
      const classes = {
        STATUS_FAILED: "failed",
        STATUS_PROCESSED: "processed",
        STATUS_IN_PROCESS: "process",
        STATUS_PENDING: "pending",
        STATUS_IN_QUEUED: "queue",
        STATUS_ACTIVE: "active",
        STATUS_INACTIVE: "inactive",
        STATUS_PROCESSING: "process",
        STATUS_EXPIRED: "expired"
      }
      return classes[props.status] || ""
    }

    const getStatusText = () => {
      const texts = {
        STATUS_FAILED: "Failed",
        STATUS_PROCESSED: "Processed",
        STATUS_IN_PROCESS: "In Process",
        STATUS_PENDING: "Pending",
        STATUS_IN_QUEUED: "Queued",
        STATUS_ACTIVE: "Active",
        STATUS_INACTIVE: "Inactive",
        STATUS_PROCESSING: "Processing",
        STATUS_EXPIRED: "Expired"
      }
      return texts[props.status] || ""
    }

    return {
      getStatusClass,
      getStatusText
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/_variables";
@import "src/assets/v3scss/_variables";
.status-pill {
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  padding: 4px;
}
.status-pill i.circle {
  color: $white;
  border-radius: 50%;
  &.active {
    background-color: $green-lighten-10;
  }
  &.inactive {
    background-color: $red-lighten-10;
  }
}
.failed {
  border-color: $red-failed-status;
}
.processed {
  border-color: $green-success-status;
}
.process {
  border-color: $accent-blue-light;
}
.expired {
  border-color: $gray-dark;
}
.pending {
  border-color: $yellow;
}
.queue {
  border-color: $orange-lighten-20;
}
.queue {
  border-color: $orange-lighten-20;
}
.active {
  border-color: $green-lighten-10;
}
.inactive {
  border-color: $red-lighten-10;
}
</style>
