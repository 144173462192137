import { createRouter, createWebHistory } from "vue-router"
import Routes from "./routes"
import store from "@/store/index"
import tokenService from "@/helpers/tokenService.js"
import { shouldVisitorWebAppBeHidden } from "@/helpers/featureFlags"
import visitorConstants from "@/constants/visitorConstants"

const router = createRouter({
  history: createWebHistory(""),
  linkActiveClass: "open active",
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
  routes: [...Routes]
})

// Expose the router to be used by sockets.
window.vueRouter = router

// nav guard for authenticated users
let currentRole = store.getters["authentication/getUserRole"]
let user = store.getters["authentication/user"]
let activeModules = null

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiredRoles)) {
    // check if 'to' route requires spec ROLE
    activeModules = store.getters["schools/activeModulesNames"]
    /**
     * Allowing Vistor Module to be accessed by any user
     * It allows user to visitor routes even if the visitor module is not activated
     */
    const isVisitorModuleRequired =
      to.meta.requiredModule === visitorConstants.MODULE
    const keepVisitorHidden = shouldVisitorWebAppBeHidden()
    if (
      (!to.meta.requiredModule ||
        (!keepVisitorHidden && isVisitorModuleRequired) ||
        activeModules.includes(to.meta.requiredModule)) &&
      (!to.meta.routeDependency || !!store.getters[to.meta.routeDependency])
      //check for route show/hide dependency
    ) {
      // check for required module and chekc if is activated
      if (tokenService.getToken()) {
        // check if 'user' is auth
        if (!currentRole) {
          // check if user has setted ROLE
          user = store.getters["authentication/user"]
          if (!user.is_locked) {
            currentRole = store.getters["authentication/getUserRole"]

            if (to.meta.requiredRoles.includes(currentRole)) {
              if (
                currentRole == "student" &&
                (to.path == "/" || to.path == "/dashboard")
              ) {
                store.dispatch("passes/getStudentActivePass").then(() => {
                  const activePass = store.getters["passes/activePass"]
                  if (
                    currentRole == "student" &&
                    activePass &&
                    activePass.pass_status !== 0 &&
                    to.path != "/passes/activepass"
                  ) {
                    return next("/passes/activepass")
                  } else {
                    return next("/passes/create")
                  }
                })
              } else {
                store.dispatch("passes/getStudentActivePass").then(() => {
                  const activePass = store.getters["passes/activePass"]
                  if (
                    currentRole == "student" &&
                    activePass &&
                    activePass.pass_status !== 0 &&
                    to.path != "/passes/activepass"
                  ) {
                    return next("/passes/activepass")
                  } else {
                    return next()
                  }
                })
              }
            } else {
              return next("/404")
            }
          } else {
            return next("/locked")
          }
        } else {
          if (to.meta.requiredRoles.includes(currentRole)) {
            if (currentRole == "student") {
              if (to.path == "/" || to.path == "/dashboard") {
                return next("/passes/create")
              }
              const activePass = store.getters["passes/activePass"]
              if (
                currentRole == "student" &&
                activePass &&
                activePass.pass_status !== 0 &&
                to.path != "/passes/activepass" &&
                to.path != "/id-card"
              ) {
                return next("/passes/activepass")
              } else {
                return next()
              }
            } else {
              return next()
            }
          } else {
            return next("/404")
          }
        }
      } else {
        return next("/login")
      }
    } else {
      return next("/404")
    }
  } else {
    next()
  }
})

export default router
