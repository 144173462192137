<template>
  <CContainer>
    <CRow class="justify-content-center">
      <CCol md="12" class="text-start">
        <CAlert
          v-if="reqResponse.message"
          class="mt-4"
          :color="reqResponse.type"
          :show="!!reqResponse.message"
          close-button
        >
          {{ reqResponse.message }}
        </CAlert>
        <CForm class="mb-2">
          <VSelect
            v-model="form.limit_for"
            :class="{
              'is-invalid':
                v$.form.limit_for.$error || reqResponse.errors['limit_for']
            }"
            class="cs-select mb-3 ps__child--consume"
            :options="locationAvailabilityOptions"
            placeholder="Location Availability for"
            :clearable="false"
            label="label"
            :reduce="(item) => item.value"
            @update:model-value="resetOptinalInputs"
          >
            <template #footer>
              <div
                v-if="v$.form.$error && v$.form.limit_for.required.$invalid"
                class="invalid-feedback"
              >
                This field is required
              </div>
              <div
                v-if="reqResponse.errors['limit_for']"
                class="invalid-feedback"
              >
                {{ reqResponse.errors["limit_for"][0] }}
              </div>
            </template>
          </VSelect>

          <div v-if="form.limit_for === 'student'">
            <LazyLoadSelect
              :classes="[
                {
                  'is-invalid':
                    v$.form.selected_student_ids.$error ||
                    reqResponse.errors['selected_student_ids']
                },
                'mb-3'
              ]"
              :is-multiple="true"
              :selected="form.selected_student_ids"
              type="student"
              placeholder="Select students"
              @changed="
                (value) => {
                  form.selected_student_ids = value
                }
              "
            >
              <template #footer>
                <div
                  v-if="
                    v$.form.$error &&
                    v$.form.selected_student_ids.required.$invalid
                  "
                  class="invalid-feedback"
                >
                  This field is required
                </div>
                <div
                  v-if="reqResponse.errors['selected_student_ids']"
                  class="invalid-feedback"
                >
                  {{ reqResponse.errors["selected_student_ids"][0] }}
                </div>
              </template>
            </LazyLoadSelect>
          </div>

          <div v-if="gradeYears && form.limit_for === 'gradeyear'">
            <VSelect
              v-model="form.grade_years"
              :class="{
                'is-invalid':
                  v$.form.grade_years.$error ||
                  reqResponse.errors['grade_years']
              }"
              class="cs-select mb-3 ps__child--consume"
              multiple
              :clear-search-on-blur="
                () => {
                  return true
                }
              "
              :options="gradeYears"
              placeholder="Select grad years"
              :clearable="false"
              @open="getGradeYears"
            >
              <template #footer>
                <div
                  v-if="v$.form.$error && v$.form.grade_years.required.$invalid"
                  class="invalid-feedback"
                >
                  This field is required
                </div>
                <div
                  v-if="reqResponse.errors['grade_years']"
                  class="invalid-feedback"
                >
                  {{ reqResponse.errors["grade_years"][0] }}
                </div>
              </template>
            </VSelect>
          </div>
          <div v-if="form.limit_for === 'csv'" class="mb-3">
            <div class="d-flex align-items-start">
              <div class="form-group me-3 mb-0">
                <div class="cs-file-input">
                  <div class="upload-btn">
                    <i class="fi flaticon-upload me-1" /> UPLOAD CSV
                  </div>
                  <input
                    type="file"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        v$.form.csv_file.$error ||
                        reqResponse.errors['csv_file']
                    }"
                    @change="onFileUpload"
                  />
                  <div
                    v-if="v$.form.$error && v$.form.csv_file.required.$invalid"
                    class="invalid-feedback"
                  >
                    This field is required
                  </div>
                  <div
                    v-if="v$.form.$error && !v$.form.csv_file.fileValidator"
                    class="invalid-feedback"
                  >
                    The csv file must be a file of type: csv, txt.
                  </div>
                  <div
                    v-if="reqResponse.errors['csv_file']"
                    class="invalid-feedback"
                  >
                    {{ reqResponse.errors["csv_file"][0] }}
                  </div>
                </div>
              </div>

              <a href="/csv-samples/passLimitSample.csv" class="download-btn">
                <i class="fi flaticon-download me-1" /> SAMPLE CSV
              </a>
            </div>
            <p v-if="form.csv_file" class="mb-0 mt-2">
              <b>{{ form.csv_file.name ? form.csv_file.name : "" }}</b>
            </p>
          </div>

          <LazyLoadSelect
            :classes="[
              {
                'is-invalid':
                  v$.form.room.$error || reqResponse.errors['room_id']
              },
              'mb-3'
            ]"
            :is-multiple="false"
            :force-clear-cache="forceClearCache"
            :selected="form.room"
            type="locations_availability"
            placeholder="Location"
            @changed="
              (value) => {
                selectLocation(value)
              }
            "
          >
            <template #footer>
              <div
                v-if="v$.form.$error && v$.form.room.required.$invalid"
                class="invalid-feedback"
              >
                This field is required
              </div>
              <div
                v-if="reqResponse.errors['room_id']"
                class="invalid-feedback"
              >
                {{ reqResponse.errors["room_id"][0] }}
              </div>
            </template>
          </LazyLoadSelect>

          <CRow>
            <CCol sm="6">
              <DatePicker
                v-model="form.start_date"
                class="cs-date-picker"
                :masks="{ input: 'MM/DD/YYYY' }"
                placeholder="Start date"
                :min-date="today"
                :max-date="maxDate"
                :popover="{ visibility: 'click' }"
                @update:model-value="onStartDateChange"
              >
                <template #default="{ inputValue, togglePopover }">
                  <div class="input-icon-field" @click="togglePopover">
                    <div class="form-group">
                      <input
                        class="form-control"
                        :class="{
                          'is-invalid':
                            v$.form.start_date.$error ||
                            reqResponse.errors['from_date']
                        }"
                        placeholder="Select start date"
                        readonly
                        :value="inputValue"
                      />
                      <div
                        v-if="
                          v$.form.$error && v$.form.start_date.required.$invalid
                        "
                        class="invalid-feedback"
                      >
                        This field is required
                      </div>
                      <div
                        v-if="reqResponse.errors['from_date']"
                        class="invalid-feedback"
                      >
                        {{ reqResponse.errors["from_date"][0] }}
                      </div>
                    </div>
                    <span class="field-icon">
                      <i class="fi flaticon-calendar me-1" />
                    </span>
                  </div>
                </template>
              </DatePicker>
            </CCol>
            <CCol sm="6" class="ps-3 ps-sm-1">
              <DatePicker
                v-model="form.end_date"
                class="cs-date-picker"
                :masks="{ input: 'MM/DD/YYYY' }"
                placeholder="End date"
                :popover="{ visibility: 'click' }"
                :min-date="form.start_date ? form.start_date : today"
                :max-date="maxDate"
              >
                <template #default="{ inputValue, togglePopover }">
                  <div class="input-icon-field" @click="togglePopover">
                    <div class="form-group">
                      <input
                        class="form-control"
                        :class="{
                          'is-invalid':
                            v$.form.end_date.$error ||
                            reqResponse.errors['to_date']
                        }"
                        placeholder="Select end date"
                        readonly
                        :value="inputValue"
                      />
                      <div
                        v-if="
                          v$.form.$error && v$.form.end_date.required.$invalid
                        "
                        class="invalid-feedback"
                      >
                        This field is required
                      </div>
                      <div
                        v-if="reqResponse.errors['to_date']"
                        class="invalid-feedback"
                      >
                        {{ reqResponse.errors["to_date"][0] }}
                      </div>
                    </div>
                    <span class="field-icon">
                      <i class="fi flaticon-calendar me-1" />
                    </span>
                  </div>
                </template>
              </DatePicker>
            </CCol>
          </CRow>

          <VSelect
            v-model="form.limit_type"
            :class="{
              'is-invalid':
                v$.form.limit_type.$error || reqResponse.errors['type']
            }"
            class="cs-select mb-3 ps__child--consume"
            :options="limitationsTypes"
            placeholder="Choose Limitation Type"
            :clearable="false"
            label="label"
            :reduce="(item) => item.value"
            :disabled="isLimitationTypeDisabled"
          >
            <template #option="{ label, icon }">
              <div class="d-flex align-items-center">
                <span class="me-2">{{ label }}</span>
                <i v-if="icon" :class="icon" />
              </div>
            </template>
            <template #selected-option="{ label, icon }">
              <div class="d-flex align-items-center">
                <span class="me-2">{{ label }}</span>
                <i v-if="icon" :class="icon" />
              </div>
            </template>
            <template #footer>
              <div
                v-if="v$.form.$error && v$.form.limit_type.required.$invalid"
                class="invalid-feedback"
              >
                This field is required
              </div>
              <div v-if="reqResponse.errors['type']" class="invalid-feedback">
                {{ reqResponse.errors["type"][0] }}
              </div>
            </template>
          </VSelect>

          <div class="form-group form-actions mt-4 text-center">
            <div class="d-flex align-items-center justify-content-center">
              <div @click="submit()">
                <CLoadingButton
                  :spinner="isLoading"
                  :disabled="isLoading"
                  :loading="isLoading"
                  class="me-2 btn px-5 py-3 bg-gradient-blue text-white"
                >
                  Submit
                </CLoadingButton>
              </div>
              <router-link
                v-if="!isInView"
                class="text-graident-blue ms-3"
                to="/limits/limit-location-availability"
              >
                <div class="d-flex align-items-center" @click="goToFullMenu()">
                  <img
                    width="18"
                    class="m-0 me-2"
                    src="@/assets/images/icons/gradient-link.png"
                  />
                  <span class="text-graident-blue">Go to full menu</span>
                </div>
              </router-link>
            </div>
          </div>
        </CForm>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import { required, requiredIf, helpers } from "vuelidate/lib/validators"
import vSelect from "vue-select"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelectOld.vue"
const fileValidator = (value) =>
  !helpers.req(value) ||
  value.type == "text/csv" ||
  value.type == "text/plain" ||
  value.type == "application/vnd.ms-excel" //"text/plain"

import "vue-select/dist/vue-select.css"
import moment from "moment-timezone"
export default {
  name: "CreateLimitLocationMaxCapForm",
  components: { vSelect, LazyLoadSelect },
  props: ["isInView"],
  emits: ["createLocationRestriction", "closeModal"],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      isLoading: false,
      locationAvailabilityOptions: [
        { value: "student", label: "Selected Students" },
        { value: "gradeyear", label: "Grad Year Students" },
        { value: "csv", label: "CSV Student List" }
      ],
      limitationsTypes: [
        {
          value: "access_denied",
          label: "Access Denied",
          icon: "flaticon-forbidden text-red"
        },
        {
          value: "members_only",
          label: "Members Only",
          icon: "flaticon-profile-user text-accent-blue-dark"
        }
      ],
      form: {
        limit_for: null,
        room: null,
        csv_file: null,
        grade_years: null,
        selected_student_ids: null,
        start_date: null,
        end_date: null,
        limit_type: null
      },
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      },
      gradeYears: [],
      isLimitationTypeDisabled: false,
      forceClearCache: false
    }
  },
  validations: {
    form: {
      room: {
        required
      },
      limit_for: {
        required
      },
      start_date: {
        required
      },
      end_date: {
        required
      },
      limit_type: {
        required
      },
      selected_student_ids: {
        required: requiredIf((model) => {
          return model.limit_for && model.limit_for === "student"
        })
      },
      grade_years: {
        required: requiredIf((model) => {
          return model.limit_for && model.limit_for === "gradeyear"
        })
      },
      csv_file: {
        required: requiredIf((model) => {
          return model.limit_for && model.limit_for === "csv"
        }),
        fileValidator
      }
    }
  },
  computed: {
    isValid() {
      return !this.v$.form.$invalid
    },
    today() {
      return moment().format("MMM DD YYYY")
    },
    maxDate() {
      return this.$helpers.maxSchoolYearDate()
    }
  },
  methods: {
    submit() {
      this.resetResponseMessages()
      if (this.isValid) {
        let formData = null
        const inputsData = {
          room_id: this.form.room ? this.form.room.value.id : this.form.room,
          limit_for: this.form.limit_for,
          csv_file: this.form.csv_file,
          from_date: this.$helpers.currTzDate(this.form.start_date),
          to_date: this.$helpers.currTzDate(this.form.end_date),
          type: this.form.limit_type
        }
        if (this.form.selected_student_ids) {
          inputsData.selected_student_ids = JSON.stringify(
            this.form.selected_student_ids.map((el) => el.value.id)
          )
        }
        if (this.form.grade_years) {
          inputsData.grade_years = JSON.stringify(this.form.grade_years)
        }
        if (this.form.limit_for === "csv") {
          formData = new FormData()
          for (const key in inputsData) {
            if (Object.hasOwnProperty.call(inputsData, key)) {
              const element = inputsData[key]
              formData.append(key, element)
            }
          }
        }
        this.isLoading = true
        this.$store
          .dispatch(
            "rooms/createRoomRestriction",
            formData ? formData : inputsData
          )
          .then(() => {
            this.resetForm()
            this.setSuccessResponse("Successfully created.")
            this.isLoading = false
            setTimeout(() => {
              this.$emit("createLocationRestriction")
              this.resetResponseMessages()
            }, 1200)
            this.forceClearCache = true
          })
          .catch((err) => {
            const response = err.response.data
            this.setErrorResponse(response.message, response.errors)
            this.isLoading = false
          })
      } else {
        this.v$.$touch()
      }
    },
    getGradeYears() {
      this.$store.dispatch("passBlocks/getGradeYears").then((result) => {
        // let startYear = result.data.data.startYear;
        // let endYear = result.data.data.endYear;
        // this.gradeYears = [...Array(endYear - startYear + 1).keys()]. map(i => i + startYear);
        this.gradeYears = result.data.data
      })
    },
    resetOptinalInputs() {
      this.form.csv_file = null
      this.form.grade_years = null
      this.form.selected_student_ids = null
    },
    onFileUpload(event) {
      const files = event.target.files
      if (files && files.length) {
        this.form.csv_file = files[0]
      }
      event.target.value = ""
    },
    onStartDateChange() {
      this.form.end_date =
        this.form.end_date && this.form.end_date < this.form.start_date
          ? this.form.start_date
          : this.form.end_date
    },
    selectLocation(currentLocation) {
      this.resetLocationsAndLimitType()
      this.form.room = currentLocation
      if (currentLocation.restriction != null) {
        this.form.limit_type = currentLocation.restriction
        this.isLimitationTypeDisabled = true
      }
    },
    resetLocationsAndLimitType() {
      this.isLimitationTypeDisabled = false
      this.form.limit_type = null
    },
    resetForm() {
      this.v$.$reset()
      this.form = {
        limit_for: null,
        room: null,
        csv_file: null,
        grade_years: null,
        selected_student_ids: null,
        start_date: null,
        end_date: null,
        limit_type: null
      }
    },
    resetResponseMessages() {
      this.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    },
    setSuccessResponse(message) {
      this.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    },
    setErrorResponse(message, errors) {
      this.reqResponse = {
        type: "danger",
        message: message ? message : "Someting went wrong!",
        errors: errors ? errors : {}
      }
    },
    refreshForm() {
      this.resetForm()
      this.resetResponseMessages()
    },
    goToFullMenu() {
      this.$emit("closeModal")
    }
  }
}
</script>
