<template>
  <div v-if="!basic" class="dashboard-building-counters">
    <div class="d-flex justify-content-center">
      <div class="dashboard-building-counters-main-box">
        <div class="label">Active passes / Limit</div>
        <div class="dashboard-building-counters-main-box-passes">
          <span class="active-passes">{{ allActivePassesLength }}</span
          ><span class="total-passes">/{{ activePassLimit }}</span>
          <i class="ri-timer-line icon ms-1"></i>
        </div>
        <div class="settings">
          <span class="settings-number">{{ totalPassesLength }}</span>
          <span class="settings-text-purple ms-1">Building passes</span>
          <i
            @click="openLimitActivePassForm"
            class="ri-settings-2-line settings-icon ms-1 cursor-pointer"
          ></i>
        </div>
      </div>
      <div class="dashboard-building-counters-secondary-box">
        <div>
          <i class="ri-building-line icon"></i>
          <circle-progress
            :percent="
              activePassLimit == '-'
                ? 0
                : allActivePassesLength == 0
                ? 0
                : ((allActivePassesLength / activePassLimit) * 100) > 100
                ? 100
                : ((allActivePassesLength / activePassLimit) * 100)
            "
            border-width="4"
            border-bg-width="1"
            fill-color="#008566"
            size="75"
          ></circle-progress>
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-building-basic-counters" v-else>
    <div class="dashboard-building-basic-counters-box">
      <div class="dashboard-building-basic-counters-box-passes">
        <span class="active-passes">{{ allActivePassesLength }}</span
        ><span class="total-passes">/{{ totalPassesLength }}</span>
        <i class="ri-timer-line icon ms-1"></i>
      </div>
      <div class="label ms-1">Active passes / Limit</div>
    </div>
  </div>
</template>

<script>
import LimitActivePassForm from "@/v3components/Forms/LimitActivePassForm.vue"
import { computed, inject, onMounted } from "vue"
import { useStore } from "vuex"
import "vue3-circle-progress/dist/circle-progress.css"
import CircleProgress from "vue3-circle-progress"

export default {
  name: "BuildingPasses",
  components: {
    CircleProgress
  },
  props: {
    basic: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore()

    const modal = inject("modal")

    const activePassLimits = computed(
      () => store.getters["activePass/activePassLimits"]
    )
    const totalPassesLength = computed(
      () => store.getters["dashboardCounters/totalPassesLength"]
    )
    const allActivePassesLength = computed(
      () => store.getters["dashboardCounters/allActivePassesLength"]
    )

    const activePassLimit = computed(() => {
      return activePassLimits.value !== null &&
        activePassLimits.value.limit !== "-"
        ? activePassLimits.value.limit
        : "-"
    })

    onMounted(() => {
      if (!activePassLimits.value) {
        store.dispatch("activePass/getActivePassLimits")
      }
    })

    const openLimitActivePassForm = () => {
      modal.open(LimitActivePassForm, {
        size: "sm",
        title: "Limit Active Passes",
        props: {}
      })
    }

    return {
      activePassLimits,
      totalPassesLength,
      allActivePassesLength,
      activePassLimit,
      openLimitActivePassForm
    }
  }
}
</script>
