<template>
  <div class="visitor-parent-search" ref="dynamicSelect">
    <div class="input-container" @click="toggleOptions">
      <input
        class="input"
        :class="{ open: showOptions }"
        type="text"
        :placeholder="placeholder"
        :value="inputValue"
        @input="onInput"
      />
      <span class="chevron" :class="{ open: showOptions }">
        <i class="ri-arrow-down-s-line"></i>
      </span>
    </div>
    <ul v-if="showOptions" class="options ps__child--consume">
      <template v-if="options.length">
        <li
          v-for="option in options"
          :key="option.value"
          @click="selectOption(option)"
          :class="{
            option: !hasOptionSlot
          }"
        >
          <slot v-if="hasOptionSlot" name="option" :value="option"></slot>
          <template v-else>
            {{ option.label }}
          </template>
        </li>
      </template>
      <template v-else>
        <li class="no-results">Sorry, no matching options</li>
      </template>
    </ul>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed } from "vue"

export default {
  name: "VisitorParentSearch",
  props: {
    modelValue: {
      type: Object,
      default: null
    },
    options: {
      type: Array,
      default: () => [],
      validator: (options) => {
        return options.every((option) => {
          return option.label && option.value
        })
      }
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  emits: ["update:modelValue", "search"],
  setup(props, { emit, slots }) {
    const inputValue = ref("")
    const showOptions = ref(false)
    const dynamicSelect = ref(null)

    const hasOptionSlot = computed(() => !!slots.option)

    const onInput = (event) => {
      inputValue.value = event.target.value
      showOptions.value = true
      emit("search", inputValue.value)
    }

    const selectOption = (option) => {
      inputValue.value = option.label
      emit("update:modelValue", option.value)
      emit("search", inputValue.value)
      showOptions.value = false
    }

    const toggleOptions = () => {
      showOptions.value = !showOptions.value
    }

    const handleClickOutside = (event) => {
      if (dynamicSelect.value && !dynamicSelect.value.contains(event.target)) {
        showOptions.value = false
      }
    }

    onMounted(() => {
      document.addEventListener("click", handleClickOutside)
    })

    onBeforeUnmount(() => {
      document.removeEventListener("click", handleClickOutside)
    })

    return {
      hasOptionSlot,
      inputValue,
      showOptions,
      onInput,
      selectOption,
      toggleOptions,
      dynamicSelect
    }
  }
}
</script>

<style scoped></style>
