<template>
  <div v-if="getStatusText()" class="detention-status-pill text-center">
    {{ getStatusText() }}
  </div>
</template>
<script>
export default {
  name: "DetentionStatusPill",
  props: {
    status: {
      type: String
    }
  },
  setup(props) {
    const getStatusText = () => {
      const texts = {
        ENDED: "Ended",
        MISSED: "Missed",
        SCHEDULED: "Scheduled",
        CANCELED: "Canceled",
        PENDING: "Pending",
        FINISHED: "Finished"
      }
      return texts[props.status] || ""
    }
    return {
      getStatusText
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/v3scss/_variables";
.detention-status-pill {
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  border-color: $green-darken-30;
  padding: 3px;
  color: $gray-darken-40;
}
</style>
