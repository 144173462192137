<template>
  <div
    class="notification-holder"
    :style="`background-color:${
      notification.seen_at ? '#e3e3e3' : colors[notification.notification_type]
    }`"
  >
    <div v-if="notification.notification_type != 'time_change'">
      <span
        class="notification-text-holder"
        :style="`background-color:${
          notification.seen_at ? '#fff' : colors[notification.notification_type]
        }; color:${notification.seen_at ? '#000' : '#fff '}`"
        >CANCELED</span
      >Your pass to
      <strong>{{
        notification.appointment_pass.to.name
          ? notification.appointment_pass.to.name
          : notification.appointment_pass.to.first_name +
            " " +
            notification.appointment_pass.to.last_name
      }}</strong>
      at<strong style="word-break: keep-all; white-space: nowrap">
        {{
          $helpers.transformDate(
            notification.appointment_pass.for_date,
            "h:mm A"
          )
        }}</strong
      >
      for
      {{ $helpers.transformDate(notification.appointment_pass.for_date) }}
      has been
      {{
        notification.notification_type == "time_change"
          ? "moved"
          : notification.notification_type
      }}
      by
      <strong
        >{{
          notification.updated_by
            ? notification.updated_by.first_name +
              " " +
              notification.updated_by.last_name
            : "Somebody"
        }}.</strong
      >
    </div>
    <div v-if="notification.notification_type == 'time_change'">
      <span
        class="notification-text-holder"
        :style="`background-color:${
          notification.seen_at ? '#fff' : colors[notification.notification_type]
        };`"
        >EDITED</span
      >Your pass to
      <strong>{{
        notification.appointment_pass.to.name
          ? notification.appointment_pass.to.name
          : notification.appointment_pass.to.first_name +
            " " +
            notification.appointment_pass.to.last_name
      }}</strong>
      at<strong style="word-break: keep-all; white-space: nowrap">
        {{ $helpers.transformDate(notification.old_time, "h:mm A") }}</strong
      >
      for {{ $helpers.transformDate(notification.old_time) }} has been
      <strong
        >moved at
        {{ $helpers.transformDate(notification.new_time, "h:mm A") }}
        for {{ $helpers.transformDate(notification.new_time) }}</strong
      >
      by
      <strong
        >{{
          notification.updated_by
            ? notification.updated_by.first_name +
              " " +
              notification.updated_by.last_name
            : "Somebody"
        }}.</strong
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "StudentAppointmentNotification",
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  setup() {
    const colors = {
      created: "#4a7cab",
      canceled: "#8a8fdf",
      time_change: "#efd4bc"
    }

    return { colors }
  }
}
</script>
