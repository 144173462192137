<template>
  <div class="v3-helper-box mt-3">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {}
}
</script>

<style></style>
