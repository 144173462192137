<template>
  <div
    class="d-flex justify-content-center text-center align-content-start flex-wrap gap-2 visitor-row-wrapper"
  >
    {{ destination || "-" }}
  </div>
</template>

<script>
import { computed } from "vue"

export default {
  name: "VisitorDestinationColumn",
  props: {
    visitor: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const isSudentPickUpOrDropOff = computed(() => {
      const studentLookUpValues = ["student pick up", "student drop off"]
      return (
        studentLookUpValues.includes(
          props?.visitor?.lookup_value?.trim()?.toLowerCase()
        ) || false
      )
    })

    const destDescText = computed(() => {
      return props?.visitor?.destination_description || ""
    })

    const destText = computed(() => {
      return props?.visitor?.destination || ""
    })

    const destination = computed(() => {
      if (isSudentPickUpOrDropOff.value) {
        return ""
      }
      return typeof Number(props.visitor.destination) === "number"
        ? destDescText.value
        : destText.value + " - " + destDescText.value
    })

    return {
      destination
    }
  }
}
</script>

<style scoped></style>
