<template>
  <div data-test-id="student-create-pass-form">
    <Label class="mt-2" required> Departing From: </Label>
    <LazyLoadSelect
      data-test-id="stu-pass-fromid"
      v-model="state.form.from"
      :selected="state.form.from"
      :invalid-feedback="errors.from"
      type="combined"
      :adult-pass-request-disabled="true"
      :force-clear-cache="true"
      placeholder="Teacher/Location"
      @changed="
        (value) => {
          state.form.from = value
        }
      "
    >
    </LazyLoadSelect>
    <InfoBox class="danger mt-2 mb-2" v-if="state.serverErrors.from_id">
      {{ state.serverErrors.from_id[0] }}
    </InfoBox>

    <FavoriteCarousel
      data-test-id="student-from-favorites"
      :key="'departing'"
      :selected="state.form.from"
      :is-editable="true"
      :action="
        (elem) => {
          state.form.from = elem
        }
      "
      help-center-key="to_add_favorite_teacher_txt"
      :isvalid="errors.from"
      class="mb-3"
      section="departing"
    />
    <Label class="mt-2" required> Destination: </Label>
    <LazyLoadSelect
      data-test-id="stu-pass-toid"
      v-model="state.form.to"
      :invalid-feedback="errors.to"
      :is-multiple="false"
      :selected="state.form.to"
      type="combined"
      :adult-pass-request-disabled="true"
      placeholder="Teacher/Location"
      :force-clear-cache="true"
      @changed="(value) => onChangeEvent(value)"
    >
    </LazyLoadSelect>
    <InfoBox v-if="destinationUnavailability" class="danger mt-2 mb-2">
      {{
        destinationUnavailability.unavailable_type == "App\\Models\\Room"
          ? "This room is unavailable until"
          : "This adult is unavailable until"
      }}
      <span
        class="mx-1"
        v-html="
          $helpers.getHTMLDateTime(
            destinationUnavailability.to_date,
            'MMMM D, YYYY',
            'h:mm A'
          ) + '.'
        "
      />
      <span v-if="destinationUnavailability.comment"
        >Note: {{ destinationUnavailability.comment }}</span
      >
    </InfoBox>
    <InfoBox class="danger mt-2 mb-2" v-if="state.serverErrors.to_id">
      {{ state.serverErrors.to_id[0] }}
    </InfoBox>

    <FavoriteCarousel
      data-test-id="student-to-favorites"
      :key="'destination'"
      :selected="state.form.to"
      :is-editable="true"
      :show-unavailables="true"
      :action="
        (elem) => {
          onChangeEvent(elem)
        }
      "
      :isvalid="errors.to || !state.isCapacityReached"
      section="destination"
    />

    <InfoBox v-if="toCapacityReached" class="danger mt-2 mb-2">
      Maximum capacity of the location has been reached. Please try again when a
      student returns.</InfoBox
    >

    <div
      v-if="
        state.form &&
        state.form.to &&
        state.form.to.value &&
        state.form.to.value.comment_type != 'Hidden'
      "
      class="mb-3"
    >
      <Label> Reason </Label>
      <InputField
        data-test-id="stu-pass-comment"
        v-model="state.form.reason"
        :invalid-feedback="errors.reason"
        max-chars="250"
        :rows="5"
        class="w-full"
        type="textarea"
        :placeholder="
          state.form.to.value.comment_type == 'Optional'
            ? 'Reason for pass (optional)'
            : 'Reason for pass (required)'
        "
      />
      <InfoBox v-if="state.serverErrors.comment" class="danger mt-2"
        >{{ state.serverErrors.comment[0] }}
      </InfoBox>
    </div>

    <InfoBox
      v-if="state.serverRes"
      data-test-id="create-pass-form-alert"
      class="mt-3"
      :class="{ danger: Object.keys(state.serverErrors).length }"
      :title="state.serverRes.title"
      >{{ state.serverRes.message }}</InfoBox
    >

    <div class="form-group form-actions mt-2 text-center">
      <div
        data-test-id="create-pass-form-submit"
        class="d-inline"
        @click="submit()"
      >
        <LoadingButton :is-loading="state.isLoading" class="mt-2" rounded solid>
          Submit
        </LoadingButton>
      </div>
    </div>
  </div>
</template>

<script>
import FavoriteCarousel from "@/v3components/FavoriteCarousel.vue"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect.vue"
import Label from "@/v3components/shared/Form/Label.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import { useStore } from "vuex"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import { reactive, computed } from "vue"
import { helpers, required, requiredIf } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"
import { useRouter } from "vue-router"

export default {
  name: "CreatePassForm",
  components: {
    LazyLoadSelect,
    FavoriteCarousel,
    Label,
    InputField,
    LoadingButton,
    InfoBox
  },
  setup() {
    const store = useStore()

    const router = useRouter()

    const state = reactive({
      isLoading: false,
      isCapacityReached: false,
      form: {
        from: [],
        to: [],
        reason: null
      },
      serverErrors: {},
      serverRes: null
    })

    const toCapacityReached = computed(() => {
      return state.form && state.form.to && state.isCapacityReached
    })

    const destinationUnavailability = computed(() => {
      return state.form.to && state.form.to.value
        ? state.form.to.value.unavailability
        : null
    })

    const validationMessages = {
      required: "This field is required",
      requiredReason: "Reason for pass is required",
      notSameAsFrom: "Start point and destination point should not be the same."
    }

    const validations = {
      form: {
        from: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        to: {
          available: () => {
            if (
              state.form.to &&
              state.form.to.value &&
              !state.form.to.value.unavailability
            ) {
              return true
            }
          },
          notSameAsFrom: helpers.withMessage(
            validationMessages.notSameAsFrom,
            () => {
              if (
                !(state.form.from && state.form.from.value) &&
                state.form.to &&
                state.form.to.value
              ) {
                return true
              }
              if (
                !(state.form.from && state.form.from.value) ||
                !(state.form.to && state.form.to.value)
              ) {
                return false
              }
              if (state.form.from.value.type != state.form.to.value.type) {
                return true
              }

              return state.form.from.value.id != state.form.to.value.id
            }
          ),
          required: helpers.withMessage(validationMessages.required, required)
        },
        reason: {
          required: helpers.withMessage(
            validationMessages.requiredReason,
            requiredIf(() => {
              if (state.form.to && state.form.to.value) {
                return state.form.to.value.comment_type == "Optional"
                  ? false
                  : state.form.to.value.comment_type == "Hidden"
                    ? false
                    : true
              }
            })
          )
        }
      }
    }

    const v$ = useVuelidate(validations.form, state.form)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })
      return errorObj
    })

    const onChangeEvent = (value) => {
      state.form.to = value
      if (value.value.type === "App\\Models\\Room") {
        store
          .dispatch("rooms/getCapacityReached", value.value.id)
          .then((response) => {
            if (response.data) {
              const data = response.data.data
              state.isCapacityReached =
                data.limit !== "-" ? data.capacity_reached : false
            }
          })
      }

      store
        .dispatch("rooms/getUnavailability", {
          id: value.value.id,
          type: value.value.type
        })
        .then((response) => {
          if (response.data.data) {
            state.form.to.value.unavailability = response.data.data
          }
        })
    }

    const submit = () => {
      setResponseInfoBox()
      if (isValid.value) {
        const pass = {
          from_id: state.form.from.value.id,
          from_type: state.form.from.value.type,
          to_id: state.form.to.value.id,
          to_type: state.form.to.value.type,
          comment: state.form.reason
        }
        state.isLoading = true
        store
          .dispatch("passes/createPass", pass)
          .then((response) => {
            state.serverErrors = {}
            setResponseInfoBox("Success", "Successfully created.")

            setTimeout(() => {
              setResponseInfoBox()
              router.push("/passes/activepass")
              state.isLoading = false
            }, 500)

            if (response.data && response.data.data) {
              const data = response.data.data
              const params = {
                from_id: data.from_id,
                from_type: data.from_type,
                to_id: data.to_id,
                to_type: data.to_type
              }
              store.dispatch("passes/getActiveFlags", params)
            }
          })
          .catch((err) => {
            state.isLoading = false
            const response = err?.response?.data ? err.response.data : err
            state.serverErrors = response?.errors ? response.errors : {}
            setResponseInfoBox("Error", response?.message)
          })
      } else {
        v$.value.$touch()
      }
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    return {
      state,
      destinationUnavailability,
      toCapacityReached,
      onChangeEvent,
      submit,
      errors
    }
  }
}
</script>

<style></style>
