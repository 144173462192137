<template>
  <BaseButton
    :disabled="isLoading"
    class="v3-loading-button"
    :class="{ loading: isLoading }"
    :prepend-icon="isLoading ? 'ri-loader-4-line' : ''"
    v-bind="{ appendIcon, rounded, solid }"
    ><slot></slot
  ></BaseButton>
</template>

<script>
import BaseButton from "./BaseButton.vue"
export default {
  components: { BaseButton },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  extends: BaseButton
}
</script>

<style></style>
