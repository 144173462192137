<template>
  <div
    class="v3-custom-select ps__child--consume"
    :class="{
      'is-invalid': invalidFeedback || isInvalid
    }"
  >
    <VSelect
      :model-value="modelValue"
      v-bind="{
        selected,
        multiple,
        options,
        clearable,
        placeholder,
        autoscroll,
        closeOnSelect,
        filterable,
        reduce,
        selectable,
        searchable,
        title,
        disabled,
        deselectFromDropdown,
        clearCacheOnBlur
      }"
      @search="onSearch"
      @update:model-value="onChange"
      @open="onOpenSelect"
      @close="onCloseSelect"
    >
      <template v-if="customTemplate" #option="option">
        <slot name="custom-template" :option="option"></slot>
      </template>
      <template v-if="selectOnlyNames" #selected-option="{ label }">
        <span class="selected-option-label">{{ splitText(label) }}</span>
      </template>
      <slot name="list-footer"></slot>
      <slot name="footer"></slot>
      <slot name="no-options"></slot>
      <template #open-indicator="{ attributes }">
        <span class="options-length-label" v-if="selectedOptions > 3"
          >+{{ selectedOptions - 3 }} more</span
        >
        <i :class="appendIcon" v-bind="attributes"></i>
      </template>
    </VSelect>
    <slot name="content"></slot>
    <div v-if="invalidFeedbackMessage" class="invalid-message text-start">
      {{ invalidFeedbackMessage }}
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
export default {
  // For adding more props or functionalities for v-select check its own: https://vue-select.org/api/props.html
  props: {
    selected: Object,
    multiple: Boolean,
    options: Array,
    clearable: Boolean,
    placeholder: String,
    autoscroll: Boolean,
    closeOnSelect: Boolean,
    filterable: Boolean,
    reduce: Function,
    selectable: Function,
    searchable: { type: Boolean, default: true },
    title: String,
    disabled: Boolean,
    deselectFromDropdown: Boolean,
    modelValue: [String, Object, Array],
    invalidFeedback: [String, Array],
    isInvalid: Boolean,
    readonly: { type: Boolean, default: false },
    appendIcon: {
      type: String,
      default: "ri-arrow-down-s-line"
    },
    clearCacheOnBlur: Function,
    selectOnlyNames: {
      type: Boolean,
      default: false
    },
    customTemplate: {
      type: Boolean,
      default: false
    }
  },
  emits: ["search", "update:modelValue", "open", "close"],
  setup(props, { emit }) {
    const onSearch = (value) => {
      emit("search", value)
    }
    const onChange = (value) => {
      if (props.readonly) return
      emit("update:modelValue", value)
    }

    const onOpenSelect = (value) => {
      emit("open", value)
    }

    const onCloseSelect = (value) => {
      emit("close", value)
    }

    const selectedOptions = computed(() => {
      return props.multiple &&
        props.modelValue &&
        Array.isArray(props.modelValue)
        ? props.modelValue.length
        : ""
    })

    const invalidFeedbackMessage = computed(() => {
      return props.invalidFeedback && Array.isArray(props.invalidFeedback)
        ? props.invalidFeedback[0]
        : props.invalidFeedback
    })

    const splitText = (text) => {
      const textArray = text.split(",")
      return textArray[0]
    }

    return {
      onSearch,
      onChange,
      onOpenSelect,
      onCloseSelect,
      selectedOptions,
      invalidFeedbackMessage,
      splitText
    }
  }
}
</script>

<style></style>
