import Echo from "laravel-echo"
import store from "@/store/index"

let kioskMainEchoInstance = null
let kioskActivePassEchoInstance = null

const initKioskModeSockets = () => {
  let EchoInstance = null

  if (import.meta.env.VITE_SOCKET_PROVIDER == "SOKETI") {
    window.Pusher = require("pusher-js")
    EchoInstance = new Echo({
      broadcaster: "pusher",
      key: import.meta.env.VITE_PUSHER_APP_KEY,
      wsHost: import.meta.env.VITE_PUSHER_WS_HOST,
      wsPort: import.meta.env.VITE_PUSHER_WS_PORT,
      cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
      forceTLS: true,
      disableStats: true,
      enabledTransports: ["ws", "wss"]
    })
  } else {
    window.io = require("socket.io-client")
    EchoInstance = new Echo({
      broadcaster: "socket.io",
      host: import.meta.env.VITE_WS_HOST,
      encrypted: import.meta.env.VITE_WS_ENCRYPTED === "true",
      transports: ["websocket"]
    })
  }

  const directLoginToken = localStorage.getItem("kioskDirectLoginToken")

  EchoInstance.channel(`kiosks.${directLoginToken}`).listen(
    "Tardy\\KioskLatePassCreationToggled",
    (payload) => {
      store.commit("kiosks/SET_ACTIVE_KIOSK_LATES", payload.values)
    }
  )

  kioskMainEchoInstance = EchoInstance
}
const initKioskModePreferencesChannel = () => {
  kioskMainEchoInstance
    .channel(`user.preferences.kiosk`)
    .listen("ProfilePreferencesKioskEvent", () => {
      store.dispatch("favorites/getFavorites", { clearCacheEntry: true })
    })
}
const initKioskModePassSockets = (activePassId) => {
  let EchoInstance = null

  if (import.meta.env.VITE_SOCKET_PROVIDER == "SOKETI") {
    window.Pusher = require("pusher-js")
    EchoInstance = new Echo({
      broadcaster: "pusher",
      key: import.meta.env.VITE_PUSHER_APP_KEY,
      wsHost: import.meta.env.VITE_PUSHER_WS_HOST,
      wsPort: import.meta.env.VITE_PUSHER_WS_PORT,
      cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
      forceTLS: false,
      encrypted: true,
      enabledTransports: ["ws", "wss"],
      authEndpoint: `${import.meta.env.VITE_AUTH_URL}/api/v5/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("kioskAccessToken")
        }
      }
    })
  } else {
    window.io = require("socket.io-client")
    EchoInstance = new Echo({
      broadcaster: "socket.io",
      host: import.meta.env.VITE_WS_HOST,
      authEndpoint: `${import.meta.env.VITE_AUTH_URL}/broadcasting/auth`,
      encrypted: import.meta.env.VITE_WS_ENCRYPTED === "true",
      auth: {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("kioskAccessToken")
        }
      },
      transports: ["websocket"]
    })
  }

  EchoInstance.private(`kiosks.user.${activePassId}`).listen(
    "PassApproved",
    (payload) => {
      if (payload && payload.pass) {
        store.commit("kiosks/SET_ACTIVE_PASS", payload.pass)
      }
    }
  )

  kioskActivePassEchoInstance = EchoInstance
}
const destroyKioskModePassSockets = () => {
  kioskActivePassEchoInstance.disconnect()
}

export {
  initKioskModeSockets,
  initKioskModePreferencesChannel,
  initKioskModePassSockets,
  destroyKioskModePassSockets
}
