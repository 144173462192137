import helpers from "../../helpers"
import { apiV6nocache } from "../../store/modules/axiousWithCache"
import moment from "moment-timezone"
const state = {
  totalPasses: [],
  myPasses: [],
  waitingApproval: [],
  aptPassesCounters: new Map()
}
const getters = {
  totalPassesLength: (state) => state.totalPasses.length,
  allActivePassesLength: (state) =>
    state.totalPasses.filter(
      (pass) =>
        (pass.child && pass.child.pass_status === 1) || pass.pass_status === 1
    ).length,
  myPassesLength: (state) => state.myPasses.length,
  myActivePassesLength: (state) =>
    state.myPasses.filter(
      (pass) =>
        (pass.child && pass.child.pass_status === 1) || pass.pass_status === 1
    ).length,
  flaggedPassesLength: (state) =>
    state.myPasses.filter(
      (pass) =>
        pass.badge_flags.extended ||
        pass.badge_flags.system_completed ||
        pass.badge_flags.min_time
    ).length,
  waitingApprovalLength: (state) => state.waitingApproval.length,
  appTodays: (state) => {
    const allAptPasses = Array.from(state.aptPassesCounters.values())
    const filteredAptPasses = allAptPasses.filter((pass) => {
      return helpers.filterAptPassesByTab(pass, "appointments_today")
    })
    return filteredAptPasses.length
  },
  appTodaysWaiting: (state) => {
    const allAptPasses = Array.from(state.aptPassesCounters.values())
    const filteredAptPasses = allAptPasses.filter((pass) => {
      return helpers.filterAptPassesByTab(pass, "appointments_awaiting")
    })
    return filteredAptPasses.length
  },
  appFeatureWaiting: (state) => {
    const allAptPasses = Array.from(state.aptPassesCounters.values())
    const filteredAptPasses = allAptPasses.filter((pass) => {
      return helpers.filterAptPassesByTab(pass, "appointments_future_awaiting")
    })
    return filteredAptPasses.length
  },
  aptPassesCounters: (state) => Array.from(state.aptPassesCounters.values())
}
const mutations = {
  SET_PASSES(state, data) {
    state.waitingApproval = data.filter(
      (pass) =>
        pass.badge_flags.waiting_approval && helpers.doesCountMyPass(pass)
    )
    state.waitingApproval = state.waitingApproval.filter(
      (e) => !(e.badge_flags.missed || e.badge_flags.canceled)
    )
    state.totalPasses = data.filter(
      (pass) => !pass.badge_flags.waiting_approval && !pass.badge_flags.missed
    )
    state.myPasses = state.totalPasses.filter((pass) =>
      helpers.doesCountMyPass(pass)
    )
  },
  UPDATE_PASS(state, pass) {
    pass.badge_flags = helpers.getBadgeFlags(pass)
    pass.total_time = helpers.getTotalTime(pass)
    pass = helpers.setUserAssignedRoomsPerPass(pass)

    if (pass.approved_at) {
      let shouldAdd = true
      state.totalPasses = state.totalPasses.map((e) => {
        if (e.id === pass.id) {
          shouldAdd = false
          return pass
        }
        return e
      })
      if (shouldAdd) {
        state.totalPasses.push(pass)
      }
      state.myPasses = state.totalPasses.filter((pass) =>
        helpers.doesCountMyPass(pass)
      )
      state.waitingApproval = state.waitingApproval.filter(
        (e) => e.id != pass.id
      )
    } else {
      if (helpers.doesCountMyPass(pass)) {
        let shouldAdd = true

        state.waitingApproval = state.waitingApproval.map((e) => {
          if (e.id === pass.id) {
            shouldAdd = false
            return pass
          }
          return e
        })
        if (shouldAdd) {
          state.waitingApproval.push(pass)
        }

        state.waitingApproval = state.waitingApproval.filter(
          (e) => !(e.badge_flags.missed || e.badge_flags.canceled)
        )
      }
    }
  },
  UPDATE_APP_COUNTERS(state, passesCollection) {
    passesCollection.forEach((passObj) => {
      passObj = helpers.aptPassStatus(passObj)
      state.aptPassesCounters.set(passObj.id, passObj)
    })
    state.aptPassesCounters = new Map(state.aptPassesCounters)
  },
  DELETE_APP_COUNTERS_BY_ID(state, ids) {
    ids.forEach((id) => state.aptPassesCounters.delete(id))
    state.aptPassesCounters = new Map(state.aptPassesCounters)
  }
}
const actions = {
  getPassesForCounters(context) {
    const params = {
      type: "all", //my, all, appointments
      status: "both" // both, ended, active
    }
    apiV6nocache
      .get("/admin/users/dashboard/filter", {
        params: params
      })
      .then((response) => {
        let data = response.data.data
        if (data) {
          data = data.map((e) => {
            if (helpers.isPassExceedingSystemEndTime(e)) {
              const completeTime = helpers.getPassEndTime(e)
              if (e.child) {
                e.child.pass_status = 0
                e.child.system_completed = 1
                e.child.completed_at = completeTime
              } else {
                e.pass_status = 0
                e.system_completed = 1
                e.completed_at = completeTime
              }
            }
            e.badge_flags = helpers.getBadgeFlags(e)
            e.total_time = helpers.getTotalTime(e)
            e = helpers.setUserAssignedRoomsPerPass(e)
            return e
          })
          context.commit("SET_PASSES", data)
        }
      })
  },
  getAppointmentsCounters(context) {
    const todays = {
      page: 1,
      per_page: 1200,
      search_query: "",
      sort: null,
      recurrence: false,
      quick_filter: "todays_dashboard",
      between_dates: [
        moment().format("MM/DD/YYYY").toString(),
        moment().format("MM/DD/YYYY").toString()
      ]
    }
    apiV6nocache
      .get(`/admin/passes/appointments/filter`, {
        params: todays
      })
      .then((response) => {
        if (response.data.data) {
          context.commit("UPDATE_APP_COUNTERS", response.data.data)
        }
      })
    const todaysWaiting = {
      page: 1,
      per_page: 1200,
      search_query: "",
      sort: null,
      quick_filter: "awaiting",
      recurrence: false,
      between_dates: [
        moment().format("MM/DD/YYYY").toString(),
        moment().format("MM/DD/YYYY").toString()
      ]
    }
    apiV6nocache
      .get(`/admin/passes/appointments/filter`, {
        params: todaysWaiting
      })
      .then((response) => {
        if (response.data.data) {
          context.commit("UPDATE_APP_COUNTERS", response.data.data)
        }
      })
    const featureWaiting = {
      page: 1,
      per_page: 1200,
      search_query: "",
      sort: null,
      quick_filter: "awaiting",
      recurrence: false,
      between_dates: [
        moment().add(1, "days").format("MM/DD/YYYY").toString(),
        moment().add(1, "years").format("MM/DD/YYYY").toString()
      ]
    }
    apiV6nocache
      .get(`/admin/passes/appointments/filter`, {
        params: featureWaiting
      })
      .then((response) => {
        if (response.data.data) {
          context.commit("UPDATE_APP_COUNTERS", response.data.data)
        }
      })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
