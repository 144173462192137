<template>
  <div>
    <CContainer class="users-view-container">
      <!-- Modals -->

      <CModal
        backdrop="static"
        :visible="state.showAddUserWarningModal"
        :close-on-backdrop="false"
      >
        <template #header-wrapper>
          <div></div>
        </template>
        <div class="p-3">
          <CAlert class="mx-2 no-icon" :show="true">
            <div>
              <p>
                Add users here who will not be added via CSV file or third party
                sync.
              </p>
              <p>
                If you are adding a Google or Office 365 user you do not need to
                enter a password.
              </p>
              <p>
                Users added here will NOT be subject to the system
                auto-archiving process, even if they are later added via CSV
                file or third party. If you wish to archive a user added through
                this process, you will need to do so manually.
              </p>
              <p class="mt-2 mb-0">Would you like to proceed?</p>
            </div>
          </CAlert>
          <div class="d-flex align-items-center justify-content-between px-4">
            <button
              class="btn btn bg-gradient-blue reverse text-white"
              @click="
                toggleTab('addUser'), (state.showAddUserWarningModal = false)
              "
            >
              Yes
            </button>
            <button
              class="btn btn"
              @click="state.showAddUserWarningModal = false"
            >
              No
            </button>
          </div>
        </div>
        <template #footer-wrapper>
          <div></div>
        </template>
      </CModal>

      <CModal
        backdrop="static"
        :visible="state.showStudentIDsGenerattionModal"
        :close-on-backdrop="false"
      >
        <template #header-wrapper>
          <div
            class="pt-4 px-4 create-popup-header d-flex align-items-center justify-content-between"
          >
            <div
              class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
              @click="state.showStudentIDsGenerattionModal = false"
            >
              <span class="close-icon"></span>
            </div>
          </div>
        </template>
        <div class="px-3 pt-3 pb-1">
          <div>
            Generating student ID cards is processed. It will take some time.
            You can find the file on the "Dashboard" when it is completed.
          </div>
          <div class="text-center mt-3">
            <button
              @click="state.showStudentIDsGenerattionModal = false"
              class="btn p-3 bg-gradient-blue text-white ms-2"
            >
              Okay
            </button>
          </div>
        </div>
        <template #footer-wrapper>
          <div></div>
        </template>
      </CModal>

      <!-- end Modals -->
      <h1 class="text-center mb-4 mb-lg-5">Main Users</h1>
      <UsersStats />

      <CRow class="forms-container justify-content-center mt-2">
        <CCol
          v-if="!provideUsersByApiOnly"
          xs="12"
          sm="4"
          md="4"
          lg="4"
          class="px-1 mt-4"
        >
          <div
            id="user-manage-form-container"
            class="form-block rounded-panel px-3 px-xl-5 py-2"
          >
            <div class="d-flex justify-content-between align-items-center py-2">
              <h5 class="fw-light d-block rounded-panel-title my-2">
                {{
                  editableUser
                    ? "Edit User (full access)"
                    : "Add User (full access)"
                }}
              </h5>
              <span
                class="d-flex align-items-center justify-content-center text-white rounded-circle bg-gradient-blue p-2 cursor-pointer"
                @click="showCreateForm"
              >
                <i
                  :class="
                    state.tabs.addUser
                      ? 'flaticon-up-arrow'
                      : 'flaticon-down-arrow'
                  "
                ></i>
              </span>
            </div>
            <div v-show="state.tabs.addUser" class="form">
              <CreateUserForm
                ref="CreateUserForm"
                @user-created="refreshDataTable(), refreshUsersStats()"
                @user-updated="refreshDataTable()"
              />
            </div>
          </div>
        </CCol>
        <CCol xs="12" sm="4" md="4" lg="4" class="px-1 mt-4">
          <div class="form-block rounded-panel px-3 px-xl-5 py-2">
            <div class="d-flex justify-content-between align-items-center py-2">
              <h5 class="fw-light d-block rounded-panel-title my-2">
                Add Substitute (PIN Only)
              </h5>
              <span
                class="d-flex align-items-center justify-content-center text-white rounded-circle bg-gradient-blue p-2 cursor-pointer"
                @click="toggleTab('addSubstitute')"
              >
                <i
                  :class="
                    state.tabs.addSubstitute
                      ? 'flaticon-up-arrow'
                      : 'flaticon-down-arrow'
                  "
                ></i>
              </span>
            </div>
            <div v-if="state.tabs.addSubstitute" class="form">
              <CreateSubstituteForm @substitute-created="refreshUsersStats" />
            </div>
          </div>
        </CCol>
        <CCol xs="12" sm="4" md="4" lg="4" class="px-1 mt-4">
          <div
            @click="generateStudentIDsPDF"
            class="form-block rounded-panel px-3 px-xl-5 py-2"
          >
            <div class="d-flex justify-content-between align-items-center py-2">
              <h5 class="fw-light d-block rounded-panel-title my-2">
                Print Student Digital IDs
              </h5>
              <span
                class="d-flex align-items-center justify-content-center text-white rounded-circle bg-gradient-blue p-2 cursor-pointer"
              >
                <i class="flaticon-upload"></i>
              </span>
            </div>
          </div>
        </CCol>
      </CRow>

      <CRow class="forms-container justify-content-center mt-1">
        <CCol
          v-if="!provideUsersByApiOnly"
          xs="12"
          sm="4"
          md="4"
          lg="4"
          class="px-1"
        >
          <ImportUsers />
        </CCol>

        <CCol
          v-if="isActiveKioskModule && !kioskOptions.prefill_kiosk_passcode"
          xs="12"
          sm="4"
          md="4"
          lg="4"
          class="px-1"
        >
          <ImportKioskPW />
        </CCol>

        <CCol xs="12" sm="4" md="4" lg="4" class="px-1">
          <ImportStudentPhotos />
        </CCol>
      </CRow>
    </CContainer>
    <UsersTable
      ref="UsersTable"
      @set-user-editable="state.tabs.addUser = true"
    />
    <Loader :is-processing="state.isLoading" classes="position-absolute" />
  </div>
</template>

<script>
import { reactive, computed, watch, ref } from "vue"
import { useStore } from "vuex"
import UsersTable from "@/v3components/Datatables/UsersTable.vue"
import ImportUsers from "@/v3components/UsersImport/ImportUsers.vue"
import ImportStudentPhotos from "@/v3components/UsersImport/ImportStudentPhotos.vue"
import ImportKioskPW from "@/v3components/UsersImport/ImportKioskPW.vue"
import CreateUserForm from "@/v3components/Forms/CreateUserForm.vue"
import CreateSubstituteForm from "@/v3components/Forms/CreateSubstituteForm.vue"
import UsersStats from "@/v3components/UsersStats.vue"
import Loader from "@/v3components/shared/Loader/Loader.vue"
import _ from "underscore"

export default {
  name: "Users",
  components: {
    UsersTable,
    ImportUsers,
    ImportStudentPhotos,
    ImportKioskPW,
    CreateUserForm,
    UsersStats,
    CreateSubstituteForm,
    Loader
  },
  setup() {
    const store = useStore()

    const state = reactive({
      isLoading: false,
      showAddUserWarningModal: false,
      showStudentIDsGenerattionModal: false,
      tabs: {
        addUser: false,
        addSubstitute: false
      }
    })

    const editableUser = computed(() => store.getters["users/editableUser"])

    const isActiveKioskModule = computed(
      () => store.getters["schools/isActiveKioskModule"]
    )

    const studentIdCardsLink = computed(
      () => store.getters["users/studentIdCardsLink"]
    )

    const kioskOptions = computed(() => {
      const index = _.findIndex(store.state.schools.activeSchoolModules, {
        name: "Kiosk"
      })
      return index !== -1
        ? JSON.parse(
            store.state.schools.activeSchoolModules[index].pivot.option_json
          )
        : false
    })

    const activeSchool = computed(() => store.getters["schools/activeSchool"])

    const provideUsersByApiOnly = computed(() => {
      return (
        activeSchool.value && activeSchool.value.provide_users_by_api_only == 1
      )
    })

    watch(
      () => editableUser.value,
      (editableUser) => {
        if (!editableUser) {
          state.tabs.addUser = false
        }
      }
    )

    const toggleTab = (tab) => {
      state.tabs[tab] = !state.tabs[tab]
    }

    const UsersTable = ref()

    const refreshDataTable = () => {
      UsersTable.value.refreshDataTable()
    }

    const refreshUsersStats = () => {
      store.dispatch("users/getStats")
    }

    const CreateUserForm = ref(null)

    const showCreateForm = () => {
      if (state.tabs.addUser) {
        state.tabs.addUser = false
        CreateUserForm.value.cancelEdit()
      } else {
        state.showAddUserWarningModal = !editableUser.value
        state.tabs.addUser = editableUser.value
      }
    }

    const getStudentIDsPDF = () => {
      store.dispatch("users/getAllStudentIDCardsPDF").then((response) => {
        const data = response.data?.data
        if (data && data.url) {
          window.open(data.url, "_blank")
        } else {
          state.showStudentIDsGenerattionModal = true
        }
        state.isLoading = false
      })
    }

    const generateStudentIDsPDF = () => {
      if (studentIdCardsLink.value) {
        window.open(studentIdCardsLink.value, "_blank")
        return
      }
      state.isLoading = true
      store.dispatch("users/generateAllStudentIDCardsFile").then((response) => {
        const data = response.data?.data
        if (data && data.url) {
          window.open(data.url, "_blank")
          state.isLoading = false
        } else {
          setTimeout(() => {
            getStudentIDsPDF()
          }, 1200)
        }
      })
    }

    return {
      state,
      editableUser,
      isActiveKioskModule,
      studentIdCardsLink,
      provideUsersByApiOnly,
      kioskOptions,
      UsersTable,
      toggleTab,
      refreshDataTable,
      refreshUsersStats,
      CreateUserForm,
      showCreateForm,
      generateStudentIDsPDF
    }
  }
}
</script>
