import axios from "axios"
import { Promise } from "core-js"

const state = {
  files: null
}

const getters = {
  getFiles(state) {
    return state.files
  }
}

const mutations = {
  SET_FILES(state, files) {
    state.files = files
  },
  PUSH_FILES(state, files) {
    if (state.files && state.files.length) {
      state.files = state.files.concat(files)
    } else {
      state.files = files
    }
  }
}

const actions = {
  getFiles(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/users/imports/files/${params.schoolId}`, {
          params: params
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
