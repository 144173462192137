const state = {
  activeRoute: null
}
const getters = {
  isVisibleTardyManagment(state, getters, rootState, rootGetters) {
    return rootGetters["schools/isCurrentUserHasAccessToTardyManagment"]
  },
  isStudentLadderVisible(state, getters, rootState, rootGetters) {
    return (
      Boolean(
        rootGetters["schools/tardyManagementOptions"]?.[
          "allow-students-see-level"
        ]
      ) && !!rootGetters["consequencesLadder/ladderTypes"]?.length
    )
  },
  isVisibleTardyAdults(state, getters, rootState, rootGetters) {
    return !!rootGetters["schools/tardyManagementOptions"]?.[
      "allow-selected-users-only"
    ]
  },
  isVisibleTardyCSVUpload(state, getters, rootState, rootGetters) {
    return !!rootGetters["schools/tardyManagementOptions"]?.usage?.["via-csv"]
  },
  activeRoute(state) {
    return state.activeRoute
  },
  hasVisitorSettingsRouteAccess(_state, _getters, _rootState, rootGetters) {
    return rootGetters["visitorSettings/hasVisitorSettingsAccess"]
  },
  hasVisitorAdultListRouteAccess(_state, _getters, _rootState, rootGetters) {
    return rootGetters["visitorSettings/hasVisitorSettingsAccess"]
  },
  hasVisitorListSettingsRouteAccess(_state, _getters, _rootState, rootGetters) {
    return rootGetters["visitorSettings/hasVisitorSettingsAccess"]
  },
  hasVisitorGuardianStudentMatrixRouteAccess(
    _state,
    _getters,
    _rootState,
    rootGetters
  ) {
    return rootGetters["visitorSettings/hasVisitorSettingsAccess"]
  },
  hasVisitorSecuritySettingsRouteAccess(
    _state,
    _getters,
    _rootState,
    rootGetters
  ) {
    return rootGetters["visitorSettings/hasVisitorSettingsAccess"]
  },
  hasVisitorKioskSettingsRouteAccess(
    _state,
    _getters,
    _rootState,
    rootGetters
  ) {
    return rootGetters["visitorSettings/hasVisitorSettingsAccess"]
  },
  hasVisitorGeneralSettingsRouteAccess(
    _state,
    _getters,
    _rootState,
    rootGetters
  ) {
    return rootGetters["visitorSettings/hasVisitorSettingsAccess"]
  },
  hasVisitorManagementRouteAccess(_state, _getters, _rootState, rootGetters) {
    return rootGetters["visitorSettings/hasVisitorManagementAccess"]
  },
  hasVisitorCheckInsLogsRouteAccess(_state, _getters, _rootState, rootGetters) {
    return rootGetters["visitorSettings/hasVisitorManagementAccess"]
  },
  hasVisitorPrinterSetupRouteAccess(_state, _getters, _rootState, rootGetters) {
    return rootGetters["visitorSettings/hasVisitorManagementAccess"]
  }
}
const mutations = {
  SET_ACTIVE_ROUTE(state, route) {
    state.activeRoute = route
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
