<template>
  <div v-if="student" class="v3-dashboard-banners-item">
    <div class="text-center">
      <div>
        <h2 class="v3-dashboard-banners-item-title">
          <i class="ri-lock-line"></i> User Locked
          <span class="cursor-pointer ms-1" @click="seenLockAttempt()"
            ><i class="ri-close-line"></i
          ></span>
        </h2>
      </div>
      <Transition name="banner">
        <div v-if="active" class="mt-2">
          User: {{ student?.user?.name }} has attempted
          {{ student?.attempts }} incorrect PINs on
          {{
            helpersJS.transformDate(
              student?.last_attempt_at,
              "M/D/yyyy h:mm A"
            )
          }}. They will be restricted from additional attempts for the next 4
          hours.
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import helpersJS from "@/helpers/index"
import { useStore } from "vuex"
export default {
  name: "StudentRestrictedBanner",
  props: {
    student: {
      type: Object,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore()

    const seenLockAttempt = () => {
      store.dispatch("authentication/seenUserUnlockAttempt", props.student.id)
    }

    return {
      helpersJS,
      seenLockAttempt
    }
  }
}
</script>
