<template>
  <div
    class="c-app flex-row align-items-center"
    :class="{ 'c-dark-theme': $store.state.layout.darkMode }"
  >
    <CContainer>
      <CRow class="justify-content-center">
        <CCol xs="12" md="6" lg="5">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <div class="d-flex justify-content-center mb-4">
                    <img
                      src="@/assets/images/securlypass-color-logo.png"
                      alt="securlypass-logo"
                      class="small-logo"
                    />
                  </div>
                  <CInputGroup
                    class="mt-3 mb-0"
                    :class="{
                      'is-invalid':
                        v$.request.$error &&
                        v$.request.password.required.$invalid
                    }"
                  >
                    <CInputGroupText class="input-group-prepend">
                      <CIcon icon="cil-lock-locked" />
                    </CInputGroupText>
                    <CFormInput
                      :class="{
                        'is-invalid':
                          v$.request.$error &&
                          v$.request.password.required.$invalid
                      }"
                      placeholder="Old password"
                      type="password"
                      v-model="v$.request.password.$model"
                    >
                    </CFormInput>
                  </CInputGroup>
                  <div
                    v-if="
                      v$.request.$error && v$.request.password.required.$invalid
                    "
                    class="invalid-feedback"
                  >
                    This field is required
                  </div>
                  <CInputGroup
                    class="mt-3 mb-0"
                    :class="{
                      'is-invalid':
                        v$.request.$error &&
                        v$.request.new_password.required.$invalid
                    }"
                  >
                    <CInputGroupText class="input-group-prepend">
                      <CIcon icon="cil-lock-locked" />
                    </CInputGroupText>
                    <CFormInput
                      :class="{
                        'is-invalid':
                          v$.request.$error &&
                          v$.request.new_password.required.$invalid
                      }"
                      placeholder="New password"
                      type="password"
                      :is-valid="checkIfValid('new_password')"
                      v-model="request.new_password"
                    >
                    </CFormInput>
                  </CInputGroup>
                  <div
                    v-if="
                      v$.request.$error &&
                      v$.request.new_password.required.$invalid
                    "
                    class="invalid-feedback"
                  >
                    This field is required
                  </div>
                  <CInputGroup
                    class="mt-3 mb-0"
                    :class="{
                      'is-invalid':
                        v$.request.$error &&
                        v$.request.confirm_password.required.$invalid
                    }"
                  >
                    <CInputGroupText class="input-group-prepend">
                      <CIcon icon="cil-lock-locked" />
                    </CInputGroupText>
                    <CFormInput
                      :class="{
                        'is-invalid':
                          v$.request.$error &&
                          v$.request.confirm_password.required.$invalid
                      }"
                      placeholder="Confirm new password"
                      type="password"
                      :is-valid="checkIfValid('confirm_password')"
                      v-model="request.confirm_password"
                    >
                    </CFormInput>
                  </CInputGroup>
                  <div
                    v-if="
                      v$.request.$error &&
                      v$.request.confirm_password.required.$invalid
                    "
                    class="invalid-feedback"
                  >
                    This field is required
                  </div>
                  <CAlert
                    v-if="reqResponse.message"
                    class="mt-3 mb-0"
                    :color="reqResponse.type"
                    :show="!!reqResponse.message"
                  >
                    {{ reqResponse.message }}
                    <div v-if="reqResponse.errors">
                      <div v-if="(err, index) in reqResponse.errors">
                        {{ err[0] }}
                      </div>
                    </div>
                  </CAlert>
                  <CRow>
                    <CCol md="12" class="text-center">
                      <div class="mt-3 text-center" @click="resetPass()">
                        <CLoadingButton
                          class="me-2 btn bg-gradient-blue text-white"
                          :spinner="isLoading"
                          :loading="isLoading"
                        >
                          Reset Password
                        </CLoadingButton>
                      </div>
                    </CCol>
                    <CCol md="12" class="text-center mb-3">
                      <CSpinner v-if="isLoading && !goToApp" color="primary" />
                    </CCol>
                  </CRow>

                  <p class="text-muted mt-4 text-center">
                    New Password must be at least 5 (five) characters long.
                  </p>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import { required } from "vuelidate/lib/validators"
import { mapGetters } from "vuex"

export default {
  name: "ResetPassword",
  setup() {
    return { v$: useVuelidate() }
  },
  data: () => ({
    isLoading: false,
    request: {
      school_id: "",
      password: "",
      new_password: "",
      confirm_password: ""
    },
    reqResponse: {
      type: "",
      message: "",
      errors: {}
    }
  }),
  validations: {
    request: {
      password: {
        required
      },
      new_password: {
        required
      },
      confirm_password: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      activeSchool: "schools/activeSchool",
      currentUser: "authentication/user"
    }),
    isValid() {
      return !this.v$.request.$invalid
    }
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.v$.request[fieldName]
      if (field) {
        if (!field.$dirty) {
          return null
        }
        return !(field.$invalid || field.$model === "")
      } else {
        return null
      }
    },
    resetPass() {
      this.resetResponseMessages()
      if (this.isValid) {
        this.isLoading = true

        this.request.school_id = this.activeSchool.id
        this.$store
          .dispatch("authentication/forceResetPassword", this.request)
          .then(() => {
            this.isLoading = false
            this.setSuccessResponse("Password is changed")
            localStorage.setItem("showAnnouncements", true)
            if (this.currentUser && this.currentUser.role_id === 1) {
              window.location.replace("/passes/create")
            } else {
              window.location.replace("/dashboard")
            }
          })
          .catch((err) => {
            const response = err && err.response ? err.response.data : null
            if (response) {
              this.setErrorResponse(
                response.message || err.message,
                response.errors
              )
            } else {
              this.setErrorResponse(err.message, null)
            }
            this.isLoading = false
          })
      } else {
        this.v$.$touch()
      }
    },
    resetResponseMessages() {
      this.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    },
    setSuccessResponse(message) {
      this.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    },
    setErrorResponse(message, errors) {
      this.reqResponse = {
        type: "danger",
        message: message ? message : "Someting went wrong!",
        errors: errors ? errors : {}
      }
    }
  }
}
</script>
