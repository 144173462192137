<template>
  <div v-if="stats.autoPassesRoomsCount">
    <div class="dashboard-counters" v-if="!basic">
      <div class="d-flex justify-content-center">
        <div class="dashboard-counters-main-box">
          <div class="label">Auto pass rooms</div>
          <div class="dashboard-counters-main-box-passes">
            {{ stats.autoPassLimit ? stats.autoPassesPreferencesCount : "Off" }}
          </div>
          <div class="settings">
            <span class="settings-number">{{
              stats.autoPassesRoomsCount
            }}</span>
            <span class="settings-text-gray ms-1"> Available</span>
            <i
              @click="openCreateAutoPassLimit()"
              class="ri-settings-2-line settings-icon ms-1 cursor-pointer"
            ></i>
          </div>
        </div>
        <div class="dashboard-counters-secondary-box">
          <i class="ri-account-box-line"></i>
        </div>
      </div>
    </div>
    <div class="dashboard-basic-counters" v-else>
      <div class="dashboard-basic-counters-box">
        <div class="dashboard-basic-counters-box-passes">
          <span class="total-passes">
            {{ stats.autoPassLimit ? stats.autoPassesPreferencesCount : "Off" }}
          </span>
        </div>
        <div class="gray-label ms-1">Auto pass rooms</div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from "vue"
import { useStore } from "vuex"
import CreateAutoPassLimit from "@/v3components/Forms/CreateAutoPassLimit.vue"

export default {
  name: "AutoPassRooms",
  props: {
    basic: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore()

    const modal = inject("modal")

    const stats = computed(() => store.getters["dashboardTable/stats"])

    const openCreateAutoPassLimit = () => {
      modal.open(CreateAutoPassLimit, {
        size: "sm",
        title: "Set Auto Pass limit",
        props: {
          limit: stats.value.autoPassLimit
            ? stats.value.autoPassLimit.limit
            : null
        }
      })
    }

    return { stats, openCreateAutoPassLimit }
  }
}
</script>

<style></style>
