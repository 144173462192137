<template>
  <div
    class="d-flex flex-column justify-content-center align-content-center gap-1 text-center"
  >
    <template v-if="isValid">
      <div v-if="formattedTime" class="visitor-row-wrapper">
        {{ formattedDate }}
      </div>
      <div v-if="formattedTime" class="visitor-row-wrapper">
        {{ formattedTime }}
      </div>
    </template>
    <template v-else>
      <div class="visitor-row-wrapper">Invalid Date</div>
    </template>
  </div>
</template>

<script>
import visitorConfigs from "@/configs/visitorConfigs"
import passHelpers from "@/helpers/index"
import moment from "moment-timezone"
import { computed } from "vue"

export default {
  name: "VisitorDateColumn",
  props: {
    date: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const isValid = computed(() => {
      return props?.date && moment(props?.date).isValid()
    })

    const computedDate = computed(() => {
      return formatDateTime(props?.date)
    })

    const formattedDate = computed(() => {
      return computedDate?.value?.split(" ")?.[0] || ""
    })

    const formattedTime = computed(() => {
      return (
        computedDate?.value?.split(" ")?.[1] +
          " " +
          computedDate?.value?.split(" ")?.[2] || ""
      )
    })

    const formatDateTime = (date) => {
      const datePart = date?.split(" ")?.[0] || ""
      const timePart = date?.split(" ")?.[1] || ""
      return visitorConfigs?.useCustomVisitorTimeFunction
        ? passHelpers.convertToCurrentTimezone(date)
        : passHelpers.currTzDateTime(datePart, timePart, "MM-DD-YYYY h:mm A")
    }

    return {
      isValid,
      formattedDate,
      formattedTime
    }
  }
}
</script>

<style scoped></style>
