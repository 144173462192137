<template>
  <div class="profile-settings-view">
    <UserDetails
      v-if="state.loading"
      :user="user"
      :user-role="userRole"
      :active-appoinment-module-options="activeAppoinmentModuleOptions"
      :is-active-appoinment-module="isActiveAppoinmentModule"
      :is-active-auto-check-in-module="isActiveAutoCheckInModule"
      :is-active-kiosk-module="isActiveKioskModule"
    ></UserDetails>
  </div>
</template>

<script>
import { reactive, computed, onMounted } from "vue"
import { useStore } from "vuex"
import UserDetails from "./UserDetails.vue"
export default {
  name: "ViewUserDetails",
  components: {
    UserDetails
  },
  props: {
    user: {
      type: Object,
      default: null
    },
    isVisible: {
      type: Boolean
    }
  },
  setup(props) {
    const store = useStore()
    const state = reactive({
      loading: false,
      form: {
        locations: []
      }
    })
    const userRole = computed(() => {
      if (!props.user || !props.user.role_id) return null
      switch (props.user.role_id) {
        case 1:
          return "student"
        case 2:
          return "admin"
        case 3:
          return "teacher"
        case 4:
          return "staff"
        default:
          return null // Handle unknown roles
      }
    })

    const isActiveAppoinmentModule = computed(
      () => store.getters["schools/isActiveAppoinmentModule"]
    )

    const isActiveAutoCheckInModule = computed(
      () => store.getters["schools/isActiveAutoCheckInModule"]
    )

    const isActiveKioskModule = computed(
      () => store.getters["schools/isActiveKioskModule"]
    )

    const activeAppoinmentModuleOptions = computed(
      () => store.getters["schools/activeAppoinmentModuleOptions"]
    )

    onMounted(() => {
      store.dispatch("authentication/getUserSettings").then(() => {
        state.loading = true
      })
    })

    return {
      state,
      userRole,
      isActiveAppoinmentModule,
      isActiveAutoCheckInModule,
      isActiveKioskModule,
      activeAppoinmentModuleOptions
    }
  }
}
</script>
