<template>
  <div class="profile-settings-view">
    <ProfileSettings
      v-if="state.loading"
      :user="user"
      :user-role="userRole"
      :active-appoinment-module-options="activeAppoinmentModuleOptions"
      :is-active-appoinment-module="isActiveAppoinmentModule"
      :is-active-auto-check-in-module="isActiveAutoCheckInModule"
      :is-active-kiosk-module="isActiveKioskModule"
    ></ProfileSettings>
  </div>
</template>

<script>
import { reactive, computed, onMounted } from "vue"
import { useStore } from "vuex"
import ProfileSettings from "@/v3views/UserSettings/ProfileSettings.vue"

export default {
  name: "UserSettings",
  components: {
    ProfileSettings
  },
  setup() {
    const store = useStore()
    const state = reactive({
      loading: false
    })

    const user = computed(() => store.getters["authentication/user"])

    const userRole = computed(() => store.getters["authentication/getUserRole"])

    const isActiveAppoinmentModule = computed(
      () => store.getters["schools/isActiveAppoinmentModule"]
    )

    const isActiveAutoCheckInModule = computed(
      () => store.getters["schools/isActiveAutoCheckInModule"]
    )

    const isActiveKioskModule = computed(
      () => store.getters["schools/isActiveKioskModule"]
    )

    const activeAppoinmentModuleOptions = computed(
      () => store.getters["schools/activeAppoinmentModuleOptions"]
    )

    onMounted(() => {
      store.dispatch("authentication/getUserSettings").then(() => {
        state.loading = true
      })
    })

    return {
      state,
      user,
      userRole,
      isActiveAppoinmentModule,
      isActiveAutoCheckInModule,
      isActiveKioskModule,
      activeAppoinmentModuleOptions
    }
  }
}
</script>
