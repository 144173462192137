import tokenService from "./tokenService"
const api =
  import.meta.env.VITE_DD_ENV === "local"
    ? import.meta.env.VITE_PROXY_API
    : import.meta.env.VITE_API

const baseUrl =
  import.meta.env.VITE_DD_ENV === "local"
    ? import.meta.env.VITE_PROXY_BASE_URL
    : import.meta.env.VITE_BASE_URL

const isProd = import.meta.env.VITE_DD_ENV === "production" // check if the app is using production .env
const isProductionBuild = process.env.NODE_ENV === "production" // check if the app is build for real environment (dev, stage, prod) not local

const appConfig = {
  baseUrl,
  api,
  isProd,
  isProductionBuild,
  getAuthToken: function () {
    return !this.isProductionBuild ? tokenService.getDevToken() : null // we must authorize all real envrionments (dev, stage, prod) with cookie and only local with token
  },
  axios: function () {
    return {
      baseUrl,
      withCredentials: this.isProductionBuild,
      Authorization: this.getAuthToken()
        ? `Bearer ${this.getAuthToken()}`
        : null
    }
  }
}

export default appConfig
