<template>
  <div class="v3-form adult-form">
    <div class="row">
      <div>
        <SelectList
          select-placeholder="Select adults"
          select-type="adults"
          :reduce="(item) => item.value.id"
          counter-icon="ri-group-line"
          info-text="Specify teachers/staff to be allowed to keep pass transparency."
          :invalid-feedback="state.serverErrors"
          @update-list-data="(value) => updateData(value)"
        />
      </div>
      <div>
        <InfoBox
          v-if="state.serverRes"
          class="mt-4"
          :class="{ danger: Object.keys(state.serverErrors).length }"
          :title="state.serverRes.title"
          >{{ state.serverRes.message }}</InfoBox
        >
      </div>
      <div class="text-center">
        <LoadingButton class="me-2 px-4" @click="onCancel" rounded
          >Cancel</LoadingButton
        >
        <LoadingButton
          :is-loading="state.isProcessing"
          @click="allowAdults()"
          class="mt-4 px-4"
          solid
          rounded
          >Allow</LoadingButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import SelectList from "@/v3components/shared/Form/SelectList.vue"
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"
import { useStore } from "vuex"
import { reactive, computed } from "vue"

export default {
  name: "PassTransparencyForm",
  components: {
    LoadingButton,
    InfoBox,
    SelectList
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const store = useStore()
    const state = reactive({
      isProcessing: false,
      serverErrors: {},
      serverRes: null,
      form: {
        adults: null
      }
    })

    const validationMessages = {
      required: "This field is required"
    }
    const validations = {
      form: {
        adults: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isFormValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    const updateData = (value) => {
      state.form.adults = value
      state.serverErrors = {}
      state.serverRes = null
    }

    const allowAdults = () => {
      state.isProcessing = true
      store
        .dispatch("passes/createTransparency", { user_ids: state.form.adults })
        .then(() => {
          resetFormData()
          setResponseInfoBox("Success", "Successfully created!")
          state.isProcessing = false
          state.serverErrors = {}
          setTimeout(() => {
            setResponseInfoBox()
            store.dispatch("passes/getTransparencies")
            emit("cancel")
          }, 1000)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const onCancel = () => {
      resetFormData()
      emit("cancel")
    }

    const resetFormData = () => {
      state.form.adults = null
    }

    return {
      state,
      v$,
      updateData,
      allowAdults,
      isFormValid,
      errors,
      onCancel,
      resetFormData,
      setResponseInfoBox
    }
  }
}
</script>
