<template>
  <div :class="classes" class="v3-sidebar-toggler">
    <i :class="active ? 'ri-arrow-left-s-line' : 'ri-arrow-right-s-line'"></i>
  </div>
</template>

<script>
export default {
  props: { classes: [Array, String], active: Boolean }
}
</script>
