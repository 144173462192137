<template>
  <div>
    <CContainer>
      <CRow class="justify-content-center mt-4">
        <CCol md="12 text-center">
          <h1 class="mb-4">Admin's Guide</h1>
          <div v-if="trainings && trainings.guide">
            <iframe
              :src="trainings.guide[user.role.name]"
              class="trainings-iframe"
            />
          </div>
          <div v-else>No tutorials at the moment</div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import { mapGetters } from "vuex"

export default {
  name: "AdminGuide",
  components: {},
  created() {
    this.$store.dispatch("updates/getTrainings")
  },
  computed: {
    ...mapGetters({
      trainings: "updates/trainings",
      user: "authentication/user"
    })
  }
}
</script>
