import firebaseConfig from "@/configs/firebaseConfig"
import { initializeApp } from "firebase/app"

let firebaseApp = null

const getFirebaseApp = () => {
  if (!firebaseApp) {
    firebaseApp = initializeApp(firebaseConfig.firebaseApp)
  }
  return firebaseApp
}

export default getFirebaseApp
