<template>
  <div class="v3-form limit-location-max-cap-form">
    <div class="row">
      <div class="mt-3">
        <Label>Cap limit</Label>
        <InputField
          placeholder="Limit"
          v-model="state.form.limit"
          :invalid-feedback="errors.limit || state.serverErrors.limit"
          class="d-block"
          @keypress="validateLimitInput"
        ></InputField>
      </div>
      <div class="mt-2">
        <InfoBox>A "-" dash indicates unlimited.</InfoBox>
      </div>
      <div class="my-3">
        <div v-if="!editableLimitData">
          <SelectList
            select-placeholder="Select locations"
            select-type="locations"
            :reduce="(item) => item.value.id"
            counter-icon="ri-map-pin-2-fill"
            counter-icon-class="simple"
            :invalid-feedback="state.serverErrors"
            :required-feedback="errors.locations"
            :close-after-select="false"
            @update-list-data="(value) => updateData(value)"
          />
        </div>
        <div v-else>
          <InputField
            v-model="state.form.edited_location_name"
            :disabled="true"
            class="d-block"
          ></InputField>
        </div>
      </div>
      <div>
        <InfoBox
          v-if="state.serverRes"
          :class="{ danger: Object.keys(state.serverErrors).length }"
          :title="state.serverRes.title"
          >{{ state.serverRes.message }}</InfoBox
        >
      </div>
      <div
        class="d-flex align-items-center justify-content-center mt-4 text-center"
      >
        <LoadingButton
          v-if="!isHeaderCreateButton"
          class="me-3 px-4"
          @click="onCancel()"
          rounded
          >Cancel</LoadingButton
        >
        <LoadingButton
          :is-loading="state.isProcessing"
          @click="submit(editableLimitData)"
          class="px-4 me-3"
          solid
          rounded
          >{{ editableLimitData ? "Update" : "Create" }}</LoadingButton
        >
        <router-link
          v-if="isHeaderCreateButton"
          class="text-graident-blue d-flex flex-row-reverse"
          to="/limits/limit-location-max-cap"
        >
          <div class="d-flex align-items-center" @click="onCancel()">
            <img
              width="18"
              class="m-0 me-2"
              src="@/assets/images/icons/gradient-link.png"
            />
            <span class="text-graident-blue"
              ><span class="text-graident-blue">Go to full menu</span></span
            >
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted, inject } from "vue"
import { useStore } from "vuex"
import { helpers, required, requiredIf } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import Label from "@/v3components/shared/Form/Label.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"
import SelectList from "@/v3components/shared/Form/SelectList.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"

export default {
  name: "CreateLimitLocationMaxCapForm",
  components: {
    Label,
    InputField,
    SelectList,
    InfoBox,
    LoadingButton
  },
  props: {
    editableLimitData: {
      type: Object
    },
    isHeaderCreateButton: {
      type: Boolean,
      default: false
    },
    isDashboardView: {
      type: Boolean,
      default: false
    }
  },
  emits: ["cancel", "closeModal"],
  setup(props, { emit }) {
    const store = useStore()
    const modal = inject("modal")

    const state = reactive({
      isProcessing: false,
      form: {
        limit: "-",
        locations: [],
        edited_location_name: ""
      },
      serverErrors: {},
      serverRes: null
    })

    const validationMessages = {
      required: "This field is required",
      limit:
        'The limit must a number or a "-" dash (where a "-" means unlimited).'
    }
    const validations = {
      form: {
        limit: {
          required: helpers.withMessage(validationMessages.limit, required)
        },
        locations: {
          required: helpers.withMessage(
            validationMessages.required,
            requiredIf(() => {
              return !props.editableLimitData
            })
          )
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isFormValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    onMounted(() => {
      if (props.editableLimitData) {
        setFormByLimitRecord(props.editableLimitData)
      }
    })

    const updateData = (value) => {
      state.form.locations = value
      state.serverErrors = {}
      state.serverRes = null
    }

    const setFormByLimitRecord = (limit) => {
      if (limit) {
        state.form = {
          limit: limit.limit != null ? limit.limit : "-",
          locations: limit.room.id ? [limit.room.id] : [],
          edited_location_name: limit.room.name ? limit.room.name : null
        }
      }
    }

    const submit = (isUpdate) => {
      if (isFormValid.value) {
        const data = {
          limit: state.form.limit === "-" ? null : parseInt(state.form.limit),
          room_ids:
            state.form.locations && state.form.locations.length
              ? state.form.locations
              : null
        }
        if (isUpdate && props.editableLimitData.id) {
          updateLimitLocation(data)
        } else {
          createLimitLocation(data)
        }
      } else {
        v$.value.$touch()
      }
    }

    const createLimitLocation = (data) => {
      state.isProcessing = true
      store
        .dispatch("rooms/createRoomCapacityLimit", data)
        .then((response) => {
          const dataResponse = response.data.data
          setResponseInfoBox("Success", "Successfully saved!")
          state.isProcessing = false
          state.serverErrors = {}
          modal.setAction("createLimitLocationMaxCap")
          if (dataResponse) {
            if (props.isDashboardView) {
              store.commit("rooms/UPDATE_ROOM_CAPACITY_LIMIT", dataResponse)
            } else {
              store.dispatch("rooms/getRoomCapacityLimit").then((response) => {
                const dataGetTable = response.data.data
                if (dataGetTable) {
                  store.commit("rooms/PUSH_ROOM_CAPACITY_LIMITS", dataGetTable)
                }
              })
            }
          }
          setTimeout(() => {
            setResponseInfoBox()
            onCancel()
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const updateLimitLocation = (data) => {
      state.isProcessing = true
      const dataLimit = {
        limit: data.limit,
        id: props.editableLimitData.id
      }
      store
        .dispatch("rooms/updateRoomCapacityLimit", dataLimit)
        .then((response) => {
          setResponseInfoBox("Success", "Successfully updated!")
          state.isProcessing = false
          state.serverErrors = {}
          const newData = response.data.data
          if (newData) {
            store.commit("rooms/UPDATE_ROOM_CAPACITY_LIMIT", newData)
          }
          setTimeout(() => {
            setResponseInfoBox()
            onCancel()
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const validateLimitInput = (event) => {
      if (event.keyCode >= 48 && event.keyCode <= 59) {
        if (state.form.limit === "-") {
          state.form.limit = ""
        }
        return true
      } else if (event.keyCode == 45) {
        state.form.limit = ""
        return true
      } else {
        return event.preventDefault()
      }
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    const onCancel = () => {
      resetFormData()
      emit("cancel")
      emit("closeModal")
    }

    const resetFormData = () => {
      state.form.limit = "-"
      state.form.locations = null
    }

    return {
      state,
      v$,
      updateData,
      validateLimitInput,
      submit,
      isFormValid,
      errors,
      setResponseInfoBox,
      onCancel,
      resetFormData
    }
  }
}
</script>
