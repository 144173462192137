import axios from "axios"

const state = {
  summaryReports: [],
  formData: {},
  summaryReportsRequest: []
}

const mutations = {
  // NOW REPORTS tab
  SET_SUMMARY_REPORTS(state, summaryReportsCollection) {
    state.summaryReports = summaryReportsCollection
  },
  SET_SUMMARY_REPORTS_FORM_DATA(state, summaryReportsFormData) {
    state.formData = summaryReportsFormData
  },
  PUSH_SUMMARY_REPORTS(state, reportsCollection) {
    if (state.summaryReports && state.summaryReports.length) {
      state.summaryReports = state.summaryReports.concat(reportsCollection)
    } else {
      state.summaryReports = reportsCollection
    }
  },
  // BACKGROUND REPORTS tab
  SET_SUMMARY_REPORTS_REQUEST(state, summaryReportsRequestCollection) {
    state.summaryReportsRequest = summaryReportsRequestCollection
  },
  PUSH_SUMMARY_REPORTS_REQUEST(state, reportsRequestCollection) {
    if (state.summaryReportsRequest && state.summaryReportsRequest.length) {
      state.summaryReportsRequest = state.summaryReportsRequest.concat(
        reportsRequestCollection
      )
    } else {
      state.summaryReportsRequest = reportsRequestCollection
    }
  },
  DELETE_REPORT_REQUEST(state, id) {
    state.summaryReportsRequest = state.summaryReportsRequest.filter(
      (el) => el.id != id
    )
  }
}

const getters = {
  // NOW REPORTS tab
  summaryReports(state) {
    return state.summaryReports
  },
  students(state) {
    return state.formData.students
      ? state.formData.students.filter((user) => user.role_id === 1)
      : null
  },
  staffUsers(state) {
    return state.formData.adults
      ? state.formData.adults.filter((user) => user.role_id != 1)
      : null
  },
  formListStaff(state, getters) {
    if (getters.staffUsers) {
      return getters.staffUsers.map((user) => {
        return {
          value: user.id,
          label: user.first_name + " " + user.last_name
        }
      })
    }
  },
  formListStudents(state, getters) {
    return state.formData.students
      ? getters.students.map((user) => {
          return {
            value: user.id,
            label: user.first_name + " " + user.last_name
          }
        })
      : null
  },
  formListRooms(state) {
    return state.formData.rooms
      ? state.formData.rooms.map((room) => {
          return {
            value: room.id,
            label: room.name
          }
        })
      : null
  },
  // BACKGROUND REPORTS tab
  summaryReportsRequest(state) {
    return state.summaryReportsRequest
  }
}

const actions = {
  // NOW REPORTS tab
  getSummaryReportsFormData() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/reports/summary`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSummaryReports(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/reports/summary/filter`, {
          params: data
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getSummaryReportsResults(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/reports/summary/results`, {
          params: data
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  exportSummaryReports(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/reports/summary/filter`, {
          params: data
        })
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // BACKGROUND REPORTS tab
  getSummaryReportsRequestData(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/reports/summary/report/request`, {
          params
        })
        .then((response) => {
          const data = response.data.data
          context.commit("SET_SUMMARY_REPORTS_REQUEST", data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSummaryReportRequestData(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/reports/summary/report/request/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createSummaryReportRequest(context, report) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/reports/summary/report/request`, report)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  rerunSummaryReportRequest(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/reports/summary/report/request/${id}/run`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  downloadSummaryReportRequest(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/reports/summary/report/request/${id}/download`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateSummaryReportRequest(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/reports/summary/report/request/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteSummaryReportRequest(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/admin/reports/summary/report/request/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
