<template>
  <div v-if="isVisible" class="v3-action-dialog text-left" :class="{ danger }">
    <div class="dialog-header">
      <div class="dialog-title pe-3 lh-normal">
        {{ title }}
      </div>
      <div class="close-dialog" @click="close">
        <i class="ri-close-line"></i>
      </div>
    </div>
    <div class="dialog-content mb-3">
      <div v-if="question">{{ question }}</div>
      <slot></slot>
    </div>
    <div class="dialog-actions">
      <PillButton
        data-test-id="actions-dialog-confirm"
        @click="confirm"
        active
        rounded
        prepend-icon="ri-check-line"
        class="solid me-2"
        :class="{ danger }"
        >{{ props.confirmText }}</PillButton
      >
      <PillButton
        @click="close"
        :class="{ danger }"
        rounded
        prepend-icon="ri-close-line"
        >Cancel</PillButton
      >
    </div>
  </div>
</template>

<script>
import PillButton from "../Buttons/PillButton.vue"

export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    question: {
      type: String,
      default: ""
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    danger: {
      type: Boolean,
      default: false
    },
    confirmText: {
      type: String,
      default: "Confirm"
    }
  },
  emits: ["close", "confirm"],
  components: { PillButton },
  setup(props, { emit }) {
    const close = () => {
      emit("close")
    }
    const confirm = () => {
      emit("confirm")
    }
    return { close, confirm, props }
  }
}
</script>

<style></style>
