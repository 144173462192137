<template>
  <div v-if="!isAppoinmentMode" class="tabs-filter-container order-0">
    <div
      class="filter-tab"
      :class="{ active: activePassState === 'active' }"
      data-test-id="dashboard-active-passes-tab"
      @click="setActivePassState('active')"
    >
      Active
    </div>
    <div
      v-if="!showOnlyActivePasses"
      class="filter-tab"
      :class="{ active: activePassState === 'both' }"
      data-test-id="dashboard-both-passes-tab"
      @click="setActivePassState('both')"
    >
      Both
    </div>
    <div
      v-if="!showOnlyActivePasses"
      class="filter-tab"
      :class="{ active: activePassState === 'ended' }"
      data-test-id="dashboard-ended-passes-tab"
      @click="setActivePassState('ended')"
    >
      Ended
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"

export default {
  name: "DashboardStatusFiltersOld",
  setup() {
    const store = useStore()

    const isActiveAppoinmentModule = computed(
      () => store.getters["schools/isActiveAppoinmentModule"]
    )

    const activeSchoolHasAppoitmentPasses = computed(
      () => store.getters["schools/activeSchoolHasAppoitmentPasses"]
    )

    const activePassType = computed(
      () => store.getters["dashboardTable/getType"]
    )

    const activePassState = computed(
      () => store.getters["dashboardTable/getStatus"]
    )

    const isUserInTransparency = computed(
      () => store.getters["authentication/isUserInTransparency"]
    )

    const isAppoinmentMode = computed(() => {
      return activePassType.value.includes("appointment")
    })

    const showOnlyActivePasses = computed(() => {
      return activePassType.value === "all" && !isUserInTransparency.value
    })

    const setActivePassState = (status) => {
      store.commit("dashboardTable/UPDATE_STATUS", status)
    }

    return {
      isActiveAppoinmentModule,
      activeSchoolHasAppoitmentPasses,
      activePassType,
      activePassState,
      isUserInTransparency,
      isAppoinmentMode,
      showOnlyActivePasses,
      setActivePassState
    }
  }
}
</script>
