<template>
  <component
    :is="dynamicComponent"
    :settings-key="settingsKey"
    :heading="heading"
    :description="description"
    :has-learn-more="hasLearnMore"
    :learn-more="learnMore"
    :has-edit="hasEdit"
    :show-edit="showEdit"
    :has-switch="hasSwitch"
    :show-switch="showSwitch"
    :options="options"
    :edit="edit"
    :toggle="toggle"
  />
</template>

<script>
import { computed, defineAsyncComponent } from "vue"

const VisitorCustomReasonList = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/KioskSettings/VisitorCustomReasonList.vue"
    )
)

const VisitorCustomDestinationList = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/KioskSettings/VisitorCustomDestinationList.vue"
    )
)

const VisitorHealthQuestionList = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Forms/Visitor/KioskSettings/VisitorHealthQuestionList.vue"
    )
)

export default {
  name: "VisitorSettingsBody",
  props: {
    settingsKey: {
      type: String,
      default: ""
    },
    heading: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    hasLearnMore: {
      type: Boolean,
      default: false
    },
    learnMore: {
      type: String,
      default: ""
    },
    hasEdit: {
      type: Boolean,
      default: false
    },
    showEdit: {
      type: Boolean,
      default: false
    },
    hasSwitch: {
      type: Boolean,
      default: false
    },
    showSwitch: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({})
    },
    edit: {
      type: Function,
      default: () => ({})
    },
    toggle: {
      type: Function,
      default: () => ({})
    }
  },
  setup(props) {
    const dynamicComponent = computed(() => {
      const componentMap = {
        "custom-reasons": VisitorCustomReasonList,
        "custom-destinations": VisitorCustomDestinationList,
        "visitor-health-screening": VisitorHealthQuestionList
      }
      return componentMap[props.settingsKey]
    })
    return {
      dynamicComponent
    }
  }
}
</script>

<style scoped></style>
