<template>
  <div class="bulk-container">
    <div class="title">Bulk actions:</div>
    <span
      v-for="action in actions"
      :key="action.id"
      @click="clickedAction(action)"
    >
      <i :class="action.icon"></i>
      {{ action.text }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    actions: {
      type: Array,
      required: true
    }
  },

  emits: ["clickAction"],
  setup(props, { emit }) {
    const clickedAction = (action) => {
      emit("clickAction", action)
    }

    return {
      props,
      clickedAction
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/scss/_variables";
@import "src/assets/v3scss/_variables";
.bulk-container {
  display: flex;
  margin-top: 4px;
  .title {
    margin-top: 6px;
    font-size: 12px;
  }
  span {
    margin-left: 4px;
    font-size: 14px;
    cursor: pointer;
    i {
      color: $base-blue;
      font-size: large !important;
    }
  }
}
</style>
>
