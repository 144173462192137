<template>
  <div ref="dropdown" id="dropdown-container" class="position-relative">
    <div @click="toggleDropdown">
      <div
        class="visitor-student-select-action"
        :class="{
          active: dropdownOpen
        }"
      >
        {{ placeholder }}
      </div>
    </div>
    <div
      ref="dropdown"
      v-if="dropdownOpen"
      class="visitor-student-select-dropdown ps__child--consume"
    >
      <!-- STUDENT LIST -->
      <ul>
        <li
          v-for="(student, index) in students"
          :key="index"
          class="d-flex gap-2"
        >
          <CustomCheck
            type="checkbox"
            :id="'student-' + index"
            :model-value="selectedIndices.includes(index)"
            @change="(value) => updateCheckboxInput(value, index)"
          />
          <label :for="'student-' + index">
            {{ student.first_name + " " + student.last_name }}
            <span
              v-if="getGuardianDetails(student.guardians)"
              class="opacity-50"
            >
              {{ getGuardianDetails(student.guardians) }}
            </span>
          </label>
        </li>
      </ul>
      <!-- STUDENT LIST -->

      <!-- SERVER ERROR -->
      <div v-if="serverError != null" class="p-3">
        <VisitorErrorHandler
          v-if="serverError != null"
          :error="serverError"
          center
          @done="serverError = null"
        />
      </div>
      <!-- SERVER ERROR -->

      <!-- ADD STUDENT FORM -->
      <div v-if="showAddForm" class="d-flex gap-2 px-3 py-2">
        <InputField
          type="text"
          placeholder="First Name"
          v-model="newStudent.first_name"
        />
        <InputField
          type="text"
          placeholder="Last Name"
          v-model="newStudent.last_name"
        />
        <LoadingButton
          type="button"
          solid
          :disabled="isNewUserAddBtnDisabled"
          :is-loading="isLoading"
          rounded
          @click.stop="addNewStudent"
        >
          Add
        </LoadingButton>
      </div>
      <!-- ADD STUDENT FORM -->

      <!-- ADD STUDENT BUTTON -->
      <div v-else class="d-flex px-3 py-2 pb-3">
        <BaseButton
          type="button"
          prepend-icon="ri-add-line"
          rounded
          @click.stop="showAddStudentForm"
        >
          Add student
        </BaseButton>
      </div>
      <!-- ADD STUDENT BUTTON -->
    </div>
  </div>
</template>
<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import CustomCheck from "@/v3components/shared/Form/CustomCheck.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import visitorConstants from "@/constants/visitorConstants"
import { ref, computed, reactive, onMounted, onUnmounted, watch } from "vue"
import { useStore } from "vuex"
export default {
  name: "VisitorStudentSelect",
  components: {
    CustomCheck,
    InputField,
    BaseButton,
    LoadingButton,
    VisitorErrorHandler
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    visitorFirstName: {
      type: String,
      default: ""
    },
    visitorLastName: {
      type: String,
      default: ""
    }
  },
  emits: ["input"],
  setup(props, { emit }) {
    const store = useStore()

    const dropdownOpen = ref(false)
    const students = ref([])
    const isLoading = ref(false)
    const serverError = ref(null)
    const selectedIndices = ref([])
    const newStudent = reactive({
      student_id: "",
      first_name: "",
      last_name: "",
      guardians: []
    })
    const showAddForm = ref(false)

    const isNewUserAddBtnDisabled = computed(() => {
      return (
        newStudent?.first_name?.trim()?.length === 0 ||
        newStudent?.last_name?.trim()?.length === 0
      )
    })

    const placeholder = computed(() => {
      if (selectedIndices.value.length === 0) {
        return "Select student(s)"
      }

      return (
        `${selectedIndices.value.length}` +
        `${selectedIndices.value.length > 1 ? " students" : " student"} selected`
      )
    })

    const toggleDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value
    }

    const showAddStudentForm = () => {
      showAddForm.value = true
    }

    const prepareValidateStudentPayload = (students) => {
      const payload = {
        firstName: props?.visitorFirstName || "",
        lastName: props?.visitorLastName || "",
        students
      }
      if (props?.visitorId) {
        payload.visitorId = props?.visitorId || ""
      }
      return payload
    }

    const validateStudentDetails = async (students) => {
      const validatePayload = prepareValidateStudentPayload(students)

      const { error, message, type, data } = await store.dispatch(
        "visitorManage/checkVisitorGuardian",
        validatePayload
      )

      if (error) {
        throw new Error(message)
      }

      if (
        !error &&
        type ===
          visitorConstants.CHECK_IN_FORM.CHECK_VISITOR_GUARDIAN_RESPONSE_TYPES
            .VALID_STUDENT_NON_GUARDIAN_DETAILS
      ) {
        const students =
          data?.students?.map((student) => {
            return {
              student_id: student?.student?.studentId,
              first_name: student?.student?.firstName,
              last_name: student?.student?.lastName,
              guardians: student?.guardians
            }
          }) || []
        return students
      }

      if (
        !error &&
        type ===
          visitorConstants.CHECK_IN_FORM.CHECK_VISITOR_GUARDIAN_RESPONSE_TYPES
            .VALID_STUDENT_DETAILS
      ) {
        const students =
          data?.students?.map((student) => {
            return {
              student_id: student.studentId,
              first_name: student.firstName,
              last_name: student.lastName,
              guardians: []
            }
          }) || []
        return students
      }
    }

    const addNewStudent = async () => {
      const validateStudent = [
        {
          first_name: newStudent.first_name,
          last_name: newStudent.last_name
        }
      ]
      try {
        isLoading.value = true
        const validatedStudents = await validateStudentDetails(validateStudent)
        if (validatedStudents.length === 0) {
          return
        }
        addStudentsToDropDown(validatedStudents)
        newStudent.first_name = ""
        newStudent.last_name = ""
        showAddForm.value = false
      } catch (error) {
        serverError.value = error
      } finally {
        isLoading.value = false
      }
    }

    const updateCheckboxInput = (value, index) => {
      if (value) {
        selectedIndices.value.push(index)
      } else {
        selectedIndices.value = selectedIndices.value.filter(
          (selectedIndex) => selectedIndex !== index
        )
      }
      if (selectedIndices.value.length === 0) {
        emit("input", [])
      } else {
        emit(
          "input",
          selectedIndices.value.map(
            (selectedIndex) => students.value[selectedIndex]
          )
        )
      }
    }

    const addStudentsToDropDown = (newStudents) => {
      newStudents.forEach((newStudent) => {
        const duplicate = students.value.some(
          (student) =>
            Number(student.student_id) === Number(newStudent.student_id)
        )
        if (!duplicate) {
          students.value = [
            ...students.value,
            {
              first_name: newStudent?.first_name || "",
              last_name: newStudent?.last_name || "",
              student_id: newStudent?.student_id || "",
              guardians: newStudent?.guardians || []
            }
          ]
        }
      })
    }

    const getGuardianDetails = (guardians) => {
      if (!guardians || guardians.length === 0) {
        return ""
      }

      return `(${guardians.map((guardian) => guardian?.firstName + " " + guardian?.lastName + " - " + guardian?.phoneNumber + " ").join(", ")})`
    }

    const handleClickOutside = (event) => {
      if (dropdownOpen.value && !event.target.closest("#dropdown-container")) {
        dropdownOpen.value = false
      }
    }

    onMounted(() => {
      document.addEventListener("click", handleClickOutside)
      students.value = [...props.modelValue]
      selectedIndices.value = Array.from({ length: students.value.length }).map(
        (_, i) => i
      )
    })

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside)
    })

    watch(
      () => props.modelValue,
      (newValue) => {
        const indices = [...students.value]
          .map((student, index) => {
            return newValue.some(
              (newStudent) =>
                newStudent.first_name === student.first_name &&
                newStudent.last_name === student.last_name
            )
              ? index
              : null
          })
          .filter((index) => index !== null)
        selectedIndices.value = indices
      }
    )

    return {
      dropdownOpen,
      isLoading,
      serverError,
      placeholder,
      students,
      selectedIndices,
      newStudent,
      showAddForm,
      toggleDropdown,
      showAddStudentForm,
      isNewUserAddBtnDisabled,
      addNewStudent,
      getGuardianDetails,
      updateCheckboxInput
    }
  }
}
</script>

<style scoped>
.opacity-50 {
  opacity: 0.5;
}
</style>
