<template>
  <div>
    <CContainer>
      <CRow class="justify-content-center mt-4">
        <CCol md="12 text-center">
          <h1 class="mb-4">Setup Tardy Appointments</h1>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
export default {
  name: "SetupTardyAppointments",
  components: {},
  computed: {}
}
</script>
