<template>
  <div class="detentions-datatable-container">
    <ActionPanel
      v-if="false"
      @tab-clicked="setActiveTab"
      class="mb-3"
      :tabs="tabs"
      :help-button="true"
      help-button-content-key="tardy_detentions_assigned_staff"
    >
      <template #right-content>
        <IconButton
          v-if="!isDetentionMode"
          smaller
          icon="lh-normal ri-add-line"
          class="solid full-rounded"
          @click="showDetentionAdultForm"
        ></IconButton>
      </template>
    </ActionPanel>
    <DataTable :fields="activeTable.fields" :items="activeTable.items">
      <template #name="{ item }">
        <td
          :style="`background-color:${
            item.color ? item.color : 'white'
          }; color:${isColorDark(item.color) ? '#fff' : ''}`"
        >
          {{ item.name }}
        </td>
      </template>
      <template #room="{ item }">
        <td>{{ item.room.name }}</td>
      </template>
      <template #max_cap="{ item }">
        <td>
          {{ item.max_cap === null ? "-" : item.max_cap }}
        </td>
      </template>
      <template #action="{ item }">
        <td class="text-end">
          <Actions
            v-if="activeTable"
            :actions="activeTable.actions"
            @click-action="handleTableAction($event, item)"
          >
          </Actions>
        </td>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { onMounted, computed, reactive, inject } from "vue"
import { useStore } from "vuex"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel.vue"
import DataTable from "@/v3components/shared/DataTable/DataTable.vue"
import Actions from "@/v3components/shared/DataTable/Actions.vue"
import DetentionForm from "@/v3components/Forms/CreateTardyDetentionForm.vue"
import AddDetentionAdultForm from "@/v3components/Forms/AddDetentionAdultForm.vue"
import IconButton from "@/v3components/shared/Buttons/IconButton.vue"
export default {
  name: "TardyDetentionsTable",
  components: { ActionPanel, DataTable, Actions, IconButton },
  setup() {
    const store = useStore()
    const modal = inject("modal")
    const actionDialog = inject("actionDialog")

    const state = reactive({
      isProcessing: false,
      activeTab: null
    })

    onMounted(() => {
      state.activeTab = tabs[0]
      getDetentions()
    })

    const activeSchool = computed(() => store.getters["schools/activeSchool"])
    const activeTable = computed(() => {
      return !isDetentionMode.value
        ? {
            items: detentionAdults.value,
            fields: detentionAdultsTableFields,
            actions: detentionAdultsActions
          }
        : {
            items: detentions.value,
            fields: detentionTableFields,
            actions: detentionActions
          }
    })

    //detentions
    const detentions = computed(() => store.getters["detentions/detentions"])
    const editableDetention = computed(
      () => store.getters["detentions/editableDetention"]
    )
    const isDetentionMode = computed(
      () => state.activeTab && state.activeTab.value === "detentions"
    )
    const getDetentions = () => {
      store.dispatch("detentions/getDetentions")
    }
    const deleteDetention = (item) => {
      state.isProcessing = true
      store.dispatch("detentions/deleteDetention", item.id).finally(() => {
        state.isProcessing = false
      })
    }
    const detentionActions = [
      { label: "Edit", icon: "ri-checkbox-circle-line", action: "edit" },
      {
        label: "Delete",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete"
      }
    ]
    const detentionTableFields = [
      { key: "name", label: "Detention name", sorter: false, filter: false },
      { key: "room", label: "Room", sorter: false, filter: false },
      {
        key: "max_cap",
        label: "Max capacity",
        _style: "width: 150px",
        sorter: false,
        filter: false
      },
      {
        key: "action",
        label: "Action",
        _style: "text-align:end; width: 50px",
        sorter: false,
        filter: false
      }
    ]
    //detentionAdults
    const detentionAdults = computed(
      () => store.getters["detentionAdults/detentionAdults"]
    )
    const getDetentionAdults = () => {
      store.dispatch("detentionAdults/getDetentionAdults")
    }
    const deleteDetentionAdult = (adult) => {
      state.isProcessing = true
      store
        .dispatch("detentionAdults/deleteDetentionAdult", {
          user_id: adult.id,
          school_id: activeSchool.value.id
        })
        .finally(() => {
          state.isProcessing = false
        })
    }
    const showDetentionAdultForm = () => {
      modal.open(AddDetentionAdultForm, {
        size: "sm",
        title: "Add new adult that can take detention"
      })
    }
    const detentionAdultsActions = [
      {
        label: "Delete",
        icon: "ri-delete-bin-line",
        class: "important",
        action: "delete"
      }
    ]
    const detentionAdultsTableFields = [
      { key: "first_name", label: "Adult FN", sorter: false, filter: false },
      { key: "last_name", label: "Adult LN", sorter: false, filter: false },
      { key: "email", label: "Email", sorter: false, filter: false },
      {
        key: "action",
        label: "Action",
        _style: "text-align:end; width: 50px",
        sorter: false,
        filter: false
      }
    ]
    //table
    const tabs = [
      {
        label: "Detentions",
        value: "detentions",
        action: getDetentions
      },
      {
        label: "Assigned Staff",
        value: "assignedStuff",
        action: getDetentionAdults
      }
    ]
    const setActiveTab = (tab) => {
      state.activeTab = tab
      try {
        tab.action()
      } catch (error) {
        throw new Error(error)
      }
    }
    const handleTableAction = (event, item) => {
      if (event.action == "delete") {
        actionDialog.open(
          isDetentionMode.value ? deleteDetention : deleteDetentionAdult,
          {
            args: item,
            props: { danger: true, title: "Delete", question: "Are you sure?" }
          }
        )
      } else {
        store.commit("detentions/SET_EDITABLE_DETENTION", item)
        modal.open(DetentionForm, {
          size: "sm",
          title: "Modify detention",
          props: {
            editableDetention
          }
        })
      }
    }

    const isColorDark = (color) => {
      let r, g, b
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        )

        r = color[1]
        g = color[2]
        b = color[3]
      } else {
        // If RGB --> Convert it to HEX
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        )

        r = color >> 16
        g = (color >> 8) & 255
        b = color & 255
      }

      // HSP (Highly Sensitive Poo)
      const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

      // Using the HSP value, determine whether the color is light or dark
      if (hsp > 148.5) {
        return false
      } else {
        return true
      }
    }

    return {
      tabs,
      setActiveTab,
      handleTableAction,
      activeTable,
      showDetentionAdultForm,
      isDetentionMode,
      isColorDark
    }
  }
}
</script>