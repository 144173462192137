<template>
  <CDropdown
    class="v3-dropdown-menu"
    v-bind="{ autoClose, placement, variant }"
  >
    <CDropdownToggle component="button">
      <slot name="toggler"></slot>
      <div
        v-if="modelValue && !slots.toggler"
        class="d-flex align-items-center"
      >
        <IconButton
          v-if="modelValue.icon"
          class="static"
          :icon="modelValue.icon"
        ></IconButton>
        <span>{{ modelValue.label }}</span>
      </div>
    </CDropdownToggle>
    <CDropdownMenu v-if="items.length">
      <CDropdownItem
        v-for="(item, i) in items"
        :key="i"
        :class="{ active: modelValue && modelValue.value === item.value }"
      >
        <div>
          <CustomCheck
            v-if="checkListMode"
            :model-value="preselected ? true : false"
            @click="setActiveItem(item)"
            class="d-flex align-items-center dropdown-item-wrap"
            :label="item.label"
          ></CustomCheck>
          <div
            @click="setActiveItem(item)"
            class="dropdown-item-wrap d-flex align-items-center"
            :class="{ 'py-0 ps-1': item.icon }"
            v-else
          >
            <IconButton
              class="static p-1"
              v-if="item.icon"
              :icon="item.icon"
            ></IconButton>
            <span>{{ item.label }}</span>
          </div>
        </div>
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import { onMounted, reactive, useSlots } from "vue"
import CustomCheck from "../Form/CustomCheck.vue"
import IconButton from "../Buttons/IconButton.vue"
export default {
  emits: ["update:model-value"],
  components: { CustomCheck, IconButton },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Object,
      default: () => {
        return
      }
    },
    checkListMode: {
      type: Boolean,
      default: false
    },
    autoClose: {
      type: [String, Boolean],
      default: true
    },
    placement: {
      type: String,
      default: "bottom-end"
    },
    variant: {
      type: String,
      default: "nav-item"
    },
    preselected: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit }) {
    const checkedItems = reactive([])
    const slots = useSlots()
    const setActiveItem = (item) => {
      if (props.checkListMode) {
        if (checkedItems.includes(item.value)) {
          checkedItems.splice(checkedItems.indexOf(item.value), 1)
        } else {
          checkedItems.push(item.value)
        }
      }
      emit("update:model-value", props.checkListMode ? checkedItems : item)
    }
    onMounted(() => {
      if (props.preselected) {
        setActiveItem(props.preselected)
      } else if (!slots.toggler) {
        setActiveItem(props.items[0])
      }
    })
    return {
      setActiveItem,
      slots
    }
  }
}
</script>
