<template>
  <div class="v3-status-box" :style="'background-color:' + backgroundColor">
    <div class="v3-status-box-content">
      <i v-if="icon" :style="'color:' + iconColor" :class="icon"></i>
      <span v-if="title" :style="'color:' + titleColor">{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusBox",
  props: {
    title: {
      type: String,
      default: ""
    },
    titleColor: {
      type: String,
      default: "#141D24"
    },
    backgroundColor: {
      type: String,
      default: "#ffffff"
    },
    icon: {
      type: String,
      default: "ri-flag-2-fill"
    },
    iconColor: {
      type: String,
      default: "#141D24"
    }
  }
}
</script>
