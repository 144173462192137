const imageXML = (image) => {
  return `<ObjectInfo>
    <ImageObject>
      <Name>GRAPHIC</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <Image>${image}</Image>
      <ScaleMode>Uniform</ScaleMode>
      <BorderWidth>0</BorderWidth>
      <BorderColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <HorizontalAlignment>Left</HorizontalAlignment>
      <VerticalAlignment>Center</VerticalAlignment>
    </ImageObject>
    <Bounds X="335.9998" Y="1243.397" Width="1577.48" Height="1790.377"/>
  </ObjectInfo>`
}

const labelXML = (imageXml, name, date, descriptionLabel, descriptionValue) => {
  descriptionLabel = descriptionLabel.toUpperCase()
  descriptionValue = descriptionValue.toUpperCase()
  return `<?xml version="1.0" encoding="utf-8"?>
    <DieCutLabel Version="8.0" Units="twips" MediaType="Default">
      <PaperOrientation>Landscape</PaperOrientation>
      <Id>NameBadge</Id>
      <PaperName>30256 Shipping</PaperName>
      <DrawCommands>
        <RoundRectangle X="0" Y="0" Width="3331" Height="5760" Rx="180" Ry="180" />
      </DrawCommands>
      <ObjectInfo>
        <TextObject>
          <Name>TEXT</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
          <LinkedObjectName></LinkedObjectName>
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <HorizontalAlignment>Center</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>None</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText>
            <Element>
              <String>VISITOR</String>
              <Attributes>
                <Font Family="Arial" Size="24" Bold="True" Italic="False" Underline="False" Strikeout="False"/>
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
              </Attributes>
            </Element>
          </StyledText>
        </TextObject>
        <Bounds X="335.9998" Y="57.6001" Width="5037.678" Height="730.8279"/>
      </ObjectInfo>
      <ObjectInfo>
        <TextObject>
          <Name>TEXT_1</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
          <LinkedObjectName></LinkedObjectName>
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <HorizontalAlignment>Left</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>None</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText>
            <Element>
              <String>NAME</String>
              <Attributes>
                <Font Family="Arial" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
              </Attributes>
            </Element>
          </StyledText>
        </TextObject>
        <Bounds X="1987.2" Y="1213.94" Width="1408.057" Height="583.8463"/>
      </ObjectInfo>
      ${imageXml}
      <ObjectInfo>
        <ShapeObject>
          <Name>SHAPE_1</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
          <LinkedObjectName></LinkedObjectName>
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <ShapeType>HorizontalLine</ShapeType>
          <LineWidth>20</LineWidth>
          <LineAlignment>LeftOrTop</LineAlignment>
          <FillColor Alpha="0" Red="0" Green="0" Blue="0"/>
        </ShapeObject>
        <Bounds X="1929.6" Y="1280.657" Width="3744" Height="166.5814"/>
      </ObjectInfo>
      <ObjectInfo>
        <ShapeObject>
          <Name>SHAPE_3</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
          <LinkedObjectName></LinkedObjectName>
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <ShapeType>HorizontalLine</ShapeType>
          <LineWidth>20</LineWidth>
          <LineAlignment>LeftOrTop</LineAlignment>
          <FillColor Alpha="0" Red="0" Green="0" Blue="0"/>
        </ShapeObject>
        <Bounds X="1929.6" Y="1965.44" Width="3744" Height="278.7301"/>
      </ObjectInfo>
      <ObjectInfo>
        <ShapeObject>
          <Name>SHAPE_4</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
          <LinkedObjectName></LinkedObjectName>
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <ShapeType>HorizontalLine</ShapeType>
          <LineWidth>20</LineWidth>
          <LineAlignment>LeftOrTop</LineAlignment>
          <FillColor Alpha="0" Red="0" Green="0" Blue="0"/>
        </ShapeObject>
        <Bounds X="1929.6" Y="2729.717" Width="3744" Height="259.317"/>
      </ObjectInfo>
      <ObjectInfo>
        <TextObject>
          <Name>TEXT_3</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
          <LinkedObjectName></LinkedObjectName>
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <HorizontalAlignment>Left</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>None</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText>
            <Element>
              <String>DATE</String>
              <Attributes>
                <Font Family="Arial" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
              </Attributes>
            </Element>
          </StyledText>
        </TextObject>
        <Bounds X="1987.2" Y="2127.419" Width="678.2838" Height="302.6297"/>
      </ObjectInfo>
      <ObjectInfo>
        <TextObject>
          <Name>TEXT_4</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
          <LinkedObjectName></LinkedObjectName>
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <HorizontalAlignment>Left</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>None</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText>
            <Element>
              <String>${descriptionLabel}</String>
              <Attributes>
                <Font Family="Arial" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
              </Attributes>
            </Element>
          </StyledText>
        </TextObject>
        <Bounds X="1987.2" Y="2790.13" Width="3456" Height="392.9103"/>
      </ObjectInfo>
      <ObjectInfo>
        <TextObject>
          <Name>TEXT_2</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
          <LinkedObjectName></LinkedObjectName>
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <HorizontalAlignment>Left</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>None</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText>
            <Element>
              <String>${name}</String>
              <Attributes>
                <Font Family="Arial" Size="11" Bold="True" Italic="False" Underline="False" Strikeout="False"/>
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
              </Attributes>
            </Element>
          </StyledText>
        </TextObject>
        <Bounds X="1929.6" Y="1035.328" Width="3744" Height="370.9227"/>
      </ObjectInfo>
      <ObjectInfo>
        <TextObject>
          <Name>TEXT_5</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
          <LinkedObjectName></LinkedObjectName>
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <HorizontalAlignment>Left</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>None</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText>
            <Element>
              <String>${date}</String>
              <Attributes>
                <Font Family="Arial" Size="11" Bold="True" Italic="False" Underline="False" Strikeout="False"/>
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
              </Attributes>
            </Element>
          </StyledText>
        </TextObject>
        <Bounds X="1929.6" Y="1777.828" Width="3744" Height="343.259"/>
      </ObjectInfo>
      <ObjectInfo>
        <TextObject>
          <Name>TEXT_6</Name>
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
          <LinkedObjectName></LinkedObjectName>
          <Rotation>Rotation0</Rotation>
          <IsMirrored>False</IsMirrored>
          <IsVariable>False</IsVariable>
          <HorizontalAlignment>Left</HorizontalAlignment>
          <VerticalAlignment>Middle</VerticalAlignment>
          <TextFitMode>None</TextFitMode>
          <UseFullFontHeight>True</UseFullFontHeight>
          <Verticalized>False</Verticalized>
          <StyledText>
            <Element>
              <String>${descriptionValue}</String>
              <Attributes>
                <Font Family="Arial" Size="11" Bold="True" Italic="False" Underline="False" Strikeout="False"/>
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
              </Attributes>
            </Element>
          </StyledText>
        </TextObject>
        <Bounds X="1929.6" Y="2446.478" Width="3744" Height="379.2859"/>
      </ObjectInfo>
    </DieCutLabel>`
}

const printerWindowTemplate = (printContent) => {
  return `
      <!DOCTYPE html>
      <html>
        <head>
          <title>Visitor Badge Print</title>
          <style>
              .visitor-pbadge {
                  position: absolute !important;
                  inset: 0 !important;
                  display: flex;
                  flex-direction: column;
                  gap: 0.25rem;
                  align-items: center;
                  justify-content: center;
                  padding: 0.5rem;
                  box-sizing: border-box;
                  transform: rotate(90deg) scale(1.2);
                  border: 1px solid #333;
                  font-family: 'Arial', sans-serif;
              }

              .visitor-pbadge-title {
                  display: block !important;
                  width: 100% !important;
                  min-width: 100% !important;
                  max-width: 100% !important;
                  text-align: center;
                  color: #333;
                  font-size: 5vw;
                  font-weight: 500;
                  border-bottom: 2px solid #333;
                  overflow: hidden;
                  white-space: nowrap;
                  word-break: break-all;
                  text-overflow: ellipsis;
              }

              .visitor-pbadge-info-container {
                  width: 100% !important;
                  max-width: 100% !important;
                  min-width: 100% !important;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 0.5rem;
              }

              .visitor-pbadge-profile-pic {
                  display: block !important;
                  width: 50% !important;
                  max-width: 50% !important;
                  min-width: 50% !important;
                  aspect-ratio: 1 / 1 !important;
                  border-radius: 8px;
                  object-fit: cover;
                  object-position: center;
              }

              .visitor-pbadge-info {
                  width: 50% !important;
                  max-width: 50% !important;
                  min-width: 50% !important;
                  overflow: hidden;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
              }

              .visitor-pbadge-name,
              .visitor-pbadge-role,
              .visitor-pbadge-reason,
              .visitor-pbadge-date {
                  width: 100% !important;
                  min-width: 100% !important;
                  max-width: 100% !important;
                  word-break: break-word;
                  overflow-wrap: break-word;
                  color: #333;
                  font-size: 4vw;
                  margin-bottom: 8px;
              }
          </style>
        </head>
        <body>
          ${printContent}
        </body>
      </html>
    `
}

const xmlConstants = {
  useImageXMLTemplate: imageXML,
  useLabelXMLTemplate: labelXML,
  usePrinterWindowTemplate: printerWindowTemplate
}

export default xmlConstants
