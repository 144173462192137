<template>
  <div class="status-guide">
    <div class="status-list">
      <CCheckBox
        label="Student Number"
        :checked="aptFilterData.student_sis_id"
        name="student_sis_id"
        class="custom-checkbox"
        @change="updateFilters"
        :inline="true"
        :custom="true"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"

export default {
  name: "DashboardTimeFiltersOld",
  setup() {
    const store = useStore()

    const aptFilterData = computed(
      () => store.getters["dashboardTable/aptFilterData"]
    )

    const updateFilters = (event) => {
      store.commit("dashboardTable/UPDATE_APT_FILTERS_DATA", {
        filter: event.target.name,
        value: event.target.checked
      })
    }

    return {
      aptFilterData,
      updateFilters
    }
  }
}
</script>
