<template>
  <div class="no-target" ref="root">
    <CModal
      backdrop="static"
      class="ps__child--consume"
      :visible="state.modal.isVisible"
      :close-on-backdrop="false"
    >
      <div class="pt-4 px-4 create-popup-header d-flex align-items-center">
        <div
          class="d-inline-block bg-gradient-blue text-white rounded-circle p-1 lh-normal cursor-pointer"
          @click="closeModal()"
        >
          <span class="close-icon no-target"></span>
        </div>
        <div class="ps-4" v-if="studentName">
          <p class="mb-0">{{ studentName }}</p>
        </div>
      </div>
      <div v-if="activePass" class="px-3">
        <CommentsComponent
          ref="CommentsComponent"
          :to-i-d="activePass.id"
          :commentable-type="commentableType"
          :silent="false"
          @comment-created="closeModal(), onCommentCreated($event)"
        />
      </div>
      <template #footer-wrapper>
        <div></div>
      </template>
    </CModal>
    <div class="comments-column">
      <div class="ended-by">
        <div v-if="passEndedBy" class="mb-2">
          {{ passEndedBy }}
        </div>
      </div>
      <div v-if="pass.latest_comment" class="d-flex">
        <div class="comments-count me-2" @click="showModal()">
          <i class="fi flaticon-chat no-target"></i
          ><b class="no-target">{{ pass.comments_count }}</b>
        </div>
        <div
          v-show="!isBasicView"
          v-if="pass.latest_comment"
          class="comments-list cursor-pointer"
          @click="showModal()"
        >
          <div class="comment" data-test-id="dashboard-row-pass-comment">
            {{ pass.latest_comment.comment }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed, ref } from "vue"
import { useStore } from "vuex"
import CommentsComponent from "@/v3components/CommentsComponentOld.vue"
import helpers from "@/helpers/index"

export default {
  name: "PassComments",
  emits: ["commentCreated"],
  components: {
    CommentsComponent
  },
  props: ["pass", "commentableType", "isBasicView", "isTabular"],
  setup(props, { emit }) {
    const store = useStore()

    const state = reactive({
      modal: {
        isVisible: false
      }
    })

    const activePass = computed(
      () => store.getters["dashboardTable/activeEditablePass"]
    )

    const passEndedBy = computed(() => {
      if (
        (props.pass.badge_flags && props.pass.badge_flags.system_completed) ||
        (props.pass.badge_flags &&
          props.pass.badge_flags.extended &&
          props.pass.badge_flags.ended)
      ) {
        return "Ended by System"
      }
      if (props.pass.child) {
        return props.pass.child.completed_by_user &&
          props.pass.child.completed_by_user.name
          ? "Ended by " + props.pass.child.completed_by_user.name
          : ""
      } else {
        return props.pass.completed_by_user
          ? "Ended by " + props.pass.completed_by_user.name
          : ""
      }
    })

    const studentName = computed(() => {
      return activePass.value && activePass.value.user
        ? activePass.value.user.first_name +
            " " +
            activePass.value.user.last_name
        : null
    })

    const root = ref(null)
    const CommentsComponent = ref(null)

    const closeModal = () => {
      state.modal.isVisible = false
      if (CommentsComponent.value) {
        CommentsComponent.value.resetForm()
      }
      helpers.setModalToggleClass(false)
      setTimeout(() => {
        const elem = root.value.closest(".vue-recycle-scroller__item-view")
        if (elem) {
          elem.removeAttribute("comments-modal", true)
        }
        store.commit("dashboardTable/SET_ACTIVE_EDITABLE_PASS", null)
        store.commit("comments/SET_COMMENTS", [])
      }, 100)
    }

    const showModal = () => {
      store.commit("dashboardTable/SET_ACTIVE_EDITABLE_PASS", props.pass)
      helpers.setModalToggleClass(true)
      setTimeout(() => {
        const elem = root.value.closest(".vue-recycle-scroller__item-view")
        if (elem) {
          elem.setAttribute("comments-modal", true)
        }
        state.modal.isVisible = true
      }, 100)
    }

    const onCommentCreated = (data) => {
      emit("commentCreated", data)
    }

    return {
      state,
      activePass,
      passEndedBy,
      studentName,
      root,
      CommentsComponent,
      closeModal,
      showModal,
      onCommentCreated
    }
  }
}
</script>

<style lang="scss">
.vue-recycle-scroller__item-wrapper {
  .vue-recycle-scroller__item-view[comments-modal] {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 999999999999999 !important;
    z-index: 999999999999999 !important;
    transform: none !important;
    & .t-row {
      border-color: transparent;
    }
    & .t-row > div {
      visibility: hidden;
    }
    & .t-row > div.no-target {
      visibility: visible;
    }
    .comments-column {
      visibility: hidden !important;
    }
    .modal {
      overflow: visible !important;
    }
  }
}
</style>
