<template>
  <RoomsDataTable />
</template>
<script>
import RoomsDataTable from "@/v3components/Datatables/RoomsDataTable.vue"
export default {
  name: "Rooms",
  components: { RoomsDataTable }
}
</script>
