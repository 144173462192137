import { apiV7nocache } from "../axiousWithCache"

const state = {
  exceededIncidents: null
}

const getters = {
  exceededIncidents(state) {
    return state.exceededIncidents
  }
}

const mutations = {
  SET_EXCEEDED_INCIDENTS(state, exceededIncidents) {
    state.exceededIncidents = exceededIncidents
  }
}

const actions = {
  getExceededIncidents(context) {
    return new Promise((resolve, reject) => {
      apiV7nocache
        .get("/admin/tardy/ladder/exceeded-incidents")
        .then((response) => {
          const data = response.data
          context.commit("SET_EXCEEDED_INCIDENTS", data.data.incidents_reached)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
