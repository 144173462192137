import axios from "axios"

const state = {
  activePassLimits: null,
  activePassFlags: null
}

const getters = {
  activePassLimits(state) {
    return state.activePassLimits
  }
}

const mutations = {
  SET_ACTIVE_PASS_LIMITS(state, data) {
    state.activePassLimits = data
  },
  SET_ACTIVE_PASS_LIMITS_LISENER(state, data) {
    state.activePassLimits = {
      ...state.activePassLimits,
      limit: data.newLimit == null ? "-" : data.newLimit,
      student_pass: !!data.studentPass,
      proxy_pass: !!data.proxyPass,
      kiosk_pass: !!data.kioskPass,
      teacher_override: data.teacherOverride == true ? 1 : 0
    }
  }
}

const actions = {
  createActivePassLimit(context, passLimit) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/active-pass-limits`, passLimit)
        .then((response) => {
          context.commit("SET_ACTIVE_PASS_LIMITS", passLimit)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getActivePassLimits(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/active-pass-limits`)
        .then((response) => {
          const data = response.data.data
          if (data) {
            context.commit("SET_ACTIVE_PASS_LIMITS", data)
          }
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
