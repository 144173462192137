<template>
  <div class="v3-custom-switch">
    <input
      :checked="modelValue"
      @change="onChange"
      type="checkbox"
      v-bind="{ name, disabled }"
      :data-test-id="testId"
    />
    <div class="switch-toggler"></div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    testId: {
      type: String,
      default: ""
    },
    modelValue: Boolean
  },
  emits: ["change", "update:model-value"],
  setup(props, { emit }) {
    const onChange = (event) => {
      emit("change", event.target.checked)
      emit("update:model-value", event.target.checked)
    }
    return {
      onChange
    }
  }
}
</script>

<style></style>
