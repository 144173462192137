<template>
  <div
    class="notification-holder"
    :style="`background-color:${
      notification.seen_at ? '#e3e3e3' : colors[notification.data.status]
    }`"
  >
    <span
      class="notification-text-holder"
      :style="`background-color:${
        notification.seen_at ? '#fff' : colors[notification.data.status]
      }; color:${notification.seen_at ? '#000' : '#fff '}`"
      >{{ notification.data.status.toUpperCase() }}</span
    >
    <span :style="`color:${notification.seen_at !== null ? '#000' : '#fff '}`">
      Summary Report {{ notification.data.dates }}
      {{
        notification.data.status === "PROCESSED"
          ? "is ready to be downloaded."
          : "failed."
      }}
    </span>
  </div>
</template>

<script>
export default {
  name: "AdultSummaryReportNotification",
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  setup() {
    const colors = {
      PROCESSED: "#008566",
      FAILED: "#e11d00"
    }

    return { colors }
  }
}
</script>
