<template>
  <div v-if="pass">
    <div v-if="section === 'approved_at'">
      <div
        v-if="
          (pass.approved_by_user &&
            pass.from &&
            pass.type !== 'KSK' &&
            pass.approved_with !== 'autopass') ||
          (pass.approved_by_user &&
            pass.from &&
            pass.approved_with !== 'autopass')
        "
        class="mt-1"
      >
        <div v-if="pass.from_type && pass.from_type == 'App\\Models\\User'">
          <b
            v-show="pass.from.id !== pass.approved_by_user.id"
            :title="
              pass.approved_by_user.first_name +
              ' ' +
              pass.approved_by_user.last_name
            "
            class="processed-by not-same"
            data-test-id="approved-by-user"
          >
            {{ approved_by_user }}
          </b>
        </div>
        <div v-else>
          <b
            :title="
              pass.approved_by_user.first_name +
              ' ' +
              pass.approved_by_user.last_name
            "
            :class="{
              'not-same': !pass.from.user_ids_assigned.includes(
                pass.approved_by
              )
            }"
            class="processed-by"
            >{{ approved_by_user }}</b
          >
        </div>
      </div>
      <div
        v-if="
          pass.from_kiosk &&
          pass.approved_by &&
          pass.approved_by !== pass.from_kiosk.user.id &&
          pass.type === 'KSK'
        "
      >
        <div v-if="pass.approved_with === 'autopass'" class="processed-by">
          Auto Pass
        </div>
        <div v-else>
          <b
            class="processed-by"
            :class="{ 'not-same': pass.approved_by_user.role_id !== 1 }"
            >kiosk</b
          >
        </div>
      </div>
      <div
        v-if="
          pass.type !== 'KSK' &&
          pass.approved_with &&
          pass.approved_with === 'autopass'
        "
        class="processed-by mt-1"
      >
        Auto Pass
      </div>
    </div>

    <div v-if="section === 'completed_at'">
      <div v-if="pass.completed_by && pass.to && !pass.ended_with">
        <div v-if="pass.to_type && pass.to_type == 'App\\Models\\User'">
          <b
            v-show="pass.completed_by !== pass.to_id"
            :title="
              pass.completed_by_user.first_name +
              ' ' +
              pass.completed_by_user.last_name
            "
            class="processed-by not-same"
            data-test-id="completed-by-user"
            >{{ completed_by }}</b
          >
        </div>
        <div v-else>
          <b
            v-if="
              pass.to.trip_type == 'Roundtrip' &&
              ((pass.from_type === 'App\\Models\\User' &&
                pass.from.id !== pass.completed_by_user.id) ||
                (pass.created_by !== pass.completed_by &&
                  pass.from_type === 'App\\Models\\Room'))
            "
            :title="
              pass.completed_by_user.first_name +
              ' ' +
              pass.completed_by_user.last_name
            "
            :class="{
              'not-same': !pass.to.user_ids_assigned.includes(pass.completed_by)
            }"
            class="processed-by"
            >{{ completed_by }}</b
          >
          <b v-else>
            <div
              v-if="pass.ended_with && pass.ended_with === 'autopass'"
              class="processed-by"
            >
              Auto Pass
            </div>
            <div v-else>
              <div
                v-if="pass.to && pass.to.trip_type !== 'Roundtrip'"
                :title="
                  pass.completed_by_user.first_name +
                  ' ' +
                  pass.completed_by_user.last_name
                "
                :class="{
                  'not-same': !pass.to.user_ids_assigned.includes(
                    pass.completed_by
                  )
                }"
                class="processed-by"
              >
                {{ completed_by }}
              </div>
            </div>
          </b>
        </div>
      </div>

      <div v-if="pass.to_kiosk">
        <b
          v-if="
            (pass.ended_with !== 'autopass' &&
              pass.to_type == 'App\\Models\\User' &&
              !pass.to_kiosk.room &&
              pass.to_kiosk.user.id === pass.to_id) ||
            (pass.ended_with !== 'autopass' &&
              pass.to_type == 'App\\Models\\Room' &&
              pass.to_kiosk.room &&
              pass.to_kiosk.room.id === pass.to_id &&
              pass.to.trip_type !== 'Roundtrip') ||
            (pass.ended_with !== 'autopass' &&
              pass.to.trip_type == 'Roundtrip' &&
              pass.created_by_kiosk.id === pass.to_kiosk.id)
          "
          class="processed-by"
        >
          kiosk</b
        >
        <b v-else>
          <div
            v-if="
              pass.ended_with &&
              pass.ended_with === 'autopass' &&
              !pass.to_kiosk.room
            "
            class="processed-by"
          >
            Auto Pass
          </div>
          <div v-else>
            <div
              v-if="pass.to_kiosk && pass.to_kiosk.room && pass.to_kiosk.user"
            >
              <span
                v-if="pass.from.id === pass.to_kiosk.room.id"
                class="processed-by"
              >
                kiosk
              </span>
              <span v-else class="processed-by not-same">kiosk</span>
            </div>

            <div
              v-if="pass.to_kiosk && !pass.to_kiosk.room && pass.to_kiosk.user"
            >
              <span
                v-if="pass.from.id === pass.to_kiosk.user.id"
                class="processed-by"
              >
                kiosk
              </span>
              <span v-else class="processed-by not-same">kiosk</span>
            </div>
          </div>
        </b>
      </div>

      <div
        v-if="
          pass.ended_with && pass.ended_with === 'autopass' && !pass.to_kiosk
        "
        class="processed-by mt-1"
      >
        Auto Pass
      </div>
      <div
        v-if="pass.ended_with && pass.ended_with === 'checkin'"
        class="processed-by mt-1"
      >
        Check-in
      </div>
      <div
        v-if="
          (!pass.child && pass.badge_flags.system_completed) ||
          (!pass.child && pass.badge_flags.extended && pass.badge_flags.ended)
        "
        class="processed-by not-same mt-1 fw-bold"
      >
        System Ended
      </div>
    </div>

    <div v-if="section === 'child_approved_at'">
      <div
        v-if="
          pass.child &&
          pass.child.approved_by &&
          pass.child.from &&
          !pass.child.approved_with
        "
      >
        <div
          v-if="
            pass.child.from_type && pass.child.from_type == 'App\\Models\\User'
          "
        >
          <b
            v-show="pass.child.approved_by !== pass.child.from.id"
            :title="
              pass.child.approved_by_user.first_name +
              ' ' +
              pass.child.approved_by_user.last_name
            "
            class="processed-by not-same"
            >{{ child_approved_by_user }}</b
          >
        </div>
        <div v-else>
          <b
            :title="
              pass.child.approved_by_user.first_name +
              ' ' +
              pass.child.approved_by_user.last_name
            "
            :class="{
              'not-same': !pass.child.from.user_ids_assigned.includes(
                pass.child.approved_by
              )
            }"
            class="processed-by"
            >{{ child_approved_by_user }}</b
          >
        </div>
      </div>
      <div
        v-if="
          pass &&
          pass.child &&
          pass.child.approved_with &&
          pass.child.approved_with === 'autopass' &&
          pass.child.from_kiosk &&
          !pass.child.from_kiosk.room
        "
        class="processed-by mt-1"
      >
        Auto Pass
      </div>
      <div
        v-if="
          pass.child &&
          pass.child.from_kiosk &&
          pass.child.approved_with !== 'autopass' &&
          pass.child.ended_with !== 'autopass'
        "
      >
        <b
          v-if="
            (pass.child.from_type == 'App\\Models\\User' &&
              !pass.child.from_kiosk.room &&
              pass.child.from_kiosk.user.id === pass.child.from.id) ||
            (pass.child.from_type == 'App\\Models\\Room' &&
              pass.child.from_kiosk.room &&
              pass.child.from_kiosk.room.id === pass.child.from.id)
          "
          class="processed-by"
        >
          kiosk</b
        >
        <b v-else>
          <span
            v-if="
              (pass.child.from_kiosk &&
                pass.child.from_kiosk.room &&
                !pass.child.from_kiosk.user &&
                pass.child.from_kiosk.room.id === pass.child.to.id) ||
              (pass.child.from_kiosk &&
                pass.child.from_kiosk.user &&
                !pass.child.from_kiosk.room &&
                pass.child.from_kiosk.user.id === pass.child.to.id) ||
              (pass.child.from_kiosk &&
                pass.child.from_kiosk.room &&
                pass.child.from_kiosk.user &&
                pass.child.from_kiosk.room.id === pass.child.to.id)
            "
            class="processed-by"
          >
            kiosk
          </span>
          <span v-else class="processed-by not-same">kiosk</span>
        </b>
      </div>
      <div v-else>
        <div
          v-if="
            pass.child &&
            pass.child.approved_with == 'autopass' &&
            pass.child.from_kiosk &&
            pass.child.from_kiosk.room
          "
        >
          <span
            v-if="
              (pass.child.from_kiosk &&
                pass.child.from_kiosk.room &&
                !pass.child.from_kiosk.user &&
                pass.child.from_kiosk.room.id === pass.child.to.id) ||
              (pass.child.from_kiosk &&
                pass.child.from_kiosk.user &&
                !pass.child.from_kiosk.room &&
                pass.child.from_kiosk.user.id === pass.child.to.id) ||
              (pass.child.from_kiosk &&
                pass.child.from_kiosk.room &&
                pass.child.from_kiosk.user &&
                pass.child.from_kiosk.room.id === pass.child.to.id)
            "
            class="processed-by"
          >
            kiosk
          </span>
          <span v-else class="processed-by not-same">kiosk</span>
        </div>
      </div>

      <div
        v-if="
          (pass.child &&
            !pass.child.approved_by &&
            pass.badge_flags.system_completed) ||
          (pass.child &&
            pass.badge_flags.extended &&
            pass.badge_flags.ended &&
            pass.child &&
            !pass.child.approved_by)
        "
        class="processed-by not-same mt-1 fw-bold"
      >
        System Ended
      </div>
    </div>

    <div v-if="section === 'child_completed_at'">
      <div
        v-if="
          pass.child &&
          pass.child.completed_by &&
          pass.child.to &&
          !pass.child.ended_with
        "
      >
        <div
          v-if="
            pass.child &&
            pass.child.to_type &&
            pass.child.to_type == 'App\\Models\\User'
          "
        >
          <b
            v-show="pass.child.completed_by !== pass.child.to_id"
            :title="
              pass.child.completed_by_user.first_name +
              ' ' +
              pass.child.completed_by_user.last_name
            "
            class="processed-by not-same"
            >{{ child_completed_by_user }}</b
          >
        </div>
        <div v-else>
          <b
            :title="
              pass.child.completed_by_user.first_name +
              ' ' +
              pass.child.completed_by_user.last_name
            "
            :class="{
              'not-same': !pass.child.to.user_ids_assigned.includes(
                pass.child.completed_by
              )
            }"
            class="processed-by"
            >{{ child_completed_by_user }}</b
          >
        </div>
      </div>

      <div
        v-if="
          pass.child &&
          pass.child.to_kiosk &&
          pass.child.approved_with !== 'autopass' &&
          pass.child.ended_with !== 'autopass'
        "
      >
        <b
          v-if="
            (pass.child.to_type == 'App\\Models\\User' &&
              !pass.child.to_kiosk.room &&
              pass.child.to_kiosk.user.id === pass.child.to.id) ||
            (pass.child.to_type == 'App\\Models\\Room' &&
              pass.child.to_kiosk.room &&
              pass.child.to_kiosk.room.id === pass.child.to.id)
          "
          class="processed-by"
          >kiosk</b
        >
        <b v-else class="processed-by not-same">kiosk </b>
      </div>
      <div
        v-if="
          pass &&
          pass.child &&
          pass.child.ended_with &&
          pass.child.ended_with === 'autopass' &&
          pass.child.to_kiosk &&
          !pass.child.to_kiosk.room
        "
        class="processed-by mt-1"
      >
        Auto Pass
      </div>
      <div
        v-if="
          pass &&
          pass.child &&
          pass.child.ended_with &&
          pass.child.ended_with === 'autopass' &&
          pass.child.to_kiosk &&
          pass.child.to_kiosk.room
        "
      >
        <span
          v-if="
            pass.child.to_kiosk &&
            pass.child.to_kiosk.room &&
            pass.child.to_kiosk.room.id === pass.child.to.id
          "
          class="processed-by"
        >
          kiosk
        </span>
        <span v-else class="processed-by not-same">kiosk</span>
      </div>
      <div
        v-if="
          (pass.child &&
            !pass.child.completed_by &&
            pass.child.approved_by &&
            pass.badge_flags.system_completed) ||
          (pass.child &&
            pass.badge_flags.extended &&
            pass.badge_flags.ended &&
            pass.child &&
            !pass.child.completed_by &&
            pass.child.approved_by)
        "
        class="processed-by not-same mt-1 fw-bold"
      >
        System Ended
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import helpers from "@/helpers/index.js"

export default {
  name: "InOutColumnOld",
  props: ["pass", "section"],
  setup(props) {
    const isAutoApprove = computed(() => {
      if (props.pass && props.pass.created_at && props.pass.approved_at) {
        return (
          helpers.date(props.pass.created_at).format("DD-MM-YYYY HH:mm:ss") ===
          helpers.date(props.pass.approved_at).format("DD-MM-YYYY HH:mm:ss")
        )
      }
      return false
    })

    const approved_by_user = computed(() => {
      const fullName =
        props.pass.approved_by_user.first_name +
        " " +
        props.pass.approved_by_user.last_name
      return fullName.length > 11 ? fullName.slice(0, 11) + ".." : fullName
    })

    const completed_by = computed(() => {
      const fullName =
        props.pass.completed_by_user.first_name +
        " " +
        props.pass.completed_by_user.last_name
      return fullName.length > 11 ? fullName.slice(0, 11) + ".." : fullName
    })

    const child_approved_by_user = computed(() => {
      const fullName =
        props.pass.child.approved_by_user.first_name +
        " " +
        props.pass.child.approved_by_user.last_name
      return fullName.length > 11 ? fullName.slice(0, 11) + ".." : fullName
    })

    const child_completed_by_user = computed(() => {
      const fullName =
        props.pass.child.completed_by_user.first_name +
        " " +
        props.pass.child.completed_by_user.last_name
      return fullName.length > 11 ? fullName.slice(0, 11) + ".." : fullName
    })

    return {
      isAutoApprove,
      approved_by_user,
      completed_by,
      child_approved_by_user,
      child_completed_by_user
    }
  }
}
</script>
