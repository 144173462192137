import StudentAppointmentNotification from "./StudentAppointmentNotification.vue"
import AdultSummaryReportNotification from "./AdultSummaryReportNotification.vue"

export const notificationMappings = {
  SUMMARY_REPORT_REQUEST_NOTIFICATION: "AdultSummaryReportNotification",
  STUDENT_APPOINTMENT_NOTIFICATION: "StudentAppointmentNotification"
}

export const notificationComponents = {
  StudentAppointmentNotification,
  AdultSummaryReportNotification
}
