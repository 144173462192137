<template>
  <form
    v-if="data.form !== null"
    @submit.prevent="onHealthFormSubmit"
    class="d-flex flex-grow-1 flex-column gap-4 justify-content-around align-content-around"
  >
    <div class="visitor-health-question-container">
      <div
        v-for="(qstn, index) in data?.form?.questions"
        :key="index"
        class="visitor-health-question-item"
      >
        <div class="d-flex flex-column gap-2">
          <div>
            {{ qstn?.question }}
          </div>
          <div class="d-flex gap-2">
            <CustomCheck
              name="Yes"
              label="Yes"
              v-model="qstn.isCorrectAnswerYes"
              @change="
                (value) => {
                  qstn.isCorrectAnswerNo = !value
                }
              "
            />
            <CustomCheck
              name="No"
              label="No"
              v-model="qstn.isCorrectAnswerNo"
              @change="
                (value) => {
                  qstn.isCorrectAnswerYes = !value
                }
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="w-100">
      <BaseButton
        type="submit"
        :is-loading="data?.form?.isLoading || isLoading"
        prepend-icon="ri-login-box-line"
        solid
        rounded
        :disabled="isButtonDisabled"
      >
        Check in
      </BaseButton>
    </div>
  </form>
</template>

<script>
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import CustomCheck from "@/v3components/shared/Form/CustomCheck.vue"
import { computed, onMounted, reactive, ref, watch } from "vue"

export default {
  name: "VisitorHealthForm",
  components: {
    BaseButton,
    CustomCheck
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    onFormSubmit: {
      type: Function,
      default: () => ({})
    }
  },
  setup(props) {
    const isLoading = ref(false)
    const data = reactive({
      form: null
    })

    const areSomeQuestionsNotFilled = computed(() => {
      return data?.form.questions.some((question) => {
        return question.isCorrectAnswerYes === question.isCorrectAnswerNo
      })
    })

    const isButtonDisabled = computed(() => {
      return areSomeQuestionsNotFilled.value
    })

    const onHealthFormSubmit = async () => {
      if (areSomeQuestionsNotFilled.value) {
        return
      }

      isLoading.value = true
      await props.onFormSubmit(data.form)
      isLoading.value = false
    }

    onMounted(() => {
      data.form = props.form
    })

    watch(
      () => props.form,
      (value) => {
        data.form = value
      }
    )

    return {
      data,
      isLoading,
      isButtonDisabled,
      onHealthFormSubmit
    }
  }
}
</script>

<style scoped></style>
