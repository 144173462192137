<template>
  <div class="d-flex flex-column justify-content-center align-content-center">
    <Label>{{ searchUserPlaceholder }}</Label>
    <div class="w-350px">
      <div v-if="isStudentPickUpOrDropOffReasonSelected">
        <VisitorParentSearch
          placeholder="Search name"
          :options="filter.parentOptions"
          :model-value="filter.value"
          @update:model-value="onValueChange({ value: $event })"
          @search="onParentSearch"
        >
          <template #option="{ value: { value } }">
            <VisitorParentSearchItem :data="value" />
          </template>
        </VisitorParentSearch>
      </div>
      <div v-else class="v3-custom-select ps__child--consume">
        <VSelect
          placeholder="Search name"
          :options="filter.visitorOptions"
          :filterable="false"
          label="label"
          :value="filter.value"
          @update:model-value="onValueChange"
          @search="onVisitorSearch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VisitorParentSearchItem from "@/v3components/Forms/Visitor/CheckIns/VisitorParentSearchItem.vue"
import VisitorParentSearch from "@/v3components/Forms/Visitor/CheckIns/VisitorParentSearch.vue"
import Label from "@/v3components/shared/Form/Label.vue"
import { useStore } from "vuex"
import { reactive } from "vue"
import { debounce } from "lodash"

export default {
  name: "VisitorSearchField",
  components: {
    Label,
    VisitorParentSearch,
    VisitorParentSearchItem
  },
  props: {
    searchUserPlaceholder: {
      type: String,
      default: ""
    },
    isSisSettingEnabled: {
      type: Boolean,
      default: false
    },
    isStudentPickUpOrDropOffReasonSelected: {
      type: Boolean,
      default: false
    }
  },
  emits: ["onError", "onValueChange"],
  setup(props, { emit }) {
    const store = useStore()
    const filter = reactive({
      search: "",
      value: null,
      parentOptions: [],
      visitorOptions: []
    })

    const onParentSearch = debounce(async (search) => {
      if (search.trim().length === 0) {
        filter.parentOptions = []
        return
      }
      try {
        const {
          data: { data = [] }
        } = await store.dispatch("visitorManage/searchParent", {
          search
        })

        if (data?.length === 0) {
          filter.parentOptions = []
          return
        }

        filter.parentOptions =
          data?.map((parent) => {
            return {
              label: `${parent?.parent_first_name} ${parent?.parent_last_name}`,
              value: parent
            }
          }) || []
      } catch (error) {
        emit("onError", error)
      }
    }, 500)

    const onVisitorSearch = debounce(async (search) => {
      if (search.trim().length === 0) {
        filter.visitorOptions = []
        return
      }
      try {
        const {
          data: { data = [] }
        } = await store.dispatch("visitorManage/searchVisitor", {
          search
        })

        if (data?.length === 0) {
          filter.visitorOptions = []
          return
        }

        filter.visitorOptions =
          data?.map((visitor) => {
            return {
              label: `${visitor?.first_name} ${visitor?.last_name}`,
              value: visitor
            }
          }) || []
      } catch (error) {
        emit("onError", error)
      }
    }, 500)

    const onValueChange = (value) => {
      filter.value = value
      emit("onValueChange", value)
    }

    return {
      onParentSearch,
      filter,
      onVisitorSearch,
      onValueChange
    }
  }
}
</script>

<style scoped>
.w-350px {
  width: 350px;
}
</style>
