<template>
  <div v-if="activeTab == 'nextSevenDays'" class="d-flex p-2">
    <div class="d-flex align-items-center">
      Quick filters:
      <CustomDropDown
        :toggler-text="state.quickFilter.label"
        class="ms-2"
        :options="state.selectorsOptions.quickFilter"
        @item-click="setQuickFilter"
      >
      </CustomDropDown>
    </div>
    <div class="d-flex align-items-center ms-3">
      Pass Types:

      <CustomDropDown
        :selected-label="passTypeSelectLabel"
        :toggler-text="state.quickFilter.label"
        class="ms-2"
        :options="state.selectorsOptions.passType"
        @item-click="setPassType"
        :selected-items="passType"
      >
      </CustomDropDown>
    </div>
  </div>
</template>

<script>
import CustomDropDown from "@/v3components/shared/Form/CustomDropDown.vue"

import { useStore } from "vuex"
import { computed, reactive } from "vue"
export default {
  name: "AdultAptPassFilters",
  components: { CustomDropDown },
  setup() {
    const store = useStore()

    const state = reactive({
      quickFilter: "All Passes",
      passType: "All Types",
      selectorsOptions: {
        quickFilter: [
          { label: "All Passes", value: "" },
          { label: "Just My Created", value: "just_my_created" },
          { label: "From Me", value: "from_me" },
          { label: "To me", value: "to_me" },
          { label: "To & From Others", value: "to_and_from_others" }
        ],
        passType: [
          { label: "All Types", value: "" },
          { label: "Activated", value: "type_activated" },
          { label: "Missed APT", value: "type_missed_apt" },
          { label: "Canceled", value: "type_canceled" },
          { label: "Missed Request", value: "type_missed" },
          { label: "Future APT", value: "type_future" },
          { label: "Needs Confirmation", value: "needs_confirmation" },
          { label: "Ended", value: "type_ended" },
          { label: "On Other Pass", value: "type_on_other_pass" }
        ]
      }
    })

    const quickFilter = computed(
      () => store.getters["adultAptPass/quickFilter"]
    )
    const passType = computed(() => store.getters["adultAptPass/passType"])
    const activeTab = computed(() => store.getters["adultAptPass/activeTab"])

    const passTypeSelectLabel = computed(() => {
      if (passType.value.length) {
        const count =
          passType.value.length > 1 ? " +" + (passType.value.length - 1) : ""
        const label = passType.value[passType.value.length - 1].label
        return label + count
      }
      return ""
    })

    const setQuickFilter = (item) => {
      store.commit("adultAptPass/SET_QUICK_FILTER", item)
      store.commit("dashboardTable/SET_DASHBOARD_UPDATES", false)
      store.dispatch("adultAptPass/getAppointmentPasses")
    }

    const setPassType = (item) => {
      store.commit("adultAptPass/SET_PASS_TYPE", item)
      store.commit("dashboardTable/SET_DASHBOARD_UPDATES", false)
      store.dispatch("adultAptPass/getAppointmentPasses")
    }
    return {
      state,
      quickFilter,
      passType,
      activeTab,
      setQuickFilter,
      setPassType,
      passTypeSelectLabel
    }
  }
}
</script>

<style></style>
