export const tableConfig = {
  columnSearchKeys: {
    globalQuery: "",
    user: { value: "", column: ["user.name"], sortBy: "user.last_name" },
    user_id: { value: "", column: ["user.name"], sortBy: "user.last_name" },
    recurrence_appointment_pass: {
      value: "",
      column: ["for_date"],
      sortBy: "for_date"
    },
    period: { value: "", column: ["period.name"], sortBy: "period.name" },
    from: { value: "", column: ["from.name"], sortBy: "from.name" },
    to: { value: "", column: ["to.name"], sortBy: "to.name" },
    created_by_user: {
      value: "",
      column: ["created_by_user.name"],
      sortBy: "created_by_user.last_name"
    },
    comments: {
      value: "",
      column: ["latest_comment.comment"],
      sortBy: "created_date"
    },
    latest_comment: {
      value: "",
      column: ["latest_comment.comment"],
      sortBy: "latest_comment.comment"
    },
    approved_at: {
      value: "",
      column: ["approved_at"],
      sortBy: "elastic_advanced_fields.approve_time_only"
    },
    completed_at: {
      value: "",
      column: ["completed_at"],
      sortBy: "elastic_advanced_fields.completed_time_only"
    },
    approved_by: {
      value: "",
      column: ["child.approved_at"],
      sortBy: "elastic_advanced_fields.approve_child_time_only"
    },
    completed_by: {
      value: "",
      column: ["child.completed_at"],
      sortBy: "elastic_advanced_fields.completed_child_time_only"
    },
    total_time: { value: "", column: ["total_time"], sortBy: "total_time" },
    type: { value: "", column: ["type"], sortBy: "type" },
    badge_flags: { value: "", column: ["status"], sortBy: "status" }
  },
  dataTableFields: [
    {
      key: "user",
      label: "Student",
      width: 300
    },
    {
      key: "student_sis_id",
      label: "Student number",
      width: 150
    },
    {
      key: "badge_flags",
      label: "Status",
      width: 100
    },
    {
      key: "approved_at",
      label: "Out time",
      width: 120
    },
    {
      key: "completed_at",
      label: "In time",
      width: 220
    },
    {
      key: "approved_by",
      label: "Out time",
      width: 120
    },
    {
      key: "completed_by",
      label: "In time",
      width: 120
    },
    { key: "total_time", label: "Total", width: 100 },
    { key: "type", label: "Type", width: 100 },
    {
      key: "comments",
      label: "Status/Comments",
      width: 200
    }
  ]
}
export default tableConfig
