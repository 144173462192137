<template>
  <div>
    <CContainer>
      <CRow class="justify-content-center mt-5">
        <CCol md="6" class="text-center mt-5">
          <div class="text-center mb-4">
            <img
              src="@/assets/images/securly-transperant-logo.png"
              alt="securlypass-logo"
              class="small-logo"
            />
          </div>
          <h1 id="not-found-404" class="v3base-blue mt-5">
            <b>404</b>
          </h1>
          <h2 class="mb-5">Page not found..</h2>
          <router-link class="v3base-blue" to="/">
            <b>Go back to home</b>
          </router-link>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
export default {
  name: "NotFound"
}
</script>

<style>
#not-found-404 {
  font-size: 120px;
}
</style>
