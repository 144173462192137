<template>
  <div>
    <PassBlockingTable />
  </div>
</template>
<script>
import PassBlockingTable from "@/v3components/Datatables/PassBlockingTable.vue"

export default {
  name: "PassBlocking",
  components: {
    PassBlockingTable
  }
}
</script>
