<template>
  <div
    class="d-flex flex-column gap-2"
    :class="{
      'visitor-loading': isLoading || isStatusLoading
    }"
  >
    <div class="d-flex justify-content-between align-content-center gap-2">
      <!-- SETTINGS HEADER -->
      <div
        class="d-flex justify-content-between align-content-center flex-wrap gap-4"
      >
        <h4>
          {{ currentAlert.heading }}
        </h4>
        <HelpCenterButton
          v-if="hasContentKeyLink"
          :content-key="currentAlert.contentKey"
        />
      </div>
      <!-- SETTINGS HEADER -->

      <!-- SETTING TOGGLE ACTION -->
      <div>
        <CustomSwitch
          type="radio"
          :model-value="isAlertSettingActive"
          @change="updateAlertSettingStatus"
        />
      </div>
      <!-- SETTING TOGGLE ACTION -->
    </div>

    <!-- FORM -->
    <form
      v-if="isAlertSettingActive"
      class="d-flex flex-column gap-2"
      @submit.prevent="onSave"
    >
      <!-- SERVER ERROR -->
      <VisitorErrorHandler
        v-if="serverError != null"
        :error="serverError"
        @done="serverError = null"
      />
      <!-- SERVER ERROR -->

      <!-- ALERT SUBJECT LINE -->
      <div class="d-flex flex-column gap-1">
        <Label required>{{ currentAlert.subjectInputLabel }}</Label>
        <InputField
          id="email-subject"
          placeholder="Subject"
          v-model="form.subject"
          :invalid-feedback="errors.subject"
        />
      </div>
      <!-- ALERT SUBJECT LINE -->

      <!-- ALERT CONTENT -->
      <div class="d-flex flex-column gap-1">
        <Label required>{{ currentAlert.contentInputLabel }}</Label>
        <StrictInputField
          id="email-content"
          placeholder="Content"
          v-model="form.message"
          max-length="160"
          :invalid-feedback="errors.message"
        />
        <Label>
          {{ contentHelperText }}
        </Label>
      </div>
      <!-- ALERT CONTENT -->

      <!-- SEND TO SECURITY STAFF -->
      <div class="d-flex flex-column gap-1">
        <Label>Send to List 1 (Security Staff)</Label>
        <CustomCheck
          type="checkbox"
          :model-value="form.securityStaff"
          @change="form.securityStaff = !form.securityStaff"
        />
      </div>
      <!-- SEND TO SECURITY STAFF -->

      <!-- SEND TO EMERGENCY CONTACTS -->
      <div class="d-flex flex-column gap-1">
        <Label>Send to List 2 (Emergency Contacts)</Label>
        <CustomCheck
          type="checkbox"
          :model-value="form.emergencyContacts"
          @change="form.emergencyContacts = !form.emergencyContacts"
        />
      </div>
      <!-- SEND TO EMERGENCY CONTACTS -->

      <!-- SUBMIT BUTTON -->
      <div class="d-flex mt-4 justify-content-start align-content-center">
        <LoadingButton :is-loading="isLoading" rounded solid>
          Submit
        </LoadingButton>
      </div>
      <!-- SUBMIT BUTTON -->
    </form>
    <!-- FORM -->
  </div>
</template>

<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import HelpCenterButton from "@/v3components/HelpCenterButton.vue"
import CustomSwitch from "@/v3components/shared/Form/CustomSwitch.vue"
import CustomCheck from "@/v3components/shared/Form/CustomCheck.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"
import StrictInputField from "@/v3components/shared/Form/StrictInputField.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import Label from "@/v3components/shared/Form/Label.vue"
import visitorConstants from "@/constants/visitorConstants"
import passHelpers from "@/helpers/index"
import { computed, onMounted, reactive, ref } from "vue"
import { cloneDeep } from "lodash"
import { useStore } from "vuex"
import useVuelidate from "@vuelidate/core"
import { helpers, required, maxLength } from "@vuelidate/validators"

const staticData = {
  emergency: {
    settingId: visitorConstants.SETTINGS.EMERGENCY,
    alertType: visitorConstants.ALERTS.EMERGENCY,
    heading: visitorConstants.SECURITY_SETTINGS.EMERGENCY.HEADING,
    contentKey: visitorConstants.SECURITY_SETTINGS.EMERGENCY.CONTENT_KEY,
    description: visitorConstants.SECURITY_SETTINGS.EMERGENCY.DESCRIPTION,
    subjectInputLabel:
      visitorConstants.SECURITY_SETTINGS.EMERGENCY.SUBJECT_INPUT_LABEL,
    contentInputLabel:
      visitorConstants.SECURITY_SETTINGS.EMERGENCY.CONTENT_INPUT_LABEL
  },
  "sex-offender": {
    settingId: visitorConstants.SETTINGS.SEX_OFFENDER,
    alertType: visitorConstants.ALERTS.SEX_OFFENDER,
    heading: visitorConstants.SECURITY_SETTINGS.SEX_OFFENDER.HEADING,
    contentKey: visitorConstants.SECURITY_SETTINGS.SEX_OFFENDER.CONTENT_KEY,
    description: visitorConstants.SECURITY_SETTINGS.SEX_OFFENDER.DESCRIPTION,
    subjectInputLabel:
      visitorConstants.SECURITY_SETTINGS.SEX_OFFENDER.SUBJECT_INPUT_LABEL,
    contentInputLabel:
      visitorConstants.SECURITY_SETTINGS.SEX_OFFENDER.CONTENT_INPUT_LABEL
  },
  watchlist: {
    settingId: visitorConstants.SETTINGS.WATCHLIST,
    alertType: visitorConstants.ALERTS.WATCHLIST,
    heading: visitorConstants.SECURITY_SETTINGS.WATCHLIST.HEADING,
    contentKey: visitorConstants.SECURITY_SETTINGS.WATCHLIST.CONTENT_KEY,
    description: visitorConstants.SECURITY_SETTINGS.WATCHLIST.DESCRIPTION,
    subjectInputLabel:
      visitorConstants.SECURITY_SETTINGS.WATCHLIST.SUBJECT_INPUT_LABEL,
    contentInputLabel:
      visitorConstants.SECURITY_SETTINGS.WATCHLIST.CONTENT_INPUT_LABEL
  }
}

export default {
  name: "VisitorAlertCard",
  components: {
    CustomSwitch,
    InputField,
    StrictInputField,
    Label,
    CustomCheck,
    LoadingButton,
    HelpCenterButton,
    VisitorErrorHandler
  },
  props: {
    type: {
      type: String,
      default: "emergency",
      validator: (value) => {
        return Object.keys(staticData).includes(value)
      }
    }
  },
  setup(props) {
    const store = useStore()
    const isLoading = ref(false)
    const isStatusLoading = ref(false)
    const serverError = ref(null)

    const form = reactive({
      subject: "",
      message: "",
      securityStaff: false,
      emergencyContacts: false
    })

    const rules = {
      subject: {
        required: helpers.withMessage(
          visitorConstants.VALIDATIONS.REQUIRED,
          required
        ),
        alphaNumSpaceSpecialCharacterValidator: helpers.withMessage(
          visitorConstants.VALIDATIONS.ALPHA_NUM_SPACE_SPECIAL_CHARACTER,
          passHelpers.alphaNumSpaceSpecialCharacterValidator
        )
      },
      message: {
        required: helpers.withMessage(
          visitorConstants.VALIDATIONS.REQUIRED,
          required
        ),
        maxLength: helpers.withMessage(
          visitorConstants.VALIDATIONS.MAX_160,
          maxLength(160)
        ),
        alphaNumSpaceSpecialCharacterValidator: helpers.withMessage(
          visitorConstants.VALIDATIONS.ALPHA_NUM_SPACE_SPECIAL_CHARACTER,
          passHelpers.alphaNumSpaceSpecialCharacterValidator
        )
      }
    }

    const errors = computed(() => ({
      subject: v$?.value?.subject?.$error
        ? v$?.value?.subject?.$errors
            ?.map((error) => error?.$message)
            ?.join(", ") || ""
        : "",
      message: v$?.value?.message?.$error
        ? v$?.value?.message?.$errors
            ?.map((error) => error?.$message)
            ?.join(", ") || ""
        : ""
    }))

    const v$ = useVuelidate(rules, form)

    const currentAlert = computed(() => {
      return staticData[props.type]
    })

    const hasContentKeyLink = computed(
      () => currentAlert?.value?.contentKey?.trim()?.length > 0
    )

    const contentHelperText = computed(() => {
      return `${form.message.length} / 160`
    })

    const school = computed(() => store.getters["visitorSettings/school"])

    const visitorSchoolData = computed(() => {
      return school?.value?.data || null
    })

    const alertSetting = computed(() => {
      return visitorSchoolData.value?.building_settings?.find(
        (setting) => setting.settings_id === currentAlert.value.settingId
      )
    })

    const alert = computed(() => {
      return visitorSchoolData.value?.building_alerts?.find(
        (setting) => setting.alert_type === currentAlert.value.alertType
      )
    })

    const isAlertSettingActive = computed(() => {
      return alertSetting.value?.value === "true"
    })

    const updateAlertSettingStatus = async () => {
      if (
        isLoading.value ||
        isStatusLoading.value ||
        !visitorSchoolData?.value?.building_settings
      ) {
        return
      }

      const data = cloneDeep(visitorSchoolData.value)
      data.building_settings = data.building_settings.map((setting) => {
        const newSettings = cloneDeep(setting)
        if (newSettings.settings_id === currentAlert.value.settingId) {
          newSettings.value = isAlertSettingActive.value ? "false" : "true"
        }
        return newSettings
      })
      try {
        isStatusLoading.value = true
        await store.dispatch("visitorSettings/updateSchoolSettings", {
          building_settings: data.building_settings
        })
        await store.dispatch("visitorSettings/getSchoolSettings")
      } catch (error) {
        serverError.value = error
      } finally {
        isStatusLoading.value = false
      }
    }

    const onSave = async () => {
      if (isLoading.value || isStatusLoading.value) return

      const isFormCorrect = await v$.value.$validate()
      if (!isFormCorrect) return

      const data = cloneDeep(visitorSchoolData.value)
      data.building_alerts = data.building_alerts.map((alert) => {
        const newAlert = cloneDeep(alert)
        if (newAlert.alert_type === currentAlert.value.alertType) {
          newAlert.subject = form?.subject || ""
          newAlert.message = form?.message || ""
          newAlert.security_staff = form?.securityStaff ? 1 : 0
          newAlert.emergency_contacts = form?.emergencyContacts ? 1 : 0
        }
        return newAlert
      })
      try {
        isLoading.value = true
        await store.dispatch("visitorSettings/updateSchoolSettings", {
          building_alerts: data.building_alerts?.filter(
            (alert) => alert.alert_type === currentAlert.value.alertType
          )
        })
        await store.dispatch("visitorSettings/getSchoolSettings")
      } catch (error) {
        serverError.value = error
      } finally {
        isLoading.value = false
      }
    }

    onMounted(() => {
      form.message = alert?.value?.message || ""
      form.subject = alert?.value?.subject || ""
      form.securityStaff = alert?.value?.security_staff === 1 || false
      form.emergencyContacts = alert?.value?.emergency_contacts === 1 || false
    })

    return {
      v$,
      serverError,
      errors,
      hasContentKeyLink,
      isLoading,
      isStatusLoading,
      currentAlert,
      isAlertSettingActive,
      form,
      contentHelperText,
      updateAlertSettingStatus,
      onSave
    }
  }
}
</script>

<style scoped></style>
