<template>
  <div class="mt-4">
    <!-- SERVER ERROR -->
    <VisitorErrorHandler
      v-if="serverError != null"
      :error="serverError"
      @done="serverError = null"
    />
    <!-- SERVER ERROR -->
  </div>
</template>

<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import moment from "moment-timezone"
import visitorConstants from "@/constants/visitorConstants"
import { onMounted, inject, reactive, ref, defineAsyncComponent } from "vue"
import { useStore } from "vuex"

const VisitorSignedInVisitorTablePopUp = defineAsyncComponent(
  () =>
    import(
      "@/v3components/Datatables/Visitor/CheckIns/VisitorSignedInVisitorTablePopUp.vue"
    )
)

export default {
  name: "VisitorSignedInVisitors",
  components: {
    VisitorErrorHandler
  },
  setup() {
    const store = useStore()
    const modal = inject("modal")
    const isLoading = ref(false)
    const serverError = ref(null)
    const state = reactive({
      data: [],
      filter: {
        firstName: "",
        lastName: "",
        startDate: moment().subtract(6, "days").format("MM/DD/YYYY"),
        endDate: moment().format("MM/DD/YYYY"),
        dateOfBirth: null,
        status: "Signed in",
        reasonForVisit: null,
        reasonForVisitText: null,
        destinationId: 0,
        destination: "All destinations",
        pagination: {
          activePage: 1,
          total: 0,
          pages: 0,
          per_page: { label: "25 / Page", value: 25 }
        }
      }
    })

    const getShowModal = () => {
      const keyExists = sessionStorage.getItem(
        visitorConstants.VISITOR_LOCAL_STORAGE_KEYS
          .SHOW_VISITOR_SIGNED_IN_LIST_MODAL
      )
      if (!keyExists) {
        setShowModal(true)
      }
      return keyExists
        ? JSON.parse(
            sessionStorage.getItem(
              visitorConstants.VISITOR_LOCAL_STORAGE_KEYS
                .SHOW_VISITOR_SIGNED_IN_LIST_MODAL
            )
          )
        : true
    }

    const setShowModal = (value) => {
      sessionStorage.setItem(
        visitorConstants.VISITOR_LOCAL_STORAGE_KEYS
          .SHOW_VISITOR_SIGNED_IN_LIST_MODAL,
        JSON.stringify(value)
      )
    }

    const setPage = async (option) => {
      if (!option) return

      state.filter.pagination.per_page = {
        label: option.label,
        value: Number(option.value)
      }
      state.filter.pagination.activePage = 1
      await getSignedInVisitorList()
    }

    const setActivePage = async (page) => {
      if (!page) return

      state.filter.pagination.activePage = Number(page)
      await getSignedInVisitorList()
    }

    const getSignedInVisitorList = async () => {
      const page = Number(state?.filter?.pagination?.activePage || 1)
      const perPage = Number(state?.filter?.pagination?.per_page?.value || 25)
      const payload = {
        startDate: state.filter.startDate,
        endDate: state.filter.endDate,
        page,
        perPage
      }
      const { totalCount, result } = await store.dispatch(
        "visitorManage/getSignedInVisitorList",
        payload
      )
      state.data = result
      state.filter.pagination.total = totalCount
    }

    const showSignedInVisitorListModal = () => {
      const showVisitorSignedInListModal = getShowModal()
      if (
        showVisitorSignedInListModal &&
        Number(state.filter.pagination.total) > 0
      ) {
        modal.open(VisitorSignedInVisitorTablePopUp, {
          size: "lg",
          hideCloseButton: true,
          title:
            visitorConstants.CHECK_IN_FORM.MODAL_TITLE_VISITORS_STILL_SIGNED_IN,
          props: {
            isLoading,
            state,
            setPage,
            setActivePage,
            closePopUp: () => {
              setShowModal(false)
            }
          }
        })
      }
    }

    onMounted(async () => {
      try {
        isLoading.value = true
        await getSignedInVisitorList()
      } catch (error) {
        serverError.value = error
      } finally {
        isLoading.value = false
      }
      showSignedInVisitorListModal()
    })

    return {
      state,
      isLoading,
      serverError
    }
  }
}
</script>

<style scoped></style>
